import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Input, Button, Alert, Loader } from "@mantine/core";

export default function Recupera({ setMostraNavbar }) {
  const email = useRef("");
  const [stateInviata, setStateInviate] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const buttonRef = useRef("");

  useEffect(() => {
    setMostraNavbar(true);
  }, []);
  function handleSkip(e) {
    const { name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");
    let fieldIntIndex = parseInt(fieldIndex, 10);
    if (e.key === "Enter") {
      const nextfield = document.querySelector(
        `input[name=field-${fieldIntIndex + 1}]`
      );
      // If found, focus the next field
      if (nextfield == null) {
        buttonRef.current.click();
      }
    }
  }
  async function sendRecuperaPass() {
    setIsLoading(true);
    if (email.current.value == "") return;
    const url = "https://apot.mondorevive.com/auth/forgotPassword";
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        username: email.current.value,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status == 200) {
      setStateInviate(true);
      setError("");
      setIsLoading(false);
    } else {
      setError("E-Mail Errata");
      setIsLoading(false);
    }
  }
  return (
    <div className="log-container">
      <div className="log-box2">
        <div className="register-box min">
          <div className="register-title login">
            <h1>Recupera Password</h1>
          </div>

          {error != "" && (
            <Alert title="Errore!" color="red" style={{ marginBottom: "10px" }}>
              {error}
            </Alert>
          )}
          {stateInviata ? (
            <p>
              Email inviata correttamente, Controlla la tua casella di posta per
              modificare la mail
            </p>
          ) : (
            <>
              <Input
                ref={email}
                placeholder="E-mail (Username)"
                radius="lg"
                name="field-1"
                onKeyUp={handleSkip}
                size="xl"
                style={{ marginBottom: "30px" }}
              />

              <Button
                radius="md"
                ref={buttonRef}
                size="lg"
                style={{ marginTop: "15px" }}
                onClick={sendRecuperaPass}
              >
                {isLoading ? (
                  <Loader color="red" variant="bars" />
                ) : (
                  "Invia E-Mail"
                )}
              </Button>
            </>
          )}
          <div className="inline-item">
            <Link to="/login">
              <p className="account-ex">Accedi</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
