import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Form from "./components/Form";
import Login from "./components/Login";
import Registrazione from "./components/Registrazione";
import ScrollToTop from "./components/ScrollToTop";
import Navbar from "./components/Navbar";
import Recupera from "./components/Recupera";
import PageResetta from "./components/PageResetta";
import AdminDashboard from "./components/AdminDashboard";
import VerificaAppalto from "./components/VerificaAppalto";
import Footer from "./components/Footer";
import PrivateRoute from "./components/PrivateRoute";
import { validateToken } from "./components/Auth";
import { useEffect, useState } from "react";
import UpdateForm from "./components/UpdateForm";
import UserDashboard from "./components/UserDashboard";
import Cookies from "js-cookie";
import VerificheInCampo from "./components/Aggiuntivi/VerificheInCampo";
import { setPossoModificare } from "./components/Utilities";
function App() {
  const [status, setStatus] = useState(Cookies.get("status") || null);
  const [loading, setLoading] = useState(true);
  useEffect(async () => {
    validateToken().then((res) => {
      if (res) ottieniPossoModificare();
      else setLoading(false);
    });
  }, []);
  //Funzione che controlla se posso modificare
  const ottieniPossoModificare = async () => {
    const url = "https://apot.mondorevive.com/api/modificaAbilitata";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    });
    if (response.status == 200) {
      const data = await response.text();
      if (data == "true") {
        setPossoModificare(true);
      } else {
        setPossoModificare(false);
      }
      setLoading(false);
    } else {
      setPossoModificare(false);
    }
  };
  const handleStatusLogout = () => {
    setStatus(null);
    setMostraNavbar(true);
  };
  const [mostraNavbar, setMostraNavbar] = useState(true);
  return (
    <div className="App">
      {loading ? (
        <></>
      ) : (
        <Router>
          <ScrollToTop>
            {mostraNavbar && (
              <header>
                <Navbar status={status} setStatus={setStatus} />
              </header>
            )}
            <Routes>
              <Route exact path="/" element={<Login />} />
              <Route
                exact
                path="/form"
                element={
                  <PrivateRoute>
                    <Form setMostraNavbar={setMostraNavbar} />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/update"
                element={
                  <PrivateRoute>
                    <UpdateForm setMostraNavbar={setMostraNavbar} />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/form/:id"
                element={
                  <PrivateRoute>
                    <VerificaAppalto setMostraNavbar={setMostraNavbar} />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/verifiche"
                element={
                  <PrivateRoute>
                    <VerificheInCampo setMostraNavbar={setMostraNavbar} />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/login"
                element={<Login setMostraNavbar={setMostraNavbar} />}
              />
              <Route
                exact
                path="/registrati"
                element={<Registrazione setMostraNavbar={setMostraNavbar} />}
              />
              <Route
                exact
                path="/recupera"
                element={<Recupera setMostraNavbar={setMostraNavbar} />}
              />
              <Route
                exact
                path="/recuperoPassword"
                element={<PageResetta setMostraNavbar={setMostraNavbar} />}
              />
              <Route
                exact
                path="/admin"
                element={
                  <PrivateRoute>
                    <AdminDashboard
                      setMostraNavbar={setMostraNavbar}
                      setStatusNavbar={handleStatusLogout}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/profilo"
                element={
                  <PrivateRoute>
                    <UserDashboard setMostraNavbar={setMostraNavbar} />
                  </PrivateRoute>
                }
              />
            </Routes>
          </ScrollToTop>
        </Router>
      )}
      {/* <Footer /> */}
    </div>
  );
}

export default App;
