import {
  faDownload,
  faEllipsisVertical,
  faPen,
  faPlus,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Menu, Modal, Select, Table, Textarea } from "@mantine/core";
import { color } from "@mui/system";
import Cookies from "js-cookie";
import React, { useCallback, useRef } from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, Flip, toast } from "react-toastify";
import { errorNotification, successNotification } from "../Notification";
import { fromAmericaToItalia } from "../Utilities";
import FileButton from "./FileButton";
export default function VerificheInCampo({ id }) {
  const location = useLocation();
  // const stabilimenti = [
  // 	{ value: "BUSTO ARSIZIO", label: "Mondorevive Busto Arsizio" },
  // 	{ value: "Mondorevive Ferentino", label: "Mondorevive Ferentino" },
  // 	{ value: "Caltek Chignolo Po", label: "Caltek Chignolo Po" },
  // 	{ value: "MondoSD Bernate Ticino", label: "MondoSD Bernate Ticino" },
  // 	{ value: "Mondorevive Marnate", label: "Mondorevive Marnate" },
  // ];
  const [allVerifiche, setAllverifiche] = useState([]);
  const [openModalAggiunta, setOpenModalAggiunta] = useState(false);
  const [openModalModifica, setOpenModalModifica] = useState(false);
  const [nuovaVerifica, setNuovaVerifica] = useState({
    note: "aaa",
    idStabilimento: "",
  });
  const [fileDaAggiungere, setFileDaAggiungere] = useState([]);
  const [modificaVerifica, setModificaVerifica] = useState({});
  const [stabilimenti, setStabilimenti] = useState([]);
  const idAppaltatore = useRef(46);
  const navigate = useNavigate();
  const idFile = useRef(-1);
  useEffect(() => {
    if (location.state && location.state.id) {
      getDati(location.state.id);
      idAppaltatore.current = location.state.id;
      getStabilimenti();
    } else {
      navigate("/admin");
    }
    // inserisciVerifica();
  }, []);
  //Funzione che ottiene tutte le verifiche
  const getDati = async (id) => {
    const url =
      "https://apot.mondorevive.com/api/verificheInCampo?idAppaltatore=" + id;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    });
    if (response.status == 200) {
      const data = await response.json();
      setAllverifiche(data);
    } else {
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        Cookies.remove("token");
        Cookies.remove("status");
        navigate("/login");
      }
    }
  };
  //Funzione che ottiene tutte le verifiche
  const getStabilimenti = async () => {
    const url = "https://apot.mondorevive.com/api/allStabilimenti";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    });
    if (response.status == 200) {
      const data = await response.json();
      let arr = [];
      data.map((stabilimento) => {
        arr.push({
          ...stabilimento,
          label: stabilimento.nome,
          value: stabilimento.id,
        });
      });
      setStabilimenti(arr);
    } else {
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        Cookies.remove("token");
        Cookies.remove("status");
        navigate("/login");
      }
    }
  };
  //Funzione che inserisce una nuova verifica
  const inserisciVerifica = async () => {
    const url = "https://apot.mondorevive.com/api/verificaInCampo";
    let formData = new FormData();
    fileDaAggiungere.map((file) => {
      formData.append("files", file.file);
    });
    formData.append(
      "json",
      new Blob(
        [
          JSON.stringify({
            ...nuovaVerifica,
            appaltatoreId: idAppaltatore.current,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );
    const response = await fetch(url, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    });
    if (response.status == 200) {
      successNotification("Verifica creata correttamente");
      getDati(idAppaltatore.current);
      resetAggiunta();
    } else {
      const data = response.text();
      errorNotification(
        "Errore nella creazione della Verifica, riprova più tardi!"
      );
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        Cookies.remove("token");
        Cookies.remove("status");
        navigate("/login");
      }
    }
  };
  //Funzione che inserisce una nuova verifica
  const updateVerifica = async () => {
    const url = "https://apot.mondorevive.com/api/updateVerificaInCampo";
    let formData = new FormData();
    modificaVerifica.allegatiList.map((file) => {
      if (file.id < 0) formData.append("files", file.file);
    });
    formData.append(
      "json",
      new Blob(
        [
          JSON.stringify({
            idVerifica: modificaVerifica.idVerifica,
            note: modificaVerifica.note,
            appaltatoreId: idAppaltatore.current,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );
    const response = await fetch(url, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    });
    if (response.status == 200) {
      successNotification("Verifica modificata correttamente");
      getDati(idAppaltatore.current);
      setOpenModalModifica(false);
    } else {
      const data = response.text();
      errorNotification(
        "Errore nella modifica della Verifica, riprova più tardi!"
      );
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        Cookies.remove("token");
        Cookies.remove("status");
        navigate("/login");
      }
    }
  };
  //Funzione che ottiene un allegato della verifica dal db
  const getFile = async (id) => {
    if (id < 0) return;
    const url =
      "https://apot.mondorevive.com/api/downloadAllegatoVerifica?idAllegato=" +
      id;
    const response = await toast.promise(
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
        responseType: "blob",
      }),
      {
        pending: "Download in corso",
        success: {
          render() {
            return `File scaricato correttamente`;
          },
          autoClose: 1000,
        },
        error: {
          render() {
            return `Errore nel download del file`;
          },
          autoClose: 1000,
        },
      }
    );
    if (response.status == 200) {
      const data = await response.arrayBuffer();
      const filename = response.headers
        .get("content-disposition")
        .split("filename=")[1];
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename.substring(1, filename.length - 1));
      document.body.appendChild(link);
      link.click();
    } else {
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        Cookies.remove("token");
        Cookies.remove("status");
        navigate("/login");
      }
    }
  };
  //Funzione che inserisce una nuova verifica
  const deleteVerifica = async (id) => {
    const url =
      "https://apot.mondorevive.com/api/verificaInCampo?idVerifica=" + id;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    });
    if (response.status == 200) {
      getDati(idAppaltatore.current);
      successNotification("Verifica eliminata correttamente");
    } else {
      errorNotification(
        "Errore nella eliminazione della Verifica, riprova più tardi!"
      );
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        Cookies.remove("token");
        Cookies.remove("status");
        navigate("/login");
      }
    }
  };

  //Funzione che elimina un file appena aggiunto
  const deleteAllegatoAppenaAggiunto = useCallback((id) => {
    setModificaVerifica((modificaVerifica) => {
      let arr = [];
      modificaVerifica.allegatiList.map((allegato) => {
        if (allegato.id != id) arr.push(allegato);
      });
      return { ...modificaVerifica, allegatiList: arr };
    });
  }, []);
  //Funzione che inserisce una nuova verifica
  const deleteAllegato = async (id) => {
    if (id < 0) {
      deleteAllegatoAppenaAggiunto(id);
      return;
    }
    const url =
      "https://apot.mondorevive.com/api/allegatoVerificaInCampo?idAllegato=" +
      id;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    });
    if (response.status == 200) {
      successNotification("Allegato eliminato correttamente");
      getDati(idAppaltatore.current);
    } else {
      const data = response.json();
      errorNotification(
        "Errore nella eliminazione dell`allegato, riprova più tardi!"
      );
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        Cookies.remove("token");
        Cookies.remove("status");
        navigate("/login");
      }
    }
  };
  //Funzione che gestisce il click per aggiungere un file
  const handleClickOpenFile = (label) => {
    document.getElementById(label).click();
  };
  //Funzione che rimuove un file appena aggiunto
  const rimuoviFileAppenaAggiunto = (id) => {
    setFileDaAggiungere((fileDaAggiungere) => {
      let arr = [];
      fileDaAggiungere.map((file) => {
        if (file.id != id) arr.push(file);
      });
      return arr;
    });
  };
  let debounceTimer;
  const resetAggiunta = () => {
    setNuovaVerifica({});
    setOpenModalAggiunta(false);
    setFileDaAggiungere([]);
  };
  const debounce = useCallback((callback) => {
    window.clearTimeout(debounceTimer);
    debounceTimer = window.setTimeout(callback, 250);
  }, []);
  return (
    <div>
      <ToastContainer
        transition={Flip}
        newestOnTop
        rtl={false}
        pauseOnFocusLoss
      />

      <div className="form-container">
        <div className="container_header mt20 mb50">
          <h2>Verifiche in Campo</h2>
          <div className="container_button_indietro_verifica">
            <Button onClick={() => navigate(-1)}>Indietro</Button>
            <Button
              leftIcon={<FontAwesomeIcon icon={faPlus} color="white" />}
              color={"green"}
              onClick={() => setOpenModalAggiunta(true)}
            >
              Nuovo
            </Button>
          </div>
        </div>
        <Table striped style={{ marginBottom: "50px" }} captionSide="bottom">
          {allVerifiche.length == 0 && (
            <caption>Nessun elemento da visualizzare</caption>
          )}
          <thead>
            <tr>
              <th></th>
              <th>Note</th>
              <th>Stabilimento</th>
              <th>Data</th>
              <th>File</th>
              <th>Username</th>
              <th>Azioni</th>
            </tr>
          </thead>
          <tbody>
            {allVerifiche.map((verifica, count) => (
              <tr key={verifica.id}>
                <td>Rev - {allVerifiche.length - count}</td>
                <td>{verifica.note}</td>
                <td>{verifica.nomeStabilimento}</td>
                <td>{fromAmericaToItalia(verifica.timestampCreazione)}</td>
                <td>
                  <div className="container_button_file_tabella">
                    {verifica.allegatiList.map((allegato) => (
                      <div key={allegato.id}>
                        <FileButton
                          nome={allegato.filename}
                          onClick={() => {
                            getFile(allegato.id);
                          }}
                          elimina={() => {
                            deleteAllegato(allegato.id);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </td>
                <td>{verifica.usernameCreatore}</td>
                <td>
                  <Menu position="bottom-end">
                    <Menu.Target>
                      <div>
                        <FontAwesomeIcon
                          icon={faEllipsisVertical}
                          size={"lg"}
                          className={"pointer"}
                        />
                      </div>
                    </Menu.Target>

                    <Menu.Dropdown>
                      <Menu.Item
                        icon={<FontAwesomeIcon icon={faPen} color="green" />}
                        onClick={() => {
                          setModificaVerifica(verifica);
                          setOpenModalModifica(true);
                        }}
                      >
                        Modifica
                      </Menu.Item>

                      <Menu.Item
                        icon={<FontAwesomeIcon icon={faTimes} color={"red"} />}
                        onClick={() => deleteVerifica(verifica.idVerifica)}
                      >
                        Elimina
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Modal
        opened={openModalAggiunta}
        size={"lg"}
        onClose={() => {
          resetAggiunta();
        }}
        centered
        title={<h2>Nuova Verifica</h2>}
      >
        <div>
          <div className="container_valori_verifica">
            <Textarea
              placeholder="Note"
              label={"Note"}
              onChange={(e) => {
                debounce(() => {
                  setNuovaVerifica({ ...nuovaVerifica, note: e.target.value });
                });
              }}
            />
            <Select
              data={stabilimenti}
              placeholder={"Stabilimento"}
              className={"mt20"}
              label={"Stabilimento"}
              onChange={(e) => {
                setNuovaVerifica({ ...nuovaVerifica, idStabilimento: e });
              }}
            />
            <input
              type="file"
              id="file"
              style={{ display: "none" }}
              onChange={(e) => {
                if (e.target.value == "") return;
                setFileDaAggiungere([
                  ...fileDaAggiungere,
                  {
                    file: e.target.files[0],
                    filename: e.target.files[0].name,
                    id: idFile.current,
                  },
                ]);
                idFile.current = --idFile.current;
              }}
            />
            <div className="container_file_selezionati mt20">
              {fileDaAggiungere.map((file) => (
                <div key={file.id}>
                  <FileButton
                    nome={file.filename}
                    onClick={() => {}}
                    elimina={() => {
                      rimuoviFileAppenaAggiunto(file.id);
                    }}
                  />
                </div>
              ))}
              <Button onClick={() => handleClickOpenFile("file")}>
                Aggiungi File
              </Button>
            </div>
          </div>
          <div className="mt30 buttonConfermaContainer">
            <Button
              onClick={() => {
                resetAggiunta();
              }}
              color={"orange"}
              fullWidth
            >
              Annulla
            </Button>
            <Button
              onClick={() => {
                inserisciVerifica();
              }}
              disabled={
                nuovaVerifica.note === "" || nuovaVerifica.idStabilimento === ""
              }
              color={"green"}
              fullWidth
            >
              Conferma
            </Button>
          </div>
        </div>
      </Modal>
      {openModalModifica && (
        <Modal
          opened={openModalModifica}
          size={"lg"}
          onClose={() => {
            setOpenModalModifica(false);
          }}
          centered
          title={<h2>Modifica Verifica</h2>}
        >
          <div>
            <div className="container_valori_verifica">
              <Textarea
                placeholder="Note"
                value={modificaVerifica.note}
                label={"Note"}
                onChange={(e) => {
                  setModificaVerifica({
                    ...modificaVerifica,
                    note: e.target.value,
                  });
                }}
              />
              <Select
                data={stabilimenti}
                value={modificaVerifica.idStabilimento}
                readOnly
                disabled
                placeholder={"Stabilimento"}
                className={"mt20"}
                label={"Stabilimento"}
                onChange={(e) => {
                  setModificaVerifica({ ...modificaVerifica, stabilimento: e });
                }}
              />
              <input
                type="file"
                id="file"
                style={{ display: "none" }}
                onChange={(e) => {
                  if (e.target.value == "") return;
                  setModificaVerifica({
                    ...modificaVerifica,
                    allegatiList: [
                      ...modificaVerifica.allegatiList,
                      {
                        id: idFile.current,
                        file: e.target.files[0],
                        filename: e.target.files[0].name,
                      },
                    ],
                  });
                  idFile.current = --idFile.current;
                }}
              />
              <div className="container_file_selezionati mt20">
                {modificaVerifica.allegatiList.map((file, count) => (
                  <div className="container_file_selezionati" key={count}>
                    {file.id < 0 ? (
                      <FileButton
                        nome={file.filename}
                        onClick={() => {
                          getFile(file.id);
                        }}
                        elimina={() => {
                          deleteAllegato(file.id);
                        }}
                      />
                    ) : (
                      <Button
                        color={"grape"}
                        onClick={() => {
                          getFile(file.id);
                        }}
                      >
                        {file.filename}
                      </Button>
                    )}
                  </div>
                ))}
                <Button onClick={() => handleClickOpenFile("file")}>
                  Aggiungi File
                </Button>
              </div>
            </div>
            <div className="mt30 buttonConfermaContainer">
              <Button
                onClick={() => {
                  setOpenModalModifica(false);
                }}
                color={"orange"}
                fullWidth
              >
                Annulla
              </Button>
              <Button
                onClick={() => {
                  updateVerifica();
                }}
                color={"green"}
                fullWidth
              >
                Conferma
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
