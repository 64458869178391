import React from "react";

export default function CampiMancanti({ campiMancanti }) {
	return (
		<div className="container_campi_mancanti mt20">
			<h2>Campi mancanti:</h2>
			<ul>
				{campiMancanti.map((errore) => (
					<li>{errore}</li>
				))}
			</ul>
		</div>
	);
}
