import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  Container,
  Button,
  Badge,
  TextInput,
  Textarea,
  Tabs,
  Modal,
  Checkbox,
} from "@mantine/core";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faPlus,
  faIndustry,
  faTimes,
  faUsers,
  faTrash,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import { errorNotification, successNotification } from "../Notification";
import { useNavigate } from "react-router";
import Tabella from "./Tabella";
import { getPossoModificare } from "../Utilities";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};
const focusedStyle = {
  borderColor: "#2196f3",
};
const acceptStyle = {
  borderColor: "#00e676",
};
const rejectStyle = {
  borderColor: "#ff1744",
};
let modificaAbilitata = getPossoModificare();
export default function InvioEmail() {
  const navigate = useNavigate();
  const [testoMail, setTestoMail] = useState("");
  const [titoloMail, setTitoloMail] = useState("");
  const [mailInserite, setMailInserite] = useState({ generali: [] });
  const [files, setFiles] = useState([]);
  const [filesHTML, setFilesHTML] = useState();
  const appaltatori = useRef([]);
  const [mailInserita, setMailInserita] = useState("");
  const [disabledInvia, setDisabledInvia] = useState(true);
  const [openModalConferma, setOpenModalConferma] = useState(false);
  const [openModalAppaltatori, setOpenModalAppaltatori] = useState(false);
  const [stringaDaCercare, setStringaDaCercare] = useState("");
  const [loading, setLoading] = useState(false);
  const [rowHTML, setRowHTML] = useState();
  const [tabSelezionata, setTabSelezionata] = useState("");
  const mailSelezionate = useRef({});
  const [tuttiSelected, setTuttiSelected] = useState({});
  let validateMail = new RegExp(
    "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
  );
  useEffect(() => {
    getDati(0);

    modificaAbilitata = getPossoModificare();
  }, []);
  //Funzione che ottiene gli appaltatori dal db
  const getDati = async () => {
    let url = "https://apot.mondorevive.com/api/appaltatoriPerStabilimento";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    });
    if (response.status == 200) {
      const data = await response.json();
      let obj = {};
      let stab = "";
      data.map((stabilimento, count) => {
        if (count == 0) stab = stabilimento.nomeStabilimento;
        obj[stabilimento.nomeStabilimento] = stabilimento.appaltatori;
      });
      setTabella(obj, stab);
      setTabSelezionata(stab);
      appaltatori.current = obj;
    } else {
    }
  };
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    fileRejections,
  } = useDropzone({ maxFiles: 5, multiple: true });
  useEffect(() => {
    setFiles([...files, ...acceptedFiles]);
  }, [acceptedFiles]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  //Funzione che resetta i file
  const resetFile = () => {
    setFiles([]);
    setFilesHTML();
    setTitoloMail("");
    setTestoMail("");
    let obj = { generali: [] };
    mailSelezionate.current = {};
    setMailInserita("");
    setMailInserite(null);
    setTimeout(() => {
      setMailInserite(obj);
    }, [500]);
  };
  //Funzione che controlla se sono presenti delle mail non valide
  const checkMailNonValide = () => {
    let status = false;
    mailInserite.generali.map((mail) => {
      if (!mail.valid) status = true;
    });
    return status;
  };
  //Funzione che invia la mail
  const inviaEmail = async (force = false, mail = null) => {
    let url = "https://apot.mondorevive.com/api/invioMail";
    var formData = new FormData();
    if (!force && checkMailNonValide()) {
      setOpenModalConferma(true);
      return;
    }
    formData.append(
      "content",
      new Blob(
        [
          //manca rls list
          JSON.stringify({
            mails: mail ? getMail(mail) : getMail(mailInserite),
            titolo: titoloMail,
            text: testoMail,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );

    files.map((file) => {
      formData.append("allegati", file);
    });

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
      body: formData,
    });
    if (response.status == 200) {
      successNotification("E-Mail inviata con successo");
      resetFile();
    } else {
      errorNotification("Impossibile Inviare la E-Mail, riprova più tardi!");
      if (response.status == 401 || response.status == 403) {
        Cookies.remove("token");
        Cookies.remove("status");
        navigate("/login");
      }
    }
  };
  useEffect(() => {
    setFilesHTML(
      <div className="container_file_list mt10">
        {files.map((file, count) => (
          <div className="item_file" key={count}>
            {file.name +
              " - " +
              Number(Number(file.size) / 1024 / 1024).toFixed(2) +
              " MB"}{" "}
            <FontAwesomeIcon
              className="pointer"
              icon={faTimes}
              color={"red"}
              onClick={() => removeFile(file)}
            />
          </div>
        ))}
      </div>
    );
  }, [files]);
  //Funzione che rimuove un file inserito
  const removeFile = (filee) => {
    let arr = [];
    files.map((file) => {
      if (file.name != filee.name) arr.push(file);
    });
    setFiles(arr);
  };
  //Funzione che controlla se una mail e` gia inserita
  const checkMailPresente = (mail) => {
    for (let i = 0; i < mailInserite.length; i++) {
      if (mailInserite[i].mail.toLowerCase() == mail.toLowerCase()) return true;
    }

    return false;
  };
  //Funzione che aggiunge una mail
  const aggiungiMail = (mail) => {
    if (checkMailPresente(mail)) return;
    let validMail = validateMail.test(mail);
    setMailInserite({
      ...mailInserite,
      ["generali"]: [
        ...mailInserite["generali"],
        { mail: mail, valid: validMail },
      ],
    });
  };
  //Funzione che aggiunge un gruppo di mail
  const aggiungiGruppoMail = () => {
    let obj = mailInserite;
    Object.keys(mailSelezionate.current).map((key) => {
      obj[key] = { ...obj[key], ...mailSelezionate.current[key] };
    });
    setOpenModalAppaltatori(false);
    setMailInserite(obj);
  };
  //Funzione che rimuove una mail inserita
  const rimuoviMail = (mailDaRimuovere, key = null) => {
    if (!modificaAbilitata) return;
    setMailInserite((mailInserite) => {
      let obj = { ...mailInserite };
      if (key) {
        let objTmp = {};
        Object.keys(mailInserite[key]).map((mail) => {
          if (mail != mailDaRimuovere) objTmp[mail] = mail;
        });
        obj[key] = objTmp;
      } else {
        let arr = [];
        mailInserite.generali.map((mail) => {
          if (mail.mail != mailDaRimuovere.mail) arr.push(mail);
        });
        obj.generali = arr;
      }
      return obj;
    });
  };
  //Funzione che controlla se ci sono delle mail inserite
  const isEmpty = (data) => {
    if (!data) return;
    let status = true;
    Object.keys(data).map((key) => {
      if (key != "generali") {
        if (Object.keys(data[key]).length > 0) status = false;
      } else {
        status = data["generali"].length > 0 ? false : status;
      }
    });
    return status;
  };
  //Funzione che ottiene se il button per inviare la mail deve essere disabilitato o meno
  const getDisabledInvia = () => {
    if (!mailInserite) return;
    let disabledMail = isEmpty(mailInserite);
    Object.keys(mailInserite).map((key) => {
      if (key != "generali") {
        if (Object.keys(mailInserite[key]).length > 0) disabledMail = false;
      } else {
        disabledMail =
          mailInserite["generali"].length > 0 ? false : disabledMail;
      }
    });

    if (
      disabledMail ||
      titoloMail == "" ||
      titoloMail == undefined ||
      testoMail == "" ||
      testoMail == undefined
    )
      setDisabledInvia(true);
    else setDisabledInvia(false);
  };
  useEffect(() => {
    getDisabledInvia();
  }, [testoMail, mailInserite, titoloMail]);
  //Se premo invio e sono nel campo password "premo" su login
  function handleSkip(e) {
    const { name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");
    let fieldIntIndex = parseInt(fieldIndex, 10);
    if (e.key === "Enter") {
      const nextfield = document.querySelector(
        `input[name=field-${fieldIntIndex + 1}]`
      );
      // If found, focus the next field
      if (nextfield !== null) {
        nextfield.focus();
      } else {
        if (mailInserita != "" && mailInserita != undefined) {
          aggiungiMail(mailInserita);
          setMailInserita("");
        }
      }
    }
  }
  //Funzione che ottiene le mail
  const getMail = (listaMail) => {
    let arr = [];

    Object.keys(listaMail).map((stab) => {
      if (stab != "generali")
        Object.keys(listaMail[stab]).map((mail) => arr.push(mail));
      else {
        listaMail.generali.map((mail) => {
          arr.push(mail.mail);
        });
      }
    });
    return arr;
  };
  //Funzione che elimina le mail non valide
  const eliminaMailNonValide = () => {
    let arr = [];
    mailInserite.generali.map((mail) => {
      if (mail.valid) arr.push(mail);
    });
    setMailInserite({ ...mailInserite, generali: arr });
    if (!isEmpty({ ...mailInserite, generali: arr }))
      inviaEmail(false, { ...mailInserite, generali: arr });
    setOpenModalConferma(false);
  };
  //Funzione che gestisce le checkbox della tabella
  const handleCheckboxSelezionata = (check, stabilimento, mail) => {
    if (check) {
      mailSelezionate.current = {
        ...mailSelezionate.current,
        [stabilimento]: {
          ...mailSelezionate.current[stabilimento],
          [mail]: mail,
        },
      };
    } else {
      let obj = mailSelezionate.current;
      delete obj[stabilimento][mail];
      mailSelezionate.current = obj;
    }

    setTabella(appaltatori.current, stabilimento);
  };
  //Funzione che gestisce il click sulla checkbox totale
  const setAllSelezionati = (value, tab) => {
    let obj = {};
    if (value) {
      setTuttiSelected({ ...tuttiSelected, [tab]: true });
      appaltatori.current[tab].map((app) => {
        obj[app.mail] = app.mail;
      });
      mailSelezionate.current[tab] = obj;
    } else {
      setTuttiSelected({ ...tuttiSelected, [tab]: false });
      mailSelezionate.current[tab] = {};
    }
    setTabella(appaltatori.current, tab);
  };
  //Header tabella
  const ths = (
    <tr>
      <th>Pos</th>
      <th>
        <div className="container_checkbox">
          <Checkbox
            label={"Azioni"}
            checked={tuttiSelected[tabSelezionata] ? true : false}
            onChange={(e) => {
              setAllSelezionati(e.target.checked, tabSelezionata);
            }}
          />
        </div>
      </th>
      <th>E-Mail</th>
      <th>Nome</th>
    </tr>
  );
  //Funzione che imposta le righe di una tabella
  const setTabella = (data, key, text = "") => {
    setRowHTML(() => {
      let rowHTML =
        data[key] &&
        data[key].map((appaltatore, count) => (
          <>
            {(appaltatore.mail.toLowerCase().includes(text.toLowerCase()) ||
              appaltatore.nome.toLowerCase().includes(text.toLowerCase())) && (
              <tr key={count}>
                <td className="mb-0 text-sm">{count + 1}</td>
                <td>
                  <Checkbox
                    checked={
                      (mailSelezionate.current[key] &&
                        mailSelezionate.current[key][appaltatore.mail]) ||
                      (mailInserite[key] && mailInserite[key][appaltatore.mail])
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      handleCheckboxSelezionata(
                        e.target.checked,
                        key,
                        appaltatore.mail
                      );
                    }}
                  />
                </td>
                <td className="mb-0 text-sm">{appaltatore.mail}</td>
                <td className="mb-0 text-sm">{appaltatore.nome}</td>
              </tr>
            )}
          </>
        ));
      return rowHTML;
    });
  };
  //Funzione che gestisce la tabella selezionata
  const handleTabChange = (value) => {
    setTabSelezionata(value);
    setTabella(appaltatori.current, value);
  };
  //Funzione che ottiene le iniziali di una stringa
  const ottienIniziali = (string) => {
    let splitted = string.split(/ /g);
    let finale = "";
    splitted.map((string) => {
      finale += string.substring(0, 1);
    });
    return finale;
  };
  useEffect(() => {
    setTabella(appaltatori.current, tabSelezionata, stringaDaCercare);
  }, [stringaDaCercare]);
  return (
    <Container className="mt--7 containerMantine container_dashboard" fluid>
      <div className="container_header_mail">
        <h2 className="mb20 mt20 title_dashboard">E-Mail</h2>
        <Button
          onClick={() => inviaEmail()}
          disabled={disabledInvia}
          leftIcon={<FontAwesomeIcon icon={faPaperPlane} />}
        >
          Invia
        </Button>
      </div>

      <div className="container_invio_mail">
        <div className="container_mail_inserite mb10">
          {mailInserite &&
            Object.keys(mailInserite).map((key) => (
              <>
                {key != "generali" && (
                  <>
                    {Object.keys(mailInserite[key]).map((mail) => (
                      <Badge
                        color={"green"}
                        leftSection={ottienIniziali(key)}
                        rightSection={
                          <FontAwesomeIcon
                            onClick={() => rimuoviMail(mail, key)}
                            className="pointer"
                            icon={faTimes}
                            color={"red"}
                          />
                        }
                      >
                        {mail}
                      </Badge>
                    ))}
                  </>
                )}
              </>
            ))}
          {mailInserite &&
            mailInserite.generali.map((mail) => (
              <>
                {mail.valid ? (
                  <Badge
                    color={"green"}
                    rightSection={
                      <FontAwesomeIcon
                        onClick={() => rimuoviMail(mail)}
                        className="pointer"
                        icon={faTimes}
                        color={"red"}
                      />
                    }
                  >
                    {mail.mail}
                  </Badge>
                ) : (
                  <Badge
                    color={"red"}
                    rightSection={
                      <FontAwesomeIcon
                        onClick={() => rimuoviMail(mail)}
                        className="pointer"
                        icon={faTimes}
                        color={"red"}
                      />
                    }
                  >
                    {mail.mail}
                  </Badge>
                )}
              </>
            ))}
        </div>
        <div className="inserimento_mail mb10">
          <div className="container_ricerca_e_button">
            <Button
              color={"green"}
              onClick={() => setOpenModalAppaltatori(true)}
            >
              <FontAwesomeIcon
                icon={faUsers}
                color={"white"}
                className={"pointerImportant"}
              />
            </Button>
            <div className="container_mail">
              <TextInput
                value={mailInserita}
                name="field-1"
                onKeyUp={handleSkip}
                placeholder={"E-Mail"}
                onChange={(e) => {
                  setMailInserita(e.target.value);
                }}
                className={"pointerImportant"}
                rightSection={
                  <Button
                    color={"green"}
                    size={"sm"}
                    disabled={mailInserita == "" || !modificaAbilitata}
                    onClick={() => {
                      aggiungiMail(mailInserita);
                      setMailInserita("");
                    }}
                    leftIcon={<FontAwesomeIcon icon={faPlus} />}
                  >
                    Inserisci
                  </Button>
                }
              />
            </div>
          </div>
        </div>
        {/* <Popover.Dropdown>
							<div className="container_appaltatori_list">
								{appaltatori.map((appaltatore) => (
									<div
										className="row_appaltatore_list pointer"
										key={appaltatore.id}
										onClick={() => aggiungiMail(appaltatore.username)}>
										{appaltatore.username}
									</div>
								))}
							</div>
							<Button
								size={"xs"}
								disabled={!hasNext}
								onClick={(e) => {
									e.target.stopPropagation();
									getDati(numeroPagina, mailInserita, true);
								}}>
								Carica altri
							</Button>
						</Popover.Dropdown> */}

        <div className="inserimento_mail mb20">
          <TextInput
            placeholder="Oggetto"
            value={titoloMail}
            onChange={(e) => {
              setTitoloMail(e.target.value);
            }}
          />
        </div>
        <div className="container_testo">
          <Textarea
            onChange={(e) => setTestoMail(e.target.value)}
            placeholder="Testo E-Mail"
            className="text_area"
            minRows={7}
            value={testoMail}
          />
        </div>
        <div className="container_dropzone">
          <div className="container">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} multiple />
              <p>Inserisci i file da allegare</p>
            </div>
            <aside className="mt20">
              <h4>File inseriti:</h4>
              {files.length == 0 && <h5>Nessun File Inserito</h5>}
              {filesHTML}
            </aside>
          </div>
        </div>
        <Modal
          title={<h2>Selezione Appaltatori</h2>}
          size={"95%"}
          opened={openModalAppaltatori}
          centered
          onClose={() => setOpenModalAppaltatori(false)}
        >
          <Tabs
            value={tabSelezionata}
            onTabChange={(e) => {
              handleTabChange(e);
            }}
          >
            <Tabs.List grow>
              {Object.keys(appaltatori.current).map((key) => (
                <Tabs.Tab
                  value={key}
                  icon={<FontAwesomeIcon icon={faIndustry} />}
                >
                  {key}
                </Tabs.Tab>
              ))}
            </Tabs.List>
            {Object.keys(appaltatori.current).map((key) => (
              <Tabs.Panel value={key} pt="xs">
                <Tabella
                  setStringaDaCercare={setStringaDaCercare}
                  stringaDaCercare={stringaDaCercare}
                  status={false}
                  loading={loading}
                  ths={ths}
                  rowHTML={rowHTML}
                />
              </Tabs.Panel>
            ))}
            <Tabs.Panel value="gallery" pt="xs">
              <Tabella
                titolo={"chignolo"}
                setStringaDaCercare={setStringaDaCercare}
                stringaDaCercare={stringaDaCercare}
                status={false}
                loading={loading}
                ths={ths}
                rowHTML={rowHTML}
              />
            </Tabs.Panel>
          </Tabs>

          <div className="inline-item mt20">
            <Button
              color={"orange"}
              style={{ marginLeft: "20px", width: "100% " }}
              onClick={() => setOpenModalConferma(false)}
            >
              Annulla
            </Button>
            <Button
              style={{ width: "100%" }}
              color="green"
              onClick={() => aggiungiGruppoMail()}
            >
              Conferma
            </Button>
          </div>
        </Modal>
        <Modal
          title={<h2>Mail non valide</h2>}
          opened={openModalConferma}
          centered
          onClose={() => setOpenModalConferma(false)}
        >
          <div>
            <h4>
              Sono state trovate delle E-Mail non valide, rimuovere le mail non
              valide?{" "}
            </h4>
          </div>

          <div className="inline-item mt20">
            <Button
              color={"orange"}
              style={{ marginLeft: "20px", width: "100% " }}
              onClick={() => setOpenModalConferma(false)}
            >
              NO
            </Button>
            <Button
              style={{ width: "100%" }}
              color="green"
              onClick={() => eliminaMailNonValide()}
            >
              SI
            </Button>
          </div>
        </Modal>
      </div>
    </Container>
  );
}
