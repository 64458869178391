import React, { useRef } from "react";
import {
  Container,
  Button,
  Modal,
  Checkbox,
  Badge,
  TextInput,
} from "@mantine/core";
import { ToastContainer, Flip, toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import { Table } from "@mantine/core";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Pie,
  PieChart,
  Cell,
  Tooltip,
} from "recharts";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faIndustry,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { DatePicker } from "@mantine/dates";
import AnimatedNumber from "animated-number-react";

import "dayjs/locale/it";

export default function Statistiche() {
  const [statisticheCertificazioni, setStatisticheCertificazioni] = useState(
    {}
  );
  const [certificazioneSelezionata, setCertificazioneSelezionata] = useState(
    {}
  );
  const statisticheAppaltatoriConNumero = useRef([]);
  const [tempoMedio, setTempoMedio] = useState([]);
  const [countRichieste, setCountRichieste] = useState(0);
  const [rows, setRows] = useState();
  const [numeroAppalti, setNumeroAppalti] = useState([]);
  const [dataDaA, setDataDaA] = useState({ da: "", a: "" });
  const [cercaCertificazione, setCercaCertificazione] = useState("");
  const [badgeFiltrati, setBadgeFiltrati] = useState([]);
  const [valueRicerca, setValueRicerca] = useState("");
  useEffect(() => {
    ottieniStatisticheCertificazioni();
    ottieniAppaltatoriConNumeroPlant();
    ottieniTempoMedio();
    // ottieniCountRichiesteAggiuntive();
    ottieniNumeroAppalti();
  }, []);
  //Funzione che ottiene le statistiche delle certificazioni
  const ottieniStatisticheCertificazioni = async () => {
    let url = "https://apot.mondorevive.com/statistica/certificazioni";
    const response = await fetch(url, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    });
    if (response.status == 200) {
      const data = await response.json();
      let arr = [];
      Object.keys(data).map((certificazione) => {
        if (certificazione != "totaleAppaltatori") {
          let percentuale =
            (
              (Number(data[certificazione].totale) /
                Number(data.totaleAppaltatori)) *
              100
            ).toFixed(0) + "%";
          arr.push({
            name: certificazione + " - " + percentuale,
            totale: data["totaleAppaltatori"],
            valore: data[certificazione].totale,
            appaltatori: data[certificazione].appaltatori,
          });
        }
      });
      setStatisticheCertificazioni(arr);
    } else {
    }
  };
  const sortFunction = (x, y) => {
    return y.numeroStabilimenti - x.numeroStabilimenti;
  };
  //Funzione che ottiene i dettagli degli appaltatori
  const ottieniAppaltatoriConNumeroPlant = async () => {
    let url = "https://apot.mondorevive.com/statistica/fornitoriPlant";
    const response = await fetch(url, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    });
    if (response.status == 200) {
      const data = await response.json();
      let arr = data.sort(sortFunction);
      statisticheAppaltatoriConNumero.current = arr;
      setTabella(arr);
    } else {
    }
  };
  //Funzione che ottiene le informazioni sugli stabilimenti
  const ottieniStabilimenti = async (id) => {
    let url =
      "https://apot.mondorevive.com/api/stabilimentiAppaltatore?id=" + id;
    const response = await fetch(url, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    });
    if (response.status == 200) {
      const data = await response.json();
      let arr = [];

      statisticheAppaltatoriConNumero.current.map((appaltatore) => {
        if (appaltatore.id === id)
          arr.push({ ...appaltatore, mostra: true, stabilimenti: data });
        else arr.push(appaltatore);
      });
      statisticheAppaltatoriConNumero.current = arr;
      setTabella(arr);
    } else {
    }
  };
  //Funzione che ottiene il tempo medio
  const ottieniTempoMedio = async (da = null, a = null) => {
    let url =
      "https://apot.mondorevive.com/statistica/tempoMedioVerificaPerPlant";
    if (da) {
      url += "?dataInizio=" + da;
      if (a) url += "&dataFine=" + a;
    } else {
      if (a) url += "?dataFine=" + a;
    }

    const response = await fetch(url, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    });
    if (response.status == 200) {
      const data = await response.json();
      let obj = {};
      data.map((tempo) => {
        obj[tempo.nomeStabilimento] = tempo;
      });
      /**
       * NomeStab,
       * numGIorni
       * numMinut
       * numSecondi
       *
       *
       */

      ottieniCountRichiesteAggiuntive(da, a).then((res) => {
        res.map((richiesta) => {
          obj[richiesta.nomeStabilimento] = {
            ...obj[richiesta.nomeStabilimento],
            ...richiesta,
          };
        });
        setTempoMedio(obj);
      });
    } else {
    }
  };
  //Funzione che ottiene il count delle richieste aggiuntive
  const ottieniCountRichiesteAggiuntive = async (da = null, a = null) => {
    let url =
      "https://apot.mondorevive.com/statistica/numeroRichiesteAggiuntive";
    if (da) {
      url += "?dataInizio=" + da;
      if (a) url += "&dataFine=" + a;
    } else {
      if (a) url += "?dataFine=" + a;
    }

    const response = await fetch(url, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    });
    if (response.status == 200) {
      const data = await response.json();
      return data;
    } else {
      return [];
    }
    return [];
  };
  //Funzione che gestisce la selezione di una certificazione
  const handleCertificazioneSelezionata = (certificazione) => {
    setCertificazioneSelezionata(certificazione);
    setBadgeFiltrati(certificazione.appaltatori);
  };
  //Funzione che formatta il tempo mancante
  const formattingTempo = (giorni, ore, minuti, secondi) => {
    let giorniGiusto = giorni < 10 ? 0 + "" + giorni : giorni;
    let oreGiusto = ore < 10 ? 0 + "" + ore : ore;
    let minutiGiusto = minuti < 10 ? 0 + "" + minuti : minuti;
    let secondiGiusto = secondi < 10 ? 0 + "" + secondi : secondi;
    return (
      giorniGiusto +
      " : " +
      oreGiusto +
      " : " +
      minutiGiusto +
      " : " +
      secondiGiusto
    );
  };
  //Funzione hce ottiene il colore della card per lo stabilimento
  const ottieniColoreTempo = (stabilimento, status = true) => {
    if (status) {
      if (stabilimento == "BERNATE") return " colorBlue";
      if (stabilimento == "CHIGNOLO PO") return " colorGreen";
      if (stabilimento == "MARNATE") return " colorPink";
      if (stabilimento == "BUSTO ARSIZIO") return " color1";
      if (stabilimento == "FERENTINO") return " color2";
    } else {
      if (stabilimento == "BERNATE") return " #208aff";
      if (stabilimento == "CHIGNOLO PO") return " #1fd09d";
      if (stabilimento == "MARNATE") return " #eb59ac";
      if (stabilimento == "BUSTO ARSIZIO") return " #40376e";
      if (stabilimento == "FERENTINO") return " #531cb3";
    }
  };
  //Funzione che imposta i valori ad una tabella
  const setTabella = (data, text = "") => {
    setRows(
      <>
        {data.map((element, count) => (
          <>
            {element.nomeAppaltatore
              .toLowerCase()
              .includes(text.toLowerCase()) && (
              <tr key={element.id}>
                <td>{count + 1}</td>
                <td>{element.nomeAppaltatore}</td>
                <td>{element.numeroStabilimenti}</td>
                <td>
                  {!element.mostra ? (
                    <Button
                      leftIcon={<FontAwesomeIcon icon={faEye} />}
                      color={"green"}
                      size={"xs"}
                      onClick={() => ottieniStabilimenti(element.id)}
                    >
                      Mostra
                    </Button>
                  ) : (
                    <div className="container_badge">
                      {element.stabilimenti.map((stabilimento, count) => (
                        <Badge
                          key={count}
                          className={
                            "colorWhite" + ottieniColoreTempo(stabilimento)
                          }
                        >
                          {stabilimento}
                        </Badge>
                      ))}
                    </div>
                  )}
                </td>
              </tr>
            )}
          </>
        ))}
      </>
    );
  };
  //Funzione che ottiene il numero di appalti suddivisio per stabilimento
  const ottieniNumeroAppalti = async () => {
    let url =
      "https://apot.mondorevive.com/statistica/numeroAppaltiStabilimento";
    const response = await fetch(url, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    });
    if (response.status == 200) {
      const data = await response.json();
      let arr = [];
      let totale = 0;
      data.map((app) => {
        totale += app.cont;
        arr.push({ name: app.nomeStabilimento, value: app.cont });
      });
      let arr2 = [];
      arr.map((app) => {
        arr2.push({ ...app, perc: ((app.cont / totale) * 100).toFixed(2) });
      });
      setNumeroAppalti(arr2);
    } else {
    }
  };
  const fromDataErrataToDataGiusta = (data) => {
    if (data == "" || data == null) return null;
    let dataTmp = new Date(data);
    let numero_mese =
      dataTmp.getMonth() + 1 < 10
        ? "0" + Number(dataTmp.getMonth() + 1)
        : Number(dataTmp.getMonth() + 1);
    let numero_giorni =
      dataTmp.getDate() < 10
        ? "0" + Number(dataTmp.getDate())
        : Number(dataTmp.getDate());
    return numero_giorni + "/" + numero_mese + "/" + dataTmp.getFullYear();
  };
  useEffect(() => {
    // ottieniCountRichiesteAggiuntive(
    // 	fromDataErrataToDataGiusta(dataDaA.da),
    // 	fromDataErrataToDataGiusta(dataDaA.a)
    // );
    ottieniTempoMedio(
      fromDataErrataToDataGiusta(dataDaA.da),
      fromDataErrataToDataGiusta(dataDaA.a)
    );
  }, [dataDaA]);
  //Funzione che gestisce la ricerca d
  const handleChangeSearch = (e) => {
    setCercaCertificazione(e);
    let arr = [];
    certificazioneSelezionata.appaltatori.map((appaltatore) => {
      if (appaltatore.toLowerCase().includes(e.toLowerCase()))
        arr.push(appaltatore);
    });

    setBadgeFiltrati(arr);
  };

  const customLabel = (props) => {
    const RADIAN = Math.PI / 180;
    // eslint-disable-next-line
    const radius =
      25 + props.innerRadius + (props.outerRadius - props.innerRadius);
    // eslint-disable-next-line
    const x = props.cx + radius * Math.cos(-props.midAngle * RADIAN);
    // eslint-disable-next-line
    const y = props.cy + radius * Math.sin(-props.midAngle * RADIAN);
    return (
      <text
        x={x}
        y={y}
        textAnchor={x > props.cx ? "start" : "end"}
        fill="black"
        dominantBaseline="central"
      >
        {props.payload.payload.name +
          " - " +
          (props.percent * 100).toFixed(2) +
          "% "}
      </text>
    );
  };
  useEffect(() => {
    setTabella(statisticheAppaltatoriConNumero.current, valueRicerca);
  }, [valueRicerca]);
  return (
    <Container className="mt--7 containerMantine container_dashboard" fluid>
      <div className="container_statistiche">
        <ToastContainer
          transition={Flip}
          newestOnTop
          rtl={false}
          pauseOnFocusLoss
        />
        {/* <h2 className="mt20 title_dashboard">Statistiche</h2> */}
        <div className="container_header_statistiche mt20 mb20">
          <h2 className="mb20 mt20 title_dashboard">Statistiche</h2>
          <div className="container_filtri_statistiche">
            <DatePicker
              value={dataDaA.da}
              label={"Da"}
              locale="it"
              onChange={(e) => {
                setDataDaA({ ...dataDaA, da: e });
              }}
              placeholder={"Inserisci una data"}
            />
            <DatePicker
              value={dataDaA.a}
              label={"A"}
              locale="it"
              onChange={(e) => {
                setDataDaA({ ...dataDaA, a: e });
              }}
              placeholder={"Inserisci una data"}
            />
          </div>
        </div>

        <div className="container_statistiche_tempo_medio mb50">
          <h2 className="mb20 title_statistiche">Stabilimenti</h2>
          <div className="container_card_statistiche">
            {Object.keys(tempoMedio).map((key) => (
              <div
                className={
                  "card_tempo_medio" +
                  ottieniColoreTempo(tempoMedio[key].nomeStabilimento)
                }
              >
                <div className="container_header_statistiche_stabilimento">
                  <FontAwesomeIcon icon={faIndustry} color={"white"} />
                  <h3 className="colorWhite title_stabilimento">
                    {tempoMedio[key].nomeStabilimento}
                  </h3>
                </div>
                <div className="container_tempo">
                  <h4>Tempo medio:</h4>
                  <div>
                    {tempoMedio[key].numGiorni != undefined ? (
                      <>
                        <AnimatedNumber
                          className={"colorWhite"}
                          duration={1000}
                          value={tempoMedio[key].numGiorni}
                          formatValue={(e) => {
                            let string =
                              e < 10
                                ? "0" + parseInt(e) + " : "
                                : parseInt(e) + ": ";
                            return string;
                          }}
                        />
                        <AnimatedNumber
                          className={"colorWhite"}
                          duration={1000}
                          value={tempoMedio[key].numOre}
                          formatValue={(e) => {
                            let string =
                              e < 10
                                ? "0" + parseInt(e) + " : "
                                : parseInt(e) + " : ";
                            return string;
                          }}
                        />
                        <AnimatedNumber
                          className={"colorWhite"}
                          duration={1000}
                          value={tempoMedio[key].numMinuti}
                          formatValue={(e) => {
                            let string =
                              e < 10
                                ? "0" + parseInt(e) + " : "
                                : parseInt(e) + " : ";
                            return string;
                          }}
                        />
                        <AnimatedNumber
                          className={"colorWhite"}
                          duration={1000}
                          value={tempoMedio[key].numSecondi}
                          formatValue={(e) => {
                            let string =
                              e < 10 ? "0" + parseInt(e) : parseInt(e);
                            return string;
                          }}
                        />{" "}
                      </>
                    ) : (
                      <h3>-</h3>
                    )}
                  </div>
                </div>
                <div className="container_tempo">
                  <h4>Richieste Info Aggiuntive:</h4>

                  <AnimatedNumber
                    className={"colorWhite"}
                    duration={1000}
                    value={tempoMedio[key].cont ? tempoMedio[key].cont : 0}
                    formatValue={(e) => {
                      return parseInt(e);
                    }}
                  />
                </div>
              </div>
            ))}
            {Object.keys(tempoMedio).length == 0 && (
              <h2 className="colorGray w100 taC">Nessun dato presente</h2>
            )}
          </div>
        </div>

        <div className="container_due_statistiche mb20">
          <div className="container_statistica_grafico_torta_1 ">
            <h2 className="mb20 title_statistiche">Certificazioni</h2>
            <div className="container_chart pointer">
              <ResponsiveContainer width="99%" height="100%">
                <BarChart
                  onClick={(e) => {
                    handleCertificazioneSelezionata(e.activePayload[0].payload);
                  }}
                  height={250}
                  data={statisticheCertificazioni}
                  style={{ cursor: "pointer" }}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                  barSize={20}
                >
                  <XAxis dataKey="name" padding={{ left: 10, right: 10 }} />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Bar
                    dataKey="valore"
                    fill="#8884d8"
                    background={{ fill: "#eee" }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="container_statistica_grafico_torta ">
            <h2 className="mb20 title_statistiche">Appalti</h2>
            {numeroAppalti.length > 0 && (
              <div className="container_grafico_torta">
                <ResponsiveContainer width="99%" height="100%">
                  <PieChart height={250}>
                    <Pie
                      data={numeroAppalti}
                      cx="50%"
                      cy="50%"
                      label={customLabel}
                      paddingAngle={5}
                      innerRadius={40}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {numeroAppalti.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={ottieniColoreTempo(entry.name, false)}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            )}
          </div>
        </div>
        {Object.keys(certificazioneSelezionata).length > 0 && (
          <>
            <div className="container_header_statistiche mb10">
              <h4>Certificazione: {certificazioneSelezionata.name}</h4>
              <TextInput
                value={cercaCertificazione}
                onChange={(e) => {
                  handleChangeSearch(e.target.value);
                }}
                placeholder="Ricerca"
                icon={<FontAwesomeIcon icon={faSearch} />}
                rightSection={
                  <FontAwesomeIcon
                    icon={faTimes}
                    color={cercaCertificazione == "" ? "gray" : "red"}
                    className={"pointer"}
                    onClick={() => handleChangeSearch("")}
                  />
                }
              />
            </div>
            <div className="container_certificazione_selezionata mb30">
              <div>
                {badgeFiltrati.length > 0 &&
                  badgeFiltrati
                    .slice(0, 50)
                    .map((nome) => <Badge>{nome}</Badge>)}
              </div>
              <div className="nessun_appaltatore">
                {badgeFiltrati.length == 0 && (
                  <h5>Nessun Appaltatore presente</h5>
                )}
              </div>
            </div>
          </>
        )}
        <div className="container_statistiche_appaltatori mb30">
          <h2 className="mb20 title_statistiche">Appaltatori</h2>
          <div>
            <TextInput
              placeholder="Ricerca..."
              value={valueRicerca}
              onChange={(e) => {
                setValueRicerca(e.target.value);
              }}
              rightSection={
                <FontAwesomeIcon
                  icon={faTimes}
                  color={valueRicerca != "" ? "red" : "gray"}
                />
              }
            />
          </div>
          <div className="container_info_appaltatori">
            <Table highlightOnHover horizontalSpacing={"lg"}>
              <thead>
                <tr>
                  <th style={{ width: "10%" }}>Numero</th>
                  <th style={{ width: "33%" }}>Nome</th>
                  <th style={{ width: "20%" }}>Numero Stabilimenti</th>
                  <th>Stabilimenti</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </div>
        </div>
      </div>
    </Container>
  );
}
