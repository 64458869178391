import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronCircleRight,
	faEnvelopeOpen,
	faLineChart,
	faUsers,
	faUser,
	faCogs,
	faDoorOpen,
	faReplyAll,
	faWrench,
	faListCheck,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export default function Sidebar({ setActiveTab, activeTab, setStatusNavbar }) {
	const toggle = useRef(false);
	const navigate = useNavigate();
	const handleClickToggle = () => {
		toggle.current = !toggle.current;
		const body = document.querySelector("body");
		let sidebar = body.querySelector("nav");
		sidebar.classList.toggle("close");
	};
	const handleClickMenu = (tab) => {
		setActiveTab(tab);
		if (toggle.current) handleClickToggle();
	};

	async function handleLogout() {
		setStatusNavbar(null);
		Cookies.remove("token");
		Cookies.remove("status");
		//getInfoProfilo();
		navigate("/login");
	}
	return (
		<nav className="sidebar close">
			<header>
				<div className="image-text">
					<div className="text logo-text">
						<span className="name">APOT</span>
						<span className="profession">By Mondorevive</span>
					</div>
				</div>

				<FontAwesomeIcon
					icon={faChevronCircleRight}
					className="bx bx-chevron-right toggle"
					onClick={() => {
						handleClickToggle();
					}}
				/>
			</header>

			<div className="menu-bar">
				<div className="menu">
					<ul className="menu-links">
						<li
							className="nav-link"
							onClick={() => {
								handleClickMenu(0);
							}}>
							<a href="#">
								<FontAwesomeIcon
									color={"#208aff"}
									icon={faReplyAll}
									className="bx bx-home-alt icon"
									size={"xs"}
								/>
								<span className="text nav-text">Richieste</span>
							</a>
						</li>
						{Cookies.get("status") == "ROLE_ADMINISTRATOR" && (
							<li
								className="nav-link"
								onClick={() => {
									handleClickMenu(1);
								}}>
								<a href="#">
									<FontAwesomeIcon
										icon={faCogs}
										color={"#1fd09d"}
										className="bx bx-home-alt icon"
										size={"xs"}
									/>
									<span className="text nav-text">Gestione Form</span>
								</a>
							</li>
						)}

						<li
							className="nav-link"
							onClick={() => {
								handleClickMenu(3);
							}}>
							<a href="#">
								<FontAwesomeIcon
									color={"#eb59ac"}
									icon={faUsers}
									className="bx bx-home-alt icon"
									size={"xs"}
								/>
								<span className="text nav-text">Appaltatori</span>
							</a>
						</li>
						{/* <li
							className="nav-link"
							onClick={() => {
								handleClickMenu(7);
							}}>
							<a href="#">
								<FontAwesomeIcon
									color={"#eb59ac"}
									icon={faListCheck}
									className="bx bx-home-alt icon"
									size={"xs"}
								/>
								<span className="text nav-text">Verifiche in campo</span>
							</a>
						</li> */}

						<li
							className="nav-link"
							onClick={() => {
								handleClickMenu(4);
							}}>
							<a href="#">
								<FontAwesomeIcon
									color={"#40376e"}
									icon={faLineChart}
									className="bx bx-home-alt icon"
									size={"xs"}
								/>
								<span className="text nav-text">Statistiche</span>
							</a>
						</li>
						<li
							className="nav-link"
							onClick={() => {
								handleClickMenu(2);
							}}>
							<a href="#">
								<FontAwesomeIcon
									color={"#941C2F"}
									icon={faUser}
									className="bx bx-home-alt icon"
									size={"xs"}
								/>
								<span className="text nav-text">Profilo</span>
							</a>
						</li>
						<li
							className="nav-link"
							onClick={() => {
								handleClickMenu(5);
							}}>
							<a href="#">
								<FontAwesomeIcon
									color={"#F4A261"}
									icon={faEnvelopeOpen}
									className="bx bx-home-alt icon"
									size={"xs"}
								/>
								<span className="text nav-text">E-Mail</span>
							</a>
						</li>
						<li
							className="nav-link"
							onClick={() => {
								handleClickMenu(6);
							}}>
							<a href="#">
								<FontAwesomeIcon
									color={"#E76F51"}
									icon={faWrench}
									className="bx bx-home-alt icon"
									size={"xs"}
								/>
								<span className="text nav-text">Azioni</span>
							</a>
						</li>
						<li
							className="nav-link"
							onClick={() => {
								handleLogout();
							}}>
							<a href="#">
								<FontAwesomeIcon
									color={"red"}
									icon={faDoorOpen}
									className="bx bx-home-alt icon"
									size={"xs"}
								/>
								<span className="text nav-text">Esci</span>
							</a>
						</li>
					</ul>
				</div>

				{/* <div className="bottom-content">
					<li className="">
						<a href="#">
							<i className="bx bx-log-out icon"></i>
							<span className="text nav-text">Logout</span>
						</a>
					</li>

				
				</div> */}
			</div>
		</nav>
	);
}
