import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Input, Button, PasswordInput, Alert, Loader } from "@mantine/core";
import cookie from "js-cookie";
import {
  successNotification,
  errorNotification,
  infoNotification,
} from "./Notification";
import { ToastContainer, Flip, toast } from "react-toastify";
import { setPossoModificare } from "./Utilities";
import Cookies from "js-cookie";
export default function Login() {
  const email = useRef();
  const password = useRef();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loginDisabled, setLoginDisabled] = useState(false);
  const [tempoRimanente, setTempoRimanente] = useState();
  const navigate = useNavigate();
  useEffect(async () => {
    if (cookie.get("token") != null || cookie.get("token") != undefined) {
      let tokenStatus = cookie.get("status");
      if (tokenStatus == "ROLE_APPROVER") {
        navigate("/admin");
        return;
      }
      if (tokenStatus == "ROLE_ADMINISTRATOR") {
        navigate("/admin");
        return;
      }
      if (tokenStatus == "ROLE_CONTRACTOR") {
        navigate("/update");
        return;
      }
      navigate("/login");
    }
    setLoading(false);
  }, []);

  //Funzione che controlla se posso modificare
  const ottieniPossoModificare = async () => {
    const url = "https://apot.mondorevive.com/api/modificaAbilitata";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    });
    if (response.status == 200) {
      const data = await response.text();
      if (data == "true") {
        setPossoModificare(true);
      } else {
        setPossoModificare(false);
      }
      setLoading(false);
    } else {
      setPossoModificare(false);
    }
  };

  //Login utente
  async function login() {
    setLoadingLogin(true);
    if (email.current == "" || password.current == "") return;
    const url = "https://apot.mondorevive.com/auth/login";
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        password: password.current.value,
        username: email.current.value,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status == 200) {
      const data = await response.json();
      cookie.set("token", data.token, { expires: 7 });
      await ottieniPossoModificare();
      if (data.ruolo) cookie.set("status", data.ruolo, { expires: 7 });
      if (data.ruolo == "ROLE_ADMINISTRATOR") navigate("/admin");
      if (data.ruolo == "ROLE_APPROVER") navigate("/admin");
      if (data.ruolo == "ROLE_CONTRACTOR") navigate("/update");
      setLoadingLogin(false);
      return;
    }
    if (response.status == 404) {
      const data = await response.json();
      errorNotification("Account non esistente");
      setLoadingLogin(false);
    } else {
      const data = await response.text();
      if (Number(data) == 0) {
        setLoginDisabled(true);
        setTempoRimanente(60);
        setLoadingLogin(false);
        setError("Email o Password errata");
        return;
      }
      if (Number(data) > 0) {
        let num = Number(data);
        setLoadingLogin(false);
        setError("Email o Password errate, tentivi rimanenti: " + num);
        return;
      }
      setError("Utente bloccato");
      setTempoRimanente(60 - Number(data.split(" ")[1]));
      setLoadingLogin(false);
      setLoginDisabled(true);
      setTimeout(() => {
        setLoginDisabled(false);
        setError(false);
      }, (60 - Number(data.split(" ")[1])) * 60 * 1000);

      return;
    }
  }
  //Se premo invio e sono nel campo password "premo" su login
  function handleSkip(e) {
    const { name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");
    let fieldIntIndex = parseInt(fieldIndex, 10);
    if (e.key === "Enter") {
      const nextfield = document.querySelector(
        `input[name=field-${fieldIntIndex + 1}]`
      );
      // If found, focus the next field
      if (nextfield !== null) {
        nextfield.focus();
      } else {
        login();
      }
    }
  }
  return (
    <>
      <div className="log-container colorMondorevive">
        <ToastContainer
          transition={Flip}
          newestOnTop
          rtl={false}
          pauseOnFocusLoss
        />
        {!loading && (
          <>
            {loginDisabled ? (
              <>
                <div className="log-box2">
                  <div className="register-box min">
                    <div className="register-title login">
                      <h1 style={{ textAlign: "center" }}>
                        Accesso Disabilitato
                      </h1>

                      <h3 style={{ textAlign: "center" }}>
                        Potrai effettuare una nuova richiesta tra{" "}
                        {tempoRimanente} minuti
                      </h3>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="log-box2">
                <div className="register-box min">
                  <div className="register-title login">
                    <h1>Accedi</h1>
                  </div>
                  {error != "" && (
                    <Alert
                      title="Errore!"
                      color="red"
                      style={{ marginBottom: "10px" }}
                    >
                      {error}
                    </Alert>
                  )}
                  <Input
                    ref={email}
                    name="field-1"
                    placeholder="Username"
                    radius="lg"
                    size="xl"
                    onKeyUp={handleSkip}
                    style={{ marginBottom: "30px" }}
                    autoFocus
                  />
                  <PasswordInput
                    name="field-2"
                    ref={password}
                    size="xl"
                    onKeyUp={handleSkip}
                    placeholder="Password"
                    radius="lg"
                    required
                  />

                  <Button
                    disabled={loadingLogin}
                    radius="md"
                    size="lg"
                    style={{ marginTop: "30px" }}
                    type="submit"
                    onClick={login}
                  >
                    {loadingLogin ? (
                      <Loader color="red" variant="bars" />
                    ) : (
                      "Login"
                    )}
                  </Button>
                  <div className="inline-item">
                    <Link to="/recupera">
                      <p className="account-ex">Recupera Password</p>
                    </Link>
                    <Link to="/registrati">
                      <p className="account-signup">Non hai un Account?</p>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
