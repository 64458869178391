import React, { useState, useEffect, useRef, useCallback } from "react";
import "../App.css";
import cookie from "js-cookie";
import axios from "axios";
import {
  Checkbox,
  Select,
  MultiSelect,
  Button,
  Divider,
  Input,
  Textarea,
  Modal,
  Loader,
  Progress,
} from "@mantine/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  isScaduto,
  handleClickOpenFile,
  preparaDataPerInvio,
} from "./Utilities";
import { useLocation, useNavigate } from "react-router-dom";
import { DatePicker } from "@mantine/dates";
import "dayjs/locale/it";
import { successNotification, errorNotification } from "./Notification";
import { ToastContainer, Flip, toast } from "react-toastify";
import DipendentiList from "./Aggiuntivi/DipendentiList";
import CampiMancanti from "./Aggiuntivi/CampiMancanti";
export default function Form({ setMostraNavbar }) {
  //REFERENTE AZIENDA
  const [email, setEmail] = useState("");
  const [allEmail, setAllEmail] = useState([]);
  const [telefono, setTelefono] = useState("");
  const nomeReferenteAzienda = useRef("");
  const [responsabili, setResponsabili] = useState({
    rspp: "",
    rls: "",
    ddl: "",
    mc: "",
  });
  const [nomePreposto, setNomePreposto] = useState("");
  const [preposti, setPreposti] = useState([]);
  const [rlsAll, setRlsAll] = useState([]);
  const [rls, setRls] = useState({ denominazione: "", dataNominaRls: null });
  const [dataNomina, setDataNomina] = useState({
    rspp: "",
    rls: "",
    ddl: "",
    mc: "",
  });
  const [checkedCertificazioni, setCheckedCertificazioni] = useState({
    iso9001: false,
    iso14001: false,
    iso45001: false,
    sa8000: false,
  });
  const [fileCertificazioni, setFileCertificazioni] = useState({
    iso9001: null,
    iso14001: null,
    iso45001: null,
    sa8000: null,
  });
  const [referente, setReferente] = useState("");
  //CAMERA DI COMMERCIO
  const [codice, setCodice] = useState("");
  const [visura, setVisura] = useState("");
  const [dataDvr, setDataDvr] = useState("");
  const [dataDichiarazione445, setDataDichiarazione445] = useState("");

  const [fileCamera, setFileCamera] = useState({
    iscrizione: null,
    dvr: null,
    doc445: null,
  });
  const [FileLavoratori, setFileLavoratori] = useState({
    attLav: null,
    poliza: null,
  });
  const [fileDurc, setFileDurc] = useState(null);
  //STABILIMENTI
  const stabilimenti = [
    { value: "Mondorevive Busto Arsizio", label: "Mondorevive Busto Arsizio" },
    { value: "Mondorevive Ferentino", label: "Mondorevive Ferentino" },
    { value: "Caltek Chignolo Po", label: "Caltek Chignolo Po" },
    { value: "MondoSD Bernate Ticino", label: "MondoSD Bernate Ticino" },
    { value: "Mondorevive Marnate", label: "Mondorevive Marnate" },
    { value: "Diba Chignolo Po", label: "Diba Chignolo Po" },
  ];
  const [stabilimentiSelezionati, setStabilimentiSelezionati] = useState([]);
  const [fileModulo7, setfileModulo7] = useState({
    ba: null,
    fe: null,
    cp: null,
    bt: null,
    ma: null,
    di: null,
  });
  const [fileModulo5, setfileModulo5] = useState({
    ba: null,
    fe: null,
    cp: null,
    bt: null,
    ma: null,
    di: null,
  });
  const [fileDuvri, setFileDuvri] = useState({
    ba: null,
    fe: null,
    cp: null,
    bt: null,
    ma: null,
    di: null,
  });
  const navigate = useNavigate();
  //dipendenti
  const [appalto, setAppalto] = useState("");
  const [azienda, setAzienda] = useState("");
  const [nome, setNome] = useState("");
  const [showInserimentoDipendente, setShowInserimentoDipendente] =
    useState(false);
  const [dpi, setDpi] = useState([]);
  const [attestati, setAttestati] = useState([]);
  const [dpiUnchanged, setDpiUnchanged] = useState();
  const [attestatiUnchanged, setAttestatiUnchanged] = useState();
  const [dipendenti, setDipendenti] = useState([]);
  const [dataScadenzaRTC, setDataScadenzaRTC] = useState("");
  const [dataDurc, setDataDurc] = useState("");
  const note = useRef();
  const [fileAttestati, setFileAttestati] = useState([]);
  const [loading, setLoading] = useState(false);
  const [labelFile, setLabelFile] = useState({
    iso9001: null,
    iso14001: null,
    iso45001: null,
    sa8000: null,
    camera: null,
    dvr: null,
    dichiarazione445: null,
    dipendenti: null,
    poliza: null,
    durc: null,
    m7ba: null,
    m7fe: null,
    m7cp: null,
    m7bt: null,
    m7ma: null,
    m7di: null,
    m5ba: null,
    m5fe: null,
    m5cp: null,
    m5bt: null,
    m5ma: null,
    m5di: null,
    duba: null,
    dufe: null,
    ducp: null,
    dubt: null,
    duma: null,
    dudi: null,
  });
  const [opened, setOpened] = useState(false);
  const [loadingRichiesta, setLoadingRichiesta] = useState(false);
  const [emailNonValida, setEmailNonValida] = useState(true);
  const [dimensioneFileCaricati, setDimensioneFileCaricati] = useState(0);
  const [errorSize, setErrorSize] = useState(false);
  const [errorSizeInvio, setErrorSizeInvio] = useState(false);
  const [progress, setProgress] = useState(0);
  const [codiceEtico, setCodiceEtico] = useState(false);
  const [
    informazioniAggiuntiveDipendente,
    setInformazioniAggiuntiveDipendente,
  ] = useState({
    file: undefined,
    unilav: undefined,
    data: undefined,
  });
  const [invioDisabilitato, setInvioDisabilitato] = useState(["a"]);
  const [datiGenerali, setDatiGenerali] = useState({});
  //DATI AZIENDA PER SUBAPPALTO
  const nomeAzienda = useRef();
  const indirizzoAzienda = useRef();
  const pecAzienda = useRef();
  useEffect(() => {
    setMostraNavbar(true);
  }, []);
  //Stato per gestione subappalti(passato da componente UpdateForm)
  const { state } = useLocation();
  const idAppaltoPadre =
    state !== null && state !== undefined ? state.idAppaltoPadre : undefined;

  useEffect(() => {
    console.log("idAppaltoPadreRicevuto", idAppaltoPadre);
    setLoading(true);
    if (
      !cookie.get("status") ||
      cookie.get("status") == "ROLE_APPROVER" ||
      cookie.get("status") == "ROLE_ADMINISTRATOR"
    )
      navigate("/login");
    else {
      presenzaAppalti();
      getDPI();
      getAttestati();
      setLoading(false);
    }
  }, []);
  //Controlla se un utente ha gia inserito un appalto
  const presenzaAppalti = async () => {
    if (idAppaltoPadre !== undefined || idAppaltoPadre !== null) return;
    const url = "https://apot.mondorevive.com/api/presenzaAppalti";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      //dire ok
      const data = await response.text();
      if (data == "true") navigate("/update");
      return;
    } else {
      return false;
    }
  };
  const tornaAAppaltoPrincipale = () => {
    navigate("/update", {
      state: { idAppaltoPadre: undefined, idAppaltoComune: undefined },
    });
    navigate(0);
  };
  //Aggiungi email all`array delle email inserite
  const aggiungiEmail = () => {
    setAllEmail([...allEmail, email]);
    setEmail("");
  };
  //Rimuovi email dall`array delle email inserite
  const rimuoviEmail = (email) => {
    let a = [];
    for (let i = 0; i < allEmail.length; i++) {
      if (allEmail[i] != email) {
        a.push(allEmail[i]);
      }
    }
    setAllEmail(a);
    return;
  };
  // Aggiugne un preposto all`array
  const aggiungiPreposto = () => {
    setPreposti([...preposti, nomePreposto]);
    setNomePreposto("");
  };
  //Rimuove un preposto dall`array
  const rimuoviPreposto = (preposto) => {
    let a = [];
    preposti.map((prepost) => {
      if (prepost != preposto) {
        a.push(prepost);
      }
    });
    setPreposti(a);
    return;
  };
  //Aggiunge um dipendente all`array dei dipendenti
  const aggiungiDipendente = () => {
    //in base alla label ottengo quale attestato e` stato impostato a true
    let attestat = [];
    let dpis = [];
    //Ottengo i dpi e gli attestati selezionati
    dpi.map((dp) => {
      if (dp.status) dpis.push(dp.label);
    });
    attestati.map((att) => {
      if (att.status) attestat.push(att);
    });
    //Aggiungo alla lista dei dipendenti l`ultimo dipendente creato
    setDipendenti([
      ...dipendenti,
      {
        attestatiList: attestat,
        dpiList: dpis,
        denominazione: nome,
        tempoIndeterminato: informazioniAggiuntiveDipendente.tempoIndeterminato,
        socioFondatore: informazioniAggiuntiveDipendente.socioFondatore,
        dataScadenzaUnilav: informazioniAggiuntiveDipendente.dataScadenzaUnilav,
        filenameUnilav: informazioniAggiuntiveDipendente.fileUnilav
          ? informazioniAggiuntiveDipendente.fileUnilav.name
          : null,
        filenameIdoneitaSanitaria: informazioniAggiuntiveDipendente.file
          ? informazioniAggiuntiveDipendente.file.name
          : null,
        fileUnilav: informazioniAggiuntiveDipendente.fileUnilav,
        dataScadenzaIdoneita: informazioniAggiuntiveDipendente.dataScadenza,
        fileIdoneita: informazioniAggiuntiveDipendente.file,
      },
    ]);

    //Resetto la selezione
    setShowInserimentoDipendente(false);
    resetDipendenti();
  };
  //Reset dell`array dei dipendenti
  const resetDipendenti = () => {
    setNome("");
    setAttestati(attestatiUnchanged);
    setInformazioniAggiuntiveDipendente({
      file: undefined,
      unilav: undefined,
      data: undefined,
    });
    setDpi(dpiUnchanged);
  };
  //Funzione che ottiene i DPI dal db
  const getDPI = async () => {
    const url = "https://apot.mondorevive.com/api/dpi";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      //dire ok
      const data = await response.json();
      let arr = [];
      data.map((dpi) => {
        arr.push({ label: dpi.nome, status: false });
      });
      setDpi(arr);
      setDpiUnchanged(arr);
    } else {
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  const handleChangeDpi = (dpis) => {
    let arr = [];
    dpi.map((dp) => {
      if (dp.label == dpis.label) {
        arr.push({ label: dp.label, status: !dpis.status });
      } else arr.push(dp);
    });
    setDpi(arr);
  };
  const handleChangeAttestati = (attestato) => {
    let arr = [];
    attestati.map((att) => {
      if (att.label == attestato.label) {
        arr.push({ ...att, label: att.label, status: !attestato.status });
      } else arr.push(att);
    });
    setAttestati(arr);
  };
  //Funzione che ottiene gli attestati dal db
  const getAttestati = async () => {
    const url = "https://apot.mondorevive.com/api/attestati";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      const data = await response.json();
      let arr = [];
      data.map((attestato) => {
        arr.push({
          label: attestato.nome,
          value: attestato.nome,
          status: false,
          file: undefined,
        });
      });
      setAttestati(arr);
      setAttestatiUnchanged(arr);
    } else {
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  //Dice se un determinato stabilimento e` presente negli stabilimenti selezionati
  const trovaStabilimento = (stabilimento) => {
    let trovato = false;
    stabilimentiSelezionati.map((e) => {
      if (e === stabilimento) {
        trovato = true;
        return true;
      }
    });
    if (trovato) return true;
    else return false;
  };
  //Funzione che ottiene i byte di un file
  const prendiByteFile = (file) => {
    if (!file) return 0;
    return file.size;
  };
  //Funzione che controlla se vi sono dei duplicati
  const handleDuplicatiFile = (dipendenti) => {
    let arr = [];
    dipendenti.map((dipendente) => {});
    return arr;
  };
  //Inserisce l`appalto nel db
  const insertApplatoDb = async () => {
    setLoadingRichiesta(true);
    setOpened(true);
    const url = "https://apot.mondorevive.com/api/insertAppalto";
    var formData = new FormData();
    let arr = [];
    dipendenti.map((dipendente) => {
      let arr2 = [];
      dipendente.attestatiList.map((attestato) => {
        arr2.push({
          id: null,
          filename: attestato.filename,
          dataScadenza:
            attestato.dataScadenza != "" &&
            attestato.dataScadenza != null &&
            attestato.dataScadenza != undefined
              ? preparaDataPerInvio(attestato.dataScadenza)
              : null,
          label: attestato.label,
        });
      });
      arr.push({
        dpiList: dipendente.dpiList,
        denominazione: dipendente.denominazione,
        filenameUnilav: dipendente.filenameUnilav,
        socioFondatore: dipendente.socioFondatore,
        tempoIndeterminato: dipendente.tempoIndeterminato,
        filenameIdoneitaSanitaria: dipendente.filenameIdoneitaSanitaria,
        dataScadenzaIdoneita:
          dipendente.dataScadenzaIdoneita != "" &&
          dipendente.dataScadenzaIdoneita != null
            ? preparaDataPerInvio(dipendente.dataScadenzaIdoneita)
            : null,
        dataScadenzaUnilav:
          dipendente.dataScadenzaUnilav != "" &&
          dipendente.dataScadenzaUnilav != null
            ? preparaDataPerInvio(dipendente.dataScadenzaUnilav)
            : null,
        attestatiList: arr2,
        idUnilav: null,
        idIdoneita: null,
      });
    });
    formData.append(
      "appalto",
      new Blob(
        [
          JSON.stringify({
            idAppaltoPadre: idAppaltoPadre,
            referenteAzienda: nomeReferenteAzienda.current.value,
            cellulare: telefono,
            nomeRspp: responsabili.rspp,
            dataInizio: preparaDataPerInvio(datiGenerali.dataInizio),
            dataFine: preparaDataPerInvio(datiGenerali.dataFine),
            descrizione: datiGenerali.descrizione,
            dataNominaRspp:
              dataNomina.rspp != "" && dataNomina.rspp != null
                ? preparaDataPerInvio(dataNomina.rspp)
                : null,
            rlsList: rlsAll,
            nomeDdl: responsabili.ddl,
            dataNominaDdl:
              dataNomina.ddl != "" && dataNomina.ddl != null
                ? preparaDataPerInvio(dataNomina.ddl)
                : null,
            nomeMc: responsabili.mc,
            dataNominaMc:
              dataNomina.mc != "" && dataNomina.mc != null
                ? preparaDataPerInvio(dataNomina.mc)
                : null,
            prepostiCantiere: preposti,
            listaLavoratori: arr,
            referenteMondorevive: referente,
            tipoAppalto: appalto,
            tipologiaSocieta: azienda,
            codiceCameraCommercio: codice,
            dataVisuraCamerale:
              visura != "" && visura != null
                ? preparaDataPerInvio(visura)
                : null,
            dataDichiarazione445:
              dataDichiarazione445 != "" && dataDichiarazione445 != null
                ? preparaDataPerInvio(dataDichiarazione445)
                : null,
            dataCertaDvr:
              dataDvr != "" && dataDvr != null
                ? preparaDataPerInvio(dataDvr)
                : null,
            dataPolizzaAssicurativa:
              dataScadenzaRTC != "" && dataScadenzaRTC != null
                ? preparaDataPerInvio(dataScadenzaRTC)
                : null,
            dataDurc:
              dataDurc != "" && dataDurc != null
                ? preparaDataPerInvio(dataDurc)
                : null,
            mails: allEmail,
            appaltoBusto:
              trovaStabilimento("Mondorevive Busto Arsizio") || false,
            appaltoFerentino:
              trovaStabilimento("Mondorevive Ferentino") || false,
            appaltoBernate:
              trovaStabilimento("MondoSD Bernate Ticino") || false,
            appaltoChignolo: trovaStabilimento("Caltek Chignolo Po") || false,
            appaltoMarnate: trovaStabilimento("Mondorevive Marnate") || false,
            appaltoDiba: trovaStabilimento("Diba Chignolo Po") || false,
            note: note.current.value,
            nomeAzienda:
              nomeAzienda.current !== undefined
                ? nomeAzienda.current.value
                : null,
            indirizzoAzienda:
              indirizzoAzienda.current !== undefined
                ? indirizzoAzienda.current.value
                : null,
            pecAzienda:
              pecAzienda.current !== undefined
                ? pecAzienda.current.value
                : null,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );
    let sommaGrandezzaFile = 0;
    if (checkedCertificazioni.iso9001) {
      formData.append("certIso9001", fileCertificazioni.iso9001);
      sommaGrandezzaFile += fileCertificazioni.iso9001.size;
    }
    if (checkedCertificazioni.iso14001) {
      formData.append("certIso14001", fileCertificazioni.iso14001);
      sommaGrandezzaFile += fileCertificazioni.iso14001.size;
    }
    if (checkedCertificazioni.iso45001) {
      formData.append("certIso45001", fileCertificazioni.iso45001);
      sommaGrandezzaFile += fileCertificazioni.iso45001.size;
    }
    if (checkedCertificazioni.sa8000) {
      formData.append("certificazioneSa8000", fileCertificazioni.sa8000);
      sommaGrandezzaFile += fileCertificazioni.sa8000.size;
    }

    sommaGrandezzaFile += prendiByteFile(fileCamera.iscrizione);

    sommaGrandezzaFile += prendiByteFile(fileCamera.dvr);

    sommaGrandezzaFile += prendiByteFile(fileCamera.doc445);

    sommaGrandezzaFile += prendiByteFile(FileLavoratori.poliza);

    sommaGrandezzaFile += prendiByteFile(fileModulo7.ba);
    sommaGrandezzaFile += prendiByteFile(fileModulo7.fe);
    sommaGrandezzaFile += prendiByteFile(fileModulo7.cp);
    sommaGrandezzaFile += prendiByteFile(fileModulo7.bt);
    sommaGrandezzaFile += prendiByteFile(fileModulo7.ma);
    sommaGrandezzaFile += prendiByteFile(fileModulo7.di);

    sommaGrandezzaFile += prendiByteFile(fileModulo5.ba);
    sommaGrandezzaFile += prendiByteFile(fileModulo5.fe);
    sommaGrandezzaFile += prendiByteFile(fileModulo5.cp);
    sommaGrandezzaFile += prendiByteFile(fileModulo5.bt);
    sommaGrandezzaFile += prendiByteFile(fileModulo5.ma);
    sommaGrandezzaFile += prendiByteFile(fileModulo5.di);

    sommaGrandezzaFile += prendiByteFile(fileDuvri.ba);
    sommaGrandezzaFile += prendiByteFile(fileDuvri.fe);
    sommaGrandezzaFile += prendiByteFile(fileDuvri.cp);
    sommaGrandezzaFile += prendiByteFile(fileDuvri.bt);
    sommaGrandezzaFile += prendiByteFile(fileDuvri.ma);
    sommaGrandezzaFile += prendiByteFile(fileDuvri.di);

    sommaGrandezzaFile += prendiByteFile(fileDurc);

    dipendenti.map((dipendente) => {
      sommaGrandezzaFile += prendiByteFile(dipendente.unilav);
      sommaGrandezzaFile += prendiByteFile(dipendente.sanita);
      dipendente.attestatiList.map((attestato) => {
        sommaGrandezzaFile += prendiByteFile(attestato.file);
        formData.append("fileLavoratori", attestato.file);
      });
      formData.append("fileLavoratori", dipendente.fileUnilav);
      formData.append("fileLavoratori", dipendente.fileIdoneita);
    });

    formData.append("iscrizioneCameraCommercio", fileCamera.iscrizione);
    formData.append("documentoDvr", fileCamera.dvr);
    formData.append("dichiarazione445", fileCamera.doc445);
    fileAttestati.map((file) => {
      sommaGrandezzaFile += prendiByteFile(file.file);
      formData.append("fileAggiuntivi", file.file);
    });
    if (sommaGrandezzaFile / 1024 / 1024 > 95) {
      setErrorSizeInvio(true);
      return;
    }
    // for (let i = 0; i < FileLavoratori.attLav.length; i++) {
    // 	formData.append("attestatiLavoratoriDocumento", FileLavoratori.attLav[i]);
    // }
    formData.append("polizzaAssicurativaRct", FileLavoratori.poliza);
    formData.append("modulo7Ba", fileModulo7.ba);
    formData.append("modulo7Fe", fileModulo7.fe);
    formData.append("modulo7Chi", fileModulo7.cp);
    formData.append("modulo7Be", fileModulo7.bt);
    formData.append("modulo7Ma", fileModulo7.ma);
    formData.append("modulo7Di", fileModulo7.di);

    formData.append("infoRischiRegBa", fileModulo5.ba);
    formData.append("infoRischiRegFe", fileModulo5.fe);
    formData.append("infoRischiRegChi", fileModulo5.cp);
    formData.append("infoRischiRegBe", fileModulo5.bt);
    formData.append("infoRischiRegMa", fileModulo5.ma);
    formData.append("infoRischiRegDi", fileModulo5.di);
    formData.append("duvriBa", fileDuvri.ba);
    formData.append("duvriFe", fileDuvri.fe);
    formData.append("duvriChi", fileDuvri.cp);
    formData.append("duvriBe", fileDuvri.bt);
    formData.append("duvriMa", fileDuvri.ma);
    formData.append("duvriDi", fileDuvri.di);
    formData.append("durc", fileDurc);
    const resp = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + cookie.get("token"),
      },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 50;
        setProgress(progress * 2);
      },
    });
    if (resp.request.status == 200) {
      setLoadingRichiesta(false);
      setOpened(false);
      successNotification("Appalto inviato correttamente");
      navigate("/update");
    } else {
      setLoadingRichiesta(false);
      errorNotification("Impossibile inviare appalto, riprova più tardi!");
      setOpened(false);
      if (resp.request.status == 401 || resp.request.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
    // try {
    // 	const response = await fetch(url, {
    // 		method: "POST",
    // 		headers: {
    // 			Authorization: "Bearer " + cookie.get("token"),
    // 		},
    // 		body: formData,
    // 	});
    //
    // } catch (e) {
    // 	setLoadingRichiesta(false);
    // 	setOpened(false);
    // 	errorNotification("Impossibile elaborare la richeista, riprova più tardi!");
    // }
  };
  const handleAggiuntaRlss = () => {
    setRlsAll([
      ...rlsAll,
      { denominazione: rls.denominazione, dataNominaRls: rls.dataNominaRls },
    ]);
    setRls({ denominazione: "", dataNominaRls: null });
  };
  const handleRemoveRls = (rlss) => {
    let arr = [];
    rlsAll.map((rl) => {
      if (rl.denominazione != rlss.denominazione) arr.push(rl);
    });
    setRlsAll(arr);
  };
  const handleChangeModulo7 = (nome, e) => {
    if (nome == "Mondorevive Busto Arsizio") {
      setfileModulo7({ ...fileModulo7, ba: e });
      setLabelFile({ ...labelFile, m7ba: e.name });
      return;
    }
    if (nome == "Mondorevive Ferentino") {
      setfileModulo7({ ...fileModulo7, fe: e });
      setLabelFile({ ...labelFile, m7fe: e.name });
      return;
    }
    if (nome == "Caltek Chignolo Po") {
      setfileModulo7({ ...fileModulo7, cp: e });
      setLabelFile({ ...labelFile, m7cp: e.name });
      return;
    }
    if (nome == "MondoSD Bernate Ticino") {
      setfileModulo7({ ...fileModulo7, bt: e });
      setLabelFile({ ...labelFile, m7bt: e.name });
      return;
    }
    if (nome == "Mondorevive Marnate") {
      setfileModulo7({ ...fileModulo7, ma: e });
      setLabelFile({ ...labelFile, m7ma: e.name });
      return;
    }
    if (nome == "Diba Chignolo Po") {
      setfileModulo7({ ...fileModulo7, di: e });
      setLabelFile({ ...labelFile, m7di: e.name });
      return;
    }
  };
  const handleChangeModulo5 = (nome, e) => {
    if (nome == "Mondorevive Busto Arsizio") {
      setfileModulo5({ ...fileModulo5, ba: e });
      setLabelFile({ ...labelFile, m5ba: e.name });
      return;
    }
    if (nome == "Mondorevive Ferentino") {
      setfileModulo5({ ...fileModulo5, fe: e });
      setLabelFile({ ...labelFile, m5fe: e.name });
      return;
    }
    if (nome == "Caltek Chignolo Po") {
      setfileModulo5({ ...fileModulo5, cp: e });
      setLabelFile({ ...labelFile, m5cp: e.name });
      return;
    }
    if (nome == "MondoSD Bernate Ticino") {
      setfileModulo5({ ...fileModulo5, bt: e });
      setLabelFile({ ...labelFile, m5bt: e.name });
      return;
    }
    if (nome == "Mondorevive Marnate") {
      setfileModulo5({ ...fileModulo5, ma: e });
      setLabelFile({ ...labelFile, m5ma: e.name });
      return;
    }
    if (nome == "Diba Chignolo Po") {
      setfileModulo5({ ...fileModulo5, di: e });
      setLabelFile({ ...labelFile, m5di: e.name });
      return;
    }
  };
  const handleChangeDuvri = (nome, e) => {
    if (nome == "Mondorevive Busto Arsizio") {
      setFileDuvri({ ...fileDuvri, ba: e });
      setLabelFile({ ...labelFile, duba: e.name });
      return;
    }
    if (nome == "Mondorevive Ferentino") {
      setFileDuvri({ ...fileDuvri, fe: e });
      setLabelFile({ ...labelFile, dufe: e.name });
      return;
    }
    if (nome == "Caltek Chignolo Po") {
      setFileDuvri({ ...fileDuvri, cp: e });
      setLabelFile({ ...labelFile, ducp: e.name });
      return;
    }
    if (nome == "MondoSD Bernate Ticino") {
      setFileDuvri({ ...fileDuvri, bt: e });
      setLabelFile({ ...labelFile, dubt: e.name });
      return;
    }
    if (nome == "Mondorevive Marnate") {
      setFileDuvri({ ...fileDuvri, ma: e });
      setLabelFile({ ...labelFile, duma: e.name });
      return;
    }
    if (nome == "Diba Chignolo Po") {
      setFileDuvri({ ...fileDuvri, di: e });
      setLabelFile({ ...labelFile, dudi: e.name });
      return;
    }
  };
  const add = (value, val) => {
    return value + val;
  };

  const getFile = useCallback(async (nomeFile) => {
    const url =
      "https://apot.mondorevive.com/api/downloadTemplate?campo=" + nomeFile;
    const response = await toast.promise(
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + cookie.get("token"),
        },
        responseType: "blob",
      }),
      {
        pending: "Download in corso",
        success: {
          render() {
            return `File scaricato correttamente`;
          },
          autoClose: 1000,
        },
        error: {
          render() {
            return `Errore nel download del file`;
          },
          autoClose: 1000,
        },
      }
    );
    if (response.status == 200) {
      const data = await response.arrayBuffer();
      const filename = response.headers
        .get("content-disposition")
        .split("filename=")[1];
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename.substring(1, filename.length - 1));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  }, []);
  const handleDownloadModulo7 = (stabilimento) => {
    if (stabilimento == "Mondorevive Busto Arsizio") getFile("modulo7Ba");
    if (stabilimento == "Mondorevive Ferentino") getFile("modulo7Fe");
    if (stabilimento == "Caltek Chignolo Po") getFile("modulo7Chi");
    if (stabilimento == "MondoSD Bernate Ticino") getFile("modulo7Be");
    if (stabilimento == "Mondorevive Marnate") getFile("modulo7Ma");
    if (stabilimento == "Diba Chignolo Po") getFile("modulo7Di");
  };
  const handleDownloadModulo5 = (stabilimento) => {
    if (stabilimento == "Mondorevive Busto Arsizio") getFile("infoRischiRegBa");
    if (stabilimento == "Mondorevive Ferentino") getFile("infoRischiRegFe");
    if (stabilimento == "Caltek Chignolo Po") getFile("infoRischiRegChi");
    if (stabilimento == "MondoSD Bernate Ticino") getFile("infoRischiRegBe");
    if (stabilimento == "Mondorevive Marnate") getFile("infoRischiRegMa");
    if (stabilimento == "Diba Chignolo Po") getFile("infoRischiRegDi");
  };
  const handleDownloadDuvri = (stabilimento) => {
    if (stabilimento == "Mondorevive Busto Arsizio") getFile("duvriBa");
    if (stabilimento == "Mondorevive Ferentino") getFile("duvriFe");
    if (stabilimento == "Caltek Chignolo Po") getFile("duvriChi");
    if (stabilimento == "MondoSD Bernate Ticino") getFile("duvriBe");
    if (stabilimento == "Mondorevive Marnate") getFile("duvriMa");
    if (stabilimento == "Diba Chignolo Po") getFile("duvriDi");
  };
  //Gestisce le label della selezione di piu file
  const handleChangeLabelFile = (e) => {
    let label = "";
    for (var i = 0; i < e.length; i++) {
      if (i == e.length - 1) label += e[i].name;
      else label += e[i].name + "; ";
    }
    setLabelFile({ ...labelFile, dipendenti: label });
  };
  const handleChangeFileLavoratori = (e) => {
    setFileAttestati([...fileAttestati, { file: e[0] }]);
  };
  //Ottiene la label in base alla tipologia di modulo e allo stabilimento
  const getLabelFromStabilimento = (nome, tipo) => {
    if (nome == "Mondorevive Busto Arsizio") {
      return labelFile[tipo + "ba"];
    }
    if (nome == "Mondorevive Ferentino") {
      return labelFile[tipo + "fe"];
    }
    if (nome == "Caltek Chignolo Po") {
      return labelFile[tipo + "cp"];
    }
    if (nome == "MondoSD Bernate Ticino") {
      return labelFile[tipo + "bt"];
    }
    if (nome == "Mondorevive Marnate") {
      return labelFile[tipo + "ma"];
    }
    if (nome == "Diba Chignolo Po") {
      return labelFile[tipo + "di"];
    }
  };
  //Gestisce la rimozione di un file dalla lista dei file dei dipendenti
  const handleRemoveFile = (nome) => {
    let arr = [];
    fileAttestati.map((file) => {
      if (file.file.name != nome) arr.push(file);
    });
    setFileAttestati(arr);
  };
  function emailChecker() {
    let valid = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    if (valid) {
      setEmailNonValida(false);
    } else setEmailNonValida(true);
  }
  useEffect(() => {
    if (email == "") {
      return;
    }
    const timeOutId = setTimeout(() => emailChecker(), 500);
    return () => clearTimeout(timeOutId);
  }, [email]);
  //Funzione che modifica le informazioni di un attestato
  const handleChangeValueAttestato = (
    value,
    attestatoDaCambiare,
    campo,
    status
  ) => {
    let arr = [];
    attestati.map((attestato) => {
      if (attestato.label == attestatoDaCambiare.label) {
        if (status)
          arr.push({ ...attestato, [campo]: value, filename: value.name });
        else arr.push({ ...attestato, [campo]: value });
      } else arr.push(attestato);
    });
    setAttestati(arr);
  };
  //Funzione che modifica le informazioni aggiuntive di un dipendente
  const handleChangeInformazioniAggiuntive = (value, campo) => {
    setInformazioniAggiuntiveDipendente({
      ...informazioniAggiuntiveDipendente,
      [campo]: value,
    });
  };
  const checkIfPresente = (filename) => {
    let trovato = false;
    dipendenti.map((dipendente) => {
      if (
        dipendente.filenameIdoneitaSanitaria == filename ||
        dipendente.filenameUnilav == filename
      ) {
        trovato = true;
      }
      dipendente.attestatiList.map((attestato) => {
        if (attestato.filename == filename) {
          trovato = true;
        }
      });
    });
    if (
      (informazioniAggiuntiveDipendente.file &&
        informazioniAggiuntiveDipendente.file.name == filename) ||
      (informazioniAggiuntiveDipendente.fileUnilav &&
        informazioniAggiuntiveDipendente.fileUnilav.name == filename)
    )
      trovato = true;
    attestati.map((attestato) => {
      if (attestato.filename == filename) trovato = true;
    });
    return trovato;
  };

  const changeFilename = (file) => {
    let name = file.name;
    let cont = 2;
    while (checkIfPresente(name)) {
      let filename = file.name.split(".");
      name = filename[0] + "(" + cont + ")" + "." + filename[1];
      cont++;
    }
    const newFile = new File([file], name);
    return { file: newFile, name: name };
  };

  //Funzioen che controlla se disabilitare o meno l`invio
  const controllaInvio = () => {
    let campiMancanti = [];
    if (!datiGenerali.dataInizio) campiMancanti.push("Data inizio presunta");
    if (!datiGenerali.dataFine) campiMancanti.push("Data fine presunta");
    if (!datiGenerali.descrizione)
      campiMancanti.push("Descrizione intervento ");
    if (!stabilimentiSelezionati.length) campiMancanti.push("Stabilimento");
    if (!codiceEtico && idAppaltoPadre === undefined)
      campiMancanti.push("Codice etico");
    dipendenti.map((dipendente) => {
      let contieneAccordo = false;
      let tuttiCampi = true;

      dipendente.attestatiList.map((attestato) => {
        if (attestato.label == "ACCORDO STATO/REGIONI") contieneAccordo = true;
        if (!attestato.dataScadenza || !attestato.file) tuttiCampi = false;
      });
      if (!dipendente.dataScadenzaIdoneita)
        campiMancanti.push(
          "Dipendente " +
            dipendente.denominazione +
            " Inserire campo Data scadenza idoneità sanitaria"
        );
      if (
        !dipendente.socioFondatore &&
        !dipendente.tempoIndeterminato &&
        !dipendente.dataScadenzaUnilav
      )
        campiMancanti.push(
          "Dipendente " +
            dipendente.denominazione +
            " Inserire campo Data scadenza UNILAV"
        );
      if (!dipendente.fileIdoneita)
        campiMancanti.push(
          "Dipendente " +
            dipendente.denominazione +
            " Inserire campo File Idoneità Sanitaria"
        );
      if (!dipendente.socioFondatore && !dipendente.fileUnilav)
        campiMancanti.push(
          "Dipendente " +
            dipendente.denominazione +
            " Inserire campo File UNILAV"
        );

      if (!tuttiCampi)
        campiMancanti.push(
          "Dipendente " +
            dipendente.denominazione +
            " in uno o più attestati non è stato inserito il file e/o la data scadenza."
        );
      if (!contieneAccordo)
        campiMancanti.push(
          "Dipendente " +
            dipendente.denominazione +
            " manca attestato ACCORDO STATO/REGIONI."
        );
    });
    setInvioDisabilitato(campiMancanti);
  };
  useEffect(() => {
    controllaInvio();
  }, [dipendenti, datiGenerali, codiceEtico, stabilimentiSelezionati]);
  const [disabledAggiuntaDipendente, setDisabledAggiuntaDipendente] =
    useState(true);
  useEffect(() => {
    let disabled = false;
    attestati.map((attestato) => {
      if (attestato.label == "ACCORDO STATO/REGIONI" && !attestato.status)
        disabled = true;

      if (attestato.status && (!attestato.file || !attestato.dataScadenza))
        disabled = true;
    });
    if (
      !informazioniAggiuntiveDipendente.dataScadenza ||
      (!informazioniAggiuntiveDipendente.socioFondatore &&
        !informazioniAggiuntiveDipendente.tempoIndeterminato &&
        !informazioniAggiuntiveDipendente.dataScadenzaUnilav) ||
      !informazioniAggiuntiveDipendente.file ||
      (!informazioniAggiuntiveDipendente.socioFondatore &&
        !informazioniAggiuntiveDipendente.fileUnilav)
    )
      disabled = true;
    if (nome == "") disabled = true;
    setDisabledAggiuntaDipendente(disabled);
  }, [attestati, informazioniAggiuntiveDipendente, nome]);
  return (
    <div className="form-container">
      <ToastContainer
        transition={Flip}
        newestOnTop
        rtl={false}
        pauseOnFocusLoss
      />
      {loading ? (
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Loader size={100} variant="dots" />
        </div>
      ) : (
        <>
          <h1 className="mt20">
            {idAppaltoPadre === undefined
              ? "Aggiungi un appalto"
              : "Aggiungi un subappalto"}
          </h1>
          <Modal
            hideCloseButton
            onClose={() => {}}
            transition="fade"
            centered
            opened={opened}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
              className="center"
            >
              <h3 style={{ marginBottom: "30px" }}>
                {progress < 100
                  ? "Stiamo caricando i tuoi allegati, attendi."
                  : "Allegati caricati con successo, attendi ancora un istante!"}
              </h3>
              <div style={{ width: "100%" }}>
                <Progress value={progress} striped animate />
              </div>
            </div>
          </Modal>

          <form className="payment-form">
            <h2 className="card-label mt20">Dati Generali *</h2>
            <div className="container_dati_generali">
              <div className="container_date_dati_generali mt20">
                <div className="form-group maxW300 mb0">
                  <DatePicker
                    value={datiGenerali.dataInizio}
                    locale="it"
                    label={"Data Inizio presunta"}
                    onChange={(e) => {
                      setDatiGenerali({ ...datiGenerali, dataInizio: e });
                    }}
                    placeholder={"Data Inizio presunta"}
                    size="lg"
                    required
                    sx={{ maxWidth: "300px" }}
                  />
                </div>
                <div className="maxW300 mb0">
                  <DatePicker
                    value={datiGenerali.dataFine}
                    locale="it"
                    required
                    label={"Data Fine presunta"}
                    onChange={(e) => {
                      setDatiGenerali({ ...datiGenerali, dataFine: e });
                    }}
                    placeholder={"Data Fine presunta "}
                    size="lg"
                    sx={{ maxWidth: "300px" }}
                  />
                </div>
              </div>
              <div className="container_descrizione_dati_generali mt20">
                <Textarea
                  onChange={(e) => {
                    setDatiGenerali({
                      ...datiGenerali,
                      descrizione: e.target.value,
                    });
                  }}
                  placeholder="Descrizione Intervento"
                  radius="md"
                  label={"Descrizione Intervento"}
                  size="md"
                  required
                />
              </div>
            </div>
            <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
            {idAppaltoPadre !== undefined ? (
              <div>
                <h2> Dati Azienda</h2>
                <div>
                  <div className="form-group">
                    <label className="card-label">Nome azienda</label>
                    <Input
                      type="text"
                      name="name"
                      size="lg"
                      ref={nomeAzienda}
                      spellCheck="false"
                      autoComplete="off"
                      id="cardHolder"
                      className="form-control form-control-lg"
                    />
                  </div>
                  <div className="form-group">
                    <label className="card-label">Indirizzo</label>
                    <Input
                      type="text"
                      name="name"
                      size="lg"
                      ref={indirizzoAzienda}
                      spellCheck="false"
                      autoComplete="off"
                      id="cardHolder"
                      className="form-control form-control-lg"
                    />
                  </div>
                  <div className="form-group">
                    <label className="card-label">PEC</label>
                    <Input
                      type="text"
                      name="name"
                      size="lg"
                      ref={pecAzienda}
                      spellCheck="false"
                      autoComplete="off"
                      id="cardHolder"
                      className="form-control form-control-lg"
                    />
                  </div>
                </div>
                <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
              </div>
            ) : null}
            <h2> Dati Richiesta</h2>
            <div className="grid">
              <div className="form-group">
                <label className="card-label">E-Mail Aggiuntive </label>
                {allEmail &&
                  allEmail.map((emails, count) => (
                    <div className="inline-item center">
                      <Input
                        key={count}
                        type="text"
                        name="name"
                        size="lg"
                        disabled
                        value={emails}
                        placeholder="Nome e Cognome"
                        spellCheck="false"
                        autoComplete="off"
                        id="cardHolder"
                        style={{ marginBottom: "20px" }}
                        className="form-control form-control-lg"
                      />
                      <div className="form-group">
                        <Button
                          color={"red"}
                          onClick={() => rimuoviEmail(emails)}
                        >
                          Rimuovi
                        </Button>
                      </div>
                    </div>
                  ))}
                <div className="grid-container repeat2 pl0 center">
                  <Input
                    type="text"
                    name="name"
                    size="lg"
                    invalid={emailNonValida && email != ""}
                    style={{ width: "100%" }}
                    placeholder="E-Mail"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    spellCheck="false"
                    autoComplete="off"
                    id="cardHolder"
                    className="form-control form-control-lg"
                  />
                  <Button
                    className="mw400"
                    disabled={email == "" || emailNonValida}
                    color={"green"}
                    onClick={aggiungiEmail}
                  >
                    Aggiungi
                  </Button>
                </div>
              </div>
            </div>
            <div className="grid-container center repeat2 pl0">
              <div className="form-group">
                <label className="card-label">Numero di Telefono</label>
                <Input
                  type="number"
                  name="name"
                  size="lg"
                  spellCheck="false"
                  onChange={(e) => setTelefono(e.target.value)}
                  autoComplete="off"
                  id="cardHolder"
                  className="form-control"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="form-group">
                <label className="card-label">Referente Azienda</label>
                <Input
                  type="text"
                  ref={nomeReferenteAzienda}
                  name="name"
                  size="lg"
                  spellCheck="false"
                  autoComplete="off"
                  id="cardHolder"
                  className="form-control form-control-lg"
                />
              </div>
            </div>
            {/* RESPONSABILI */}
            <h2 className="card-label">Responsabili</h2>
            {/* RSPP */}
            <div className="grid-container center repeat2 pl0">
              <div className="form-group">
                <label className="card-label">Cognome e Nome RSPP</label>

                <Input
                  type="text"
                  name="name"
                  size="lg"
                  spellCheck="false"
                  onChange={(e) =>
                    setResponsabili({ ...responsabili, rspp: e.target.value })
                  }
                  autoComplete="off"
                  id="cardHolder"
                  className="form-control form-control-lg"
                />
              </div>
              <div className="form-group">
                <label className="card-label">Data Nomina</label>

                <DatePicker
                  value={dataNomina.rspp}
                  locale="it"
                  onChange={(e) => setDataNomina({ ...dataNomina, rspp: e })}
                  placeholder={"Seleziona una data"}
                  size="lg"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            {/* RLS */}
            <label
              className="card-label"
              style={{ color: "rgb(100, 100, 100)", fontWeight: "500" }}
            >
              Cognome e Nome RLS
            </label>
            {rlsAll.length != 0 && (
              <div>
                {rlsAll.map((rlss, count) => (
                  <div
                    className="grid-container center repeat3 pl0"
                    key={count}
                  >
                    <Input
                      type="text"
                      name="name"
                      size="lg"
                      disabled
                      value={rlss.denominazione}
                      placeholder="Denominazione"
                      spellCheck="false"
                      autoComplete="off"
                      id="cardHolder"
                      className="form-control form-control-lg"
                    />
                    <DatePicker
                      value={rlss.dataNominaRls}
                      locale="it"
                      placeholder={"Seleziona una data"}
                      size="lg"
                      style={{ width: "100%" }}
                    />
                    <Button
                      className="mw200"
                      color={"red"}
                      onClick={() => handleRemoveRls(rlss)}
                    >
                      Rimuovi
                    </Button>
                  </div>
                ))}
              </div>
            )}
            <div className="grid-container repeat3 pl0">
              <div className="form-group mb0">
                <Input
                  type="text"
                  size="lg"
                  name="name"
                  spellCheck="false"
                  value={rls.denominazione}
                  placeholder={"Cognome e Nome RLS"}
                  onChange={(e) =>
                    setRls({ ...rls, denominazione: e.target.value })
                  }
                  autoComplete="off"
                  id="cardHolder"
                  className="form-control form-control-lg"
                />
              </div>
              <div className="form-group mb0">
                <DatePicker
                  value={rls.dataNominaRls}
                  locale="it"
                  onChange={(e) => {
                    setRls({ ...rls, dataNominaRls: e });
                  }}
                  placeholder={"Data Nomina"}
                  size="lg"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="inline-item center mb0">
                <Button
                  color={"green"}
                  onClick={handleAggiuntaRlss}
                  disabled={
                    rls.dataNominaRls == null || rls.denominazione == ""
                  }
                >
                  Aggiungi
                </Button>
              </div>
            </div>
            {/* DDL */}
            <div className="grid-container center repeat2 pl0">
              <div className="form-group">
                <label className="card-label">Cognome e Nome Ddl</label>

                <Input
                  type="text"
                  name="name"
                  onChange={(e) =>
                    setResponsabili({ ...responsabili, ddl: e.target.value })
                  }
                  size="lg"
                  spellCheck="false"
                  autoComplete="off"
                  id="cardHolder"
                  className="form-control form-control-lg"
                />
              </div>
              <div className="form-group">
                <label className="card-label">Data Incarico</label>

                <DatePicker
                  value={dataNomina.ddl}
                  locale="it"
                  onChange={(e) => setDataNomina({ ...dataNomina, ddl: e })}
                  placeholder={"Seleziona una data"}
                  size="lg"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            {/* MC */}
            <div className="grid-container center repeat2 pl0">
              <div className="form-group">
                <label className="card-label">Cognome e Nome MC</label>

                <Input
                  type="text"
                  name="name"
                  size="lg"
                  onChange={(e) =>
                    setResponsabili({ ...responsabili, mc: e.target.value })
                  }
                  spellCheck="false"
                  autoComplete="off"
                  id="cardHolder"
                  className="form-control form-control-lg"
                />
              </div>
              <div className="form-group">
                <label className="card-label">Data Nomina</label>

                <DatePicker
                  value={dataNomina.mc}
                  locale="it"
                  onChange={(e) => setDataNomina({ ...dataNomina, mc: e })}
                  placeholder={"Seleziona una data"}
                  size="lg"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            {/* PREPOSTI DI CANTIERE*/}
            <div className="grid-container center repeat2 pl0">
              <div className="form-group">
                <label className="card-label">Preposti di cantiere</label>
                {preposti &&
                  preposti.map((preposto, count) => (
                    <div className="inline-item center">
                      <Input
                        key={count}
                        type="text"
                        name="name"
                        size="lg"
                        disabled
                        value={preposto}
                        spellCheck="false"
                        autoComplete="off"
                        id="cardHolder"
                        style={{ marginBottom: "20px" }}
                        className="form-control form-control-lg"
                      />
                      <div className="form-group">
                        <Button
                          color={"red"}
                          onClick={() => rimuoviPreposto(preposto)}
                        >
                          Rimuovi
                        </Button>
                      </div>
                    </div>
                  ))}
                <div className="inline-item center">
                  <Input
                    type="text"
                    name="name"
                    size="lg"
                    placeholder="Nome e Cognome"
                    value={nomePreposto}
                    onChange={(e) => setNomePreposto(e.target.value)}
                    spellCheck="false"
                    autoComplete="off"
                    id="cardHolder"
                    className="form-control form-control-lg"
                  />
                  <Button
                    disabled={nomePreposto == ""}
                    color={"green"}
                    onClick={aggiungiPreposto}
                  >
                    Aggiungi
                  </Button>
                </div>
              </div>
            </div>
            <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
            <div className="form-group">
              <h2 className="card-label">Certificazioni</h2>
              <div className="checkbox">
                <div className="inline-item mb10">
                  <Checkbox
                    size="lg"
                    label=" ISO9001"
                    checked={checkedCertificazioni.iso9001}
                    onChange={(e) =>
                      setCheckedCertificazioni({
                        ...checkedCertificazioni,
                        iso9001: e.currentTarget.checked,
                      })
                    }
                  />

                  {checkedCertificazioni.iso9001 && (
                    <div className={"center"}>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        id="iso9001"
                        onChange={(e) => {
                          if (e.target.value == "") return;
                          if (e.target.files[0].size / 1024 / 1024 > 50) {
                            setErrorSize(true);
                            return;
                          }
                          setFileCertificazioni({
                            ...fileCertificazioni,
                            iso9001: e.target.files[0],
                          });
                          setLabelFile({
                            ...labelFile,
                            iso9001: e.target.files[0].name,
                          });
                        }}
                      />
                      <Button
                        className="mw400"
                        onClick={() => handleClickOpenFile("iso9001")}
                      >
                        Scegli File
                      </Button>
                      <label style={{ marginLeft: "10px" }}>
                        {labelFile.iso9001
                          ? labelFile.iso9001
                          : "Nessun file Selezionato"}
                      </label>
                    </div>
                  )}
                </div>
                <div className="inline-item mb10">
                  <Checkbox
                    size="lg"
                    label=" ISO14001"
                    checked={checkedCertificazioni.iso14001}
                    onChange={(e) =>
                      setCheckedCertificazioni({
                        ...checkedCertificazioni,
                        iso14001: e.currentTarget.checked,
                      })
                    }
                  />
                  {checkedCertificazioni.iso14001 && (
                    <div className={"center"}>
                      <input
                        type="file"
                        id="iso14001"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          if (e.target.value == "") return;
                          if (e.target.files[0].size / 1024 / 1024 > 50) {
                            setErrorSize(true);
                            return;
                          }
                          setFileCertificazioni({
                            ...fileCertificazioni,
                            iso14001: e.target.files[0],
                          });
                          setLabelFile({
                            ...labelFile,
                            iso14001: e.target.files[0].name,
                          });
                        }}
                      />
                      <Button
                        className="mw400"
                        onClick={() => handleClickOpenFile("iso14001")}
                      >
                        Scegli File
                      </Button>
                      <label style={{ marginLeft: "10px" }}>
                        {labelFile.iso14001
                          ? labelFile.iso14001
                          : "Nessun file Selezionato"}
                      </label>
                    </div>
                  )}
                </div>
                <div className="inline-item mb10">
                  <Checkbox
                    size="lg"
                    label=" ISO45001"
                    checked={checkedCertificazioni.iso45001}
                    onChange={(e) =>
                      setCheckedCertificazioni({
                        ...checkedCertificazioni,
                        iso45001: e.currentTarget.checked,
                      })
                    }
                  />
                  {checkedCertificazioni.iso45001 && (
                    <div className={"center"}>
                      <input
                        type="file"
                        id="iso45001"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          if (e.target.value == "") return;
                          if (e.target.files[0].size / 1024 / 1024 > 50) {
                            setErrorSize(true);
                            return;
                          }
                          setFileCertificazioni({
                            ...fileCertificazioni,
                            iso45001: e.target.files[0],
                          });
                          setLabelFile({
                            ...labelFile,
                            iso45001: e.target.files[0].name,
                          });
                        }}
                      />
                      <Button
                        className="mw400"
                        onClick={() => handleClickOpenFile("iso45001")}
                      >
                        Scegli File
                      </Button>
                      <label style={{ marginLeft: "10px" }}>
                        {labelFile.iso45001
                          ? labelFile.iso45001
                          : "Nessun file Selezionato"}
                      </label>
                    </div>
                  )}
                </div>
                <div className="inline-item mb10">
                  <Checkbox
                    size="lg"
                    label=" SA8000"
                    checked={checkedCertificazioni.sa8000}
                    onChange={(e) =>
                      setCheckedCertificazioni({
                        ...checkedCertificazioni,
                        sa8000: e.currentTarget.checked,
                      })
                    }
                  />
                  {checkedCertificazioni.sa8000 && (
                    <div className={"center"}>
                      <input
                        type="file"
                        id="sa8000"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          if (e.target.value == "") return;
                          if (e.target.files[0].size / 1024 / 1024 > 50) {
                            setErrorSize(true);
                            return;
                          }
                          setFileCertificazioni({
                            ...fileCertificazioni,
                            sa8000: e.target.files[0],
                          });
                          setLabelFile({
                            ...labelFile,
                            sa8000: e.target.files[0].name,
                          });
                        }}
                      />
                      <Button
                        className="mw400"
                        onClick={() => handleClickOpenFile("sa8000")}
                      >
                        Scegli File
                      </Button>
                      <label style={{ marginLeft: "10px" }}>
                        {labelFile.sa8000
                          ? labelFile.sa8000
                          : "Nessun file Selezionato"}
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
            <div className="grid-container repeat2 pl0">
              <div>
                <label className="card-label">Referente Interno</label>
                <Input
                  type="text"
                  name="name"
                  value={referente}
                  onChange={(e) => setReferente(e.target.value)}
                  size="lg"
                  spellCheck="false"
                  autoComplete="off"
                  id="cardHolder"
                  style={{ width: "100%" }}
                  className="form-control form-control-lg"
                />
              </div>
            </div>
            <div className="grid-container repeat2 pl0">
              <div className="form-group">
                <label className="card-label">Appalto/Subappalto</label>
                <Select
                  placeholder="Scegline uno"
                  value={appalto}
                  onChange={setAppalto}
                  size="lg"
                  data={[
                    { value: "appalto", label: "Appalto" },
                    { value: "subappalto", label: "Subappalto" },
                  ]}
                />
              </div>
              <div className="form-group">
                <label className="card-label">Azienda/Autonomo</label>
                <Select
                  size="lg"
                  value={azienda}
                  onChange={setAzienda}
                  placeholder="Scegline uno"
                  data={[
                    { value: "azienda", label: "Azienda" },
                    { value: "Autonomo", label: "Autonomo" },
                  ]}
                />
              </div>
            </div>
            <div className="grid-container repeat2 pl0">
              <div>
                <label className="card-label">
                  Seleziona lo stabilimento *
                </label>
                <MultiSelect
                  style={{ width: "100%" }}
                  searchable
                  value={stabilimentiSelezionati.value}
                  onChange={setStabilimentiSelezionati}
                  size="lg"
                  data={stabilimenti}
                  placeholder="Seleziona una o più scelte"
                />
              </div>
            </div>
            <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
            <h2 className="card-label">Camera Di Commercio</h2>
            <div className="grid-container repeat2 pl0">
              <div className="form-group">
                <label className="card-label">Codice Alfanumerico</label>
                <div className="inline-item">
                  <Input
                    size="lg"
                    value={codice}
                    onChange={(e) => setCodice(e.target.value)}
                    type="text"
                    name="name"
                    spellCheck="false"
                    autoComplete="off"
                    id="cardHolder"
                    className="form-control form-control-lg"
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="card-label">Data di visura camerale</label>

                <DatePicker
                  size="lg"
                  value={visura}
                  locale="it"
                  onChange={(e) => setVisura(e)}
                  placeholder={"Seleziona una data"}
                />
              </div>
            </div>
            <div className="grid-container repeat2File pl0 center">
              <input
                type="file"
                id="camera"
                style={{ display: "none" }}
                onChange={(e) => {
                  if (e.target.value == "") return;
                  if (e.target.files[0].size / 1024 / 1024 > 50) {
                    setErrorSize(true);
                    return;
                  }
                  setFileCamera({
                    ...fileCamera,
                    iscrizione: e.target.files[0],
                  });
                  setLabelFile({
                    ...labelFile,
                    camera: e.target.files[0].name,
                  });
                }}
              />
              <Button
                className="mw400"
                onClick={() => handleClickOpenFile("camera")}
              >
                Scegli File
              </Button>
              <label style={{ marginLeft: "10px" }}>
                {labelFile.camera
                  ? labelFile.camera
                  : "Nessun file Selezionato"}
              </label>
            </div>
            <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
            <div className="grid-container repeat2File pl0 center">
              <div className="form-group">
                <label className="card-label">Data certa DVR</label>
                <DatePicker
                  size="lg"
                  locale="it"
                  value={dataDvr}
                  onChange={(e) => setDataDvr(e)}
                  placeholder={"Seleziona una data"}
                />
              </div>
              <div className="form-group">
                <label className="card-label">Allega Documento</label>
                <div className="inline-item">
                  <input
                    type="file"
                    style={{ display: "none" }}
                    id="dvr"
                    onChange={(e) => {
                      if (e.target.value == "") return;
                      if (e.target.files[0].size / 1024 / 1024 > 50) {
                        setErrorSize(true);
                        return;
                      }
                      setFileCamera({ ...fileCamera, dvr: e.target.files[0] });
                      setLabelFile({
                        ...labelFile,
                        dvr: e.target.files[0].name,
                      });
                    }}
                  />
                  <div className="grid-container repeat2File pl0 center">
                    <Button
                      className="mw400"
                      onClick={() => handleClickOpenFile("dvr")}
                    >
                      Scegli File
                    </Button>
                    <label style={{ marginLeft: "10px" }}>
                      {labelFile.dvr
                        ? labelFile.dvr
                        : "Nessun file Selezionato"}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-container repeat2File pl0 center">
              <div className="form-group">
                <label className="card-label">Dichiarazione 445:</label>
                <Button
                  style={{ width: "100%", maxWidth: "500px" }}
                  color="green"
                  onClick={() => getFile("dichiarazione445")}
                >
                  Download MODELLO
                </Button>
              </div>
              <div className="form-group"></div>
              <div className="form-group">
                <label className="card-label">
                  Data dichiarazione 445(scadenza 12 mesi)
                </label>
                <DatePicker
                  size="lg"
                  locale="it"
                  value={dataDichiarazione445}
                  onChange={(e) => setDataDichiarazione445(e)}
                  placeholder={"Seleziona una data"}
                />
              </div>
              <div className="form-group">
                <label className="card-label">Allega documento</label>
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="dichiarazione445"
                  onChange={(e) => {
                    if (e.target.value == "") return;
                    if (e.target.files[0].size / 1024 / 1024 > 50) {
                      setErrorSize(true);
                      return;
                    }
                    setFileCamera({ ...fileCamera, doc445: e.target.files[0] });
                    setLabelFile({
                      ...labelFile,
                      dichiarazione445: e.target.files[0].name,
                    });
                  }}
                />
                <div className="grid-container repeat2File pl0 center">
                  <Button
                    className="mw400"
                    onClick={() => handleClickOpenFile("dichiarazione445")}
                  >
                    Scegli File
                  </Button>
                  <label style={{ marginLeft: "10px" }}>
                    {labelFile.dichiarazione445
                      ? labelFile.dichiarazione445
                      : "Nessun file Selezionato"}
                  </label>
                </div>
              </div>
            </div>
            <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
            <h2 className="card-label">Dipendenti e Attestati</h2>
            <DipendentiList
              dipendenti={dipendenti}
              setDipendenti={setDipendenti}
              dpiList={dpiUnchanged}
              attestatiList={attestatiUnchanged}
              disabled={false}
              setErrorSize={setErrorSize}
            />
            <div className="grid-container repeat1 pl0 center">
              {showInserimentoDipendente ? (
                <Button
                  style={{ marginBottom: "15px", maxWidth: "300px" }}
                  onClick={() => {
                    setShowInserimentoDipendente(false);
                    resetDipendenti();
                  }}
                  color="orange"
                >
                  Annulla Inserimento
                </Button>
              ) : (
                <Button
                  style={{ marginBottom: "15px", maxWidth: "300px" }}
                  onClick={() => setShowInserimentoDipendente(true)}
                  color="green"
                >
                  Aggiungi Dipendente
                </Button>
              )}
              {showInserimentoDipendente && (
                <>
                  <Input
                    type="text"
                    name="name"
                    spellCheck="false"
                    autoComplete="off"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    placeholder="Nome e Cognome"
                    id="cardHolder"
                    className="form-control form-control-lg"
                    size={"lg"}
                  />
                  <h2 className="card-label">UNILAV - Idoneità sanitaria</h2>
                  <div className="form-group maxW margin0">
                    <label className="card-label">Socio fondatore</label>
                    <Checkbox
                      checked={informazioniAggiuntiveDipendente.socioFondatore}
                      size={"lg"}
                      onChange={(e) => {
                        handleChangeInformazioniAggiuntive(
                          e.target.checked,
                          "socioFondatore"
                        );
                      }}
                    />
                  </div>
                  <div className="informazioniAggiuntiveDipendente">
                    {!informazioniAggiuntiveDipendente.socioFondatore && (
                      <div>
                        <div className="form-group maxW margin0">
                          <label className="card-label">UNILAV *</label>
                          <div>
                            <input
                              type="file"
                              id="unilav"
                              style={{ display: "none" }}
                              onChange={(e) => {
                                if (e.target.files[0].size / 1024 / 1024 > 50) {
                                  setErrorSize(true);
                                  return;
                                }

                                let { file, name } = changeFilename(
                                  e.target.files[0]
                                );

                                handleChangeInformazioniAggiuntive(
                                  file,
                                  "fileUnilav"
                                );
                              }}
                            />
                            <div
                              className="grid-container center padding0"
                              style={{ width: "fit-content" }}
                            >
                              <Button
                                className="mw400"
                                onClick={() => handleClickOpenFile("unilav")}
                              >
                                Scegli File
                              </Button>
                              <label style={{ marginLeft: "10px" }}>
                                {informazioniAggiuntiveDipendente.fileUnilav
                                  ? informazioniAggiuntiveDipendente.fileUnilav
                                      .name
                                  : "Nessun file Selezionato"}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {!informazioniAggiuntiveDipendente.tempoIndeterminato &&
                      !informazioniAggiuntiveDipendente.socioFondatore && (
                        <div className="form-group maxW300 margin0">
                          <label className="card-label">
                            Data Scadenza UNILAV *
                          </label>
                          <DatePicker
                            value={
                              informazioniAggiuntiveDipendente.dataScadenzaUnilav
                            }
                            locale="it"
                            sx={{ minWidth: "300px" }}
                            onChange={(e) =>
                              handleChangeInformazioniAggiuntive(
                                e,
                                "dataScadenzaUnilav"
                              )
                            }
                            placeholder={"Seleziona una data"}
                            size="lg"
                            style={{ width: "100%" }}
                          />
                        </div>
                      )}
                    {!informazioniAggiuntiveDipendente.socioFondatore && (
                      <div className="container_tempo_indeterminato">
                        <div className="form-group maxW margin0">
                          <label className="card-label">
                            Tempo Indeterminato
                          </label>
                          <Checkbox
                            checked={
                              informazioniAggiuntiveDipendente.tempoIndeterminato
                            }
                            size={"lg"}
                            onChange={(e) => {
                              handleChangeInformazioniAggiuntive(
                                e.target.checked,
                                "tempoIndeterminato"
                              );
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="informazioniAggiuntiveDipendente mt20">
                    <div className="form-group maxW margin0">
                      <label className="card-label">Idoneità sanitaria *</label>
                      <input
                        type="file"
                        id="fileIdoneita"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          if (e.target.files[0].size / 1024 / 1024 > 50) {
                            setErrorSize(true);
                            return;
                          }
                          let { file, name } = changeFilename(
                            e.target.files[0]
                          );
                          handleChangeInformazioniAggiuntive(file, "file");
                          //handleChangeFileLavoratori(e.target.files);
                        }}
                      />
                      <div
                        className=" grid-container center padding0"
                        style={{ width: "fit-content" }}
                      >
                        <Button
                          className="mw400"
                          onClick={() => handleClickOpenFile("fileIdoneita")}
                        >
                          Scegli File
                        </Button>
                        <label style={{ marginLeft: "10px" }}>
                          {informazioniAggiuntiveDipendente.file
                            ? informazioniAggiuntiveDipendente.file.name
                            : "Nessun file Selezionato"}
                        </label>
                      </div>
                    </div>
                    <div className="form-group maxW300 margin0">
                      <label className="card-label">
                        Data Scadenza Idoneità sanitaria *
                      </label>
                      <DatePicker
                        value={informazioniAggiuntiveDipendente.dataScadenza}
                        locale="it"
                        sx={{ minWidth: "300px" }}
                        onChange={(e) =>
                          handleChangeInformazioniAggiuntive(e, "dataScadenza")
                        }
                        placeholder={"Seleziona una data"}
                        size="lg"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <h4 className="card-label">DPI</h4>

                  <div className="grid-container">
                    {dpi.map((dpis) => (
                      <Checkbox
                        size="md"
                        key={dpis.label}
                        label={dpis.label}
                        checked={dpis.status}
                        onChange={() => handleChangeDpi(dpis)}
                      />
                    ))}
                  </div>
                  <h4 className="card-label">Attestati</h4>
                  <div className="grid-containerAttestati">
                    {attestati.map((attestato) => (
                      <div className="itemAttestato">
                        <Checkbox
                          key={attestato.label}
                          size="md"
                          label={attestato.label}
                          checked={attestato.status}
                          onChange={() => handleChangeAttestati(attestato)}
                        />
                        {attestato.status && (
                          <div className="informazioniAggiuntiveDipendente">
                            <div className="form-group maxW margin0">
                              <label className="card-label">Attestato </label>
                              <div>
                                <input
                                  type="file"
                                  id={attestato.label.split(" ")[0]}
                                  style={{ display: "none" }}
                                  onChange={(e) => {
                                    if (
                                      e.target.files[0].size / 1024 / 1024 >
                                      50
                                    ) {
                                      setErrorSize(true);
                                      return;
                                    }

                                    let { file, name } = changeFilename(
                                      e.target.files[0]
                                    );
                                    handleChangeValueAttestato(
                                      file,
                                      attestato,
                                      "file",
                                      true
                                    );
                                  }}
                                />
                                <div
                                  className="grid-container center padding0"
                                  style={{ width: "fit-content" }}
                                >
                                  <Button
                                    className="w150"
                                    onClick={() =>
                                      handleClickOpenFile(
                                        attestato.label.split(" ")[0]
                                      )
                                    }
                                  >
                                    Scegli File *
                                  </Button>
                                  <label style={{ marginLeft: "10px" }}>
                                    {attestato.file
                                      ? attestato.file.name
                                      : "Nessun file Selezionato"}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-group maxW300 margin0">
                              <label className="card-label">
                                Data Scadenza *
                              </label>
                              <DatePicker
                                value={attestato.dataScadenza}
                                locale="it"
                                onChange={(e) =>
                                  handleChangeValueAttestato(
                                    e,
                                    attestato,
                                    "dataScadenza"
                                  )
                                }
                                placeholder={"Seleziona una data"}
                                size="lg"
                                style={{ width: "100%" }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Button
                      style={{
                        marginBottom: "20px",
                        maxWidth: "400px",
                        minWidth: "50vw",
                      }}
                      disabled={disabledAggiuntaDipendente}
                      onClick={aggiungiDipendente}
                    >
                      Aggiungi
                    </Button>
                  </div>
                </>
              )}
              <input
                type="file"
                id="dipendenti"
                style={{ display: "none" }}
                onChange={(e) => {
                  if (e.target.files[0].size / 1024 / 1024 > 50) {
                    setErrorSize(true);
                    return;
                  }
                  handleChangeFileLavoratori(e.target.files);
                }}
              />
              {false ? (
                <>
                  <h2 className="card-label">Allegati Aggiuntivi</h2>
                  <div
                    className="grid-container repeat2File pl0 center"
                    style={{ width: "fit-content" }}
                  >
                    <Button
                      className="mw400"
                      onClick={() => handleClickOpenFile("dipendenti")}
                    >
                      Scegli File
                    </Button>
                  </div>
                  {fileAttestati &&
                    fileAttestati.map((file) => (
                      <div
                        style={{ maxWidth: "500px " }}
                        className="grid-container repeat2 pl0 center"
                      >
                        <Button
                          className="mw400"
                          sx={{ color: "black !important" }}
                          disabled={!file.file.idAttestato}
                          onClick={() => {
                            if (file.idAttestat)
                              getFile(
                                "attestatilavoratori",
                                file.file.idAttestato
                              );
                          }}
                        >
                          {file.file.name}
                        </Button>
                        <FontAwesomeIcon
                          style={{ cursor: "pointer" }}
                          icon={faTimes}
                          color={"red"}
                          size="lg"
                          onClick={() => handleRemoveFile(file.file.name)}
                        />
                      </div>
                    ))}
                </>
              ) : null}
            </div>
            <Divider style={{ marginTop: "30px", marginBottom: "60px" }} />
            <h2 className="card-label">Poliza assicurativa RTC RCO</h2>
            <div className="form-group">
              <div className="grid-container repeat2 pl0 center">
                <DatePicker
                  size="lg"
                  value={dataScadenzaRTC}
                  locale="it"
                  onChange={(e) => setDataScadenzaRTC(e)}
                  placeholder={"Data Scadenza"}
                />
                <div>
                  <input
                    type="file"
                    id="poliza"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      if (e.target.value == "") return;
                      if (e.target.files[0].size / 1024 / 1024 > 50) {
                        setErrorSize(true);
                        return;
                      }
                      setFileLavoratori({
                        ...FileLavoratori,
                        poliza: e.target.files[0],
                      });
                      setLabelFile({
                        ...labelFile,
                        poliza: e.target.files[0].name,
                      });
                    }}
                  />
                  <Button
                    className="mw400"
                    onClick={() => handleClickOpenFile("poliza")}
                  >
                    Scegli File
                  </Button>
                  <label style={{ marginLeft: "10px" }}>
                    {labelFile.poliza
                      ? labelFile.poliza
                      : "Nessun file Selezionato"}
                  </label>
                </div>
              </div>
            </div>
            <Divider style={{ marginTop: "30px", marginBottom: "60px" }} />
            <h2 className="card-label">DURC online validità 4 mesi</h2>
            <div className="form-group">
              <label className="card-label">Data scadenza</label>

              <div className="grid-container repeat2 pl0 center ">
                <DatePicker
                  size="lg"
                  value={dataDurc}
                  locale="it"
                  onChange={(e) => setDataDurc(e)}
                  placeholder={"Data Scadenza"}
                />
                <div>
                  <input
                    type="file"
                    id="durc"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      if (e.target.value == "") return;
                      if (e.target.files[0].size / 1024 / 1024 > 50) {
                        setErrorSize(true);
                        return;
                      }
                      setFileDurc(e.target.files[0]);
                      setLabelFile({
                        ...labelFile,
                        durc: e.target.files[0].name,
                      });
                    }}
                  />
                  <Button
                    className="mw400"
                    onClick={() => handleClickOpenFile("durc")}
                  >
                    Scegli File
                  </Button>
                  <label style={{ marginLeft: "10px" }}>
                    {labelFile.durc
                      ? labelFile.durc
                      : "Nessun file Selezionato"}
                  </label>
                </div>
              </div>
            </div>
            <Divider style={{ marginTop: "30px", marginBottom: "60px" }} />
            {stabilimentiSelezionati.map((stabilimento) => (
              <>
                <h1 style={{ color: "red" }}>{stabilimento}</h1>

                <h2 className="card-label">Sopralluogo MODULO 7</h2>
                <div className="form-group">
                  <label className="card-label">Da compilare e rimandare</label>

                  <div className="inline-item center">
                    <Button
                      style={{ width: "300px" }}
                      color="green"
                      onClick={() => handleDownloadModulo7(stabilimento)}
                    >
                      DOWNLOAD MODELLO
                    </Button>
                    <input
                      type="file"
                      id={"m7" + stabilimento}
                      style={{ display: "none" }}
                      onChange={(e) => {
                        if (e.target.value == "") return;
                        if (e.target.files[0].size / 1024 / 1024 > 50) {
                          setErrorSize(true);
                          return;
                        }
                        handleChangeModulo7(stabilimento, e.target.files[0]);
                      }}
                    />
                    <Button
                      className="mw400"
                      onClick={() => handleClickOpenFile("m7" + stabilimento)}
                    >
                      Scegli File
                    </Button>
                    <label style={{ marginLeft: "10px" }}>
                      {getLabelFromStabilimento(stabilimento, "m7")
                        ? getLabelFromStabilimento(stabilimento, "m7")
                        : "Nessun file Selezionato"}
                    </label>
                  </div>
                </div>

                <h2 className="card-label">
                  Informativa sui Rischi e Regolamenti MODULO 05 (modulo IO
                  firmato)
                </h2>
                <div className="form-group">
                  <label className="card-label">Da compilare e rimandare</label>

                  <div className="inline-item center">
                    <Button
                      style={{ width: "300px" }}
                      color="green"
                      onClick={() => handleDownloadModulo5(stabilimento)}
                    >
                      DOWNLOAD MODELLO
                    </Button>
                    <input
                      type="file"
                      id={"m5" + stabilimento}
                      style={{ display: "none" }}
                      onChange={(e) => {
                        if (e.target.value == "") return;
                        if (e.target.files[0].size / 1024 / 1024 > 50) {
                          setErrorSize(true);
                          return;
                        }
                        handleChangeModulo5(stabilimento, e.target.files[0]);
                      }}
                    />
                    <Button
                      className="mw400"
                      onClick={() => handleClickOpenFile("m5" + stabilimento)}
                    >
                      Scegli File
                    </Button>
                    <label style={{ marginLeft: "10px" }}>
                      {getLabelFromStabilimento(stabilimento, "m5")
                        ? getLabelFromStabilimento(stabilimento, "m5")
                        : "Nessun file Selezionato"}
                    </label>
                  </div>
                </div>
                <h2 className="card-label">DUVRI</h2>
                <div className="form-group">
                  <label className="card-label">Da compilare e rimandare</label>

                  <div className="inline-item center">
                    <Button
                      style={{ width: "300px" }}
                      color="green"
                      onClick={() => handleDownloadDuvri(stabilimento)}
                    >
                      DOWNLOAD MODELLO
                    </Button>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id={"duvri" + stabilimento}
                      onChange={(e) => {
                        if (e.target.value == "") return;
                        if (e.target.files[0].size / 1024 / 1024 > 50) {
                          setErrorSize(true);
                          return;
                        }
                        handleChangeDuvri(stabilimento, e.target.files[0]);
                      }}
                    />
                    <Button
                      className="mw400"
                      onClick={() =>
                        handleClickOpenFile("duvri" + stabilimento)
                      }
                    >
                      Scegli File
                    </Button>
                    <label style={{ marginLeft: "10px" }}>
                      {getLabelFromStabilimento(stabilimento, "du")
                        ? getLabelFromStabilimento(stabilimento, "du")
                        : "Nessun file Selezionato"}
                    </label>
                  </div>
                </div>
              </>
            ))}
          </form>
          {stabilimentiSelezionati.length > 0 && (
            <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
          )}
          {idAppaltoPadre === undefined ? (
            <div>
              <div className="codiceEtico">
                <h2 className="card-label">Codice Etico *</h2>
                <Button
                  onClick={(e) => {
                    getFile("codiceEtico");
                  }}
                >
                  Download Codice Etico
                </Button>
              </div>
              <h4>
                Con l'accettazione da parte vostra dell' allegato CODICE ETICO E
                DI CONDOTTA, dichiarate inoltre la vostra consapevolezza sul
                fatto che l'oggetto della vostra fornitura di
                prodotti/attrezzature e/o erogazione di servizi/appalti deve
                avvenire in conformità a tutti i relativi requisiti applicabili,
                sia legali/normativi che contrattuali, inclusi quelli in materia
                di Salute Sicurezza sul Lavoro (es.: DLgs 81/08 ed altri),
                Ambiente (es.: DLgs 152/06 ed altri) e di responsabilità sociale
                e diritti umani sul lavoro (es.: ILO, ISO 26000, CCNL, ecc.).
              </h4>
              <Checkbox
                checked={codiceEtico}
                required
                className="mt20 mb20"
                onChange={(e) => {
                  setCodiceEtico(e.target.checked);
                }}
                label={"Accetta"}
                size={"md"}
              />
            </div>
          ) : null}
          <h2 className="card-label mt20">Ulteriori Note</h2>
          <Textarea
            ref={note}
            placeholder="Note Aggiuntive"
            radius="md"
            size="lg"
            required
          />

          {invioDisabilitato.length > 0 && (
            <CampiMancanti campiMancanti={invioDisabilitato} />
          )}
          <Button
            color={true ? "green" : "red"}
            onClick={insertApplatoDb}
            disabled={loadingRichiesta || invioDisabilitato.length > 0}
            style={{ width: "100%", marginBottom: "100px", marginTop: "50px" }}
          >
            {loadingRichiesta ? (
              <Loader color="red" variant="bars" />
            ) : (
              "INVIA RICHIESTA"
            )}
          </Button>
          {idAppaltoPadre ? (
            <Button
              color={"blue"}
              onClick={tornaAAppaltoPrincipale}
              style={{ width: "100%" }}
            >
              TORNA ALL'APPALTO PRINCIPALE
            </Button>
          ) : null}
        </>
      )}
      <Modal
        title="Attenzione"
        transition="fade"
        size="xl"
        centered
        opened={errorSize}
        onClose={() => {
          setErrorSize(false);
        }}
        transitionDuration={600}
        transitionTimingFunction="ease"
      >
        <h1>La dimensione del file non può superare 50MB</h1>
      </Modal>
      <Modal
        title="Attenzione"
        transition="fade"
        size="xl"
        centered
        opened={errorSizeInvio}
        onClose={() => {
          setErrorSizeInvio(false);
        }}
        transitionDuration={600}
        transitionTimingFunction="ease"
      >
        <h1>La dimensione della richiesta non può superare i 95MB</h1>
        <h4>
          Si consiglia di inserire i file come PDF oppure comprimerli (rar/zip)
        </h4>
      </Modal>
    </div>
  );
}
