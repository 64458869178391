import React, { memo, useState, useRef, useEffect } from "react";
import {
  Checkbox,
  Button,
  Accordion,
  Modal,
  Select,
  MultiSelect,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { isScaduto, handleClickOpenFile } from "../Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

export default memo(function DipendentiList({
  dipendenti,
  setDipendenti,
  setErrorSize,
  getFile,
  dpiList,
  attestatiList,
  disabled,
}) {
  const [aggiungiDpi, setAggiungiDpi] = useState(false);
  const [aggiungiAttestato, setAggiungiAttestato] = useState(false);
  const [valueDaAggiungere, setValueDaAggiungere] = useState({});
  const [dipDaModificare, setDipDaModificare] = useState();
  const [dipDaModificare2, setDipDaModificare2] = useState();
  const [attestatiDaVisualizzare, setAttestatiDaVisualizzare] = useState([]);
  const [dpiDaVisualizzare, setDpiDaVisualizzare] = useState([]);
  //Funzione che modifica le informazioni aggiuntive di un dipendente
  const handleChangeInformazioniAggiuntiveDipendente = (
    value,
    campo,
    dipendenteDaCambiare,
    value1 = null,
    campo1 = null,
    rimuoviId = false,
    campoRimuovi
  ) => {
    let arr = [];
    dipendenti.map((dipendente) => {
      if (dipendente.denominazione == dipendenteDaCambiare.denominazione) {
        if (value1) {
          arr.push({
            ...dipendente,
            [campo]: value,
            [campo1]: value1,
            [campoRimuovi]: rimuoviId,
          });
        } else arr.push({ ...dipendente, [campo]: value });
      } else {
        arr.push(dipendente);
      }
    });
    setDipendenti(arr);
  };
  //Rimuove un dipendente dall`array dei dipendenti
  const handleEliminaDipendente = (dipendente) => {
    let arr = [];
    dipendenti.map((dip) => {
      if (dipendente.denominazione != dip.denominazione) arr.push(dip);
    });
    setDipendenti(arr);
  };
  //Funzione che modifica un valore degli attestati dipendenti
  const handleChangeAttestatoDipendente = (
    value,
    campo,
    dipendenteDaCambiare,
    attestatoDaCambiare,
    value1 = null,
    campo1 = null,
    eliminaId = false
  ) => {
    let arr = [];
    dipendenti.map((dipendente) => {
      let arr2 = [];
      if (dipendente.denominazione == dipendenteDaCambiare) {
        dipendente.attestatiList.map((attestato) => {
          if (attestato.label == attestatoDaCambiare.label) {
            if (value1 != null) {
              arr2.push({
                ...attestato,
                [campo]: value,
                [campo1]: value1,
                eliminaId: eliminaId,
              });
            } else arr2.push({ ...attestato, [campo]: value });
          } else {
            arr2.push({ ...attestato });
          }
        });
        arr.push({ ...dipendente, attestatiList: arr2 });
      } else {
        arr.push(dipendente);
      }
    });
    setDipendenti((dipendenti) => {
      return arr;
    });
  };
  useEffect(() => {
    if (!dipDaModificare) return;
    let arr = [];
    attestatiList.map((attestato) => {
      let trovato = false;
      dipDaModificare.attestatiList.map((attestatoS) => {
        if (attestato.label == attestatoS.label) trovato = true;
      });
      if (!trovato) arr.push(attestato);
    });
    setAttestatiDaVisualizzare(arr);
  }, [dipDaModificare]);

  useEffect(() => {
    if (!dipDaModificare2) return;
    let arr = [];
    dpiList.map((attestato) => {
      let trovato = false;
      dipDaModificare2.dpiList.map((attestatoS) => {
        if (attestato.label == attestatoS) trovato = true;
      });
      if (!trovato) arr.push(attestato.label);
    });
    setDpiDaVisualizzare(arr);
  }, [dipDaModificare2]);

  //Funzione che salva l`aggiunta di un attestato o dipendente
  const salvaAggiunta = (cosa) => {
    let arr = [];
    if (cosa == "attestato") {
      dipendenti.map((dipendente) => {
        if (dipendente.denominazione == dipDaModificare.denominazione) {
          arr.push({
            ...dipendente,
            attestatiList: [...dipendente.attestatiList, valueDaAggiungere],
          });
        } else arr.push(dipendente);
      });
      setDipendenti(arr);
      setAggiungiAttestato(false);
      setValueDaAggiungere({});
      return;
    } else {
      dipendenti.map((dipendente) => {
        if (dipendente.denominazione == dipDaModificare2.denominazione) {
          arr.push({
            ...dipendente,
            dpiList: [...dipendente.dpiList, ...valueDaAggiungere.label],
          });
        } else arr.push(dipendente);
      });
      setDipendenti(arr);
      setAggiungiDpi(false);
      setValueDaAggiungere({});
    }
  };
  //Funzione che elimina un attestato
  const deleteAttestato = (attestatoDaE, dipendenteDaE) => {
    let arr = [];
    let arr2 = [];
    setDipendenti((dipendentii) => {
      dipendentii.map((dipendente) => {
        if (dipendente.denominazione == dipendenteDaE.denominazione) {
          dipendente.attestatiList.map((attestato) => {
            if (attestato.label != attestatoDaE.label) arr2.push(attestato);
          });
          arr.push({ ...dipendente, attestatiList: arr2 });
        } else arr.push(dipendente);
      });
      return arr;
    });
  };
  //Funzione che elimina un dpi da un dipendente
  const deleteDpi = (dpiDaE, dipendenteDaE) => {
    let arr = [];
    let arr2 = [];
    setDipendenti((dipendentii) => {
      dipendentii.map((dipendente) => {
        if (dipendente.denominazione == dipendenteDaE.denominazione) {
          dipendente.dpiList.map((dpi) => {
            if (dpi != dpiDaE) arr2.push(dpi);
          });
          arr.push({ ...dipendente, dpiList: arr2 });
        } else arr.push(dipendente);
      });
      return arr;
    });
  };

  const checkIfPresente = (filename) => {
    let trovato = false;
    dipendenti.map((dipendente) => {
      if (
        dipendente.filenameIdoneitaSanitaria == filename ||
        dipendente.filenameUnilav == filename
      ) {
        trovato = true;
      }
      dipendente.attestatiList.map((attestato) => {
        if (attestato.filename == filename) {
          trovato = true;
        }
      });
    });
    return trovato;
  };

  const changeFilename = (file) => {
    let name = file.name;
    let cont = 2;
    while (checkIfPresente(name)) {
      let filename = file.name.split(".");
      name = filename[0] + "(" + cont + ")" + "." + filename[1];
      cont++;
    }
    const newFile = new File([file], name);
    return { file: newFile, name: name };
  };
  return (
    <>
      <Accordion chevronPosition="left">
        {dipendenti.map((dipendente, count) => (
          <Accordion.Item
            value={dipendente.denominazione}
            key={dipendente.denominazione}
          >
            <Accordion.Control>{dipendente.denominazione}</Accordion.Control>
            <Accordion.Panel>
              <h3>UNILAV - Idoneità sanitaria</h3>
              <div className="form-group maxW margin0">
                <label className="card-label">Socio fondatore</label>
                <Checkbox
                  checked={dipendente.socioFondatore}
                  size={"lg"}
                  disabled={disabled}
                  onChange={(e) => {
                    handleChangeInformazioniAggiuntiveDipendente(
                      e.target.checked,
                      "socioFondatore",
                      dipendente
                    );
                  }}
                />
              </div>
              {!dipendente.socioFondatore && (
                <div className="container_unilav mb10">
                  <div className="form-group mw700 margin0">
                    <input
                      type="file"
                      id={dipendente.denominazione}
                      style={{ display: "none" }}
                      onChange={(e) => {
                        if (e.target.files[0].size / 1024 / 1024 > 50) {
                          setErrorSize(true);
                          return;
                        }
                        let { file, name } = changeFilename(e.target.files[0]);
                        handleChangeInformazioniAggiuntiveDipendente(
                          file,
                          "fileUnilav",
                          dipendente,
                          name,
                          "filenameUnilav",
                          true,
                          "eliminaIdUnilav"
                        );
                      }}
                    />
                    <label className="card-label mt20">UNILAV *</label>
                    <div className="containerDownload">
                      <div
                        className={
                          disabled
                            ? " center padding0"
                            : "grid-container center padding0"
                        }
                        style={{ width: "fit-content" }}
                      >
                        {!disabled && (
                          <Button
                            className="w200"
                            onClick={() =>
                              handleClickOpenFile(dipendente.denominazione)
                            }
                          >
                            Scegli File
                          </Button>
                        )}
                        <label style={{ marginLeft: "10px" }}>
                          {dipendente.filenameUnilav
                            ? dipendente.filenameUnilav
                            : disabled
                            ? " "
                            : "Nessun file Selezionato"}
                        </label>
                      </div>
                      {dipendente.downloadUnilav && (
                        <Button
                          style={{ marginLeft: "10px" }}
                          className="mw400"
                          variant={"outline"}
                          size="sm"
                          onClick={() => getFile("unilav", dipendente.idUnilav)}
                        >
                          Download
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="container_tempo_indeterminato mr10">
                    <div className="form-group maxW margin0">
                      <label className="card-label">Tempo Indeterminato</label>
                      <Checkbox
                        checked={dipendente.tempoIndeterminato}
                        size={"lg"}
                        disabled={disabled}
                        onChange={(e) => {
                          handleChangeInformazioniAggiuntiveDipendente(
                            e.target.checked,
                            "tempoIndeterminato",
                            dipendente
                          );
                        }}
                      />
                    </div>
                  </div>
                  {!dipendente.tempoIndeterminato && (
                    <div className="exclamation">
                      {isScaduto(dipendente.dataScadenzaUnilav) && (
                        <FontAwesomeIcon
                          icon={faExclamationCircle}
                          color={"orange"}
                          size={"lg"}
                        />
                      )}
                      <div className="form-group mw300  margin0 flex">
                        <label className="card-label">
                          Data Scadenza UNILAV *
                        </label>
                        <DatePicker
                          value={
                            dipendente.dataScadenzaUnilav != null
                              ? new Date(dipendente.dataScadenzaUnilav)
                              : undefined
                          }
                          locale="it"
                          invalid={
                            dipendente.dataScadenzaUnilav == null ||
                            dipendente.dataScadenzaUnilav == ""
                          }
                          disabled={disabled}
                          onChange={(e) =>
                            handleChangeInformazioniAggiuntiveDipendente(
                              e,
                              "dataScadenzaUnilav",
                              dipendente
                            )
                          }
                          placeholder={"Seleziona una data"}
                          size="lg"
                          sx={{ backgroundColor: "#f8f9fa !important" }}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="container_idoneita mb20">
                <div className="form-group margin0 mw700 flex">
                  <label className="card-label">Idoneità sanitaria *</label>
                  <input
                    type="file"
                    id={dipendente.denominazione + "b"}
                    style={{ display: "none" }}
                    onChange={(e) => {
                      if (e.target.files[0].size / 1024 / 1024 > 50) {
                        setErrorSize(true);
                        return;
                      }

                      let { file, name } = changeFilename(e.target.files[0]);
                      handleChangeInformazioniAggiuntiveDipendente(
                        file,
                        "fileIdoneita",
                        dipendente,
                        name,
                        "filenameIdoneitaSanitaria",
                        true,
                        "eliminaIdIdoneita"
                      );
                    }}
                  />
                  <div className="containerDownload">
                    <div
                      className={
                        disabled
                          ? " center padding0"
                          : " grid-container center padding0"
                      }
                      style={{ width: "fit-content" }}
                    >
                      {!disabled && (
                        <Button
                          className="w200"
                          disabled={disabled}
                          onClick={() =>
                            handleClickOpenFile(dipendente.denominazione + "b")
                          }
                        >
                          Scegli File
                        </Button>
                      )}
                      <label style={{ marginLeft: "10px" }}>
                        {dipendente.filenameIdoneitaSanitaria
                          ? dipendente.filenameIdoneitaSanitaria
                          : "Nessun file Selezionato"}
                      </label>
                    </div>
                    {dipendente.downloadIdoneita && (
                      <Button
                        style={{ marginLeft: "10px" }}
                        className="mw400"
                        size="sm"
                        variant={"outline"}
                        onClick={() =>
                          getFile("idoneita", dipendente.idIdoneita)
                        }
                      >
                        Download
                      </Button>
                    )}
                  </div>
                </div>
                <div className="exclamation">
                  {isScaduto(dipendente.dataScadenzaIdoneita) && (
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      color={"orange"}
                      size={"lg"}
                    />
                  )}
                  <div className="form-group mw300  margin0 flex">
                    <label className="card-label">
                      Data Scadenza Idoneità sanitaria *
                    </label>
                    <DatePicker
                      value={
                        dipendente.dataScadenzaIdoneita != null
                          ? new Date(dipendente.dataScadenzaIdoneita)
                          : undefined
                      }
                      locale="it"
                      invalid={
                        dipendente.dataScadenzaIdoneita == null ||
                        dipendente.dataScadenzaIdoneita == ""
                      }
                      disabled={disabled}
                      onChange={(e) =>
                        handleChangeInformazioniAggiuntiveDipendente(
                          e,
                          "dataScadenzaIdoneita",
                          dipendente
                        )
                      }
                      placeholder={"Seleziona una data"}
                      size="lg"
                      sx={{ backgroundColor: "#f8f9fa !important" }}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
              <div className="inlineTitle">
                <h3>DPI</h3>
                {!disabled && (
                  <Button
                    leftIcon={<FontAwesomeIcon icon={faPlus} />}
                    color={"green"}
                    onClick={() => {
                      setAggiungiDpi(true);
                      setDipDaModificare2(dipendente);
                    }}
                  >
                    Agggiungi DPI
                  </Button>
                )}
              </div>
              {(!dipendente.dpiList || dipendente.dpiList.length == 0) &&
                "Nessun DPI Selezionato"}
              <ul className="mb10">
                {dipendente.dpiList.map((dpi) => (
                  <div className="inlineCheck">
                    <Checkbox
                      size="md"
                      key={dpi}
                      label={dpi}
                      checked={true}
                      disabled={true}
                      sx={{ marginBottom: "5px" }}
                    />
                    {!disabled && (
                      <FontAwesomeIcon
                        icon={faTrash}
                        color={"red"}
                        className={"pointer"}
                        onClick={() => {
                          deleteDpi(dpi, dipendente);
                        }}
                      />
                    )}
                  </div>
                ))}
              </ul>
              <div className="inlineTitle">
                <h3>ATTESTATI</h3>
                {!disabled && (
                  <Button
                    leftIcon={<FontAwesomeIcon icon={faPlus} />}
                    color={"green"}
                    onClick={() => {
                      setAggiungiAttestato(true);
                      setDipDaModificare(dipendente);
                    }}
                  >
                    Agggiungi Attestato
                  </Button>
                )}
              </div>
              {(!dipendente.attestatiList ||
                dipendente.attestatiList.length == 0) &&
                "Nessun ATTESTATO Selezionato"}
              <ul>
                {dipendente.attestatiList.map((attestato) => (
                  <div className="itemAttestato" key={attestato.label}>
                    <Checkbox
                      size="md"
                      label={attestato.label}
                      checked={true}
                      disabled={true}
                      sx={{ marginBottom: "5px" }}
                    />
                    <div className="informazioniAggiuntiveDipendente row2">
                      <div className="form-group margin0">
                        <label className="card-label">Attestato</label>
                        <div>
                          <input
                            type="file"
                            id={
                              attestato.label.split(" ")[0] +
                              dipendente.denominazione
                            }
                            style={{ display: "none" }}
                            onChange={(e) => {
                              if (e.target.files[0].size / 1024 / 1024 > 50) {
                                setErrorSize(true);
                                return;
                              }
                              handleChangeAttestatoDipendente(
                                e.target.files[0],
                                "file",
                                dipendente.denominazione,
                                attestato,
                                e.target.files[0].name,
                                "filename",
                                true
                              );
                            }}
                          />
                          <div className="containerDownload">
                            <div
                              className={
                                disabled
                                  ? "center padding0"
                                  : " center padding0"
                              }
                              style={{ width: "fit-content" }}
                            >
                              {!disabled && (
                                <Button
                                  className="w150"
                                  disabled={disabled}
                                  onClick={() =>
                                    handleClickOpenFile(
                                      attestato.label.split(" ")[0] +
                                        dipendente.denominazione
                                    )
                                  }
                                >
                                  Scegli File *
                                </Button>
                              )}
                              <label
                                style={{ marginLeft: "10px" }}
                                className={"break-word"}
                              >
                                {attestato.filename
                                  ? attestato.filename
                                  : "Nessun file Selezionato"}
                              </label>
                            </div>
                            {attestato.downlaodPresente && (
                              <Button
                                style={{ marginLeft: "10px" }}
                                className="mw400"
                                size="sm"
                                variant={"outline"}
                                onClick={() =>
                                  getFile("attestato", attestato.id)
                                }
                              >
                                Download
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="exclamation">
                        {isScaduto(attestato.dataScadenza) && (
                          <FontAwesomeIcon
                            icon={faExclamationCircle}
                            color={"orange"}
                            size={"lg"}
                          />
                        )}
                        <div className="form-group maxW300 margin0">
                          <label className="card-label">Data Scadenza *</label>
                          <DatePicker
                            value={
                              attestato.dataScadenza != null
                                ? new Date(attestato.dataScadenza)
                                : undefined
                            }
                            locale="it"
                            disabled={disabled}
                            onChange={(e) =>
                              handleChangeAttestatoDipendente(
                                e,
                                "dataScadenza",
                                dipendente.denominazione,
                                attestato
                              )
                            }
                            placeholder={"Seleziona una data"}
                            size="lg"
                            style={{ width: "100%" }}
                          />
                        </div>
                        <div className="center ml20">
                          {!disabled && (
                            <FontAwesomeIcon
                              icon={faTrash}
                              color={"red"}
                              className={"pointer"}
                              onClick={() => {
                                deleteAttestato(attestato, dipendente);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </ul>
              {!disabled && (
                <Button
                  color={"red"}
                  onClick={() => handleEliminaDipendente(dipendente)}
                >
                  Elimina dipendente
                </Button>
              )}
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
      <Modal
        title={<h3>Aggiungi DPI</h3>}
        transition="fade"
        size="xl"
        centered
        opened={aggiungiDpi}
        onClose={() => {
          setAggiungiDpi(false);
          setValueDaAggiungere({});
        }}
        transitionDuration={600}
        transitionTimingFunction="ease"
      >
        <MultiSelect
          data={dpiDaVisualizzare && dpiDaVisualizzare}
          label={"DPI"}
          className={"mb20"}
          onChange={(e) => {
            setValueDaAggiungere({ ...valueDaAggiungere, label: e });
          }}
        />
        <div>
          <div className="buttonConfermaContainer mt20">
            <Button
              fullWidth
              color={"orange"}
              onClick={() => {
                setAggiungiDpi(false);
                setValueDaAggiungere({});
              }}
            >
              Annulla
            </Button>
            <Button
              fullWidth
              color={"green"}
              onClick={() => salvaAggiunta("dpi")}
            >
              Conferma
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        title={<h3>Aggiungi Attestato</h3>}
        transition="fade"
        size="75%"
        centered
        opened={aggiungiAttestato}
        onClose={() => {
          setAggiungiAttestato(false);
          setValueDaAggiungere({});
        }}
        transitionDuration={600}
        transitionTimingFunction="ease"
      >
        <Select
          data={attestatiDaVisualizzare && attestatiDaVisualizzare}
          label={"Attestato"}
          searchable
          className={"mb20"}
          onChange={(e) => {
            setValueDaAggiungere({ ...valueDaAggiungere, label: e });
          }}
        />
        <div>
          <div className="containerAdd mt20">
            <div>
              <input
                type="file"
                id={"attDaAgg"}
                style={{ display: "none" }}
                onChange={(e) => {
                  if (e.target.files[0].size / 1024 / 1024 > 50) {
                    setErrorSize(true);
                    return;
                  }
                  setValueDaAggiungere({
                    ...valueDaAggiungere,
                    file: e.target.files[0],
                    filename: e.target.files[0].name,
                  });
                }}
              />
              <div className="containerDownload">
                <div
                  className={"grid-container center padding0"}
                  style={{ width: "fit-content" }}
                >
                  <Button
                    className="w150"
                    onClick={() => handleClickOpenFile("attDaAgg")}
                  >
                    Scegli File *
                  </Button>
                  <label style={{ marginLeft: "10px" }}>
                    {valueDaAggiungere.filename
                      ? valueDaAggiungere.filename
                      : "Nessun file Selezionato"}
                  </label>
                </div>
              </div>
            </div>
            <div className="exclamation">
              {isScaduto(valueDaAggiungere.dataScadenzaIdoneita) && (
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  color={"orange"}
                  size={"lg"}
                />
              )}
              <div className="form-group maxW300 margin0">
                <label className="card-label">Data Scadenza *</label>
                <DatePicker
                  value={valueDaAggiungere.dataScadenza}
                  locale="it"
                  disabled={disabled}
                  onChange={(e) =>
                    setValueDaAggiungere({
                      ...valueDaAggiungere,
                      dataScadenza: e,
                    })
                  }
                  placeholder={"Seleziona una data"}
                  size="lg"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
          <div className="buttonConfermaContainer mt20">
            <Button
              fullWidth
              color={"orange"}
              onClick={() => {
                setAggiungiAttestato(false);
                setValueDaAggiungere({});
              }}
            >
              Annulla
            </Button>
            <Button
              disabled={
                !valueDaAggiungere.file || !valueDaAggiungere.dataScadenza
              }
              fullWidth
              color={"green"}
              onClick={() => salvaAggiunta("attestato")}
            >
              Conferma
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
});
