import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Input, Button, PasswordInput, Alert } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReCAPTCHA from "react-google-recaptcha";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function PageResetta({ setMostraNavbar }) {
  const navigate = useNavigate();
  const [pass, setPass] = useState("");
  const [confermaPass, setConfermaPass] = useState("");
  const [error, setError] = useState("");
  const [state, setState] = useState(false);
  const [query, setQuery] = useState();
  const [reCAPTCHA, setReCAPTCHA] = useState(false);
  let strongPassword = new RegExp(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
  );

  useEffect(() => {
    setMostraNavbar(true);
  }, []);
  const recaptchaRef = React.createRef();
  useState(() => {
    setQuery(useQuery);
  });
  //Controllo che nell`url siano inseriti i codice e l`username
  useEffect(() => {
    if (!query.get("code") || !query.get("username")) {
      navigate("/login");
    }
  }, [query]);
  //Effettuo il cambiamento della pass
  async function changePass() {
    if (!query.get("code") || !query.get("username")) return;
    if (pass != confermaPass) {
      setError("Le password non corrispondono");
      return;
    }
    const url = "https://apot.mondorevive.com/auth/forgotPasswordChange";

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        code: query.get("code"),
        username: query.get("username"),
        password: pass,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status == 200) {
      setState(true);
      navigate("/login");
    } else {
      setState(false);
      setError("Password errata");
    }
  }
  function StrengthChecker() {
    let strengthBar = document.getElementById("signup-strength-bar");
    let lunghezzaBarra = 0;
    //Controllo la sicurezza della pass
    if (strongPassword.test(pass)) {
      /**PASSWORD STRONG */
      lunghezzaBarra = 4;
      if (error == "") setError("");
    } else {
      /**PASSWORD DEBOLE */
      lunghezzaBarra = 1;
      setError("Password Debole");
    }
    strengthBar.innerHTML = "";
    for (let i = 0; i < lunghezzaBarra; i++) {
      let span = document.createElement("span");
      if (i == 0) span.classList.add("firstSpan");
      if (i == 3) span.classList.add("lastSpan");
      span.classList.add("signup-strength");
      strengthBar.appendChild(span);
    }

    let spanRef = document.getElementsByClassName("signup-strength");
    for (let i = 0; i < spanRef.length; i++) {
      switch (spanRef.length - 1) {
        case 0:
          spanRef[i].style.background = "#ff3e36";
          break;
        case 1:
          spanRef[i].style.background = "#ff691f";
          break;
        case 2:
          spanRef[i].style.background = "#ffda36";
          break;
        case 3:
          spanRef[i].style.background = "#0be881";
          break;
      }
    }
  }
  useEffect(() => {
    if (pass != confermaPass) {
      setError("Le password non corrispondono");
    } else setError("");
    const timeOutId = setTimeout(() => StrengthChecker(), 500);
    return () => clearTimeout(timeOutId);
  }, [pass]);
  useEffect(() => {
    if (pass != confermaPass) {
      setError("Le password non corrispondono");
      return;
    } else setError("");
  }, [confermaPass]);

  //Funzione che verifica il recaptcha
  const handleVerify = (key) => {
    setReCAPTCHA(key);
  };
  return (
    <div className="log-container">
      <div className="log-box2">
        <div className="register-box min">
          <div className="register-title login">
            <h1>Reimposta Password</h1>
          </div>
          {error != "" && pass != "" && (
            <Alert title="Errore!" color="red" style={{ marginBottom: "10px" }}>
              {error}
            </Alert>
          )}
          {state ? (
            <p>Password cambiata con successo, ora puoi autenticarti</p>
          ) : (
            <>
              <PasswordInput
                value={pass}
                className={"provass"}
                onChange={(e) => setPass(e.target.value)}
                size="xl"
                placeholder="Password"
                radius="lg"
                required
              />
              <div id="signup-strength-bar"></div>
              <PasswordInput
                value={confermaPass}
                size="xl"
                onChange={(e) => setConfermaPass(e.target.value)}
                style={{ marginTop: "20px" }}
                placeholder="Conferma Password"
                radius="lg"
                required
              />
              {error && (
                <div style={{ maxWidth: "600px", marginTop: "10px" }}>
                  <Alert
                    color={"gray"}
                    icon={<FontAwesomeIcon icon={faInfoCircle} color="blue" />}
                  >
                    La password deve contenere almeno 8 caratteri di cui almeno
                    una lettera maiuscola un carattere speciale e un numero,
                  </Alert>
                </div>
              )}
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LcjjA8gAAAAAHpqdzMdgVtXB1aYCsQn336fmWYF"
                onChange={handleVerify}
              />
              <Button
                disabled={pass != confermaPass || error || !reCAPTCHA}
                radius="md"
                size="lg"
                style={{ marginTop: "30px" }}
                onClick={changePass}
              >
                Cambia Password
              </Button>
            </>
          )}
          <Link to="/login">
            <p className="account-signup">Ho un account</p>
          </Link>
        </div>
      </div>
    </div>
  );
}
