import React, { useState, useEffect, useRef, useCallback } from "react";
import "../App.css";
import cookie from "js-cookie";
import { isScaduto, preparaDataPerInvio } from "./Utilities";
import axios from "axios";
import {
  Checkbox,
  Select,
  MultiSelect,
  Button,
  Divider,
  Input,
  Textarea,
  Loader,
  Modal,
  Progress,
  Table,
} from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";
import { DatePicker } from "@mantine/dates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import "dayjs/locale/it";
import {
  successNotification,
  errorNotification,
  infoNotification,
} from "./Notification";
import { ToastContainer, Flip, toast } from "react-toastify";
import DipendentiList from "./Aggiuntivi/DipendentiList";
import CampiMancanti from "./Aggiuntivi/CampiMancanti";
import dayjs from "dayjs";
export default function UpdateForm({ setMostraNavbar }) {
  const [idComune, setIdComune] = useState("");
  const [id, setId] = useState("");
  const idC = useRef();
  const idT = useRef();
  //REFERENTE AZIENDA
  const [email, setEmail] = useState("");
  const [allEmail, setAllEmail] = useState([]);
  const [telefono, setTelefono] = useState("");
  const nomeReferenteAzienda = useRef("");
  const [responsabili, setResponsabili] = useState({
    rspp: "",
    rls: "",
    ddl: "",
    mc: "",
  });
  const [rspp, setRspp] = useState("");
  const [ddl, setDdl] = useState("");
  const [mc, setMc] = useState("");
  const [fileAttestati, setFileAttestati] = useState([]);
  const [nomePreposto, setNomePreposto] = useState("");
  const [preposti, setPreposti] = useState([]);
  const [rlsAll, setRlsAll] = useState([]);
  const [rls, setRls] = useState({ denominazione: "", dataNominaRls: null });
  const [dataNomina, setDataNomina] = useState({
    rspp: "",
    rls: "",
    ddl: "",
    mc: "",
  });
  const [dataRspp, setDataRspp] = useState(null);
  const [dataDdl, setDataDdl] = useState(null);
  const [dataMc, setDataMc] = useState(null);
  const [checkedCertificazioni, setCheckedCertificazioni] = useState({
    iso9001: false,
    iso14001: false,
    iso45001: false,
    sa8000: false,
  });
  const [
    checkedCertificazioniNonModificate,
    setCheckedCertificazioniNonModificate,
  ] = useState({
    iso9001: false,
    iso14001: false,
    iso45001: false,
    sa8000: false,
  });
  const [fileCertificazioni, setFileCertificazioni] = useState({
    iso9001: null,
    iso14001: null,
    iso45001: null,
    sa8000: null,
  });
  const [referente, setReferente] = useState("");
  //CAMERA DI COMMERCIO
  const [codice, setCodice] = useState("");
  const [visura, setVisura] = useState("");
  const [dataDvr, setDataDvr] = useState("");
  const [dataDichiarazione445, setDataDichiarazione445] = useState("");
  const [fileCamera, setFileCamera] = useState({
    iscrizione: null,
    dvr: null,
    doc445: null,
  });
  const [FileLavoratori, setFileLavoratori] = useState({
    attLav: null,
    poliza: null,
  });
  const [fileDurc, setFileDurc] = useState(null);
  //STABILIMENTI
  const stabilimenti = [
    { value: "Mondorevive Busto Arsizio", label: "Mondorevive Busto Arsizio" },
    { value: "Mondorevive Ferentino", label: "Mondorevive Ferentino" },
    { value: "Caltek Chignolo Po", label: "Caltek Chignolo Po" },
    { value: "MondoSD Bernate Ticino", label: "MondoSD Bernate Ticino" },
    { value: "Mondorevive Marnate", label: "Mondorevive Marnate" },
    { value: "Diba Chignolo Po", label: "Diba Chignolo Po" },
  ];
  const [stabilimentiSelezionati, setStabilimentiSelezionati] = useState([]);
  const [labelStabilimenti, setLabelStabilimenti] = useState([]);
  const [fileModulo7, setfileModulo7] = useState({
    ba: null,
    fe: null,
    cp: null,
    bt: null,
    ma: null,
    di: null,
  });
  const [fileModulo5, setfileModulo5] = useState({
    ba: null,
    fe: null,
    cp: null,
    bt: null,
    ma: null,
    di: null,
  });
  const [fileDuvri, setFileDuvri] = useState({
    ba: null,
    fe: null,
    cp: null,
    bt: null,
    ma: null,
    di: null,
  });
  const navigate = useNavigate();
  //dipendenti
  const [appalto, setAppalto] = useState("");
  const [azienda, setAzienda] = useState("");
  const [nome, setNome] = useState("");
  const [showInserimentoDipendente, setShowInserimentoDipendente] =
    useState(false);
  const [dpi, setDpi] = useState([]);
  const [attestati, setAttestati] = useState([]);
  const [dpiUnchanged, setDpiUnchanged] = useState();
  const [attestatiUnchanged, setAttestatiUnchanged] = useState();
  const [dipendenti, setDipendenti] = useState([]);
  const [dataScadenzaRTC, setDataScadenzaRTC] = useState("");
  const [dataDurc, setDataDurc] = useState("");
  const [checkedFile, setCheckedFile] = useState({});
  const note = useRef("");
  const [storico, setStorico] = useState([]);
  const [labelFile, setLabelFile] = useState({
    iso9001: null,
    iso14001: null,
    iso45001: null,
    sa8000: null,
    camera: null,
    dvr: null,
    dichiarazione445: null,
    dipendenti: null,
    poliza: null,
    durc: null,
    m7ba: null,
    m7fe: null,
    m7cp: null,
    m7bt: null,
    m7ma: null,
    m7di: null,
    m5ba: null,
    m5fe: null,
    m5cp: null,
    m5bt: null,
    m5ma: null,
    m5di: null,
    duba: null,
    dufe: null,
    ducp: null,
    dubt: null,
    duma: null,
    dudi: null,
  });
  const [loadingRichiesta, setLoadingRichiesta] = useState(false);
  const [loading, setLoading] = useState(true);
  const [opened, setOpened] = useState(false);
  const [modSubappaltoOpened, setModSubappaltoOpened] = useState(false);
  const [emailNonValida, setEmailNonValida] = useState(true);
  const [errorSize, setErrorSize] = useState(false);
  const [errorSizeInvio, setErrorSizeInvio] = useState(false);
  const [progress, setProgress] = useState(0);
  const [
    informazioniAggiuntiveDipendente,
    setInformazioniAggiuntiveDipendente,
  ] = useState({});
  const [datiGenerali, setDatiGenerali] = useState({});
  const [invioDisabilitato, setInvioDisabilitato] = useState([]);
  //Gestione subappalti
  const [subappalti, setSubappalti] = useState([]);
  const { state } = useLocation();
  const idAppaltoPadre =
    state !== null && state !== undefined ? state.idAppaltoPadre : undefined;
  const [idAppaltoComune, setIdAppaltoComune] = useState(
    state !== null && state !== undefined ? state.idAppaltoComune : undefined
  );
  const nomeAzienda = useRef("");
  const indirizzoAzienda = useRef("");
  const pecAzienda = useRef("");

  useEffect(() => {
    setMostraNavbar(true);
  }, []);
  useEffect(() => {
    if (
      !cookie.get("status") ||
      cookie.get("status") == "ROLE_APPROVER" ||
      cookie.get("status") == "ROLE_ADMINISTRATOR"
    )
      navigate("/login");
    else {
      getDPI();
      getAttestati();
    }
  }, []);
  //Aggiungi email all`array delle email inserite
  const aggiungiEmail = () => {
    setAllEmail([...allEmail, email]);
    setEmail("");
  };
  //Rimuovi email dall`array delle email inserite
  const rimuoviEmail = (email) => {
    let a = [];
    for (let i = 0; i < allEmail.length; i++) {
      if (allEmail[i] != email) {
        a.push(allEmail[i]);
      }
    }
    setAllEmail(a);
    return;
  };
  // Aggiugne un preposto all`array
  const aggiungiPreposto = () => {
    setPreposti([...preposti, nomePreposto]);
    setNomePreposto("");
  };
  //Rimuove un preposto dall`array
  const rimuoviPreposto = (preposto) => {
    let a = [];
    preposti.map((prepost) => {
      if (prepost != preposto) {
        a.push(prepost);
      }
    });
    setPreposti(a);
    return;
  };
  //Aggiunge um dipendente all`array dei dipendenti
  const aggiungiDipendente = () => {
    //in base alla label ottengo quale attestato e` stato impostato a true
    let attestat = [];
    let dpis = [];
    //Ottengo i dpi e gli attestati selezionati
    dpi.map((dp) => {
      if (dp.status) dpis.push(dp.label);
    });

    attestati.map((att) => {
      if (att.status)
        attestat.push({ ...att, filename: att.file ? att.file.name : null });
    });

    //Aggiungo alla lista dei dipendenti l`ultimo dipendente creato
    setDipendenti([
      ...dipendenti,
      {
        attestatiList: attestat,
        dpiList: dpis,
        denominazione: nome,
        socioFondatore: informazioniAggiuntiveDipendente.socioFondatore,
        tempoIndeterminato: informazioniAggiuntiveDipendente.tempoIndeterminato,
        filenameUnilav: informazioniAggiuntiveDipendente.fileUnilav
          ? informazioniAggiuntiveDipendente.filenameUnilav
          : null,
        filenameIdoneitaSanitaria: informazioniAggiuntiveDipendente.file
          ? informazioniAggiuntiveDipendente.filenameIdoneitaSanitaria
          : null,
        fileUnilav: informazioniAggiuntiveDipendente.fileUnilav,
        dataScadenzaIdoneita: informazioniAggiuntiveDipendente.dataScadenza,
        dataScadenzaUnilav: informazioniAggiuntiveDipendente.dataScadenzaUnilav,
        fileIdoneita: informazioniAggiuntiveDipendente.file,
      },
    ]);
    setShowInserimentoDipendente(false);
    //Resetto la selezione
    resetDipendenti();
  };
  //Rimuove un dipendente dall`array dei dipendenti
  const handleEliminaDipendente = (dipendente) => {
    let arr = [];
    dipendenti.map((dip) => {
      if (dipendente.denominazione != dip.denominazione) arr.push(dip);
    });
    setDipendenti(arr);
  };
  //Reset dell`array dei dipendenti
  const resetDipendenti = () => {
    setNome("");
    setAttestati(attestatiUnchanged);
    setInformazioniAggiuntiveDipendente({
      file: undefined,
      unilav: undefined,
      data: undefined,
      dataScadenza: null,
    });
    setDpi(dpiUnchanged);

    setLabelFile({ ...labelFile, unilav: undefined, idoneita: undefined });
  };
  //Funzione che ottiene i DPI dal db
  const getDPI = async () => {
    const url = "https://apot.mondorevive.com/api/dpi";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      //dire ok
      const data = await response.json();
      let arr = [];
      data.map((dpi) => {
        arr.push({ label: dpi.nome, status: false, value: dpi.nome });
      });
      setDpi(arr);
      setDpiUnchanged(arr);
    } else {
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
      //DARE ERROR
    }
  };
  const handleChangeDpi = (dpis) => {
    let arr = [];
    dpi.map((dp) => {
      if (dp.label == dpis.label) {
        arr.push({ label: dp.label, status: !dpis.status });
      } else arr.push(dp);
    });
    setDpi(arr);
  };
  const handleChangeAttestati = (attestato) => {
    let arr = [];
    attestati.map((att) => {
      if (att.label == attestato.label) {
        arr.push({ label: att.label, status: !attestato.status });
      } else arr.push(att);
    });
    setAttestati(arr);
  };
  //Funzione che ottiene gli attestati dal db
  const getAttestati = async () => {
    const url = "https://apot.mondorevive.com/api/attestati";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      const data = await response.json();
      let arr = [];
      data.map((attestato) => {
        arr.push({
          label: attestato.nome,
          status: false,
          value: attestato.nome,
        });
      });
      setAttestati(arr);
      setAttestatiUnchanged(arr);
    } else {
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  //Dice se un determinato stabilimento e` presente negli stabilimenti selezionati
  const trovaStabilimento = (stabilimento) => {
    let trovato = false;
    labelStabilimenti.map((e) => {
      if (e === stabilimento) {
        trovato = true;
        return true;
      }
    });
    if (trovato) return true;
    else return false;
  };
  const prendiByteFile = (file) => {
    if (!file) return 0;
    return file.size;
  };
  //Inserisce l`appalto nel db
  const updateAppaltoDb = async () => {
    setLoadingRichiesta(true);
    setOpened(true);
    const url = "https://apot.mondorevive.com/api/updateAppalto";
    var formData = new FormData();
    let lista = [];

    let sommaGrandezzaFile = 0;
    fileAttestati.map((file) => {
      if (file.file) {
        formData.append("fileAggiuntivi", file.file);
        sommaGrandezzaFile += prendiByteFile(file.file);
      }
      lista.push({
        idFile: file.idAttestato || file.idFile || null,
        nome: file.nome,
      });
    });
    let arr = [];
    dipendenti.map((dipendente) => {
      let arr2 = [];
      dipendente.attestatiList.map((attestato) => {
        arr2.push({
          id: attestato.eliminaId ? null : attestato.id ? attestato.id : null,
          filename: attestato.filename,
          dataScadenza:
            attestato.dataScadenza != "" &&
            attestato.dataScadenza != null &&
            attestato.dataScadenza != undefined
              ? preparaDataPerInvio(attestato.dataScadenza)
              : null,
          label: attestato.label,
        });
      });
      arr.push({
        dpiList: dipendente.dpiList,
        denominazione: dipendente.denominazione,
        socioFondatore: dipendente.socioFondatore,
        tempoIndeterminato: dipendente.tempoIndeterminato,
        filenameUnilav: dipendente.filenameUnilav,
        filenameIdoneitaSanitaria: dipendente.filenameIdoneitaSanitaria,
        dataScadenzaIdoneita:
          dipendente.dataScadenzaIdoneita != "" &&
          dipendente.dataScadenzaIdoneita != null
            ? preparaDataPerInvio(dipendente.dataScadenzaIdoneita)
            : null,
        dataScadenzaUnilav:
          dipendente.dataScadenzaUnilav != "" &&
          dipendente.dataScadenzaUnilav != null
            ? preparaDataPerInvio(dipendente.dataScadenzaUnilav)
            : null,
        attestatiList: arr2,
        idUnilav: dipendente.eliminaIdUnilav ? null : dipendente.idUnilav,
        idIdoneita: dipendente.eliminaIdIdoneita ? null : dipendente.idIdoneita,
      });
    });
    formData.append(
      "appalto",
      new Blob(
        [
          JSON.stringify({
            idAppaltoComune: idComune,
            fileAggiuntivi: lista,
            dataInizio: preparaDataPerInvio(datiGenerali.dataInizio),
            dataFine: preparaDataPerInvio(datiGenerali.dataFine),
            descrizione: datiGenerali.descrizione,
            referenteAzienda: nomeReferenteAzienda.current.value,
            cellulare: telefono,
            nomeRspp: rspp,
            dataNominaRspp:
              dataRspp != "" && dataRspp != null
                ? preparaDataPerInvio(dataRspp)
                : null,
            rlsList: rlsAll,
            nomeDdl: ddl,
            dataNominaDdl:
              dataDdl != "" && dataDdl != null
                ? preparaDataPerInvio(dataDdl)
                : null,
            nomeMc: mc,
            dataNominaMc:
              dataMc != "" && dataMc != null
                ? preparaDataPerInvio(dataMc)
                : null,
            prepostiCantiere: preposti,
            listaLavoratori: arr,
            referenteMondorevive: referente,
            tipoAppalto: appalto,
            tipologiaSocieta: azienda,
            codiceCameraCommercio: codice,
            dataVisuraCamerale:
              visura != "" && visura != null
                ? preparaDataPerInvio(visura)
                : null,
            dataCertaDvr:
              dataDvr != "" && dataDvr != null
                ? preparaDataPerInvio(dataDvr)
                : null,
            dataPolizzaAssicurativa:
              dataScadenzaRTC != "" && dataScadenzaRTC != null
                ? preparaDataPerInvio(dataScadenzaRTC)
                : null,
            dataDichiarazione445:
              dataDichiarazione445 != "" && dataDichiarazione445 != null
                ? preparaDataPerInvio(dataDichiarazione445)
                : null,
            dataDurc:
              dataDurc != "" && dataDurc != null
                ? preparaDataPerInvio(dataDurc)
                : null,
            mails: allEmail,
            appaltoBusto:
              trovaStabilimento("Mondorevive Busto Arsizio") || false,
            appaltoFerentino:
              trovaStabilimento("Mondorevive Ferentino") || false,
            appaltoBernate:
              trovaStabilimento("MondoSD Bernate Ticino") || false,
            appaltoChignolo: trovaStabilimento("Caltek Chignolo Po") || false,
            appaltoMarnate: trovaStabilimento("Mondorevive Marnate") || false,
            appaltoDiba: trovaStabilimento("Diba Chignolo Po") || false,
            note: note.current.value,
            nomeAzienda: nomeAzienda.current.value,
            indirizzoAzienda: indirizzoAzienda.current.value,
            pecAzienda: pecAzienda.current.value,
            idAppaltoPadre: idAppaltoPadre,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );

    sommaGrandezzaFile += prendiByteFile(fileCamera.iscrizione);

    sommaGrandezzaFile += prendiByteFile(fileCamera.dvr);

    sommaGrandezzaFile += prendiByteFile(fileCamera.doc445);

    sommaGrandezzaFile += prendiByteFile(FileLavoratori.poliza);

    sommaGrandezzaFile += prendiByteFile(fileModulo7.ba);
    sommaGrandezzaFile += prendiByteFile(fileModulo7.fe);
    sommaGrandezzaFile += prendiByteFile(fileModulo7.cp);
    sommaGrandezzaFile += prendiByteFile(fileModulo7.bt);
    sommaGrandezzaFile += prendiByteFile(fileModulo7.ma);
    sommaGrandezzaFile += prendiByteFile(fileModulo7.di);

    sommaGrandezzaFile += prendiByteFile(fileModulo5.ba);
    sommaGrandezzaFile += prendiByteFile(fileModulo5.fe);
    sommaGrandezzaFile += prendiByteFile(fileModulo5.cp);
    sommaGrandezzaFile += prendiByteFile(fileModulo5.bt);
    sommaGrandezzaFile += prendiByteFile(fileModulo5.ma);
    sommaGrandezzaFile += prendiByteFile(fileModulo5.di);

    sommaGrandezzaFile += prendiByteFile(fileDuvri.ba);
    sommaGrandezzaFile += prendiByteFile(fileDuvri.fe);
    sommaGrandezzaFile += prendiByteFile(fileDuvri.cp);
    sommaGrandezzaFile += prendiByteFile(fileDuvri.bt);
    sommaGrandezzaFile += prendiByteFile(fileDuvri.ma);
    sommaGrandezzaFile += prendiByteFile(fileDuvri.di);
    sommaGrandezzaFile += prendiByteFile(fileDurc);

    dipendenti.map((dipendente) => {
      sommaGrandezzaFile += prendiByteFile(dipendente.fileUnilav);
      sommaGrandezzaFile += prendiByteFile(dipendente.fileIdoneita);
      dipendente.attestatiList.map((attestato) => {
        sommaGrandezzaFile += prendiByteFile(attestato.file);
        formData.append("fileLavoratori", attestato.file);
      });
      formData.append("fileLavoratori", dipendente.fileUnilav);
      formData.append("fileLavoratori", dipendente.fileIdoneita);
    });

    if (checkedCertificazioni.iso9001) {
      formData.append("certIso9001", fileCertificazioni.iso9001);

      sommaGrandezzaFile += prendiByteFile(fileCertificazioni.iso9001);
    }
    if (checkedCertificazioni.iso14001) {
      formData.append("certIso14001", fileCertificazioni.iso14001);
      sommaGrandezzaFile += prendiByteFile(fileCertificazioni.iso14001);
    }
    if (checkedCertificazioni.iso45001) {
      formData.append("certIso45001", fileCertificazioni.iso45001);
      sommaGrandezzaFile += prendiByteFile(fileCertificazioni.iso45001);
    }
    if (checkedCertificazioni.sa8000) {
      formData.append("certificazioneSa8000", fileCertificazioni.sa8000);
      sommaGrandezzaFile += prendiByteFile(fileCertificazioni.sa8000);
    }
    formData.append("iscrizioneCameraCommercio", fileCamera.iscrizione);
    formData.append("documentoDvr", fileCamera.dvr);
    formData.append("dichiarazione445", fileCamera.doc445);
    formData.append("polizzaAssicurativaRct", FileLavoratori.poliza);
    if (trovaStabilimento("Mondorevive Busto Arsizio")) {
      formData.append("modulo7Ba", fileModulo7.ba);
      formData.append("infoRischiRegBa", fileModulo5.ba);
      formData.append("duvriBa", fileDuvri.ba);
    }
    if (trovaStabilimento("Mondorevive Ferentino")) {
      formData.append("modulo7Fe", fileModulo7.fe);
      formData.append("infoRischiRegFe", fileModulo5.fe);
      formData.append("duvriFe", fileDuvri.fe);
    }
    if (trovaStabilimento("Caltek Chignolo Po")) {
      formData.append("modulo7Chi", fileModulo7.cp);
      formData.append("infoRischiRegChi", fileModulo5.cp);
      formData.append("duvriChi", fileDuvri.cp);
    }
    if (trovaStabilimento("MondoSD Bernate Ticino")) {
      formData.append("modulo7Be", fileModulo7.bt);
      formData.append("infoRischiRegBe", fileModulo5.bt);
      formData.append("duvriBe", fileDuvri.bt);
    }
    if (trovaStabilimento("Mondorevive Marnate")) {
      formData.append("modulo7Ma", fileModulo7.ma);
      formData.append("infoRischiRegMa", fileModulo5.ma);
      formData.append("duvriMa", fileDuvri.ma);
    }
    if (trovaStabilimento("Diba Chignolo Po")) {
      formData.append("modulo7Di", fileModulo7.di);
      formData.append("infoRischiRegDi", fileModulo5.di);
      formData.append("duvriDi", fileDuvri.di);
    }
    formData.append("durc", fileDurc);
    if (sommaGrandezzaFile / 1024 / 1024 > 95) {
      setErrorSizeInvio(true);
      return;
    }

    const resp = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + cookie.get("token"),
      },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 50;
        setProgress(progress * 2);
      },
    });

    // const response = await fetch(url, {
    // 	method: "POST",
    // 	headers: {
    // 		Authorization: "Bearer " + cookie.get("token"),
    // 	},
    // 	body: formData,
    // });
    if (resp.status == 200) {
      console.log("responseId", resp.data.id);
      setIdAppaltoComune(resp.data.id);
      setLoadingRichiesta(false);
      successNotification("Appalto aggiornato correttamente");
      getInfoAppalto(resp.data.id);
      resetFile();
      resetLabelFile();
      setOpened(false);
    } else {
      if (resp.status == 401 || resp.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
      setLoadingRichiesta(false);
      errorNotification("Impossibile aggiornare appalto, riprova più tardi!");
    }
    setLoadingRichiesta(false);
    setOpened(false);
  };
  const resetFile = () => {
    setFileCertificazioni({
      iso9001: null,
      iso14001: null,
      iso45001: null,
      sa8000: null,
    });
    setfileModulo5({
      ba: null,
      fe: null,
      cp: null,
      bt: null,
      ma: null,
      di: null,
    });
    setfileModulo7({
      ba: null,
      fe: null,
      cp: null,
      bt: null,
      ma: null,
      di: null,
    });
    setFileCamera({ iscrizione: null, dvr: null, doc445: null });
    setFileLavoratori({ attLav: null, poliza: null });
    setFileDuvri({
      ba: null,
      fe: null,
      cp: null,
      bt: null,
      ma: null,
      di: null,
    });
  };
  const resetLabelFile = () => {
    setLabelFile({
      iso9001: null,
      iso14001: null,
      iso45001: null,
      sa8000: null,
      camera: null,
      dvr: null,
      dichiarazione445: null,
      dipendenti: null,
      poliza: null,
      durc: null,
      m7ba: null,
      m7fe: null,
      m7cp: null,
      m7bt: null,
      m7ma: null,
      m7di: null,
      m5ba: null,
      m5fe: null,
      m5cp: null,
      m5bt: null,
      m5ma: null,
      m5di: null,
      duba: null,
      dufe: null,
      ducp: null,
      dubt: null,
      duma: null,
      dudi: null,
    });
  };
  const clickAggiungiSubAppalto = () => {
    //Per il momento apro il modal
    setModSubappaltoOpened(true);
  };
  const apriNuovoSubAppalto = () => {
    //Devo navigare a pagina Form.js
    //Devo passargli il mio id
    navigate("/form", { state: { idAppaltoPadre: idComune } });
  };
  const tornaAAppaltoPrincipale = () => {
    navigate("/update", {
      state: { idAppaltoPadre: undefined, idAppaltoComune: undefined },
    });
    navigate(0);
  };
  const handleAggiuntaRlss = () => {
    setRlsAll([
      ...rlsAll,
      { denominazione: rls.denominazione, dataNominaRls: rls.dataNominaRls },
    ]);
    setRls({ denominazione: "", dataNominaRls: null });
  };
  const handleRemoveRls = (rlss) => {
    let arr = [];
    rlsAll.map((rl) => {
      if (rl.denominazione != rlss.denominazione) arr.push(rl);
    });
    setRlsAll(arr);
  };
  const handleChangeModulo7 = (nome, e) => {
    if (nome == "Mondorevive Busto Arsizio") {
      setfileModulo7({ ...fileModulo7, ba: e });
      setLabelFile({ ...labelFile, m7ba: e.name });
      return;
    }
    if (nome == "Mondorevive Ferentino") {
      setfileModulo7({ ...fileModulo7, fe: e });
      setLabelFile({ ...labelFile, m7fe: e.name });
      return;
    }
    if (nome == "Caltek Chignolo Po") {
      setfileModulo7({ ...fileModulo7, cp: e });
      setLabelFile({ ...labelFile, m7cp: e.name });
      return;
    }
    if (nome == "MondoSD Bernate Ticino") {
      setfileModulo7({ ...fileModulo7, bt: e });
      setLabelFile({ ...labelFile, m7bt: e.name });
      return;
    }
    if (nome == "Mondorevive Marnate") {
      setfileModulo7({ ...fileModulo7, ma: e });
      setLabelFile({ ...labelFile, m7ma: e.name });
      return;
    }
    if (nome == "Diba Chignolo Po") {
      setfileModulo7({ ...fileModulo7, di: e });
      setLabelFile({ ...labelFile, m7di: e.name });
      return;
    }
  };
  const getLabelFromStabilimento = (nome, tipo) => {
    if (nome == "Mondorevive Busto Arsizio") {
      return labelFile[tipo + "ba"];
    }
    if (nome == "Mondorevive Ferentino") {
      return labelFile[tipo + "fe"];
    }
    if (nome == "Caltek Chignolo Po") {
      return labelFile[tipo + "cp"];
    }
    if (nome == "MondoSD Bernate Ticino") {
      return labelFile[tipo + "bt"];
    }
    if (nome == "Mondorevive Marnate") {
      return labelFile[tipo + "ma"];
    }
    if (nome == "Diba Chignolo Po") {
      return labelFile[tipo + "di"];
    }
  };
  const handleChangeModulo5 = (nome, e) => {
    if (nome == "Mondorevive Busto Arsizio") {
      setfileModulo5({ ...fileModulo5, ba: e });
      setLabelFile({ ...labelFile, m5ba: e.name });
      return;
    }
    if (nome == "Mondorevive Ferentino") {
      setfileModulo5({ ...fileModulo5, fe: e });
      setLabelFile({ ...labelFile, m5fe: e.name });
      return;
    }
    if (nome == "Caltek Chignolo Po") {
      setfileModulo5({ ...fileModulo5, cp: e });
      setLabelFile({ ...labelFile, m5cp: e.name });
      return;
    }
    if (nome == "MondoSD Bernate Ticino") {
      setfileModulo5({ ...fileModulo5, bt: e });
      setLabelFile({ ...labelFile, m5bt: e.name });
      return;
    }
    if (nome == "Mondorevive Marnate") {
      setfileModulo5({ ...fileModulo5, ma: e });
      setLabelFile({ ...labelFile, m5ma: e.name });
      return;
    }
    if (nome == "Diba Chignolo Po") {
      setfileModulo5({ ...fileModulo5, di: e });
      setLabelFile({ ...labelFile, m5di: e.name });
      return;
    }
  };
  const handleChangeDuvri = (nome, e) => {
    if (nome == "Mondorevive Busto Arsizio") {
      setFileDuvri({ ...fileDuvri, ba: e });
      setLabelFile({ ...labelFile, duba: e.name });
      return;
    }
    if (nome == "Mondorevive Ferentino") {
      setFileDuvri({ ...fileDuvri, fe: e });
      setLabelFile({ ...labelFile, dufe: e.name });
      return;
    }
    if (nome == "Caltek Chignolo Po") {
      setFileDuvri({ ...fileDuvri, cp: e });
      setLabelFile({ ...labelFile, ducp: e.name });
      return;
    }
    if (nome == "MondoSD Bernate Ticino") {
      setFileDuvri({ ...fileDuvri, bt: e });
      setLabelFile({ ...labelFile, dubt: e.name });
      return;
    }
    if (nome == "Mondorevive Marnate") {
      setFileDuvri({ ...fileDuvri, ma: e });
      setLabelFile({ ...labelFile, duma: e.name });
      return;
    }
    if (nome == "Diba Chignolo Po") {
      setFileDuvri({ ...fileDuvri, di: e });
      setLabelFile({ ...labelFile, dudi: e.name });
      return;
    }
  };
  const add = (value, val) => {
    return value + val;
  };
  const handleClickOpenFile = (label) => {
    document.getElementById(label).click();
  };
  const getFileTemplate = async (nomeFile) => {
    const url =
      "https://apot.mondorevive.com/api/downloadTemplate?campo=" + nomeFile;
    const response = await toast.promise(
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + cookie.get("token"),
        },
        responseType: "blob",
      }),
      {
        pending: "Download in corso",
        success: {
          render() {
            return `File scaricato correttamente`;
          },
          autoClose: 1000,
        },
        error: {
          render() {
            return `Errore nel download del file`;
          },
          autoClose: 1000,
        },
      }
    );
    if (response.status == 200) {
      const data = await response.arrayBuffer();
      const filename = response.headers
        .get("content-disposition")
        .split("filename=")[1];
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename.substring(1, filename.length - 1));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
      //DARE ERROR
    }
  };
  //GESTISCO I DOWNLOAD DEI TEMPLATE DEI FILE
  const handleDownloadModulo7 = (stabilimento) => {
    if (stabilimento == "Mondorevive Busto Arsizio")
      getFileTemplate("modulo7Ba");
    if (stabilimento == "Mondorevive Ferentino") getFileTemplate("modulo7Fe");
    if (stabilimento == "Caltek Chignolo Po") getFileTemplate("modulo7Chi");
    if (stabilimento == "MondoSD Bernate Ticino") getFileTemplate("modulo7Be");
    if (stabilimento == "Mondorevive Marnate") getFileTemplate("modulo7Ma");
    if (stabilimento == "Diba Chignolo Po") getFileTemplate("modulo7Di");
  };
  const handleDownloadModulo5 = (stabilimento) => {
    if (stabilimento == "Mondorevive Busto Arsizio")
      getFileTemplate("infoRischiRegBa");
    if (stabilimento == "Mondorevive Ferentino")
      getFileTemplate("infoRischiRegFe");
    if (stabilimento == "Caltek Chignolo Po")
      getFileTemplate("infoRischiRegChi");
    if (stabilimento == "MondoSD Bernate Ticino")
      getFileTemplate("infoRischiRegBe");
    if (stabilimento == "Mondorevive Marnate")
      getFileTemplate("infoRischiRegMa");
    if (stabilimento == "Diba Chignolo Po") getFileTemplate("infoRischiRegDi");
  };
  const handleDownloadDuvri = (stabilimento) => {
    if (stabilimento == "Mondorevive Busto Arsizio") getFileTemplate("duvriBa");
    if (stabilimento == "Mondorevive Ferentino") getFileTemplate("duvriFe");
    if (stabilimento == "Caltek Chignolo Po") getFileTemplate("duvriChi");
    if (stabilimento == "MondoSD Bernate Ticino") getFileTemplate("duvriBe");
    if (stabilimento == "Mondorevive Marnate") getFileTemplate("duvriMa");
    if (stabilimento == "Diba Chignolo Po") getFileTemplate("duvriDi");
  };
  //Gestisce le label della selezione di piu file
  const handleChangeLabelFile = (e) => {
    let label = "";
    for (var i = 0; i < e.length; i++) {
      if (i == e.length - 1) label += e[i].name;
      else label += e[i].name + "; ";
    }
    setLabelFile({ ...labelFile, dipendenti: label });
  };
  const handleChangeFileLavoratori = (e) => {
    setFileAttestati([...fileAttestati, { file: e[0], nome: e[0].name }]);
  };
  useEffect(() => {
    getInfoAppalto();
  }, []);
  //Ottiene le informazioni sull`appalto dal db
  const getInfoAppalto = async (idPassato = undefined) => {
    const finalId = idPassato !== undefined ? idPassato : idAppaltoComune;
    const url = finalId
      ? "https://apot.mondorevive.com/api/appaltiAppaltatore?idSubappalto=" +
        finalId
      : "https://apot.mondorevive.com/api/appaltiAppaltatore";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      //dire ok
      const data = await response.json();
      if (data.datiComuni == null) {
        navigate("/form");
        return;
      }
      setDatiGenerali({
        dataInizio: new Date(data.datiComuni.dataInizio),
        dataFine: new Date(data.datiComuni.dataFine),
        descrizione: data.datiComuni.descrizione,
      });
      setId(data.datiAppaltiSpecifici[0].idAppalto);
      idT.current = data.datiAppaltiSpecifici[0].idAppalto;
      setIdComune(data.datiComuni.idAppaltoComune);
      idC.current = data.datiComuni.idAppaltoComune;
      setTelefono(data.datiComuni.cellulare);
      setCheckedCertificazioni({
        iso14001: data.datiComuni.certIso14001,
        iso9001: data.datiComuni.certIso9001,
        iso45001: data.datiComuni.certIso45001,
        sa8000: data.datiComuni.certificazioneSa8000,
      });
      setCheckedCertificazioniNonModificate({
        iso14001: data.datiComuni.certIso14001,
        iso9001: data.datiComuni.certIso9001,
        iso45001: data.datiComuni.certIso45001,
        sa8000: data.datiComuni.certificazioneSa8000,
      });
      setStorico(data.storicoAppalti);
      setPreposti(data.datiComuni.prepostoCantiereList);
      setAllEmail(data.datiComuni.mailList);
      setFileAttestati(data.datiComuni.fileAggiuntiviList);
      setRspp(data.datiComuni.nomeRspp);
      setDdl(data.datiComuni.nomeDdl);
      setMc(data.datiComuni.nomeMc);
      let a = [];
      data.datiComuni.rlsAppaltoList.map((rls) => {
        a.push({
          denominazione: rls.denominazione,
          dataNominaRls: new Date(rls.dataNominaRls),
        });
      });
      setRlsAll(a);
      if (data.datiComuni.nominaDdl) {
        setDataDdl(new Date(data.datiComuni.nominaDdl.split("T")[0]));
      }
      if (data.datiComuni.nominaMc)
        setDataMc(new Date(data.datiComuni.nominaMc.split("T")[0]));

      if (data.datiComuni.nominaRspp)
        setDataRspp(new Date(data.datiComuni.nominaRspp.split("T")[0]));

      if (data.datiComuni.dataDurc)
        setDataDurc(new Date(data.datiComuni.dataDurc.split("T")[0]));
      if (data.datiComuni.dataDichiarazione445)
        setDataDichiarazione445(new Date(data.datiComuni.dataDichiarazione445));

      setReferente(data.datiComuni.referenteMondorevive);
      setAppalto(data.datiComuni.tipoAppalto);
      setAzienda(data.datiComuni.tipologiaSocieta);

      let arr = [];
      let ass = [];
      data.datiAppaltiSpecifici.map((appalto) => {
        arr.push(appalto.nomeStabilimento);
        let s = appalto.stato.substring(2, appalto.stato.length);

        ass.push({
          nomeStabilimento: appalto.nomeStabilimento,
          stato: s.replace("_", " "),
          modulo7: appalto.modulo7,
          infoRischiReg: appalto.infoRischiReg,
          idAppalto: appalto.idAppalto,
          duvri: appalto.duvri,
        });
      });
      setStabilimentiSelezionati(ass);
      setLabelStabilimenti(arr);
      setCodice(data.datiComuni.codiceCameraCommercio);
      if (data.datiComuni.dataVisuraCamerale)
        setVisura(new Date(data.datiComuni.dataVisuraCamerale.split("T")[0]));
      if (data.datiComuni.dataCertaDvr)
        setDataDvr(new Date(data.datiComuni.dataCertaDvr.split("T")[0]));
      let arr3 = [];
      data.datiComuni.lavoratoreList.map((dipendente) => {
        let arr2 = [];

        dipendente.attestatiList.map((attestato) => {
          arr2.push({
            ...attestato,
            downlaodPresente: attestato.filename ? true : false,
          });
        });

        arr3.push({
          ...dipendente,
          downloadIdoneita: dipendente.filenameIdoneitaSanitaria ? true : false,
          downloadUnilav: dipendente.filenameUnilav ? true : false,
          attestatiList: arr2,
        });
      });
      setDipendenti(arr3);
      if (data.datiComuni.dataPolizzaAssicurativa)
        setDataScadenzaRTC(
          new Date(data.datiComuni.dataPolizzaAssicurativa.split("T")[0])
        );
      if (data.dataDurc)
        setDataDurc(new Date(data.datiComuni.dataDurc.split("T")[0]));

      setLoading(false);
      nomeReferenteAzienda.current.value = data.datiComuni.referenteAzienda;
      note.current.value = data.datiComuni.note;
      if (data.datiComuni.nomeAzienda !== null)
        nomeAzienda.current.value = data.datiComuni.nomeAzienda;
      if (data.datiComuni.indirizzo !== null)
        indirizzoAzienda.current.value = data.datiComuni.indirizzo;
      if (data.datiComuni.mailPec !== null)
        pecAzienda.current.value = data.datiComuni.mailPec;
      setCheckedFile({
        dichiarazione445: data.datiComuni.dichiarazione445,
        documentoDvr: data.datiComuni.documentoDvr,
        durc: data.datiComuni.durc,
        duvri: data.datiComuni.duvri,
        infoRischiReg: data.datiComuni.infoRischiReg,
        iscrizioneCameraCommercio: data.datiComuni.iscrizioneCameraCommercio,
        modulo7: data.datiComuni.modulo7,
        polizzaAssicurativaRct: data.datiComuni.polizzaAssicurativaRct,
      });
      setSubappalti(data.datiComuni.subappalti);
    } else {
      //DARE ERROR
      errorNotification("Impossibile ottenere i dati, riprova più tardi!");
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  const getFile = useCallback(async (nomeFile, idA = null) => {
    const url = "https://apot.mondorevive.com/api/downloadAllegatoAppalto";
    let a;
    let idStab = idT.current;
    if (
      nomeFile === "modulo7" ||
      nomeFile === "inforischi" ||
      nomeFile === "duvri"
    ) {
      idStab = idA;
    }
    if (idA != null)
      a = JSON.stringify({
        id: idStab,
        idAppaltoComune: idC.current,
        campo: nomeFile,
        idFile: idA,
      });
    else
      a = JSON.stringify({
        id: idStab,
        idAppaltoComune: idC.current,
        campo: nomeFile,
      });

    const response = await toast.promise(
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + cookie.get("token"),
        },
        body: a,
        responseType: "blob",
      }),
      {
        pending: "Download in corso",
        success: {
          render() {
            return `File scaricato correttamente`;
          },
          autoClose: 1000,
        },
        error: {
          render() {
            return `Errore nel download del file`;
          },
          autoClose: 1000,
        },
      }
    );
    if (response.status == 200) {
      const data = await response.arrayBuffer();
      const filename = response.headers
        .get("content-disposition")
        .split("filename=")[1];
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename.substring(1, filename.length - 1));
      document.body.appendChild(link);
      link.click();
    } else {
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  }, []);
  // GESTISCO I DOWNLOAD DEI FILE COMPILATI
  const handleDownloadModulo7File = (stabilimento, idA = null) => {
    if (stabilimento == "Mondorevive Busto Arsizio") getFile("modulo7", idA);
    if (stabilimento == "Mondorevive Ferentino") getFile("modulo7", idA);
    if (stabilimento == "Caltek Chignolo Po") getFile("modulo7", idA);
    if (stabilimento == "MondoSD Bernate Ticino") getFile("modulo7", idA);
    if (stabilimento == "Mondorevive Marnate") getFile("modulo7", idA);
    if (stabilimento == "Diba Chignolo Po") getFile("modulo7", idA);
  };
  const handleDownloadModulo5File = (stabilimento, idA = null) => {
    if (stabilimento == "Mondorevive Busto Arsizio") getFile("inforischi", idA);
    if (stabilimento == "Mondorevive Ferentino") getFile("inforischi", idA);
    if (stabilimento == "Caltek Chignolo Po") getFile("inforischi", idA);
    if (stabilimento == "MondoSD Bernate Ticino") getFile("inforischi", idA);
    if (stabilimento == "Mondorevive Marnate") getFile("inforischi", idA);
    if (stabilimento == "Diba Chignolo Po") getFile("inforischi", idA);
  };
  const handleDownloadDuvriFile = (stabilimento, idA = null) => {
    if (stabilimento == "Mondorevive Busto Arsizio") getFile("duvri", idA);
    if (stabilimento == "Mondorevive Ferentino") getFile("duvri", idA);
    if (stabilimento == "Caltek Chignolo Po") getFile("duvri", idA);
    if (stabilimento == "MondoSD Bernate Ticino") getFile("duvri", idA);
    if (stabilimento == "Mondorevive Marnate") getFile("duvri", idA);
    if (stabilimento == "Diba Chignolo Po") getFile("duvri", idA);
  };
  //Gestisce la rimozione di un file dalla lista dei file dei dipendenti
  const handleRemoveFile = (nome) => {
    let arr = [];
    fileAttestati.map((file) => {
      if (file.nome != nome) arr.push(file);
    });
    setFileAttestati(arr);
  };
  const handlechangeStabilimenti = (e) => {
    let a = [];
    let trovato = false;
    if (e.length < stabilimentiSelezionati.length) {
      e.map((stab) => {
        stabilimentiSelezionati.map((stabilimento) => {
          if (stab == stabilimento.nomeStabilimento) {
            a.push(stabilimento);
          }
        });
      });
    } else {
      e.map((stab) => {
        stabilimentiSelezionati.map((stabilimento) => {
          if (stab == stabilimento.nomeStabilimento) {
            trovato = true;
            a.push(stabilimento);
          }
        });
        if (!trovato) {
          a.push({
            duvri: false,
            infoRischiReg: false,
            modulo7: false,
            nomeStabilimento: stab,
            stato: "",
          });
        }
        trovato = false;
      });
    }
    setStabilimentiSelezionati(a);
    setLabelStabilimenti(e);
  };
  function emailChecker() {
    let valid = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    if (valid) {
      setEmailNonValida(false);
    } else setEmailNonValida(true);
  }
  useEffect(() => {
    if (email == "") {
      return;
    }
    const timeOutId = setTimeout(() => emailChecker(), 500);
    return () => clearTimeout(timeOutId);
  }, [email]);
  //Funzione che modifica le informazioni aggiuntive di un dipendente
  const handleChangeInformazioniAggiuntive = (value, campo, value1, campo1) => {
    if (value1) {
      setInformazioniAggiuntiveDipendente({
        ...informazioniAggiuntiveDipendente,
        [campo]: value,
        [campo1]: value1,
      });
      return;
    }
    setInformazioniAggiuntiveDipendente({
      ...informazioniAggiuntiveDipendente,
      [campo]: value,
    });
  };
  //Funzione che modifica le informazioni di un attestato
  const handleChangeValueAttestato = (value, attestatoDaCambiare, campo) => {
    let arr = [];
    attestati.map((attestato) => {
      if (attestato.label == attestatoDaCambiare.label) {
        arr.push({ ...attestato, [campo]: value });
      } else arr.push(attestato);
    });
    setAttestati(arr);
  };

  const checkIfPresente = (filename) => {
    let trovato = false;
    dipendenti.map((dipendente) => {
      if (
        dipendente.filenameIdoneitaSanitaria == filename ||
        dipendente.filenameUnilav == filename
      ) {
        trovato = true;
      }
      dipendente.attestatiList.map((attestato) => {
        if (attestato.filename == filename) {
          trovato = true;
        }
      });
    });
    if (
      informazioniAggiuntiveDipendente.filenameIdoneitaSanitari == filename ||
      informazioniAggiuntiveDipendente.filenameUnilav == filename
    )
      trovato = true;
    attestati.map((attestato) => {
      if (attestato.status && attestato.file && attestato.file.name == filename)
        trovato = true;
    });
    return trovato;
  };

  const changeFilename = (file) => {
    let name = file.name;
    let cont = 2;
    while (checkIfPresente(name)) {
      let filename = file.name.split(".");
      name = filename[0] + "(" + cont + ")" + "." + filename[1];
      cont++;
    }
    const newFile = new File([file], name);
    return { file: newFile, name: name };
  };
  const [disabledAggiuntaDipendente, setDisabledAggiuntaDipendente] =
    useState(true);
  useEffect(() => {
    let disabled = false;
    attestati.map((attestato) => {
      if (attestato.label == "ACCORDO STATO/REGIONI" && !attestato.status)
        disabled = true;

      if (attestato.status && (!attestato.file || !attestato.dataScadenza))
        disabled = true;
    });
    if (
      !informazioniAggiuntiveDipendente.dataScadenza ||
      (!informazioniAggiuntiveDipendente.socioFondatore &&
        !informazioniAggiuntiveDipendente.tempoIndeterminato &&
        !informazioniAggiuntiveDipendente.dataScadenzaUnilav) ||
      !informazioniAggiuntiveDipendente.file ||
      (!informazioniAggiuntiveDipendente.socioFondatore &&
        !informazioniAggiuntiveDipendente.fileUnilav)
    )
      disabled = true;
    if (nome == "") disabled = true;
    setDisabledAggiuntaDipendente(disabled);
  }, [attestati, informazioniAggiuntiveDipendente, nome]);

  //Funzioen che controlla se disabilitare o meno l`invio
  const controllaInvio = () => {
    let campiMancanti = [];
    if (!datiGenerali.dataInizio) campiMancanti.push("Data inizio presunta");
    if (!datiGenerali.dataFine) campiMancanti.push("Data fine presunta");
    if (!datiGenerali.descrizione)
      campiMancanti.push("Descrizione intervento ");
    if (!stabilimentiSelezionati.length) campiMancanti.push("Stabilimento");
    dipendenti.map((dipendente) => {
      let contieneAccordo = false;
      let tuttiCampi = true;

      dipendente.attestatiList.map((attestato) => {
        if (attestato.label == "ACCORDO STATO/REGIONI") contieneAccordo = true;
        if (!attestato.dataScadenza || !attestato.filename) tuttiCampi = false;
      });
      if (!dipendente.dataScadenzaIdoneita)
        campiMancanti.push(
          "Dipendente " +
            dipendente.denominazione +
            " Inserire campo Data scadenza idoneità sanitaria"
        );
      if (
        !dipendente.socioFondatore &&
        !dipendente.tempoIndeterminato &&
        !dipendente.dataScadenzaUnilav
      )
        campiMancanti.push(
          "Dipendente " +
            dipendente.denominazione +
            " Inserire campo Data scadenza UNILAV"
        );
      if (!dipendente.filenameIdoneitaSanitaria)
        campiMancanti.push(
          "Dipendente " +
            dipendente.denominazione +
            " Inserire campo File Idoneità Sanitaria"
        );
      if (!dipendente.socioFondatore && !dipendente.filenameUnilav)
        campiMancanti.push(
          "Dipendente " +
            dipendente.denominazione +
            " Inserire campo File UNILAV"
        );

      if (!tuttiCampi)
        campiMancanti.push(
          "Dipendente " +
            dipendente.denominazione +
            " in uno o più attestati non è stato inserito il file e/o la data scadenza."
        );
      if (!contieneAccordo)
        campiMancanti.push(
          "Dipendente " +
            dipendente.denominazione +
            " manca attestato ACCORDO STATO/REGIONI."
        );
    });
    setInvioDisabilitato(campiMancanti);
  };

  const modificaSubappalto = (id) => {
    navigate("/update", {
      state: { idAppaltoPadre: idComune, idAppaltoComune: id },
    });
    navigate(0);
  };

  const eliminaSubappalto = (id) => {
    console.log("id", id);
  };
  useEffect(() => {
    controllaInvio();
  }, [dipendenti, datiGenerali, stabilimentiSelezionati]);
  return (
    <div className="form-container">
      <ToastContainer
        transition={Flip}
        newestOnTop
        rtl={false}
        pauseOnFocusLoss
      />
      {loading ? (
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Loader size={100} variant="dots" />
        </div>
      ) : (
        <>
          <h1 className="mt10">
            {!idAppaltoPadre ? "Modifica l'appalto" : "Modifica il subappalto"}
          </h1>
          <Modal
            hideCloseButton
            onClose={() => {}}
            transition="fade"
            centered
            opened={opened}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
              className="center"
            >
              <h3 style={{ marginBottom: "30px" }}>
                {progress < 100
                  ? "Stiamo caricando i tuoi allegati, attendi."
                  : "Allegati caricati con successo, attendi ancora un istante!"}
              </h3>
              <div style={{ width: "100%" }}>
                <Progress value={progress} striped animate />
              </div>
            </div>
          </Modal>
          <form className="payment-form">
            <h5>I campi constrassegnati con * sono obbligatori</h5>
            <h2 className="card-label mt20">Dati Generali</h2>
            <div className="container_dati_generali mb20">
              <div className="container_date_dati_generali mt20">
                <div className="form-group maxW300 mb0">
                  <DatePicker
                    value={datiGenerali.dataInizio}
                    locale="it"
                    onChange={(e) => {
                      setDatiGenerali({ ...datiGenerali, dataInizio: e });
                    }}
                    invalid={
                      datiGenerali.dataInizio == "" ||
                      datiGenerali.dataInizio == null
                    }
                    placeholder={"Data Inizio presunta"}
                    label={"Data Inizio presunta"}
                    size="lg"
                    required
                    sx={{ maxWidth: "300px" }}
                  />
                </div>
                <div className="maxW300 mb0">
                  <DatePicker
                    value={datiGenerali.dataFine}
                    locale="it"
                    invalid={
                      datiGenerali.dataFine == "" ||
                      datiGenerali.dataFine == null
                    }
                    required
                    onChange={(e) => {
                      setDatiGenerali({ ...datiGenerali, dataFine: e });
                    }}
                    placeholder={"Data Fine presunta"}
                    label={"Data Fine presunta"}
                    size="lg"
                    sx={{ maxWidth: "300px" }}
                  />
                </div>
              </div>
              <div className="container_descrizione_dati_generali mt20">
                <Textarea
                  value={datiGenerali.descrizione}
                  onChange={(e) => {
                    setDatiGenerali({
                      ...datiGenerali,
                      descrizione: e.target.value,
                    });
                  }}
                  placeholder="Descrizione Intervento"
                  label="Descrizione Intervento"
                  radius="md"
                  size="md"
                  required
                  invalid={
                    datiGenerali.descrizione && datiGenerali.descrizione == ""
                  }
                />
              </div>
            </div>
            <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
            {idAppaltoPadre !== undefined ? (
              <div>
                <h2> Dati Azienda</h2>
                <div>
                  <div className="form-group">
                    <label className="card-label">Nome azienda</label>
                    <Input
                      type="text"
                      name="name"
                      size="lg"
                      ref={nomeAzienda}
                      spellCheck="false"
                      autoComplete="off"
                      id="cardHolder"
                      className="form-control form-control-lg"
                    />
                  </div>
                  <div className="form-group">
                    <label className="card-label">Indirizzo</label>
                    <Input
                      type="text"
                      name="name"
                      size="lg"
                      ref={indirizzoAzienda}
                      spellCheck="false"
                      autoComplete="off"
                      id="cardHolder"
                      className="form-control form-control-lg"
                    />
                  </div>
                  <div className="form-group">
                    <label className="card-label">PEC</label>
                    <Input
                      type="text"
                      name="name"
                      size="lg"
                      ref={pecAzienda}
                      spellCheck="false"
                      autoComplete="off"
                      id="cardHolder"
                      className="form-control form-control-lg"
                    />
                  </div>
                </div>
                <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
              </div>
            ) : null}
            <div className="grid">
              <div className="form-group">
                <label className="card-label">E-Mail Aggiuntive </label>
                {allEmail &&
                  allEmail.map((emails, count) => (
                    <div className="inline-item center" key={count}>
                      <Input
                        type="text"
                        name="name"
                        size="lg"
                        disabled
                        value={emails}
                        placeholder="Nome e Cognome"
                        spellCheck="false"
                        autoComplete="off"
                        id="cardHolder"
                        style={{ marginBottom: "20px" }}
                        className="form-control form-control-lg"
                      />
                      <div className="form-group">
                        <Button
                          color={"red"}
                          onClick={() => rimuoviEmail(emails)}
                        >
                          Rimuovi
                        </Button>
                      </div>
                    </div>
                  ))}
                {allEmail.length == 0 && (
                  <h4 style={{ color: "red" }}>Nessuna E-mail Aggiuntiva</h4>
                )}

                <div className="grid-container repeat2 pl0 center">
                  <Input
                    type="text"
                    name="name"
                    size="lg"
                    invalid={emailNonValida && email != ""}
                    style={{ width: "100%" }}
                    placeholder="E-Mail"
                    value={email}
                    onChange={(e) => {
                      if (e.target.value == "") return;
                      setEmail(e.target.value);
                    }}
                    spellCheck="false"
                    autoComplete="off"
                    id="cardHolder"
                    className="form-control form-control-lg"
                  />
                  <Button
                    className="mw400"
                    disabled={email == "" || emailNonValida}
                    color={"green"}
                    onClick={aggiungiEmail}
                  >
                    Aggiungi
                  </Button>
                </div>
              </div>
            </div>
            <div className="grid-container repeat2 pl0">
              <div className="form-group">
                <label className="card-label">Numero di Telefono</label>
                <Input
                  type="number"
                  name="name"
                  value={telefono}
                  size="lg"
                  invalid={telefono == ""}
                  spellCheck="false"
                  onChange={(e) => setTelefono(e.target.value)}
                  autoComplete="off"
                  id="cardHolder"
                  className="form-control"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="form-group">
                <label className="card-label">Referente Azienda</label>
                <Input
                  type="text"
                  ref={nomeReferenteAzienda}
                  invalid={nomeReferenteAzienda.current.value == ""}
                  name="name"
                  size="lg"
                  spellCheck="false"
                  autoComplete="off"
                  id="cardHolder"
                  className="form-control form-control-lg"
                />
              </div>
            </div>
            {/* RESPONSABILI */}
            <h2 className="card-label">Responsabili</h2>
            {/* RSPP */}
            <div className="grid-container repeat2 pl0">
              <div className="form-group">
                <label
                  className="card-label"
                  style={{ fontWeight: "bold !important" }}
                >
                  Cognome e Nome RSPP
                </label>
                <Input
                  type="text"
                  name="name"
                  size="lg"
                  spellCheck="false"
                  value={rspp}
                  invalid={rspp == ""}
                  onChange={(e) => setRspp(e.target.value)}
                  autoComplete="off"
                  id="cardHolder"
                  className="form-control form-control-lg"
                />
              </div>

              <div className="form-group">
                <label className="card-label">Data Nomina</label>

                <DatePicker
                  value={dataRspp}
                  locale="it"
                  invalid={dataRspp == "" || dataRspp == null}
                  onChange={(e) => {
                    setDataRspp(e);
                  }}
                  placeholder={"Seleziona una data"}
                  size="lg"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            {/* RLS */}
            <label
              className="card-label"
              style={{
                color: "rgb(100, 100, 100)",
                fontWeight: "500",
              }}
            >
              Cognome e Nome RLS
            </label>
            {rlsAll.length != 0 ? (
              <div>
                {rlsAll.map((rlss, count) => (
                  <div
                    className="grid-container center repeat3 pl0"
                    key={count}
                  >
                    <Input
                      type="text"
                      name="name"
                      size="lg"
                      disabled
                      value={rlss.denominazione}
                      placeholder="Denominazione"
                      spellCheck="false"
                      autoComplete="off"
                      id="cardHolder"
                      className="form-control form-control-lg"
                    />

                    <DatePicker
                      value={rlss.dataNominaRls}
                      locale="it"
                      placeholder={"Seleziona una data"}
                      size="lg"
                      style={{ width: "100%" }}
                    />
                    <Button
                      className="mw200"
                      color={"red"}
                      onClick={() => handleRemoveRls(rlss)}
                    >
                      Rimuovi
                    </Button>
                  </div>
                ))}
              </div>
            ) : (
              <h4 style={{ color: "red" }}>Nessun RLS impostato</h4>
            )}
            <div
              style={{ marginTop: "30px" }}
              className="grid-container repeat3 pl0"
            >
              <div className="form-group mb0">
                <Input
                  type="text"
                  size="lg"
                  name="name"
                  spellCheck="false"
                  value={rls.denominazione}
                  placeholder={"Cognome e Nome RLS"}
                  onChange={(e) =>
                    setRls({ ...rls, denominazione: e.target.value })
                  }
                  autoComplete="off"
                  id="cardHolder"
                  className="form-control form-control-lg"
                />
              </div>
              <div className="form-group mb0">
                <DatePicker
                  value={rls.dataNominaRls}
                  locale="it"
                  onChange={(e) => {
                    setRls({ ...rls, dataNominaRls: e });
                  }}
                  placeholder={"Data Nomina"}
                  size="lg"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="inline-item center mb0">
                <Button
                  color={"green"}
                  onClick={handleAggiuntaRlss}
                  disabled={
                    rls.dataNominaRls == null || rls.denominazione == ""
                  }
                >
                  Aggiungi
                </Button>
              </div>
            </div>
            {/* DDL */}
            <div className="grid-container repeat2 pl0">
              <div className="form-group">
                <label className="card-label">Cognome e Nome Ddl</label>

                <Input
                  type="text"
                  name="name"
                  value={ddl}
                  invalid={ddl == ""}
                  onChange={(e) => setDdl(e.target.value)}
                  size="lg"
                  spellCheck="false"
                  autoComplete="off"
                  id="cardHolder"
                  className="form-control form-control-lg"
                />
              </div>

              <div className="form-group">
                <label className="card-label">Data Incarico</label>

                <DatePicker
                  value={dataDdl}
                  locale="it"
                  invalid={dataDdl == "" || dataDdl == null}
                  onChange={(e) => setDataDdl(e)}
                  placeholder={"Seleziona una data"}
                  size="lg"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            {/* MC */}
            <div className="grid-container repeat2 pl0">
              <div className="form-group">
                <label className="card-label">Cognome e Nome MC</label>

                <Input
                  type="text"
                  name="name"
                  size="lg"
                  value={mc}
                  invalid={mc == ""}
                  onChange={(e) => setMc(e.target.value)}
                  spellCheck="false"
                  autoComplete="off"
                  id="cardHolder"
                  className="form-control form-control-lg"
                />
              </div>

              <div className="form-group">
                <label className="card-label">Data Nomina</label>

                <DatePicker
                  value={dataMc}
                  locale="it"
                  invalid={dataMc == "" || dataMc == null}
                  onChange={(e) => setDataMc(e)}
                  placeholder={"Seleziona una data"}
                  size="lg"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            {/* PREPOSTI DI CANTIERE*/}
            <div className="grid">
              <div className="form-group">
                <label className="card-label">Preposti di cantiere</label>

                {preposti.length == 0 && (
                  <h4 style={{ color: "red" }}>Nessun Preposto inserito</h4>
                )}
                {preposti &&
                  preposti.map((preposto, count) => (
                    <div className="inline-item center" key={count}>
                      <Input
                        type="text"
                        name="name"
                        size="lg"
                        disabled
                        value={preposto}
                        spellCheck="false"
                        autoComplete="off"
                        id="cardHolder"
                        style={{ marginBottom: "20px" }}
                        className="form-control form-control-lg"
                      />
                      <div className="form-group">
                        <Button
                          color={"red"}
                          onClick={() => rimuoviPreposto(preposto)}
                        >
                          Rimuovi
                        </Button>
                      </div>
                    </div>
                  ))}
                <div className="inline-item center">
                  <Input
                    type="text"
                    name="name"
                    size="lg"
                    placeholder="Nome e Cognome"
                    value={nomePreposto}
                    onChange={(e) => setNomePreposto(e.target.value)}
                    spellCheck="false"
                    autoComplete="off"
                    id="cardHolder"
                    className="form-control form-control-lg"
                  />
                  <Button
                    disabled={nomePreposto == ""}
                    color={"green"}
                    onClick={aggiungiPreposto}
                  >
                    Aggiungi
                  </Button>
                </div>
              </div>
            </div>
            <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
            {/*CERTIFICAZIONI*/}
            <div className="form-group">
              <h2 className="card-label">Certificazioni</h2>
              <div className="checkbox">
                <div className="inline-item mb10">
                  <Checkbox
                    size="lg"
                    label=" ISO9001"
                    checked={checkedCertificazioni.iso9001}
                    onChange={(e) =>
                      setCheckedCertificazioni({
                        ...checkedCertificazioni,
                        iso9001: e.currentTarget.checked,
                      })
                    }
                  />

                  {checkedCertificazioni.iso9001 && (
                    <div className={"center"}>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        id="iso9001"
                        onChange={(e) => {
                          if (e.target.value == "") return;
                          if (e.target.files[0].size / 1024 / 1024 > 50) {
                            setErrorSize(true);
                            return;
                          }
                          setFileCertificazioni({
                            ...fileCertificazioni,
                            iso9001: e.target.files[0],
                          });
                          setLabelFile({
                            ...labelFile,
                            iso9001: e.target.files[0].name,
                          });
                        }}
                      />
                      <Button
                        className="mw400"
                        onClick={() => handleClickOpenFile("iso9001")}
                      >
                        Scegli File
                      </Button>
                      <label style={{ marginLeft: "10px" }}>
                        {labelFile.iso9001
                          ? labelFile.iso9001
                          : "Nessun file Selezionato"}
                      </label>
                      {checkedCertificazioniNonModificate.iso9001 && (
                        <Button
                          className="mw400"
                          variant="outline"
                          radius="md"
                          size="lg"
                          compact
                          style={{ marginLeft: "10px" }}
                          onClick={() => getFile("certiso9001")}
                        >
                          DOWNLOAD ALLEGATO
                        </Button>
                      )}
                    </div>
                  )}
                </div>
                <div className="inline-item mb10">
                  <Checkbox
                    size="lg"
                    label=" ISO14001"
                    checked={checkedCertificazioni.iso14001}
                    onChange={(e) =>
                      setCheckedCertificazioni({
                        ...checkedCertificazioni,
                        iso14001: e.currentTarget.checked,
                      })
                    }
                  />
                  {checkedCertificazioni.iso14001 && (
                    <div className={"center"}>
                      <input
                        type="file"
                        id="iso14001"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          if (e.target.value == "") return;
                          if (e.target.files[0].size / 1024 / 1024 > 50) {
                            setErrorSize(true);
                            return;
                          }
                          setFileCertificazioni({
                            ...fileCertificazioni,
                            iso14001: e.target.files[0],
                          });
                          setLabelFile({
                            ...labelFile,
                            iso14001: e.target.files[0].name,
                          });
                        }}
                      />
                      <Button
                        className="mw400"
                        onClick={() => handleClickOpenFile("iso14001")}
                      >
                        Scegli File
                      </Button>
                      <label style={{ marginLeft: "10px" }}>
                        {labelFile.iso14001
                          ? labelFile.iso14001
                          : "Nessun file Selezionato"}
                      </label>
                      {checkedCertificazioniNonModificate.iso14001 && (
                        <Button
                          variant="outline"
                          radius="md"
                          size="lg"
                          compact
                          className="mw400"
                          style={{ marginLeft: "10px", minWidth: "200px" }}
                          onClick={() => getFile("certiso14001")}
                        >
                          DOWNLOAD ALLEGATO
                        </Button>
                      )}
                    </div>
                  )}
                </div>
                <div className="inline-item mb10">
                  <Checkbox
                    size="lg"
                    label=" ISO45001"
                    checked={checkedCertificazioni.iso45001}
                    onChange={(e) =>
                      setCheckedCertificazioni({
                        ...checkedCertificazioni,
                        iso45001: e.currentTarget.checked,
                      })
                    }
                  />
                  {checkedCertificazioni.iso45001 && (
                    <div className={"center"}>
                      <input
                        type="file"
                        id="iso45001"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          if (e.target.value == "") return;
                          if (e.target.files[0].size / 1024 / 1024 > 50) {
                            setErrorSize(true);
                            return;
                          }
                          setFileCertificazioni({
                            ...fileCertificazioni,
                            iso45001: e.target.files[0],
                          });
                          setLabelFile({
                            ...labelFile,
                            iso45001: e.target.files[0].name,
                          });
                        }}
                      />
                      <Button
                        className="mw400"
                        onClick={() => handleClickOpenFile("iso45001")}
                      >
                        Scegli File
                      </Button>
                      <label style={{ marginLeft: "10px" }}>
                        {labelFile.iso45001
                          ? labelFile.iso45001
                          : "Nessun file Selezionato"}
                      </label>
                      {checkedCertificazioniNonModificate.iso45001 && (
                        <Button
                          className="mw400"
                          variant="outline"
                          radius="md"
                          size="lg"
                          compact
                          style={{ marginLeft: "10px" }}
                          onClick={() => getFile("certiso45001")}
                        >
                          DOWNLOAD ALLEGATO
                        </Button>
                      )}
                    </div>
                  )}
                </div>
                <div className="inline-item mb10">
                  <Checkbox
                    size="lg"
                    label=" SA8000"
                    checked={checkedCertificazioni.sa8000}
                    onChange={(e) =>
                      setCheckedCertificazioni({
                        ...checkedCertificazioni,
                        sa8000: e.currentTarget.checked,
                      })
                    }
                  />
                  {checkedCertificazioni.sa8000 && (
                    <div className={"center"}>
                      <input
                        type="file"
                        id="sa8000"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          if (e.target.value == "") return;
                          if (e.target.files[0].size / 1024 / 1024 > 50) {
                            setErrorSize(true);
                            return;
                          }
                          setFileCertificazioni({
                            ...fileCertificazioni,
                            sa8000: e.target.files[0],
                          });
                          setLabelFile({
                            ...labelFile,
                            sa8000: e.target.files[0].name,
                          });
                        }}
                      />
                      <Button
                        className="mw400"
                        onClick={() => handleClickOpenFile("sa8000")}
                      >
                        Scegli File
                      </Button>
                      <label style={{ marginLeft: "10px" }}>
                        {labelFile.sa8000
                          ? labelFile.sa8000
                          : "Nessun file Selezionato"}
                      </label>
                      {checkedCertificazioniNonModificate.sa8000 && (
                        <Button
                          className="mw400"
                          variant="outline"
                          radius="md"
                          size="lg"
                          compact
                          style={{ marginLeft: "10px" }}
                          onClick={() => getFile("certsa8000")}
                        >
                          DOWNLOAD ALLEGATO
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
            {/*REFERENTE INTERNO*/}
            <div className="grid-container repeat2 pl0">
              <div>
                <label className="card-label">Referente Interno</label>
                <Input
                  type="text"
                  name="name"
                  value={referente}
                  invalid={referente == ""}
                  onChange={(e) => setReferente(e.target.value)}
                  size="lg"
                  spellCheck="false"
                  autoComplete="off"
                  id="cardHolder"
                  style={{ width: "100%" }}
                  className="form-control form-control-lg"
                />
              </div>
            </div>
            {/**APPALTO/SUBAPPALTO */}
            <div className="grid-container repeat2 pl0">
              <div className="form-group">
                <label className="card-label">Appalto/Subappalto</label>
                <Select
                  placeholder="Scegline uno"
                  value={appalto}
                  error={appalto == ""}
                  onChange={setAppalto}
                  size="lg"
                  data={[
                    { value: "appalto", label: "Appalto" },
                    { value: "subappalto", label: "Subappalto" },
                  ]}
                />
              </div>
              <div className="form-group">
                <label className="card-label">Azienda/Autonomo</label>
                <Select
                  size="lg"
                  value={azienda}
                  error={azienda == ""}
                  onChange={setAzienda}
                  placeholder="Scegline uno"
                  data={[
                    { value: "azienda", label: "Azienda" },
                    { value: "Autonomo", label: "Autonomo" },
                  ]}
                />
              </div>
            </div>
            <div className="grid-container repeat2 pl0">
              <div>
                <MultiSelect
                  style={{ width: "100%" }}
                  searchable
                  required
                  label={"Seleziona lo stabilimento"}
                  error={stabilimentiSelezionati.length == 0}
                  value={labelStabilimenti}
                  onChange={handlechangeStabilimenti}
                  size="lg"
                  data={stabilimenti}
                  placeholder="Seleziona una o più scelte"
                />
              </div>
            </div>
            <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
            <h2 className="card-label">Camera Di Commercio</h2>
            <div className="grid-container repeat2 pl0">
              <div className="form-group">
                <label className="card-label">Codice Alfanumerico</label>
                <div className="inline-item">
                  <Input
                    size="lg"
                    value={codice}
                    invalid={codice == ""}
                    onChange={(e) => setCodice(e.target.value)}
                    type="text"
                    name="name"
                    spellCheck="false"
                    autoComplete="off"
                    id="cardHolder"
                    className="form-control form-control-lg"
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="card-label">Data di visura camerale</label>
                <DatePicker
                  size="lg"
                  value={visura}
                  invalid={visura == "" || visura == null}
                  locale="it"
                  onChange={(e) => setVisura(e)}
                  placeholder={"Seleziona una data"}
                />
              </div>
            </div>
            <div className="grid-container repeat3 pl0 center">
              <input
                type="file"
                id="camera"
                style={{ display: "none" }}
                onChange={(e) => {
                  if (e.target.value == "") return;
                  if (e.target.files[0].size / 1024 / 1024 > 50) {
                    setErrorSize(true);
                    return;
                  }
                  setFileCamera({
                    ...fileCamera,
                    iscrizione: e.target.files[0],
                  });
                  setLabelFile({
                    ...labelFile,
                    camera: e.target.files[0].name,
                  });
                }}
              />
              <Button
                className="mw400"
                onClick={() => handleClickOpenFile("camera")}
              >
                Scegli File
              </Button>
              <label style={{ marginLeft: "10px" }}>
                {labelFile.camera
                  ? labelFile.camera
                  : "Nessun file Selezionato"}
              </label>
              {checkedFile.iscrizioneCameraCommercio && (
                <Button
                  variant="outline"
                  radius="md"
                  size="lg"
                  compact
                  className="mw400"
                  onClick={() => getFile("iscrizionecameracommercio")}
                >
                  DOWNLOAD ALLEGATO
                </Button>
              )}
            </div>
            <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
            <div className="grid-container repeat2File pl0 center">
              <div className="form-group">
                <label className="card-label">Data certa DVR</label>
                <DatePicker
                  size="lg"
                  locale="it"
                  value={dataDvr}
                  invalid={dataDvr == "" || dataDvr == null}
                  onChange={(e) => setDataDvr(e)}
                  placeholder={"Seleziona una data"}
                />
              </div>
              <div className="form-group">
                <label className="card-label">Allega Documento</label>
                <div className="inline-item">
                  <input
                    type="file"
                    style={{ display: "none" }}
                    id="dvr"
                    onChange={(e) => {
                      if (e.target.value == "") return;
                      if (e.target.files[0].size / 1024 / 1024 > 50) {
                        setErrorSize(true);
                        return;
                      }
                      setFileCamera({ ...fileCamera, dvr: e.target.files[0] });
                      setLabelFile({
                        ...labelFile,
                        dvr: e.target.files[0].name,
                      });
                    }}
                  />
                  <div className="grid-container repeat3 pl0 center">
                    <Button
                      className="mw400"
                      onClick={() => handleClickOpenFile("dvr")}
                    >
                      Scegli File
                    </Button>
                    <label style={{ marginLeft: "10px" }}>
                      {labelFile.dvr
                        ? labelFile.dvr
                        : "Nessun file Selezionato"}
                    </label>
                    {checkedFile.documentoDvr && (
                      <Button
                        variant="outline"
                        radius="md"
                        size="lg"
                        compact
                        className="mw400"
                        onClick={() => getFile("dvr")}
                      >
                        DOWNLOAD ALLEGATO
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-container repeat2File pl0 center">
              <div className="form-group">
                <label className="card-label">Dichiarazione 445:</label>
                <Button
                  style={{ width: "100%", maxWidth: "500px" }}
                  color="green"
                  onClick={() => getFileTemplate("dichiarazione445")}
                >
                  DOWNLOAD MODELLO
                </Button>
              </div>
              <div className="form-group"></div>
              <div className="form-group">
                <label className="card-label">Data dichiarazione 445</label>
                <DatePicker
                  size="lg"
                  locale="it"
                  value={dataDichiarazione445}
                  invalid={
                    dataDichiarazione445 == "" || dataDichiarazione445 == null
                  }
                  onChange={(e) => setDataDichiarazione445(e)}
                  placeholder={"Seleziona una data"}
                />
              </div>
              <div className="form-group">
                <label className="card-label">Allega Documento</label>
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="dichiarazione445"
                  onChange={(e) => {
                    if (e.target.value == "") return;
                    if (e.target.files[0].size / 1024 / 1024 > 50) {
                      setErrorSize(true);
                      return;
                    }
                    setFileCamera({ ...fileCamera, doc445: e.target.files[0] });
                    setLabelFile({
                      ...labelFile,
                      dichiarazione445: e.target.files[0].name,
                    });
                  }}
                />
                <div className="grid-container repeat3 pl0 center">
                  <Button
                    className="mw400"
                    onClick={() => handleClickOpenFile("dichiarazione445")}
                  >
                    Scegli File
                  </Button>
                  <label style={{ marginLeft: "10px" }}>
                    {labelFile.dichiarazione445
                      ? labelFile.dichiarazione445
                      : "Nessun file Selezionato"}
                  </label>
                  {checkedFile.dichiarazione445 && (
                    <Button
                      variant="outline"
                      radius="md"
                      size="lg"
                      compact
                      className="mw400"
                      onClick={() => getFile("dichia445")}
                    >
                      DOWNLOAD ALLEGATO
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
            <h2 className="card-label">Dipendenti e Attestati</h2>
            {dipendenti.length == 0 && (
              <h4 style={{ color: "red" }}>Nessun dipendente aggiunto</h4>
            )}
            <DipendentiList
              dipendenti={dipendenti}
              setDipendenti={setDipendenti}
              setErrorSize={setErrorSize}
              status={true}
              getFile={getFile}
              dpiList={dpiUnchanged}
              attestatiList={attestatiUnchanged}
            />
            <div className="grid-container repeat1 pl0 center">
              {showInserimentoDipendente ? (
                <Button
                  color={"orange"}
                  style={{ marginBottom: "15px", maxWidth: "300px" }}
                  onClick={() => {
                    setShowInserimentoDipendente(false);
                    resetDipendenti();
                  }}
                >
                  Annulla Inserimento
                </Button>
              ) : (
                <Button
                  style={{ marginBottom: "15px", maxWidth: "300px" }}
                  onClick={() => setShowInserimentoDipendente(true)}
                  color="green"
                >
                  Aggiungi Dipendente
                </Button>
              )}
              {showInserimentoDipendente && (
                <>
                  <Input
                    type="text"
                    name="name"
                    spellCheck="false"
                    autoComplete="off"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    placeholder="Nome e Cognome *"
                    id="cardHolder"
                    className="form-control form-control-lg"
                    size={"lg"}
                  />

                  <h2 className="card-label">Informazioni Aggiuntive</h2>
                  <div className="form-group maxW margin0">
                    <label className="card-label">Socio fondatore</label>
                    <Checkbox
                      size="lg"
                      checked={informazioniAggiuntiveDipendente.socioFondatore}
                      onChange={(e) => {
                        handleChangeInformazioniAggiuntive(
                          e.target.checked,
                          "socioFondatore"
                        );
                      }}
                    />
                  </div>
                  {!informazioniAggiuntiveDipendente.socioFondatore && (
                    <div className="informazioniAggiuntiveDipendente">
                      <div className="form-group maxW margin0">
                        <label className="card-label">UNILAV * </label>
                        <div>
                          <input
                            type="file"
                            id="unilav"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              if (e.target.files[0].size / 1024 / 1024 > 50) {
                                setErrorSize(true);
                                return;
                              }
                              let { file, name } = changeFilename(
                                e.target.files[0]
                              );
                              //setLabelFile({ ...labelFile, unilav: e.target.files[0].name });
                              handleChangeInformazioniAggiuntive(
                                file,
                                "fileUnilav",
                                name,
                                "filenameUnilav"
                              );
                              // setTimeout(() => {
                              // 	handleChangeInformazioniAggiuntive(
                              // 		e.target.files[0].name,
                              // 		"filenameUnilav"
                              // 	);
                              // }, 100);
                            }}
                          />
                          <div
                            className="grid-container center padding0"
                            style={{ width: "fit-content" }}
                          >
                            <Button
                              className="mw400"
                              onClick={() => handleClickOpenFile("unilav")}
                            >
                              Scegli File
                            </Button>
                            <label style={{ marginLeft: "10px" }}>
                              {informazioniAggiuntiveDipendente.filenameUnilav
                                ? informazioniAggiuntiveDipendente.filenameUnilav
                                : "Nessun file Selezionato"}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="container_tempo_indeterminato">
                        <div className="form-group maxW margin0">
                          <label className="card-label">
                            Tempo Indeterminato
                          </label>
                          <Checkbox
                            size="lg"
                            checked={
                              informazioniAggiuntiveDipendente.tempoIndeterminato
                            }
                            onChange={(e) => {
                              handleChangeInformazioniAggiuntive(
                                e.target.checked,
                                "tempoIndeterminato"
                              );
                            }}
                          />
                        </div>
                      </div>
                      {!informazioniAggiuntiveDipendente.tempoIndeterminato && (
                        <div className="exclamation">
                          {isScaduto(
                            informazioniAggiuntiveDipendente.dataScadenzaUnilav
                          ) && (
                            <FontAwesomeIcon
                              icon={faExclamationCircle}
                              color={"orange"}
                              size={"lg"}
                            />
                          )}
                          <div className="form-group maxW300 margin0">
                            <label className="card-label">
                              Data Scadenza UNILAV *
                            </label>
                            <DatePicker
                              value={
                                informazioniAggiuntiveDipendente.dataScadenzaUnilav
                              }
                              locale="it"
                              sx={{ minWidth: "300px" }}
                              onChange={(e) =>
                                handleChangeInformazioniAggiuntive(
                                  e,
                                  "dataScadenzaUnilav"
                                )
                              }
                              placeholder={"Seleziona una data"}
                              size="lg"
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="informazioniAggiuntiveDipendente mt20">
                    <div className="form-group maxW margin0">
                      <label className="card-label">Idoneità sanitaria *</label>
                      <input
                        type="file"
                        id="fileIdoneita"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          if (e.target.files[0].size / 1024 / 1024 > 50) {
                            setErrorSize(true);
                            return;
                          }
                          let { file, name } = changeFilename(
                            e.target.files[0]
                          );
                          handleChangeInformazioniAggiuntive(
                            file,
                            "file",
                            name,
                            "filenameIdoneitaSanitaria"
                          );

                          //handleChangeFileLavoratori(e.target.files);
                        }}
                      />
                      <div
                        className=" grid-container center padding0"
                        style={{ width: "fit-content" }}
                      >
                        <Button
                          className="mw400"
                          onClick={() => handleClickOpenFile("fileIdoneita")}
                        >
                          Scegli File
                        </Button>
                        <label
                          style={{ marginLeft: "10px" }}
                          className="break-word"
                        >
                          {informazioniAggiuntiveDipendente.filenameIdoneitaSanitaria
                            ? informazioniAggiuntiveDipendente.filenameIdoneitaSanitaria
                            : "Nessun file Selezionato"}
                        </label>
                      </div>
                    </div>
                    <div className="exclamation">
                      {isScaduto(
                        informazioniAggiuntiveDipendente.dataScadenza
                      ) && (
                        <FontAwesomeIcon
                          icon={faExclamationCircle}
                          color={"orange"}
                          size={"lg"}
                        />
                      )}
                      <div className="form-group mw300 margin0">
                        <label className="card-label">
                          Data Scadenza Idoneità sanitaria *
                        </label>
                        <DatePicker
                          value={informazioniAggiuntiveDipendente.dataScadenza}
                          locale="it"
                          className="mw300"
                          sx={{ width: "300px !important" }}
                          onChange={(e) =>
                            handleChangeInformazioniAggiuntive(
                              e,
                              "dataScadenza"
                            )
                          }
                          placeholder={"Seleziona una data"}
                          size="lg"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                  <h4 className="card-label">DPI</h4>
                  <div className="grid-container">
                    {dpi.map((dpis) => (
                      <Checkbox
                        size="md"
                        key={dpis.label}
                        label={dpis.label}
                        checked={dpis.status}
                        onChange={() => handleChangeDpi(dpis)}
                      />
                    ))}
                  </div>
                  <h4 className="card-label">Attestati</h4>

                  <div className="grid-containerAttestati">
                    {attestati.map((attestato) => (
                      <div className="itemAttestato">
                        <Checkbox
                          key={attestato.label}
                          size="md"
                          label={
                            attestato.label == "ACCORDO STATO/REGIONI"
                              ? "ACCORDO STATO/REGIONI *"
                              : attestato.label
                          }
                          checked={attestato.status}
                          onChange={() => handleChangeAttestati(attestato)}
                        />
                        {attestato.status && (
                          <div className="informazioniAggiuntiveDipendente">
                            <div className="form-group maxW margin0">
                              <label className="card-label">Attestato</label>
                              <div>
                                <input
                                  type="file"
                                  id={attestato.label.split(" ")[0]}
                                  style={{ display: "none" }}
                                  onChange={(e) => {
                                    if (
                                      e.target.files[0].size / 1024 / 1024 >
                                      50
                                    ) {
                                      setErrorSize(true);
                                      return;
                                    }
                                    setLabelFile({
                                      ...labelFile,
                                      [attestato.label.split(" ")[0]]:
                                        e.target.files[0].name,
                                    });

                                    let { file, name } = changeFilename(
                                      e.target.files[0]
                                    );
                                    handleChangeValueAttestato(
                                      file,
                                      attestato,
                                      "file"
                                    );
                                  }}
                                />
                                <div
                                  className="grid-container center padding0"
                                  style={{ width: "fit-content" }}
                                >
                                  <Button
                                    className="w150"
                                    onClick={() =>
                                      handleClickOpenFile(
                                        attestato.label.split(" ")[0]
                                      )
                                    }
                                  >
                                    Scegli File
                                  </Button>
                                  <label style={{ marginLeft: "10px" }}>
                                    {attestato.file
                                      ? attestato.file.name
                                      : "Nessun file Selezionato"}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="exclamation">
                              {isScaduto(attestato.dataScadenza) && (
                                <FontAwesomeIcon
                                  icon={faExclamationCircle}
                                  color={"orange"}
                                  size={"lg"}
                                />
                              )}
                              <div className="form-group maxW300 margin0">
                                <label className="card-label">
                                  Data Scadenza attestato *
                                </label>
                                <DatePicker
                                  value={attestato.dataScadenza}
                                  locale="it"
                                  onChange={(e) =>
                                    handleChangeValueAttestato(
                                      e,
                                      attestato,
                                      "dataScadenza"
                                    )
                                  }
                                  placeholder={"Seleziona una data"}
                                  size="lg"
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Button
                      style={{
                        marginBottom: "20px",
                        maxWidth: "400px",
                        minWidth: "50vw",
                      }}
                      disabled={disabledAggiuntaDipendente}
                      color={"green"}
                      onClick={aggiungiDipendente}
                    >
                      Aggiungi
                    </Button>
                  </div>
                </>
              )}
              {false ? (
                <>
                  <h2 className="card-label">File Aggiuntivi</h2>
                  {fileAttestati.length == 0 && (
                    <h5 style={{ marginTop: 0 }}>
                      Nessun file aggiuntivo presente
                    </h5>
                  )}
                  <input
                    type="file"
                    id="dipendenti"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      if (e.target.value == "") return;
                      if (e.target.files[0].size / 1024 / 1024 > 50) {
                        setErrorSize(true);
                        return;
                      }
                      handleChangeFileLavoratori(e.target.files);
                    }}
                  />
                  <div
                    className="grid-container repeat2 pl0 center"
                    style={{ width: "fit-content" }}
                  >
                    <Button
                      className="mw400"
                      color={"green"}
                      onClick={() => handleClickOpenFile("dipendenti")}
                    >
                      Aggiungi File
                    </Button>
                  </div>
                  {fileAttestati &&
                    fileAttestati.map((file, count) => (
                      <div
                        style={{ maxWidth: "500px " }}
                        className="grid-container repeat2 pl0 center"
                        key={count}
                      >
                        <Button
                          className="mw400"
                          sx={{
                            color: file.idFile
                              ? "white !important"
                              : "black !important",
                          }}
                          disabled={!file.idFile}
                          onClick={() => {
                            if (file.idFile)
                              getFile("fileAggiuntivo", file.idFile);
                          }}
                        >
                          {file.nome}
                        </Button>
                        <FontAwesomeIcon
                          style={{ cursor: "pointer" }}
                          icon={faTimes}
                          color={"red"}
                          size="lg"
                          onClick={() => handleRemoveFile(file.nome)}
                        />
                      </div>
                    ))}
                </>
              ) : null}
            </div>
            <Divider style={{ marginTop: "30px", marginBottom: "60px" }} />
            <h2 className="card-label">Poliza assicurativa RTC RCO</h2>

            <div className="form-group">
              <div className="grid-container repeat2 pl0 center">
                <div className="exclamation">
                  {isScaduto(dataScadenzaRTC) && (
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      color={"orange"}
                      size={"lg"}
                    />
                  )}
                  <DatePicker
                    size="lg"
                    value={dataScadenzaRTC}
                    invalid={dataScadenzaRTC == "" || dataScadenzaRTC == null}
                    locale="it"
                    onChange={(e) => {
                      setDataScadenzaRTC(e);
                    }}
                    placeholder={"Data Scadenza"}
                  />
                </div>
                <div>
                  <input
                    type="file"
                    id="poliza"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      if (e.target.value == "") return;
                      if (e.target.files[0].size / 1024 / 1024 > 50) {
                        setErrorSize(true);
                        return;
                      }
                      setFileLavoratori({
                        ...FileLavoratori,
                        poliza: e.target.files[0],
                      });
                      setLabelFile({
                        ...labelFile,
                        poliza: e.target.files[0].name,
                      });
                    }}
                  />
                  <Button
                    className="mw400"
                    onClick={() => handleClickOpenFile("poliza")}
                  >
                    Scegli File
                  </Button>
                  <label style={{ marginLeft: "10px" }}>
                    {labelFile.poliza
                      ? labelFile.poliza
                      : "Nessun file Selezionato"}
                  </label>
                  {checkedFile.polizzaAssicurativaRct && (
                    <Button
                      style={{ marginLeft: "10px" }}
                      className="mw400"
                      variant="outline"
                      radius="md"
                      size="lg"
                      compact
                      onClick={() => getFile("polizzarct")}
                    >
                      DOWNLOAD ALLEGATO
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <Divider style={{ marginTop: "30px", marginBottom: "60px" }} />
            <h2 className="card-label">DURC online validità 4 mesi</h2>
            <div className="exclamation">
              {isScaduto(dataDurc) && (
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  color={"orange"}
                  size={"lg"}
                />
              )}
              <div className="form-group">
                <label className="card-label">Data scadenza</label>

                <div className="grid-container repeat2 pl0 center ">
                  <DatePicker
                    size="lg"
                    value={dataDurc}
                    invalid={dataDurc == "" || dataDurc == null}
                    locale="it"
                    onChange={(e) => {
                      setDataDurc(e);
                    }}
                    placeholder={"Data Scadenza"}
                  />
                  <div>
                    <input
                      type="file"
                      id="durc"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        if (e.target.value == "") return;
                        if (e.target.files[0].size / 1024 / 1024 > 50) {
                          setErrorSize(true);
                          return;
                        }
                        setFileDurc(e.target.files[0]);
                        setLabelFile({
                          ...labelFile,
                          durc: e.target.files[0].name,
                        });
                      }}
                    />
                    <Button
                      className="mw400"
                      onClick={() => handleClickOpenFile("durc")}
                    >
                      Scegli File
                    </Button>
                    <label style={{ marginLeft: "10px" }}>
                      {labelFile.durc
                        ? labelFile.durc
                        : "Nessun file Selezionato"}
                    </label>
                    {checkedFile.durc && (
                      <Button
                        style={{ marginLeft: "10px" }}
                        className="mw400"
                        variant="outline"
                        radius="md"
                        size="lg"
                        compact
                        onClick={() => getFile("durc")}
                      >
                        DOWNLOAD ALLEGATO
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Divider style={{ marginTop: "30px", marginBottom: "60px" }} />
            {/********************STABILIMENTI***************/}
            {stabilimentiSelezionati.map((stabilimento) => (
              <>
                <div className="center grid-container repeat2 ">
                  <h1 style={{ color: "red" }}>
                    {stabilimento.nomeStabilimento}
                  </h1>
                  <h2
                    style={{
                      color:
                        stabilimento.stato == "APPROVATO"
                          ? "green"
                          : stabilimento.stato == "IN VERIFICA"
                          ? "gray"
                          : "red",
                    }}
                  >
                    {stabilimento.stato}
                  </h2>
                </div>
                <h2 className="card-label">Sopralluogo MODULO 7</h2>
                <div className="form-group">
                  <label className="card-label">Da compilare e rimandare</label>

                  <div className="grid-container repeat4 center">
                    <Button
                      color="green"
                      onClick={() =>
                        handleDownloadModulo7(stabilimento.nomeStabilimento)
                      }
                    >
                      DOWNLOAD MODELLO
                    </Button>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id={"m7" + stabilimento.nomeStabilimento}
                      onChange={(e) => {
                        if (e.target.value == "") return;
                        if (e.target.files[0].size / 1024 / 1024 > 50) {
                          setErrorSize(true);
                          return;
                        }
                        handleChangeModulo7(
                          stabilimento.nomeStabilimento,
                          e.target.files[0]
                        );
                      }}
                    />
                    <Button
                      onClick={() =>
                        handleClickOpenFile(
                          "m7" + stabilimento.nomeStabilimento
                        )
                      }
                    >
                      Scegli File
                    </Button>
                    <label style={{ marginLeft: "10px" }}>
                      {getLabelFromStabilimento(
                        stabilimento.nomeStabilimento,
                        "m7"
                      )
                        ? getLabelFromStabilimento(
                            stabilimento.nomeStabilimento,
                            "m7"
                          )
                        : "Nessun file Selezionato"}
                    </label>
                    {stabilimento.modulo7 && (
                      <Button
                        variant="outline"
                        radius="md"
                        size="lg"
                        compact
                        onClick={() =>
                          handleDownloadModulo7File(
                            stabilimento.nomeStabilimento,
                            stabilimento.idAppalto
                          )
                        }
                      >
                        DOWNLOAD ALLEGATO
                      </Button>
                    )}
                  </div>
                </div>
                <h2 className="card-label">
                  Informativa sui Rischi e Regolamenti MODULO 05 (modulo IO
                  firmato)
                </h2>
                <div className="form-group">
                  <label className="card-label">Da compilare e rimandare</label>

                  <div className="grid-container repeat4 center">
                    <Button
                      color="green"
                      onClick={() =>
                        handleDownloadModulo5(stabilimento.nomeStabilimento)
                      }
                    >
                      DOWNLOAD MODELLO
                    </Button>
                    <input
                      type="file"
                      id={"m5" + stabilimento.nomeStabilimento}
                      style={{ display: "none" }}
                      onChange={(e) => {
                        if (e.target.value == "") return;
                        if (e.target.files[0].size / 1024 / 1024 > 50) {
                          setErrorSize(true);
                          return;
                        }
                        handleChangeModulo5(
                          stabilimento.nomeStabilimento,
                          e.target.files[0]
                        );
                      }}
                    />
                    <Button
                      onClick={() =>
                        handleClickOpenFile(
                          "m5" + stabilimento.nomeStabilimento
                        )
                      }
                    >
                      Scegli File
                    </Button>
                    <label style={{ marginLeft: "10px" }}>
                      {getLabelFromStabilimento(
                        stabilimento.nomeStabilimento,
                        "m5"
                      )
                        ? getLabelFromStabilimento(
                            stabilimento.nomeStabilimento,
                            "m5"
                          )
                        : "Nessun file Selezionato"}
                    </label>
                    {stabilimento.infoRischiReg && (
                      <Button
                        variant="outline"
                        radius="md"
                        size="lg"
                        compact
                        onClick={() =>
                          handleDownloadModulo5File(
                            stabilimento.nomeStabilimento,
                            stabilimento.idAppalto
                          )
                        }
                      >
                        DOWNLOAD ALLEGATO
                      </Button>
                    )}
                  </div>
                </div>
                <h2 className="card-label">DUVRI</h2>
                <div className="form-group">
                  <label className="card-label">Da compilare e rimandare</label>

                  <div className="grid-container repeat4 center">
                    <Button
                      color="green"
                      onClick={() =>
                        handleDownloadDuvri(stabilimento.nomeStabilimento)
                      }
                    >
                      DOWNLOAD MODELLO
                    </Button>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id={"duvri" + stabilimento.nomeStabilimento}
                      onChange={(e) => {
                        if (e.target.value == "") return;
                        if (e.target.files[0].size / 1024 / 1024 > 50) {
                          setErrorSize(true);
                          return;
                        }
                        handleChangeDuvri(
                          stabilimento.nomeStabilimento,
                          e.target.files[0]
                        );
                      }}
                    />
                    <Button
                      onClick={() =>
                        handleClickOpenFile(
                          "duvri" + stabilimento.nomeStabilimento
                        )
                      }
                    >
                      Scegli File
                    </Button>
                    <label style={{ marginLeft: "10px" }}>
                      {getLabelFromStabilimento(
                        stabilimento.nomeStabilimento,
                        "du"
                      )
                        ? getLabelFromStabilimento(
                            stabilimento.nomeStabilimento,
                            "du"
                          )
                        : "Nessun file Selezionato"}
                    </label>
                    {stabilimento.duvri && (
                      <Button
                        variant="outline"
                        radius="md"
                        size="lg"
                        compact
                        onClick={() =>
                          handleDownloadDuvriFile(
                            stabilimento.nomeStabilimento,
                            stabilimento.idAppalto
                          )
                        }
                      >
                        DOWNLOAD ALLEGATO
                      </Button>
                    )}
                  </div>
                </div>
              </>
            ))}
          </form>
          {stabilimentiSelezionati.length > 0 && (
            <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
          )}
          {idAppaltoPadre === undefined ? (
            <div>
              <h2 className="card-label mt20">Subappalti</h2>
              <Button
                color={"blue"}
                onClick={clickAggiungiSubAppalto}
                style={{ marginBottom: 5, marginTop: 5 }}
              >
                AGGIUNGI SUBAPPALTO
              </Button>
              <Table
                striped
                style={{ marginBottom: "50px" }}
                captionSide="bottom"
              >
                {subappalti.length == 0 && (
                  <caption>Nessun elemento da visualizzare</caption>
                )}
                <thead>
                  <tr>
                    <th>Nome azienda</th>
                    <th>Indirizzo</th>
                    <th>PEC</th>
                    <th>Data inserimento</th>
                    <th>Azione</th>
                  </tr>
                </thead>
                <tbody>
                  {subappalti.map((subappalto) => (
                    <tr key={subappalto.id}>
                      <td>{subappalto.nomeAzienda}</td>
                      <td>{subappalto.indirizzoAzienda}</td>
                      <td>{subappalto.pecAzienda}</td>
                      <td>
                        {dayjs(subappalto.timestampCreazione).format(
                          "DD/MM/YYYY"
                        )}
                      </td>
                      <td>
                        <Button
                          onClick={() => {
                            modificaSubappalto(subappalto.idSubappalto);
                          }}
                          color="orange"
                        >
                          Modifica
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
            </div>
          ) : null}

          <h2 className="card-label mt20">Ulteriori Note</h2>
          <Textarea
            ref={note}
            invalid={note.current.value == ""}
            placeholder="Note Aggiuntive"
            radius="md"
            size="lg"
            required
          />

          {invioDisabilitato.length > 0 && (
            <CampiMancanti campiMancanti={invioDisabilitato} />
          )}
          <div
            className="grid-container repeat3 pl0 center "
            style={{ marginTop: "40px", marginBottom: "30px" }}
          >
            <Button
              color={"green"}
              onClick={updateAppaltoDb}
              disabled={invioDisabilitato.length > 0 || loadingRichiesta}
              style={{ width: "100%" }}
            >
              {loadingRichiesta ? (
                <Loader color="red" variant="bars" />
              ) : (
                "AGGIORNA"
              )}
            </Button>
            {idAppaltoPadre ? (
              <Button
                color={"blue"}
                onClick={tornaAAppaltoPrincipale}
                style={{ width: "100%" }}
              >
                TORNA ALL'APPALTO PRINCIPALE
              </Button>
            ) : null}
          </div>
        </>
      )}
      <Modal
        title="Attenzione"
        transition="fade"
        centered
        opened={errorSize}
        onClose={() => {
          setErrorSize(false);
        }}
        transitionDuration={600}
        transitionTimingFunction="ease"
      >
        <h1>La dimensione del file non può superare 50MB</h1>
      </Modal>

      <Modal
        title="Aggiunta nuovo subappalto"
        transition="fade"
        centered
        opened={modSubappaltoOpened}
        onClose={() => {
          setModSubappaltoOpened(false);
        }}
        transitionDuration={600}
        transitionTimingFunction="ease"
      >
        <h3>
          Attenzione aggiungendo un nuovo subappalto, la compilazione attuale
          verrà persa, vuoi continuare?
        </h3>
        <Button
          color={"orange"}
          onClick={() => setModSubappaltoOpened(false)}
          style={{ marginRight: 10 }}
        >
          ANNULLA
        </Button>
        <Button color={"green"} onClick={apriNuovoSubAppalto}>
          CONTINUA
        </Button>
      </Modal>

      <Modal
        title="Attenzione"
        transition="fade"
        size="xl"
        centered
        opened={errorSizeInvio}
        onClose={() => {
          setErrorSizeInvio(false);
        }}
        transitionDuration={600}
        transitionTimingFunction="ease"
      >
        <h1>La dimensione della richiesta non può superare i 95MB</h1>
        <h4>
          Si consiglia di inserire i file come PDF oppure comprimerli (rar/zip)
        </h4>
      </Modal>
    </div>
  );
}
