import React, { useState, useEffect, useRef } from "react";
import { Button, Container, Modal } from "@mantine/core";
import Cookies from "js-cookie";
import { errorNotification, successNotification } from "../Notification";
import { useNavigate } from "react-router-dom";
import { getPossoModificare } from "../Utilities";
import { ToastContainer, Flip, toast } from "react-toastify";
let modificaAbilitata = getPossoModificare();
export default function Azioni({ getForm, appalti }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  //Funzione che permette di inviare mail a tutti
  const invioMailMassivo = async () => {
    const url = "https://apot.mondorevive.com/api/invioMailMassivo";
    const response = await fetch(url, {
      method: "POST",

      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
        "Content-Type": "application/json",
      },
    });
    if (response.status == 200) {
      //dire ok
      successNotification("E-Mail inviate correttamente");
    } else {
      //DARE ERROR
      errorNotification("Impossibile inviare E-Mail");
      if (response.status == 401 || response.status == 403) {
        Cookies.remove("token");
        Cookies.remove("status");
        navigate("/login");
      }
    }
  };
  const invioMailSollecito = async () => {
    const url = "https://apot.mondorevive.com/api/invioMailMassivoRossi";
    const response = await fetch(url, {
      method: "POST",

      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
        "Content-Type": "application/json",
      },
    });
    if (response.status == 200) {
      //dire ok
      successNotification("E-Mail inviate correttamente");
    } else {
      //DARE ERROR
      errorNotification("Impossibile inviare E-Mail");
      if (response.status == 401 || response.status == 403) {
        Cookies.remove("token");
        Cookies.remove("status");
        navigate("/login");
      }
    }
  };

  //Funzione che elimina le vecchie versioni degli appalti dal db
  const eliminaVecchieVersioni = async () => {
    const url = "https://apot.mondorevive.com/api/vecchieVersioniAppalti";
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    });
    if (response.status == 200) {
      successNotification("Vecchie versioni eliminate con successo");
      setOpen(false);
      getForm();
    } else {
      errorNotification("Impossibile modificare i file, riprova più tardi!");
      setOpen(false);
      if (response.status == 401 || response.status == 403) {
        Cookies.remove("token");
        Cookies.remove("status");
        navigate("/login");
      }
    }
  };

  const generaReport = async () => {
    const url = "https://apot.mondorevive.com/api/reportExcel";
    const response = await toast.promise(
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
        responseType: "blob",
      }),
      {
        pending: "Download in corso",
        success: {
          render() {
            return `File scaricato correttamente`;
          },
          autoClose: 1000,
        },
        error: {
          render() {
            return `Errore nel download del file`;
          },
          autoClose: 1000,
        },
      }
    );
    if (response.status == 200) {
      const data = await response.arrayBuffer();
      const filename = response.headers
        .get("content-disposition")
        .split("filename=")[1];
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename.substring(1, filename.length - 1));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      if (response.status == 401 || response.status == 403) {
        Cookies.remove("token");
        Cookies.remove("status");
        navigate("/login");
      }
      //DARE ERROR
    }
  };

  useEffect(() => {
    modificaAbilitata = getPossoModificare();
  }, []);
  return (
    <Container className="mt--7 containerMantine container_dashboard" fluid>
      <ToastContainer
        transition={Flip}
        newestOnTop
        rtl={false}
        pauseOnFocusLoss
      />
      <h2 className="mb20 mt20 title_dashboard">Azioni</h2>
      <div className="container_azioni mt30">
        <div className="item_azione">
          <div className="container_descrizione_azione">
            <h4>
              Elimina vecchie versioni permette di rimuovere tutti gli appalti
              che non sono presenti nella pagina principale. La rimozione
              include tutti i dati inseriti compresi i file caricati.
            </h4>
          </div>
          <Button
            disabled={appalti.length == 0 || !modificaAbilitata}
            style={{ marginTop: "20px" }}
            color="red"
            onClick={() => setOpen(true)}
          >
            Elimina vecchie versioni appalti
          </Button>
        </div>
        <div className="item_azione">
          <div className="container_descrizione_azione">
            <h4>
              Invio E-Mail di ricordo permette l'invio di una mail di notifica a
              tutti gli appaltatori registrati che ricorda di verificare gli
              appalti inseriti
            </h4>
          </div>
          <Button
            style={{ marginTop: "20px" }}
            color="blue"
            disabled={!modificaAbilitata}
            onClick={invioMailMassivo}
          >
            Invia E-Mail di Ricordo
          </Button>
        </div>
        <div className="item_azione">
          <div className="container_descrizione_azione">
            <h4>Genera report Excel con lo stato degli appalti.</h4>
          </div>
          <Button
            style={{ marginTop: "20px" }}
            color="green"
            onClick={() => generaReport()}
          >
            Genera
          </Button>
        </div>
        <div className="item_azione">
          <div className="container_descrizione_azione">
            <h4>
              Invio E-Mail di sollecito permette l'invio di una mail di
              sollecito a tutti gli appaltatori registrati che hanno stato
              appalto NON APPROVATO
            </h4>
          </div>
          <Button
            style={{ marginTop: "20px" }}
            color="blue"
            disabled={!modificaAbilitata}
            onClick={invioMailSollecito}
          >
            Invia E-Mail di Sollecito
          </Button>
        </div>
        <Modal
          withCloseButton={false}
          opened={open}
          centered
          onClose={() => setOpen(false)}
        >
          <h4 style={{ margin: "0 !important" }}>
            Questa operazione cancellerà lo storico di tutti gli appalti (dati
            inseriti, documenti caricati) lasciando solamente la versione più
            recente.
          </h4>
          <p style={{ margin: "0 !important" }}>
            Sicuro di voler continuare? questa operazione è irreversibile
          </p>
          <div className="inline-item">
            <Button
              style={{ width: "100%" }}
              color="red"
              onClick={eliminaVecchieVersioni}
            >
              ELIMINA
            </Button>
            <Button
              color={"orange"}
              style={{ marginLeft: "20px", width: "100% " }}
              onClick={() => setOpen(false)}
            >
              ANNULLA
            </Button>
          </div>
        </Modal>
      </div>
    </Container>
  );
}
