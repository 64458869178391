import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import cookie from "js-cookie";
export default function Navbar({ status, setStatus }) {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  //Effettua il logout
  async function handleLogout() {
    setStatus(null);
    cookie.remove("token");
    cookie.remove("status");
    //getInfoProfilo();
    navigate("/login");
  }
  const getInfoProfilo = async () => {
    const url = "https://apot.mondorevive.com/api/appaltoreInfoUpdate";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      //dire ok
      const data = await response.json();
      setUsername(data.username);
    } else {
      //DARE ERROR
    }
  };
  useEffect(() => {
    if (cookie.get("token") != null) {
      setStatus(cookie.get("status"));
      getInfoProfilo();
    }
  }, [cookie.get("status")]);

  const clickOnAppalti = () => {
    navigate("/update", {
      state: { idAppaltoPadre: undefined, idAppaltoComune: undefined },
    });
    navigate(0);
  };
  return (
    <>
      <div className="nav-menu">
        <div>
          <div className={`navbar-container`}>
            <div>
              <a href="httpss://www.mondorevive.com/" className="item">
                <img
                  width={"200px"}
                  src={require("./img/logo.png")}
                  style={{
                    cursor: "pointer",
                    color: "white",
                    fontWeight: "bold",
                  }}
                />
              </a>
            </div>
            <ul>
              {status && status == "ROLE_CONTRACTOR" && username && (
                <li>
                  <Link className="item" to="/profilo">
                    {username}
                  </Link>
                </li>
              )}
              {status && status == "ROLE_CONTRACTOR" && (
                <li>
                  <Link className="item" to="/update" onClick={clickOnAppalti}>
                    Appalti
                  </Link>
                </li>
              )}
              {status && status != "ROLE_CONTRACTOR" && (
                <li>
                  <Link className="item" to="/admin">
                    Dashboard
                  </Link>
                </li>
              )}
              {status && (
                <li>
                  <h3
                    style={{ color: "white", cursor: "pointer" }}
                    onClick={handleLogout}
                  >
                    Esci
                  </h3>
                </li>
              )}

              {!status && (
                <li>
                  <Link className="item" to="/login">
                    Entra
                  </Link>
                </li>
              )}
              {!status && (
                <li>
                  <Link className="item" to="/registrati">
                    Registrati
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
