import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function FileButton({ nome, onClick, elimina }) {
	return (
		<div className="container_fileButton">
			<div className="container_nome" onClick={onClick}>
				{nome}
			</div>
			<div className="container_delete" onClick={elimina}>
				<FontAwesomeIcon icon={faTimesCircle} />
			</div>
		</div>
	);
}
