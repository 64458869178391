export const isScaduto = (data) => {
  if (data == null || data == undefined) return false;
  data = new Date(data);
  if (data - new Date() < 0) return true;
  else return false;
};
export const isScadutaDichiarazione445 = (data) => {
  if (data == null || data == undefined) return false;
  data = new Date(data);
  data.setFullYear(data.getFullYear() + 1);
  if (data - new Date() < 0) return true;
  else return false;
};
export const handleClickOpenFile = (label) => {
  document.getElementById(label).click();
};
export const fromAmericaToItalia = (data, type = false) => {
  if (data == null) return "Nessuna data";
  if (type) {
    let italia = data.split("T")[0].split("-");
    //2022-06-23T10:04:07
    return (
      italia[2] + "-" + italia[1] + "-" + italia[0] + " " + data.split("T")[1]
    );
  } else {
    let italia = data.split("T")[0].split("-");
    //2022-06-23T10:04:07
    return italia[2] + "-" + italia[1] + "-" + italia[0];
  }
};

export const preparaDataPerInvio = (date) => {
  if (date === null || date === "") return null;
  return new Date(new Date(date).getTime() + 14400000);
};
let possoModificare = false;
export const getPossoModificare = () => {
  return possoModificare;
};
export const setPossoModificare = (value) => {
  possoModificare = value;
};
