import React, { useState, useEffect, useRef } from "react";
import { Checkbox, ColorSwatch, Loader, Menu, Textarea } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { successNotification, errorNotification } from "./Notification";
import { ToastContainer, Flip, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import {
  Button,
  Tabs,
  Table,
  Input,
  PasswordInput,
  Select,
  Divider,
  MultiSelect,
  TextInput,
  Modal,
  Alert,
} from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPlusCircle,
  faTimes,
  faTimesCircle,
  faInfoCircle,
  faSearch,
  faPlus,
  faEye,
  faEllipsisVertical,
  faListCheck,
} from "@fortawesome/free-solid-svg-icons";

import { DateRangePicker } from "@mantine/dates";
import cookie from "js-cookie";
import PageGestione from "./Aggiuntivi/PageGestione";
import Sidebar from "./Sidebar";
import Statistiche from "./Aggiuntivi/Statistiche";
import InvioEmail from "./Aggiuntivi/InvioEmail";
import Azioni from "./Aggiuntivi/Azioni";
import VerificheInCampo from "./Aggiuntivi/VerificheInCampo";
import {
  getPossoModificare,
  setPossoModificare as setModificaAbilitata,
} from "./Utilities";
export default function AdminDashboard({ setMostraNavbar, setStatusNavbar }) {
  const { state } = useLocation();
  const [approvatori, setApprovatori] = useState([]);
  const [approvatoriNonModificati, setApprovatoriNonModificati] = useState([]);
  const [vuoiAggiungere, setVuoiAggiungere] = useState(false);
  const [activeTab, setActiveTab] = useState("0");
  const [dpi, setDpi] = useState([]);
  const [dpiNonModificati, setDpiNonModificati] = useState([]);
  const [attestati, setAttestati] = useState([]);
  const [attestatiNonModificati, setAttestatiNonModificati] = useState([]);
  const [voglioAggiungereAttestati, setVoglioAggiungereAttestati] =
    useState(false);
  const [voglioAggiungereDpi, setVoglioAggiungereDpi] = useState(false);
  const labelDpi = useRef("");
  const labelAttestato = useRef("");
  const [pass, setPass] = useState("");
  const [confermaPass, setConfermaPass] = useState("");
  const [denominazione, setDenominazione] = useState("");
  const [appalti, setAppalti] = useState([]);
  const [appaltiNonModificati, setAppaltiNonModificati] = useState([]);
  const [usernameApprovatore, setUsernameApprovatore] = useState("");
  const [passwordApprovatore, setPasswordApprovatore] = useState("");
  const [denominazioneApprovatore, setDenominazioneApprovatore] = useState("");
  const [possoModificare, setPossoModificare] = useState(getPossoModificare());
  const [fileCamera, setFileCamera] = useState({
    iscrizione: null,
    dvr: null,
    doc445: null,
  });
  const [fileModulo7, setfileModulo7] = useState({
    ba: null,
    fe: null,
    cp: null,
    bt: null,
    ma: null,
    di: null,
  });
  const [fileModulo5, setfileModulo5] = useState({
    ba: null,
    fe: null,
    cp: null,
    bt: null,
    ma: null,
    di: null,
  });
  const [fileDuvri, setFileDuvri] = useState({
    ba: null,
    fe: null,
    cp: null,
    bt: null,
    ma: null,
    di: null,
  });
  const [labelFile, setLabelFile] = useState({
    iso9001: null,
    iso14001: null,
    iso45001: null,
    sa8000: null,
    camera: null,
    dvr: null,
    dichiarazione445: null,
    dipendenti: null,
    poliza: null,
    durc: null,
    m7ba: null,
    m7fe: null,
    m7cp: null,
    m7bt: null,
    m7ma: null,
    m7di: null,
    m5ba: null,
    m5fe: null,
    m5cp: null,
    m5bt: null,
    m5ma: null,
    m5di: null,
    duba: null,
    dufe: null,
    ducp: null,
    dubt: null,
    duma: null,
    dudi: null,
    codiceEtico: null,
  });
  const [codiceEtico, setCodiceEtico] = useState();
  const stabilimenti = [
    { value: "BUSTO ARSIZIO", label: "Mondorevive Busto Arsizio" },
    { value: "FERENTINO", label: "Mondorevive Ferentino" },
    { value: "CHIGNOLO PO", label: "Caltek Chignolo Po" },
    { value: "BERNATE", label: "MondoSD Bernate Ticino" },
    { value: "MARNATE", label: "Mondorevive Marnate" },
    { value: "DIBA", label: "Diba Chignolo Po" },
  ];
  const [loadingTabella, setLoadingTabella] = useState(false);
  const [open, setOpen] = useState(false);
  const [stabilimentiSelezionati, setStabilimentiSelezionati] = useState([]);
  const [orderByState, setOrderByState] = useState("");
  const [sortByState, setSortByState] = useState("");
  const [status, setStatus] = useState("tutti");
  const statusForm = [
    { value: "tutti", label: "Tutti" },
    { value: "B_NON_APPROVATO", label: "Non Approvato" },
    { value: "C_IN_VERIFICA", label: "In Verifica" },
    { value: "A_APPROVATO", label: "Approvato" },
  ];
  const [dateRange, setDateRange] = useState([]);
  const [searchUser, setSearchUser] = useState("");
  const [searchAzienda, setSearchAzienda] = useState("");
  let mediumPassword = new RegExp(
    "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
  );

  let strongPassword = new RegExp(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
  );
  const [errorPass, setErrorPass] = useState("");
  const [errorPassApp, setErrorPassApp] = useState("");
  const [passDebole, setPassDebole] = useState(false);
  const [templateStatus, setTemplateStatus] = useState({
    dichiarazione445: false,
    duvriBa: false,
    duvriBe: false,
    duvriChi: false,
    duvriFe: false,
    duvriMa: false,
    duvriDi: false,
    infoRischiRegBa: false,
    infoRischiRegBe: false,
    infoRischiRegChi: false,
    infoRischiRegFe: false,
    infoRischiRegMa: false,
    infoRischiRegDi: false,
    modulo7Ba: false,
    modulo7Be: false,
    modulo7Chi: false,
    modulo7Fe: false,
    modulo7Ma: false,
    modulo7Di: false,
  });
  const [openInformazioniAggiuntive, setOpenInformazioniAggiuntive] =
    useState(false);
  const [testoInformazioniAggiuntive, setTestoInformazioniAggiuntive] =
    useState([]);
  const appaltoSelected = useRef();
  const nuovoMessaggioRef = useRef("");
  useEffect(() => {
    setMostraNavbar(false);
  }, []);
  useEffect(() => {
    setPossoModificare(getPossoModificare());
    if (activeTab == "0") getForm();
    if (activeTab == "1") {
      getAttestati();
      getDPI();
      getTemplate();
    }
    if (activeTab == "2" && cookie.get("status") == "ROLE_ADMINISTRATOR")
      getApprovatore();
  }, [activeTab]);
  //Gestisce il change delle pagine
  const navigate = useNavigate();
  const changePage = (to) => {
    navigate(to);
  };
  useEffect(() => {
    if (!cookie.get("status") || cookie.get("status") == "ROLE_CONTRACTOR") {
      navigate("/login");
    }
  }, []);
  //Funzione che permette di accettare un appalto
  async function accettaAppalto(appalto) {
    const url = "https://apot.mondorevive.com/api/updateStatoAppalto";
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        id: appalto.id,
        stato: "APPROVATO",
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      //dire ok
      getForm();
    } else {
      //DARE ERROR
    }
  }
  //Funzione che permette di Rifiutare un appalto
  async function rifiutaAppalto(appalto) {
    const url = "https://apot.mondorevive.com/api/updateStatoAppalto";
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        id: appalto.id,
        stato: "NON_APPROVATO",
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      //dire ok
      getForm();
    } else {
      //DARE ERROR
    }
  }
  //Funzione che ripulisce i campi dell`aggiunta di un approvatore
  const clearApprovatoreInserito = () => {
    setUsernameApprovatore("");
    setPasswordApprovatore("");
    setDenominazioneApprovatore("");
    setVuoiAggiungere(false);
  };
  //Funzione che aggiunge un approvatore
  async function aggiungiApprovatore() {
    if (
      usernameApprovatore == "" ||
      passwordApprovatore == "" ||
      denominazioneApprovatore == ""
    )
      return;
    const url = "https://apot.mondorevive.com/api/registerApprover ";
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        password: passwordApprovatore,
        username: usernameApprovatore,
        denominazione: denominazioneApprovatore,
        modifica: possoModificare,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      //dire ok
      successNotification("Approvatore aggiunto con successo!");
      getApprovatore();
      clearApprovatoreInserito();
    } else {
      const data = await response.text();
      errorNotification("Impossibile creare approvatore, riprova più tardi!");
      //DARE ERROR
    }
  }
  //Rimuove un approvatore
  const rimuoviApprovatore = async (approvatore) => {
    if (approvatore.status) {
      handleAnnullaModifica(approvatore);
      return;
    }

    const url =
      "https://apot.mondorevive.com/api/user?username=" + approvatore.username;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      //dire ok
      getApprovatore();
    } else {
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
      //DARE ERROR
    }
  };
  //Ottiene tutti gli approvatori
  const getApprovatore = async () => {
    const url = "https://apot.mondorevive.com/api/approvatori";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      const data = await response.json();
      let arr = [];
      //Aggiungo a tutti gli approvatori un campo status che indica se e` stato modificato o meno
      data.map((app) => {
        arr.push({ ...app, status: false, password: "" });
      });
      setApprovatori(arr);
      setApprovatoriNonModificati(arr);
    } else {
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  //Funzione che permette di modificare i valori di un appaltatore
  const modificaApprovatore = async (approvatore) => {
    const url = "https://apot.mondorevive.com/api/updateApprover";
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookie.get("token"),
      },

      body: JSON.stringify({
        password: approvatore.password || "",
        username: approvatore.username,
        denominazione: approvatore.denominazione,
        modifica: approvatore.modifica,
      }),
    });
    if (response.status == 200) {
      successNotification("Modifica effettuata con successo");
      getApprovatore();
    } else {
      //DARE ERRO

      errorNotification(
        "Impossibile effettuare la modifica, riprova più tardi"
      );
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  //Funzione che gestisce il ritorno allo status iniziale di un campo modifica approvatori
  const handleAnnullaModifica = (approvatore) => {
    let arr = [];
    setPassDebole(false);
    approvatoriNonModificati.map((approvator) => {
      if (approvator.username == approvatore.username) {
        let obj = {
          username: approvator.username,
          denominazione: approvator.denominazione,
          password: "",
          status: false,
        };
        arr.push(obj);
      } else {
        arr.push(approvator);
      }
    });
    setApprovatori(arr);
  };
  //Funzione che gestisce lo status di modifica di un approvatore
  const handleModificaApprovatori = (approvatore) => {
    if (approvatore.status) {
      modificaApprovatore(approvatore);
      return;
    }
    let arr = [];
    approvatori.map((approvator) => {
      if (approvator.username == approvatore.username) {
        let obj = {
          username: approvator.username,
          denominazione: approvator.denominazione,
          status: true,
        };

        arr.push(obj);
      } else {
        arr.push(approvator);
      }
    });
    setApprovatori(arr);
  };
  //Funzione che permette di modificare la denominazione di un approvatore
  const handleChangeValue = (value, approvatore, campo) => {
    let arr = [];
    approvatori.map((approvator) => {
      if (approvator.username == approvatore.username) {
        let obj = {
          ...approvatore,
          [campo]: value,
          status: true,
        };
        arr.push(obj);
      } else {
        arr.push(approvator);
      }
    });
    setApprovatori(arr);
  };
  //funzione che permette di modificare la pass di un approvatore
  const handleChangePass = (e, approvatore) => {
    let arr = [];
    approvatori.map((approvator, count) => {
      if (approvator.username == approvatore.username) {
        let obj = {
          username: approvator.username,
          denominazione: approvator.denominazione,
          password: e.target.value,
          status: true,
          pass: StrengthChecker3(e.target.value, count),
        };
        arr.push(obj);
      } else {
        arr.push(approvator);
      }
    });
    setApprovatori(arr);
  };
  function StrengthChecker3(pass, id) {
    //Controllo la sicurezza della pass
    if (strongPassword.test(pass)) {
      /**PASSWORD STRONG */
      setPassDebole(false);
      return 4;
    } else {
      /**PASSWORD DEBOLE */
      setPassDebole(true);
      return 1;
    }
  }
  //Funzione che permette di modificare un username di un approvatore
  const handleChangeUsername = (e, approvatore) => {
    let arr = [];
    approvatori.map((approvator) => {
      if (approvator.username == approvatore.username) {
        let obj = {
          username: e.target.value,
          denominazione: approvator.denominazione,
          password: approvator.password,
          status: true,
        };
        arr.push(obj);
      } else {
        arr.push(approvator);
      }
    });
    setApprovatori(arr);
  };
  //Funzione che effettua la modifica della password dell`account
  const modificaDati = async () => {
    const url = "https://apot.mondorevive.com/api/updateApprover";
    let payload = {};
    if (pass != "" && denominazione != "")
      payload = { denominazione: denominazione, password: pass };
    if (pass != "" && denominazione == "")
      payload = { denominazione: denominazione, password: pass };
    if (pass == "" && denominazione != "")
      payload = { denominazione: denominazione };

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      setPass("");
      setConfermaPass("");
      //dire ok
      successNotification("Dati modificati con successo");
    } else {
      //DARE ERROR
      errorNotification("Impossibile modificare i dati, riprova più tardi");
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  //Funzione che ottiene tutti i DPI dal db
  const getDPI = async () => {
    const url = "https://apot.mondorevive.com/api/dpi";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      //dire ok
      const data = await response.json();
      let arr = [];
      data.map((dpi) => {
        arr.push({ id: dpi.id, nome: dpi.nome, status: false });
      });
      setDpi(arr);
      setDpiNonModificati(arr);
    } else {
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  //Funzione che ottiene tutti gli attestati dal db
  const getAttestati = async () => {
    const url = "https://apot.mondorevive.com/api/attestati";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      const data = await response.json();
      let arr = [];
      data.map((attestato) => {
        arr.push({ id: attestato.id, nome: attestato.nome, status: false });
      });
      setAttestati(data);

      setAttestatiNonModificati(data);
    } else {
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  //Funzione che permette di aggiungere un dpi alla lista dei dpi totali
  const aggiungiDPI = async () => {
    if (labelDpi.current.value == "") return;
    const url = "https://apot.mondorevive.com/api/dpi";
    const response = await fetch(url, {
      method: "POST",
      body: labelDpi.current.value,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      //dire ok
      getDPI();
      setVoglioAggiungereDpi(false);
    } else {
      //DARE ERROR
    }
  };
  //funzione che permette di annullare l`inserimento del dpi, pulendo la textbox
  const annullaAggiuntaDpi = () => {
    setVoglioAggiungereDpi(false);
    labelDpi.current.value = "";
  };
  const rimuoviDPI = async (dpi) => {
    const url = "https://apot.mondorevive.com/api/dpi";
    const response = await fetch(url, {
      method: "DELETE",
      body: dpi.id,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      getDPI();
    } else {
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  const rimuoviAttestato = async (attestato) => {
    const url = "https://apot.mondorevive.com/api/attestato";
    const response = await fetch(url, {
      method: "DELETE",
      body: attestato.id,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      getAttestati();
    } else {
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  //Funzione che permette di aggiungere un attestato alla lista degli attestati totali
  const aggiungiAttesto = async () => {
    if (labelAttestato.current.value == "") return;
    const url = " https://apot.mondorevive.com/api/attestato";
    const response = await fetch(url, {
      method: "POST",
      body: labelAttestato.current.value,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      //dire ok
      getAttestati();
      setVoglioAggiungereAttestati(false);
    } else {
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  //funzione che permette di annullare l`inserimento dell`attestato, pulendo la textbox
  const annullaAggiuntaAttestato = () => {
    setVoglioAggiungereAttestati(false);
    labelAttestato.current.value = "";
  };
  const findDpi = (dpis) => {
    let trovato = null;
    dpiNon.map((dp) => {
      if (dp.id == dpis.id) trovato = dp;
    });
    if (trovato != null) return trovato;
    return null;
  };
  const handleSalvaDpi = async (dpis) => {
    dpis = findDpi(dpis);
    if (!dpis) return; //dare errore
    const url = "https://apot.mondorevive.com/api/updateDpi";
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        id: dpis.id,
        nome: dpis.nome,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      //dire ok
      const data = await response.text();
      //	getDPI()

      handleAnnullaModificaDpi(dpis);
    } else {
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  const handleModificaDpi = (dpis) => {
    let arr = [];
    dpi.map((dp) => {
      if (dp.id == dpis.id) {
        arr.push({ id: dp.id, nome: dp.nome, status: true });
      } else arr.push(dp);
    });
    setDpi(arr);
  };
  const [dpiNon, setDpiNon] = useState([]);
  const handleModificaDenominazioneDpi = (dpiToChange, nuovoNome) => {
    let arr = [];
    dpi.map((dpis) => {
      if (dpis.id == dpiToChange.id) {
        arr.push({ nome: nuovoNome, id: dpis.id, status: true });
      } else arr.push(dpis);
    });
    setDpiNon(arr);
    //setDpi(arr);
  };
  const handleAnnullaModificaDpi = (dpis) => {
    let arr = [];
    dpiNonModificati.map((dp) => {
      if (dp.id == dpis.id) {
        arr.push({ id: dp.id, nome: dp.nome, status: false });
      } else arr.push(dp);
    });
    setDpi(arr);
  };
  const findAttestato = (attestato) => {
    let trovato = null;
    attestatiNon.map((att) => {
      if (att.id == attestato.id) trovato = att;
    });
    if (trovato != null) return trovato;
    return null;
  };
  const handleSalvaAttestato = async (attestato) => {
    attestato = findAttestato(attestato);
    if (attestato == null) return; //TODO dare errore
    const url = "https://apot.mondorevive.com/api/updateAttestato";
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        id: attestato.id,
        nome: attestato.nome,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      const data = await response.text();
      handleAnnullaModificaAttestato(attestato);
    } else {
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  const handleModificaAttestato = (attestato) => {
    let arr = [];
    attestati.map((attestat) => {
      if (attestat.id == attestato.id) {
        arr.push({ id: attestat.id, nome: attestat.nome, status: true });
      } else arr.push(attestat);
    });
    setAttestati(arr);
  };
  const [attestatiNon, setAttestatiNon] = useState([]);
  const handleModificaDenominazioneAttestato = (
    attestatoToChange,
    nuovoNome
  ) => {
    let arr = [];
    attestati.map((att) => {
      if (att.id == attestatoToChange.id) {
        arr.push({ nome: nuovoNome, id: att.id, status: true });
      } else arr.push(att);
    });
    setAttestatiNon(arr);
  };
  const handleAnnullaModificaAttestato = (attestato) => {
    let arr = [];
    attestatiNonModificati.map((att) => {
      if (att.id == attestato.id) {
        arr.push({ id: att.id, nome: att.nome, status: false });
      } else arr.push(att);
    });
    setAttestati(arr);
  };
  const getForm = async () => {
    setLoadingTabella(true);
    const url = " https://apot.mondorevive.com/api/appaltiDashboard";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      //dire ok
      const data = await response.json();
      setAppaltiNonModificati(data);
      if (sortByState == "" && orderByState == "") {
        setAppalti(data);
      } else {
        let arr = [];
        if (sortByState == "Tutti") {
          setAppalti([]);
          setTimeout(() => setAppalti(data), 200);
        }
        if (sortByState == "Mancano Campi") {
          data.map((appalto) => {
            if (appalto.mancanoCampi) arr.push(appalto);
          });

          setAppalti([]);
          setTimeout(() => setAppalti(arr), 200);
        }
        if (sortByState == "Stabilimento") {
          data.map((appalto) => {
            stabilimentiSelezionati.forEach((stabilimento) => {
              if (appalto.stabilimento == stabilimento) arr.push(appalto);
            });
          });
          setAppalti([]);
          setTimeout(() => setAppalti(arr), 200);
        }
        if (sortByState == "Stato") {
          data.map((appalto) => {
            //let stat = appalto.stato.splice(2, appalto.stato.length).replace("_", " ");
            if (appalto.stato == status) arr.push(appalto);
          });
          setAppalti([]);
          setTimeout(() => setAppalti(arr), 200);
        }
        if (sortByState == "Data") {
          data.map((appalto) => {
            let data = new Date(appalto.data).getTime();
            let dataStart = new Date(dateRange[0]).getTime();
            let dataFine = new Date(dateRange[1]).getTime() + 86399999;

            if (dataStart < data && data < dataFine) arr.push(appalto);
          });
          setAppalti([]);
          setTimeout(() => setAppalti(arr), 200);
        }
        if (sortByState == "Utente") {
          data.map((appalto) => {
            if (
              appalto.username.toLowerCase().includes(searchUser.toLowerCase())
            )
              arr.push(appalto);
          });

          setAppalti([]);
          setTimeout(() => setAppalti(arr), 200);
        }
        if (sortByState == "Azienda") {
          data.map((appalto) => {
            if (
              appalto.nomeAzienda
                .toLowerCase()
                .includes(searchAzienda.toLowerCase())
            )
              arr.push(appalto);
          });

          setAppalti([]);
          setTimeout(() => setAppalti(arr), 200);
        }
        if (orderByState == "") {
          setLoadingTabella(false);
          return;
        }
        if (arr.length == 0) {
          if (orderByState == "Stato") {
            let a = data.sort((a, b) => {
              return a.stato.localeCompare(b.stato);
            });
            setAppalti([]);
            setTimeout(() => setAppalti(a), 200);
            return;
          }
          if (orderByState == "Azienda") {
            let a = data.sort((a, b) => {
              return a.nomeAzienda.localeCompare(b.nomeAzienda);
            });
            setAppalti([]);
            setTimeout(() => setAppalti(a), 200);
            return;
          }
          if (orderByState == "Data") {
            let a = data.sort(getSortFunction("data", "date", "desc"));
            setAppalti([]);
            setTimeout(() => setAppalti(a), 200);
            return;
          }
          if (orderByState == "Utente") {
            let a = data.sort((a, b) => {
              return a.username.localeCompare(b.username);
            });
            setAppalti([]);
            setTimeout(() => setAppalti(a), 200);
            return;
          }
          if (orderByState == "Stabilimento") {
            let a = data.sort((a, b) => {
              return a.stabilimento.localeCompare(b.stabilimento);
            });
            setAppalti([]);
            setTimeout(() => setAppalti(a), 200);
            return;
          }
        } else {
          if (orderByState == "Stato") {
            let a = arr.sort((a, b) => {
              return a.stato.localeCompare(b.stato);
            });
            setAppalti([]);
            setTimeout(() => setAppalti(a), 200);
            return;
          }
          if (orderByState == "Azienda") {
            let a = arr.sort((a, b) => {
              return a.nomeAzienda.localeCompare(b.nomeAzienda);
            });
            setAppalti([]);
            setTimeout(() => setAppalti(a), 200);
            return;
          }
          if (orderByState == "Data") {
            let a = arr.sort(getSortFunction("data", "date", "desc"));

            setAppalti([]);
            setTimeout(() => setAppalti(a), 200);
            return;
          }
          if (orderByState == "Utente") {
            let a = arr.sort((a, b) => {
              return a.username.localeCompare(b.username);
            });

            setAppalti([]);
            setTimeout(() => setAppalti(a), 200);
            return;
          }
          if (orderByState == "Stabilimento") {
            let a = arr.sort((a, b) => {
              return a.stabilimento.localeCompare(b.stabilimento);
            });
            setAppalti([]);
            setTimeout(() => setAppalti(a), 200);
            return;
          }
          if (orderByState == "Mancano Campi") {
            let a = appalti.sort((a, b) => {
              return b.mancanoCampi - a.mancanoCampi;
            });
            setAppalti([]);
            setTimeout(() => setAppalti(a), 200);
            return;
          }
        }
      }
    } else {
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  const fromAmericaToItalia = (data) => {
    let italia = data.split("-");
    return italia[2] + "-" + italia[1] + "-" + italia[0];
  };
  //Funzione che aggiorna i template dei file
  const updateTemplateFile = async () => {
    const url = "https://apot.mondorevive.com/api/updateTemplate";
    var formData = new FormData();

    formData.append("dichiarazione445", fileCamera.doc445);
    formData.append("modulo7Ba", fileModulo7.ba);
    formData.append("modulo7Fe", fileModulo7.fe);
    formData.append("modulo7Chi", fileModulo7.cp);
    formData.append("modulo7Be", fileModulo7.bt);
    formData.append("modulo7Ma", fileModulo7.ma);
    formData.append("modulo7Di", fileModulo7.di);
    formData.append("infoRischiRegBa", fileModulo5.ba);
    formData.append("infoRischiRegFe", fileModulo5.fe);
    formData.append("infoRischiRegChi", fileModulo5.cp);
    formData.append("infoRischiRegBe", fileModulo5.bt);
    formData.append("infoRischiRegMa", fileModulo5.ma);
    formData.append("infoRischiRegDi", fileModulo5.di);
    formData.append("duvriBa", fileDuvri.ba);
    formData.append("duvriFe", fileDuvri.fe);
    formData.append("duvriChi", fileDuvri.cp);
    formData.append("duvriBe", fileDuvri.bt);
    formData.append("duvriMa", fileDuvri.ma);
    formData.append("duvriDi", fileDuvri.di);
    formData.append("codiceEtico", codiceEtico);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + cookie.get("token"),
      },
      body: formData,
    });
    if (response.status == 200) {
      successNotification("File modificati con successo");
      resetFile();
      getTemplate();
    } else {
      getTemplate();
      errorNotification("Impossibile modificare i file, riprova più tardi!");
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  //Funzione che riporta allo stato iniziale tutte le variabili dei file
  const resetFile = () => {
    setfileModulo7({
      ba: null,
      fe: null,
      cp: null,
      bt: null,
      ma: null,
      di: null,
    });
    setfileModulo5({
      ba: null,
      fe: null,
      cp: null,
      bt: null,
      ma: null,
      di: null,
    });
    setFileDuvri({
      ba: null,
      fe: null,
      cp: null,
      bt: null,
      ma: null,
      di: null,
    });
    setFileCamera({ iscrizione: null, dvr: null, doc445: null });
    setLabelFile({
      iso9001: null,
      iso14001: null,
      iso45001: null,
      sa8000: null,
      camera: null,
      dvr: null,
      dichiarazione445: null,
      dipendenti: null,
      poliza: null,
      durc: null,
      m7ba: null,
      m7fe: null,
      m7cp: null,
      m7bt: null,
      m7ma: null,
      m7di: null,
      m5ba: null,
      m5fe: null,
      m5cp: null,
      m5bt: null,
      m5ma: null,
      m5di: null,
      duba: null,
      dufe: null,
      ducp: null,
      dubt: null,
      duma: null,
      dudi: null,
    });
    setCodiceEtico();
  };
  const handleClickOpenFile = (label) => {
    document.getElementById(label).click();
  };
  //Funzione che ottiene un file dal db
  const getFile = async (nomeFile) => {
    const url =
      "https://apot.mondorevive.com/api/downloadTemplate?campo=" + nomeFile;
    const response = await toast.promise(
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + cookie.get("token"),
        },
        responseType: "blob",
      }),
      {
        pending: "Download in corso",
        success: {
          render() {
            return `File scaricato correttamente`;
          },
          autoClose: 1000,
        },
        error: {
          render() {
            return `Errore nel download del file`;
          },
          autoClose: 1000,
        },
      }
    );
    if (response.status == 200) {
      const data = await response.arrayBuffer();
      const filename = response.headers
        .get("content-disposition")
        .split("filename=")[1];
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename.substring(1, filename.length - 1));
      document.body.appendChild(link);
      link.click();
    } else {
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  //FUNZIONI CHE GESTISCONO IL DOWNLOAD DEI VARI FILE
  const handleDownloadModulo7 = (stabilimento) => {
    if (stabilimento == "Mondorevive Busto Arsizio") getFile("modulo7Ba");
    if (stabilimento == "Mondorevive Ferentino") getFile("modulo7Fe");
    if (stabilimento == "Caltek Chignolo Po") getFile("modulo7Chi");
    if (stabilimento == "MondoSD Bernate Ticino") getFile("modulo7Be");
    if (stabilimento == "Mondorevive Marnate") getFile("modulo7Ma");
    if (stabilimento == "Diba Chignolo Po") getFile("modulo7Di");
  };
  const handleDownloadModulo5 = (stabilimento) => {
    if (stabilimento == "Mondorevive Busto Arsizio") getFile("infoRischiRegBa");
    if (stabilimento == "Mondorevive Ferentino") getFile("infoRischiRegFe");
    if (stabilimento == "Caltek Chignolo Po") getFile("infoRischiRegChi");
    if (stabilimento == "MondoSD Bernate Ticino") getFile("infoRischiRegBe");
    if (stabilimento == "Mondorevive Marnate") getFile("infoRischiRegMa");
    if (stabilimento == "Diba Chignolo Po") getFile("infoRischiRegDi");
  };
  const handleDownloadDuvri = (stabilimento) => {
    if (stabilimento == "Mondorevive Busto Arsizio") getFile("duvriBa");
    if (stabilimento == "Mondorevive Ferentino") getFile("duvriFe");
    if (stabilimento == "Caltek Chignolo Po") getFile("duvriChi");
    if (stabilimento == "MondoSD Bernate Ticino") getFile("duvriBe");
    if (stabilimento == "Mondorevive Marnate") getFile("duvriMa");
    if (stabilimento == "Diba Chignolo Po") getFile("duvriDi");
  };
  //Gestisce i change del sort by
  useEffect(() => {
    let arr = [];
    if (sortByState == "") return;
    if (sortByState == "Tutti") {
      setAppalti(appaltiNonModificati);
      return;
    }
    if (sortByState == "Mancano Campi") {
      appalti.map((appalto) => {
        if (appalto.mancanoCampi) arr.push(appalto);
      });
      setAppalti(arr);
      return;
    }
    setAppalti(appaltiNonModificati);
  }, [sortByState]);
  useEffect(() => {
    if (stabilimentiSelezionati.length == 0) {
      let arr = [];
      if (orderByState != "" && orderByState != null)
        arr = orderBy(orderByState, appaltiNonModificati);
      setAppalti(arr.length == 0 ? appaltiNonModificati : arr);
      return;
    }
    let arr = [];
    appaltiNonModificati.map((appalto) => {
      stabilimentiSelezionati.forEach((stabilimento) => {
        if (appalto.stabilimento == stabilimento) arr.push(appalto);
      });
    });
    if (orderByState != "" && orderByState != null)
      arr = orderBy(orderByState, arr);
    setAppalti(arr);
  }, [stabilimentiSelezionati]);
  useEffect(() => {
    if (status == "") return;
    if (status == "tutti") {
      setAppalti(appaltiNonModificati);
      return;
    }
    let arr = [];
    appaltiNonModificati.map((appalto) => {
      if (appalto.stato == status) arr.push(appalto);
    });
    setAppalti(arr);
  }, [status]);
  useEffect(() => {
    if (dateRange[0] == null || dateRange[1] == null) {
      setAppalti(appaltiNonModificati);
      return;
    }
    let arr = [];
    appaltiNonModificati.map((appalto) => {
      let data = new Date(appalto.data).getTime();
      let dataStart = new Date(dateRange[0]).getTime();
      let dataFine = new Date(dateRange[1]).getTime() + 86399999;

      if (dataStart < data && data < dataFine) arr.push(appalto);
    });
    setAppalti(arr);
  }, [dateRange]);
  useEffect(() => {
    if (searchUser == "") {
      setAppalti(appaltiNonModificati);
      return;
    }
    let arr = [];
    appaltiNonModificati.map((appalto) => {
      if (appalto.username.toLowerCase().includes(searchUser.toLowerCase()))
        arr.push(appalto);
    });
    setAppalti(arr);
  }, [searchUser]);
  useEffect(() => {
    if (searchAzienda == "") {
      setAppalti(appaltiNonModificati);
      return;
    }
    let arr = [];
    appaltiNonModificati.map((appalto) => {
      if (
        appalto.nomeAzienda.toLowerCase().includes(searchAzienda.toLowerCase())
      )
        arr.push(appalto);
    });
    setAppalti(arr);
  }, [searchAzienda]);
  const orderBy = (order, arr) => {
    if (order == "Tutti") return arr;
    if (order == "Stato") {
      arr = arr.sort((a, b) => {
        return a.stato.localeCompare(b.stato);
      });
      return arr;
    }
    if (orderByState == "Utente") {
      arr = arr.sort((a, b) => {
        return a.username.localeCompare(b.username);
      });
      return arr;
    }
    if (orderByState == "Stabilimento") {
      arr = arr.sort((a, b) => {
        return a.stabilimento.localeCompare(b.stabilimento);
      });
      return arr;
    }
    if (orderByState == "Azienda") {
      arr = arr.sort((a, b) => {
        return a.nomeAzienda.localeCompare(b.nomeAzienda);
      });
      return arr;
    }
    if (orderByState == "Mancano Campi") {
      arr = arr.sort((a, b) => {
        return b.mancanoCampi - a.mancanoCampi;
      });
      return arr;
    }
  };
  //Funzione che ottiene la sortFunction in base al tipo
  function getSortFunction(fieldName, type, val) {
    return function (tile1, tile2) {
      if (type == "numero") {
        if (val == "asc")
          return parseFloat(tile1[fieldName]) - parseFloat(tile2[fieldName]);
        else return parseFloat(tile2[fieldName]) - parseFloat(tile1[fieldName]);
      }
      if (type == "string") {
        if (val == "asc") {
          if (tile1[fieldName] < tile2[fieldName]) return 1;
        } else return -1;
        if (val == "desc")
          return (
            (tile2[fieldName] > tile1[fieldName]) -
            (tile2[fieldName] < tile1[fieldName])
          );
      }
      if (type == "date") {
        if (val == "asc")
          return new Date(tile1[fieldName]) - new Date(tile2[fieldName]);
        if (val == "desc")
          return new Date(tile2[fieldName]) - new Date(tile1[fieldName]);
      }
    };
  }
  useEffect(() => {
    if (orderByState == "") return;
    if (orderByState == "Stato") {
      let a = appalti.sort((a, b) => {
        return a.stato.localeCompare(b.stato);
      });
      setAppalti([]);
      setTimeout(() => setAppalti(a), 200);
      return;
    }
    if (orderByState == "Azienda") {
      let a = appalti.sort((a, b) => {
        return a.nomeAzienda.localeCompare(b.nomeAzienda);
      });
      setAppalti([]);
      setTimeout(() => setAppalti(a), 200);
      return;
    }
    if (orderByState == "Data") {
      let a = appalti.sort(getSortFunction("data", "date", "desc"));

      setAppalti([]);
      setTimeout(() => setAppalti(a), 200);
      return;
    }
    if (orderByState == "Utente") {
      let a = appalti.sort((a, b) => {
        return a.username.localeCompare(b.username);
      });

      setAppalti([]);
      setTimeout(() => setAppalti(a), 200);
      return;
    }
    if (orderByState == "Stabilimento") {
      let a = appalti.sort((a, b) => {
        return a.stabilimento.localeCompare(b.stabilimento);
      });
      setAppalti([]);
      setTimeout(() => setAppalti(a), 200);
      return;
    }
    if (orderByState == "Mancano Campi") {
      let a = appalti.sort((a, b) => {
        return b.mancanoCampi - a.mancanoCampi;
      });
      setAppalti([]);
      setTimeout(() => setAppalti(a), 200);
      return;
    }
  }, [orderByState]);

  function StrengthChecker() {
    let lunghezzaBarra = 0;
    if (strongPassword.test(pass)) {
      /**PASSWORD STRONG */
      lunghezzaBarra = 4;
    } else {
      /**PASSWORD DEBOLE */
      lunghezzaBarra = 1;
      setErrorPass(
        "La password deve contenere almeno 8 caratteri di cui una maiuscola un carattere speciale e un numero"
      );
    }
    //Controllo la sicurezza della pass
    // if (strongPassword.test(pass)) {
    // 	/**PASSWORD STRONG */
    // 	lunghezzaBarra = 4;
    // 	setErrorPass("");
    // } else if (mediumPassword.test(pass)) {
    // 	/**PASSWORD MEDIA */
    // 	lunghezzaBarra = 3;
    // 	setErrorPass("");
    // } else {
    // 	/**PASSWORD DEBOLE */
    // 	lunghezzaBarra = 1;
    // 	setErrorPass(
    // 		"La password deve contenere almeno 8 caratteri di cui una maiuscola un carattere speciale e un numero"
    // 	);
    // }
  }
  useEffect(() => {
    if (pass == "") return;
    if (confermaPass == "") return;
    if (pass != confermaPass) {
      setErrorPass("Password diverse");
    } else setErrorPass("");
    const timeOutId = setTimeout(() => StrengthChecker(), 500);
    return () => clearTimeout(timeOutId);
  }, [pass]);
  useEffect(() => {
    if (confermaPass == "") return;
    if (pass == "") return;
    if (pass != confermaPass) {
      setErrorPass("Password diverse");
      return;
    } else {
      setErrorPass("");
      const timeOutId = setTimeout(() => StrengthChecker(), 500);
    }
  }, [confermaPass]);

  function StrengthChecker2() {
    //Controllo la sicurezza della pass
    if (strongPassword.test(passwordApprovatore)) {
      /**PASSWORD STRONG */
      setErrorPassApp("2");
    } else {
      /**PASSWORD DEBOLE */
      setErrorPassApp("Password Debole");
    }
  }
  useEffect(() => {
    if (passwordApprovatore == "") return;
    const timeOutId = setTimeout(() => StrengthChecker2(), 300);
    return () => clearTimeout(timeOutId);
  }, [passwordApprovatore]);
  const getTemplate = async () => {
    const url = " https://apot.mondorevive.com/api/templateStatus ";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      //dire ok
      const data = await response.json();

      setTemplateStatus(data);
    } else {
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  //Funzione che permette di inviare una mail con delle richieste aggiuntive
  const inviaInformazioniAggiuntive = async () => {
    const url = "https://apot.mondorevive.com/api/richiestaInformazioni";
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        idAppaltoStabilimento: appaltoSelected.current,
        textList: testoInformazioniAggiuntive,
      }),
      headers: {
        Authorization: "Bearer " + cookie.get("token"),
        "Content-Type": "application/json",
      },
    });
    if (response.status == 200) {
      //dire ok
      successNotification("E-Mail inviata correttamente");
      setTestoInformazioniAggiuntive([]);
      nuovoMessaggioRef.current.value = "";
      setOpenInformazioniAggiuntive(false);
    } else {
      //DARE ERROR
      errorNotification("Impossibile inviare E-Mail");
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  //Funzione che aggiunge un nuovo messaggio alla lista dei messaggi da aggiungere
  const aggiungiNuovoMessaggio = () => {
    setTestoInformazioniAggiuntive([
      ...testoInformazioniAggiuntive,
      nuovoMessaggioRef.current.value,
    ]);
    nuovoMessaggioRef.current.value = "";
  };
  //Funzione che rimuove un testo delle informazioni aggiuntive
  const rimuoviTesto = (testoDaRim) => {
    setTestoInformazioniAggiuntive((testoInformazioniAggiuntive) => {
      let arr = [];
      testoInformazioniAggiuntive.map((testo) => {
        if (testo != testoDaRim) arr.push(testo);
      });
      return arr;
    });
  };

  return (
    <div className="dashboard-container min100">
      <ToastContainer
        transition={Flip}
        newestOnTop
        rtl={false}
        pauseOnFocusLoss
      />
      <Sidebar
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        setStatusNavbar={setStatusNavbar}
      />

      {activeTab == 0 && (
        <div>
          <h2 className="mt20">Richieste</h2>
          <div className={"grid-container repeat2 pl0 center"}>
            <Select
              data={[
                "Tutti",
                "Stabilimento",
                "Stato",
                "Data",
                "Mancano Campi",
                "Utente",
                "Azienda",
              ]}
              defaultValue={"Tutti"}
              placeholder="Scegline uno"
              label="Filtra per"
              radius="lg"
              onChange={setSortByState}
              size="md"
              required
            />
            {sortByState == "Stabilimento" && (
              <MultiSelect
                style={{ width: "100%" }}
                searchable
                radius={"lg"}
                label={"Stabilimento"}
                value={stabilimentiSelezionati.value}
                onChange={setStabilimentiSelezionati}
                size="md"
                data={stabilimenti}
                placeholder="Seleziona una o più scelte"
              />
            )}
            {sortByState == "Stato" && (
              <Select
                data={statusForm}
                value={status}
                placeholder="Scegline uno"
                label="Filtra per"
                radius="lg"
                onChange={setStatus}
                size="md"
                required
              />
            )}

            {sortByState == "Utente" && (
              <TextInput
                placeholder="Nome Utente"
                label="Utente"
                radius={"lg"}
                size={"md"}
                onChange={(e) => setSearchUser(e.target.value)}
              />
            )}
            {sortByState == "Azienda" && (
              <TextInput
                placeholder="Nome Azienda"
                label="Azienda"
                radius={"lg"}
                size={"md"}
                onChange={(e) => setSearchAzienda(e.target.value)}
              />
            )}
            {sortByState == "Data" && (
              <DateRangePicker
                placeholder="Seleziona una data"
                amountOfMonths={2}
                size={"md"}
                locale="it"
                radius={"lg"}
                label="2 Mesi"
                value={dateRange}
                onChange={setDateRange}
              />
            )}
          </div>
          <Select
            data={[
              "Stato",
              "Azienda",
              "Data",
              "Utente",
              "Stabilimento",
              "Mancano Campi",
            ]}
            placeholder="Scegline uno"
            label="Ordina per"
            sx={{ maxWidth: "500px" }}
            onChange={setOrderByState}
            radius="lg"
            size="md"
            required
          />
          <h5 style={{ margin: "0", marginBottom: "5px", marginTop: "15px" }}>
            Stato delle Richieste:
          </h5>
          <div
            className="container_swatch_stati "
            style={{ marginBottom: "20px" }}
          >
            <div className="container_singolo_swatch">
              <ColorSwatch
                key={"red"}
                color={"red"}
                style={{ width: "18px", height: "18px" }}
              />
              Rifiutato
            </div>
            <div className="container_singolo_swatch">
              <ColorSwatch
                key={"green"}
                color={"green"}
                style={{ width: "18px", height: "18px" }}
              />
              Accettato
            </div>
            <div className="container_singolo_swatch">
              <ColorSwatch
                key={"gray"}
                color={"gray"}
                style={{ width: "18px", height: "18px" }}
              />
              In Verifica
            </div>
            <div className="container_singolo_swatch">
              <ColorSwatch
                key={"black"}
                color={"black"}
                style={{ width: "18px", height: "18px" }}
              />
              Bloccato
            </div>
          </div>
          <div className="overflowX">
            <Table
              striped
              style={{ marginBottom: "30px" }}
              captionSide="bottom"
            >
              {appalti.length == 0 && (
                <caption>Nessun elemento da visualizzare</caption>
              )}
              <thead>
                <tr>
                  <th style={{ width: "3%" }}>Azioni</th>
                  <th style={{ width: "3%" }}>Stato</th>
                  <th style={{ width: "5%" }}>In Scadenza</th>
                  <th>Azienda</th>
                  <th>Data</th>
                  <th>Utente</th>
                  <th>Stabilimento</th>
                  <th style={{ width: "5%" }}>Mancano Campi</th>
                  <th>Visualizza</th>
                </tr>
              </thead>
              <tbody>
                {appalti.map((appalto) => (
                  <tr key={appalto.id}>
                    <td>
                      <Menu position="right-start">
                        <Menu.Target>
                          <div>
                            <FontAwesomeIcon
                              icon={faEllipsisVertical}
                              size={"lg"}
                              className={"pointer"}
                            />
                          </div>
                          {/* <Button>Toggle menu</Button> */}
                        </Menu.Target>

                        <Menu.Dropdown>
                          <Menu.Item
                            icon={
                              <FontAwesomeIcon icon={faCheck} color="green" />
                            }
                            disabled={
                              appalto.stato == "A_APPROVATO" || !possoModificare
                            }
                            onClick={() => accettaAppalto(appalto)}
                          >
                            Accetta
                          </Menu.Item>

                          <Menu.Item
                            icon={
                              <FontAwesomeIcon icon={faTimes} color={"red"} />
                            }
                            disabled={
                              appalto.stato == "B_NON_APPROVATO" ||
                              !possoModificare
                            }
                            onClick={() => rifiutaAppalto(appalto)}
                          >
                            Rifiuta
                          </Menu.Item>

                          <Menu.Item
                            icon={
                              <FontAwesomeIcon icon={faPlus} color={"orange"} />
                            }
                            disabled={!possoModificare}
                            onClick={() => {
                              appaltoSelected.current = appalto.id;
                              setOpenInformazioniAggiuntive(true);
                            }}
                          >
                            Informazioni Aggiuntive
                          </Menu.Item>
                          <Menu.Item
                            icon={
                              <FontAwesomeIcon
                                icon={faListCheck}
                                color={"blue"}
                              />
                            }
                            onClick={() =>
                              navigate("/verifiche", {
                                state: { id: appalto.idAppaltatore },
                              })
                            }
                          >
                            Verifiche
                          </Menu.Item>
                        </Menu.Dropdown>
                      </Menu>
                    </td>
                    <td>
                      <ColorSwatch
                        color={
                          appalto.stato == "A_APPROVATO"
                            ? "green"
                            : appalto.stato == "C_IN_VERIFICA"
                            ? "gray"
                            : appalto.stato == "D_BLOCCATO"
                            ? "black"
                            : "red"
                        }
                      />
                    </td>

                    <td>{appalto.datiScaduti ? "SI" : "NO"} </td>
                    <td>{appalto.nomeAzienda} </td>
                    <td>{fromAmericaToItalia(appalto.data.split("T")[0])}</td>
                    <td>{appalto.username}</td>
                    <td>{appalto.stabilimento}</td>
                    <td>{appalto.mancanoCampi ? "SI" : "NO"}</td>
                    <td>
                      <Button
                        leftIcon={
                          <FontAwesomeIcon icon={faEye} color={"white"} />
                        }
                        onClick={() => changePage("/form/" + appalto.id)}
                      >
                        Visualizza
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      )}
      {cookie.get("status") == "ROLE_ADMINISTRATOR" && (
        <>
          {activeTab == 1 && (
            <>
              <h2 className="mt20">Gestione Form</h2>
              <h2 className="card-label">DPI</h2>
              <div className="grid-container">
                {dpi.map((dpis) => (
                  <div className="grid-container repeat3 pl0 center">
                    <Input
                      key={dpis.nome}
                      type="text"
                      defaultValue={dpis.nome}
                      disabled={!dpis.status}
                      name="name"
                      size="md"
                      style={{ minWidth: "350px" }}
                      //value={dpis.nome}
                      onChange={(e) =>
                        handleModificaDenominazioneDpi(dpis, e.target.value)
                      }
                      placeholder="Username"
                      spellCheck="false"
                      autoComplete="off"
                      id="cardHolder2"
                      className="form-control "
                    />
                    <Button
                      color={"green"}
                      sx={{ maxWidth: "200px !important" }}
                      disabled={!possoModificare}
                      radius="md"
                      onClick={() => {
                        dpis.status
                          ? handleSalvaDpi(dpis)
                          : handleModificaDpi(dpis);
                      }}
                    >
                      {dpis.status ? "Salva" : "Modifica"}
                    </Button>
                    <Button
                      color={"red"}
                      sx={{ maxWidth: "200px !important" }}
                      onClick={() =>
                        dpis.status
                          ? handleAnnullaModificaDpi(dpi)
                          : rimuoviDPI(dpis)
                      }
                    >
                      {dpis.status ? "Annulla" : "Elimina"}
                    </Button>
                  </div>
                ))}
              </div>
              <Button
                style={{ minWidth: "150px ", marginTop: "10px" }}
                disabled={!possoModificare}
                onClick={() =>
                  voglioAggiungereDpi
                    ? aggiungiDPI()
                    : setVoglioAggiungereDpi(!voglioAggiungereDpi)
                }
                color="green"
              >
                {voglioAggiungereDpi ? "Salva" : "Aggiungi DPI"}
              </Button>
              {voglioAggiungereDpi && (
                <Button
                  style={{ minWidth: "150px ", marginLeft: "30px" }}
                  onClick={annullaAggiuntaDpi}
                  color="orange"
                >
                  Annulla
                </Button>
              )}
              {voglioAggiungereDpi && (
                <Input
                  type="text"
                  ref={labelDpi}
                  name="name"
                  size="lg"
                  style={{ marginTop: "20px", maxWidth: "330px" }}
                  radius="md"
                  placeholder="Nome dpi"
                  spellCheck="false"
                  autoComplete="off"
                  id="cardHolder32"
                  className="form-control "
                />
              )}

              <Divider style={{ marginTop: "30px", marginBottom: "60px" }} />
              <h2 className="card-label">Attestati</h2>
              <div className="grid-container">
                {attestati.map((attestato, count) => (
                  <div
                    className="grid-container repeat3 pl0 center"
                    key={count}
                  >
                    <Input
                      key={attestato.nome}
                      type="text"
                      name="name"
                      disabled={!attestato.status}
                      size="md"
                      style={{ minWidth: "350px" }}
                      defaultValue={attestato.nome}
                      onChange={(e) =>
                        handleModificaDenominazioneAttestato(
                          attestato,
                          e.target.value
                        )
                      }
                      placeholder="Username"
                      spellCheck="false"
                      autoComplete="off"
                      id="cardHolder2"
                      className="form-control "
                    />
                    <Button
                      color={"green"}
                      radius="md"
                      disabled={!possoModificare}
                      onClick={() => {
                        attestato.status
                          ? handleSalvaAttestato(attestato)
                          : handleModificaAttestato(attestato);
                      }}
                    >
                      {attestato.status ? "Salva" : "Modifica"}
                    </Button>
                    <Button
                      color={"red"}
                      onClick={() =>
                        attestato.status
                          ? handleAnnullaModificaAttestato(attestato)
                          : rimuoviAttestato(attestato)
                      }
                    >
                      {attestato.status ? "Annulla" : "Elimina"}
                    </Button>
                  </div>
                ))}
              </div>
              <Button
                style={{
                  minWidth: "150px ",
                  marginBottom: "30px",
                  marginTop: "10px",
                }}
                onClick={() =>
                  voglioAggiungereAttestati
                    ? aggiungiAttesto()
                    : setVoglioAggiungereAttestati(!voglioAggiungereAttestati)
                }
                disabled={!possoModificare}
                color="green"
              >
                {voglioAggiungereAttestati ? "Salva" : "Aggiungi Attestato"}
              </Button>
              {voglioAggiungereAttestati && (
                <Button
                  style={{ minWidth: "150px ", marginLeft: "30px" }}
                  onClick={annullaAggiuntaAttestato}
                  color="orange"
                >
                  Annulla
                </Button>
              )}
              {voglioAggiungereAttestati && (
                <Input
                  type="text"
                  ref={labelAttestato}
                  name="name"
                  size="lg"
                  style={{
                    marginTop: "20px",
                    maxWidth: "330px",
                    marginBottom: "30px",
                  }}
                  radius="md"
                  placeholder="Nome attestato"
                  spellCheck="false"
                  autoComplete="off"
                  id="cardHolde213r"
                  className="form-control "
                />
              )}

              <h1 className="card-label">Documentazione</h1>
              <h2>Dichiarazione 445</h2>
              <div className="grid-container repeat3v2 pl0 center">
                {templateStatus.dichiarazione445 && (
                  <Button onClick={() => getFile("dichiarazione445")}>
                    Download Template
                  </Button>
                )}
                <div>
                  <input
                    type="file"
                    id="dichiarazione445"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setFileCamera({
                        ...fileCamera,
                        doc445: e.target.files[0],
                      });
                      setLabelFile({
                        ...labelFile,
                        dichiarazione445: e.target.files[0].name,
                      });
                    }}
                  />
                  <Button
                    className="mw400"
                    onClick={() => handleClickOpenFile("dichiarazione445")}
                  >
                    Scegli File
                  </Button>
                  <label style={{ marginLeft: "10px" }}>
                    {labelFile.dichiarazione445
                      ? labelFile.dichiarazione445
                      : "Nessun file Selezionato"}
                  </label>
                </div>
              </div>
              <>
                <h1 style={{ color: "Green" }}>Busto Arsizio</h1>
                <div className="grid-container repeat3v2 pl0 center">
                  <div>
                    <h3>Modulo 7</h3>
                    <div className="grid-container repeat2 pl0 center">
                      {templateStatus.modulo7Ba && (
                        <Button
                          onClick={() =>
                            handleDownloadModulo7("Mondorevive Busto Arsizio")
                          }
                        >
                          Download Template
                        </Button>
                      )}
                      <div className="grid-container repeat3 pl0 center">
                        <input
                          type="file"
                          id="modulo7ba"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            setfileModulo7({
                              ...fileModulo7,
                              ba: e.target.files[0],
                            });
                            setLabelFile({
                              ...labelFile,
                              m7ba: e.target.files[0].name,
                            });
                          }}
                        />
                        <Button
                          className="mw400"
                          onClick={() => handleClickOpenFile("modulo7ba")}
                        >
                          Scegli File
                        </Button>
                        <label style={{ marginLeft: "10px" }}>
                          {labelFile.m7ba
                            ? labelFile.m7ba
                            : "Nessun file Selezionato"}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3>Modulo 5</h3>
                    <div className="grid-container repeat2 pl0 center">
                      {templateStatus.infoRischiRegBa && (
                        <Button
                          onClick={() =>
                            handleDownloadModulo5("Mondorevive Busto Arsizio")
                          }
                        >
                          Download Template
                        </Button>
                      )}
                      <div className="grid-container repeat3 pl0 center">
                        <input
                          type="file"
                          id="modulo5ba"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            setfileModulo5({
                              ...fileModulo5,
                              ba: e.target.files[0],
                            });
                            setLabelFile({
                              ...labelFile,
                              m5ba: e.target.files[0].name,
                            });
                          }}
                        />
                        <Button
                          className="mw400"
                          onClick={() => handleClickOpenFile("modulo5ba")}
                        >
                          Scegli File
                        </Button>
                        <label style={{ marginLeft: "10px" }}>
                          {labelFile.m5ba
                            ? labelFile.m5ba
                            : "Nessun file Selezionato"}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3>Duvri</h3>
                    <div className="grid-container repeat2 pl0 center">
                      {templateStatus.duvriBa && (
                        <Button
                          onClick={() =>
                            handleDownloadDuvri("Mondorevive Busto Arsizio")
                          }
                        >
                          Download Template
                        </Button>
                      )}
                      <div className="grid-container repeat3 pl0 center">
                        <input
                          type="file"
                          id="duvriba"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            setFileDuvri({
                              ...fileDuvri,
                              ba: e.target.files[0],
                            });
                            setLabelFile({
                              ...labelFile,
                              duba: e.target.files[0].name,
                            });
                          }}
                        />
                        <Button
                          className="mw400"
                          onClick={() => handleClickOpenFile("duvriba")}
                        >
                          Scegli File
                        </Button>
                        <label style={{ marginLeft: "10px" }}>
                          {labelFile.duba
                            ? labelFile.duba
                            : "Nessun file Selezionato"}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              <h1 style={{ color: "Green" }}>Ferentino</h1>
              <div className="grid-container repeat3v2 pl0 center">
                <div>
                  <h3>Modulo 7</h3>
                  <div className="grid-container repeat2 pl0 center">
                    {templateStatus.modulo7Fe && (
                      <Button
                        onClick={() =>
                          handleDownloadModulo7("Mondorevive Ferentino")
                        }
                      >
                        Download Template
                      </Button>
                    )}
                    <div className="grid-container repeat3 pl0 center">
                      <input
                        type="file"
                        id="modulo7fe"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setfileModulo7({
                            ...fileModulo7,
                            fe: e.target.files[0],
                          });
                          setLabelFile({
                            ...labelFile,
                            m7fe: e.target.files[0].name,
                          });
                        }}
                      />
                      <Button
                        className="mw400"
                        onClick={() => handleClickOpenFile("modulo7fe")}
                      >
                        Scegli File
                      </Button>
                      <label style={{ marginLeft: "10px" }}>
                        {labelFile.m7fe
                          ? labelFile.m7fe
                          : "Nessun file Selezionato"}
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <h3>Modulo 5</h3>
                  <div className="grid-container repeat2 pl0 center">
                    {templateStatus.infoRischiRegFe && (
                      <Button
                        onClick={() =>
                          handleDownloadModulo5("Mondorevive Ferentino")
                        }
                      >
                        Download Template
                      </Button>
                    )}
                    <div className="grid-container repeat3 pl0 center">
                      <input
                        type="file"
                        id="modulo5fe"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setfileModulo5({
                            ...fileModulo5,
                            fe: e.target.files[0],
                          });
                          setLabelFile({
                            ...labelFile,
                            m5fe: e.target.files[0].name,
                          });
                        }}
                      />
                      <Button
                        className="mw400"
                        onClick={() => handleClickOpenFile("modulo5fe")}
                      >
                        Scegli File
                      </Button>
                      <label style={{ marginLeft: "10px" }}>
                        {labelFile.m5fe
                          ? labelFile.m5fe
                          : "Nessun file Selezionato"}
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <h3>Duvri</h3>
                  <div className="grid-container repeat2 pl0 center">
                    {templateStatus.duvriFe && (
                      <Button
                        onClick={() =>
                          handleDownloadDuvri("Mondorevive Ferentino")
                        }
                      >
                        Download Template
                      </Button>
                    )}
                    <div className="grid-container repeat3 pl0 center">
                      <input
                        type="file"
                        id="duvrife"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setFileDuvri({ ...fileDuvri, fe: e.target.files[0] });
                          setLabelFile({
                            ...labelFile,
                            dufe: e.target.files[0].name,
                          });
                        }}
                      />
                      <Button
                        className="mw400"
                        onClick={() => handleClickOpenFile("duvrife")}
                      >
                        Scegli File
                      </Button>
                      <label style={{ marginLeft: "10px" }}>
                        {labelFile.dufe
                          ? labelFile.dufe
                          : "Nessun file Selezionato"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <h1 style={{ color: "Green" }}>Caltek Chignolo Po</h1>
              <div className="grid-container repeat3v2 pl0 center">
                <div>
                  <h3>Modulo 7</h3>
                  <div className="grid-container repeat2 pl0 center">
                    {templateStatus.modulo7Chi && (
                      <Button
                        onClick={() =>
                          handleDownloadModulo7("Caltek Chignolo Po")
                        }
                      >
                        Download Template
                      </Button>
                    )}
                    <div className="grid-container repeat3 pl0 center">
                      <input
                        type="file"
                        id="modulo7cp"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setfileModulo7({
                            ...fileModulo7,
                            cp: e.target.files[0],
                          });
                          setLabelFile({
                            ...labelFile,
                            m7cp: e.target.files[0].name,
                          });
                        }}
                      />
                      <Button
                        className="mw400"
                        onClick={() => handleClickOpenFile("modulo7cp")}
                      >
                        Scegli File
                      </Button>
                      <label style={{ marginLeft: "10px" }}>
                        {labelFile.m7cp
                          ? labelFile.m7cp
                          : "Nessun file Selezionato"}
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <h3>Modulo 5</h3>
                  <div className="grid-container repeat2 pl0 center">
                    {templateStatus.infoRischiRegChi && (
                      <Button
                        onClick={() =>
                          handleDownloadModulo5("Caltek Chignolo Po")
                        }
                      >
                        Download Template
                      </Button>
                    )}
                    <div className="grid-container repeat3 pl0 center">
                      <input
                        type="file"
                        id="modulo5cp"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setfileModulo5({
                            ...fileModulo5,
                            cp: e.target.files[0],
                          });
                          setLabelFile({
                            ...labelFile,
                            m5cp: e.target.files[0].name,
                          });
                        }}
                      />
                      <Button
                        className="mw400"
                        onClick={() => handleClickOpenFile("modulo5cp")}
                      >
                        Scegli File
                      </Button>
                      <label style={{ marginLeft: "10px" }}>
                        {labelFile.m5cp
                          ? labelFile.m5cp
                          : "Nessun file Selezionato"}
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <h3>Duvri</h3>
                  <div className="grid-container repeat2 pl0 center">
                    {templateStatus.duvriChi && (
                      <Button
                        onClick={() =>
                          handleDownloadDuvri("Caltek Chignolo Po")
                        }
                      >
                        Download Template
                      </Button>
                    )}
                    <div className="grid-container repeat3 pl0 center">
                      <input
                        type="file"
                        id="duvricp"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setFileDuvri({ ...fileDuvri, cp: e.target.files[0] });
                          setLabelFile({
                            ...labelFile,
                            ducp: e.target.files[0].name,
                          });
                        }}
                      />
                      <Button
                        className="mw400"
                        onClick={() => handleClickOpenFile("duvricp")}
                      >
                        Scegli File
                      </Button>
                      <label style={{ marginLeft: "10px" }}>
                        {labelFile.ducp
                          ? labelFile.ducp
                          : "Nessun file Selezionato"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <h1 style={{ color: "Green" }}>Bernate Ticino</h1>
              <div className="grid-container repeat3v2 pl0 center">
                <div>
                  <h3>Modulo 7</h3>
                  <div className="grid-container repeat2 pl0 center">
                    {templateStatus.modulo7Be && (
                      <Button
                        onClick={() =>
                          handleDownloadModulo7("MondoSD Bernate Ticino")
                        }
                      >
                        Download Template
                      </Button>
                    )}
                    <div className="grid-container repeat3 pl0 center">
                      <input
                        type="file"
                        id="modulo7bt"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setfileModulo7({
                            ...fileModulo7,
                            bt: e.target.files[0],
                          });
                          setLabelFile({
                            ...labelFile,
                            m7bt: e.target.files[0].name,
                          });
                        }}
                      />
                      <Button
                        className="mw400"
                        onClick={() => handleClickOpenFile("modulo7bt")}
                      >
                        Scegli File
                      </Button>
                      <label style={{ marginLeft: "10px" }}>
                        {labelFile.m7bt
                          ? labelFile.m7bt
                          : "Nessun file Selezionato"}
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <h3>Modulo 5</h3>
                  <div className="grid-container repeat2 pl0 center">
                    {templateStatus.infoRischiRegBe && (
                      <Button
                        onClick={() =>
                          handleDownloadModulo5("MondoSD Bernate Ticino")
                        }
                      >
                        Download Template
                      </Button>
                    )}
                    <div className="grid-container repeat3 pl0 center">
                      <input
                        type="file"
                        id="modulo5bt"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setfileModulo5({
                            ...fileModulo5,
                            bt: e.target.files[0],
                          });
                          setLabelFile({
                            ...labelFile,
                            m5bt: e.target.files[0].name,
                          });
                        }}
                      />
                      <Button
                        className="mw400"
                        onClick={() => handleClickOpenFile("modulo5bt")}
                      >
                        Scegli File
                      </Button>
                      <label style={{ marginLeft: "10px" }}>
                        {labelFile.m5bt
                          ? labelFile.m5bt
                          : "Nessun file Selezionato"}
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <h3>Duvri</h3>
                  <div className="grid-container repeat2 pl0 center">
                    {templateStatus.duvriBe && (
                      <Button
                        onClick={() =>
                          handleDownloadDuvri("MondoSD Bernate Ticino")
                        }
                      >
                        Download Template
                      </Button>
                    )}
                    <div className="grid-container repeat3 pl0 center">
                      <input
                        type="file"
                        id="duvribt"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setFileDuvri({ ...fileDuvri, bt: e.target.files[0] });
                          setLabelFile({
                            ...labelFile,
                            dubt: e.target.files[0].name,
                          });
                        }}
                      />
                      <Button
                        className="mw400"
                        onClick={() => handleClickOpenFile("duvribt")}
                      >
                        Scegli File
                      </Button>
                      <label style={{ marginLeft: "10px" }}>
                        {labelFile.dubt
                          ? labelFile.dubt
                          : "Nessun file Selezionato"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <h1 style={{ color: "Green" }}>Marnate</h1>
              <div className="grid-container repeat3v2 pl0 center">
                <div>
                  <h3>Modulo 7</h3>
                  <div className="grid-container repeat2 pl0 center">
                    {templateStatus.duvriMa && (
                      <Button
                        onClick={() =>
                          handleDownloadModulo7("Mondorevive Marnate")
                        }
                      >
                        Download Template
                      </Button>
                    )}
                    <div className="grid-container repeat3 pl0 center">
                      <input
                        type="file"
                        id="modulo7ma"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setfileModulo7({
                            ...fileModulo7,
                            ma: e.target.files[0],
                          });
                          setLabelFile({
                            ...labelFile,
                            m7ma: e.target.files[0].name,
                          });
                        }}
                      />
                      <Button
                        className="mw400"
                        onClick={() => handleClickOpenFile("modulo7ma")}
                      >
                        Scegli File
                      </Button>
                      <label style={{ marginLeft: "10px" }}>
                        {labelFile.m7ma
                          ? labelFile.m7ma
                          : "Nessun file Selezionato"}
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <h3>Modulo 5</h3>
                  <div className="grid-container repeat2 pl0 center">
                    {templateStatus.infoRischiRegMa && (
                      <Button
                        onClick={() =>
                          handleDownloadModulo5("Mondorevive Marnate")
                        }
                      >
                        Download Template
                      </Button>
                    )}
                    <div className="grid-container repeat3 pl0 center">
                      <input
                        type="file"
                        id="modulo5ma"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setfileModulo5({
                            ...fileModulo5,
                            ma: e.target.files[0],
                          });
                          setLabelFile({
                            ...labelFile,
                            m5ma: e.target.files[0].name,
                          });
                        }}
                      />
                      <Button
                        className="mw400"
                        onClick={() => handleClickOpenFile("modulo5ma")}
                      >
                        Scegli File
                      </Button>
                      <label style={{ marginLeft: "10px" }}>
                        {labelFile.m5ma
                          ? labelFile.m5ma
                          : "Nessun file Selezionato"}
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <h3>Duvri</h3>
                  <div className="grid-container repeat2 pl0 center">
                    {templateStatus.duvriMa && (
                      <Button
                        onClick={() =>
                          handleDownloadDuvri("Mondorevive Marnate")
                        }
                      >
                        Download Template
                      </Button>
                    )}
                    <div className="grid-container repeat3 pl0 center">
                      <input
                        type="file"
                        id="duvrima"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setFileDuvri({ ...fileDuvri, ma: e.target.files[0] });
                          setLabelFile({
                            ...labelFile,
                            duma: e.target.files[0].name,
                          });
                        }}
                      />
                      <Button
                        className="mw400"
                        onClick={() => handleClickOpenFile("duvrima")}
                      >
                        Scegli File
                      </Button>
                      <label style={{ marginLeft: "10px" }}>
                        {labelFile.duma
                          ? labelFile.duma
                          : "Nessun file Selezionato"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <>
                <h1 style={{ color: "Green" }}>Diba Chignolo Po</h1>
                <div className="grid-container repeat3v2 pl0 center">
                  <div>
                    <h3>Modulo 7</h3>
                    <div className="grid-container repeat2 pl0 center">
                      {templateStatus.modulo7Di && (
                        <Button
                          onClick={() =>
                            handleDownloadModulo7("Diba Chignolo Po")
                          }
                        >
                          Download Template
                        </Button>
                      )}
                      <div className="grid-container repeat3 pl0 center">
                        <input
                          type="file"
                          id="modulo7di"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            setfileModulo7({
                              ...fileModulo7,
                              di: e.target.files[0],
                            });
                            setLabelFile({
                              ...labelFile,
                              m7di: e.target.files[0].name,
                            });
                          }}
                        />
                        <Button
                          className="mw400"
                          onClick={() => handleClickOpenFile("modulo7di")}
                        >
                          Scegli File
                        </Button>
                        <label style={{ marginLeft: "10px" }}>
                          {labelFile.m7di
                            ? labelFile.m7di
                            : "Nessun file Selezionato"}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3>Modulo 5</h3>
                    <div className="grid-container repeat2 pl0 center">
                      {templateStatus.infoRischiRegDi && (
                        <Button
                          onClick={() =>
                            handleDownloadModulo5("Diba Chignolo Po")
                          }
                        >
                          Download Template
                        </Button>
                      )}
                      <div className="grid-container repeat3 pl0 center">
                        <input
                          type="file"
                          id="modulo5di"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            setfileModulo5({
                              ...fileModulo5,
                              di: e.target.files[0],
                            });
                            setLabelFile({
                              ...labelFile,
                              m5di: e.target.files[0].name,
                            });
                          }}
                        />
                        <Button
                          className="mw400"
                          onClick={() => handleClickOpenFile("modulo5di")}
                        >
                          Scegli File
                        </Button>
                        <label style={{ marginLeft: "10px" }}>
                          {labelFile.m5di
                            ? labelFile.m5di
                            : "Nessun file Selezionato"}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3>Duvri</h3>
                    <div className="grid-container repeat2 pl0 center">
                      {templateStatus.duvriDi && (
                        <Button
                          onClick={() =>
                            handleDownloadDuvri("Diba Chignolo Po")
                          }
                        >
                          Download Template
                        </Button>
                      )}
                      <div className="grid-container repeat3 pl0 center">
                        <input
                          type="file"
                          id="duvridi"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            setFileDuvri({
                              ...fileDuvri,
                              di: e.target.files[0],
                            });
                            setLabelFile({
                              ...labelFile,
                              dudi: e.target.files[0].name,
                            });
                          }}
                        />
                        <Button
                          className="mw400"
                          onClick={() => handleClickOpenFile("duvridi")}
                        >
                          Scegli File
                        </Button>
                        <label style={{ marginLeft: "10px" }}>
                          {labelFile.dudi
                            ? labelFile.dudi
                            : "Nessun file Selezionato"}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              <Divider style={{ marginTop: "30px", marginBottom: "60px" }} />
              <h1 className="card-label">Codice Etico</h1>
              <div className="grid-container repeat3v2 pl0 center">
                {templateStatus.codiceEtico && (
                  <Button onClick={() => getFile("codiceEtico")}>
                    Download Template
                  </Button>
                )}
                <div>
                  <input
                    type="file"
                    id="codiceEtico"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setCodiceEtico(e.target.files[0]);
                      setLabelFile({
                        ...labelFile,
                        codiceEtico: e.target.files[0].name,
                      });
                    }}
                  />
                  <Button
                    className="mw400"
                    onClick={() => handleClickOpenFile("codiceEtico")}
                  >
                    Scegli File
                  </Button>
                  <label style={{ marginLeft: "10px" }}>
                    {labelFile.codiceEtico
                      ? labelFile.codiceEtico
                      : "Nessun file Selezionato"}
                  </label>
                </div>
              </div>
              <div
                className="grid-container repeat2 pl0 center"
                style={{ marginBottom: "100px", marginTop: "30px" }}
              >
                <Button
                  color={"green"}
                  onClick={updateTemplateFile}
                  disabled={!possoModificare}
                >
                  Salva
                </Button>
                <Button color={"orange"} onClick={resetFile}>
                  Annulla
                </Button>
              </div>
            </>
          )}
        </>
      )}
      {activeTab == 2 && (
        <div>
          {cookie.get("status") == "ROLE_ADMINISTRATOR" && (
            <>
              <h2 className={"mt20"}>Gestisci gli altri Account</h2>
              <div className="inline-item center inlinegap20 mt20">
                <h2>Approvatori</h2>

                {vuoiAggiungere ? (
                  <FontAwesomeIcon
                    className="pointer"
                    icon={faTimesCircle}
                    color={"Green"}
                    size="lg"
                    onClick={() => setVuoiAggiungere(!vuoiAggiungere)}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="pointer"
                    icon={faPlusCircle}
                    color={"Green"}
                    size="lg"
                    onClick={() => setVuoiAggiungere(!vuoiAggiungere)}
                  />
                )}
              </div>
              {passDebole && (
                <Alert title="Errore!" color="red">
                  La password deve contenere almeno 8 caratteri di cui una
                  maiuscola, un carattere speciale e un numero
                </Alert>
              )}
              {approvatori.map((approvatore, count) => (
                <div className="grid-container repeat6 pl0 center">
                  <Input
                    key={"app1" + approvatore.username}
                    value={approvatore.username}
                    disabled={true}
                    type="text"
                    sx={{ maxWidth: "200px" }}
                    name="name"
                    size="lg"
                    radius="md"
                    placeholder="Username"
                    spellCheck="false"
                    autoComplete="off"
                    id="cardHolder23"
                    className="form-control "
                  />
                  <Input
                    key={"app2" + approvatore.username}
                    type="text"
                    name="name"
                    value={approvatore.password}
                    icon={
                      approvatore.pass && approvatore.pass == 1 ? (
                        <FontAwesomeIcon icon={faTimes} color="red" />
                      ) : approvatore.pass == 4 ? (
                        <FontAwesomeIcon icon={faCheck} color="green" />
                      ) : approvatore.pass == 3 ? (
                        <FontAwesomeIcon icon={faCheck} color="orange" />
                      ) : (
                        ""
                      )
                    }
                    onChange={(e) => handleChangePass(e, approvatore)}
                    disabled={!approvatore.status}
                    size="lg"
                    radius="md"
                    placeholder="Password"
                    spellCheck="false"
                    sx={{ maxWidth: "200px" }}
                    autoComplete="off"
                    id="cardHolder25"
                    className="form-control "
                  />
                  <Input
                    key={"app3" + approvatore.username}
                    type="text"
                    name="name"
                    onChange={(e) =>
                      handleChangeValue(
                        e.target.value,
                        approvatore,
                        "denominazione"
                      )
                    }
                    size="lg"
                    radius="md"
                    sx={{ maxWidth: "200px" }}
                    disabled={!approvatore.status}
                    value={approvatore.denominazione}
                    placeholder="Denominazione"
                    spellCheck="false"
                    autoComplete="off"
                    id="cardHolder32133"
                    className="form-control"
                  />
                  <Checkbox
                    checked={approvatore.modifica}
                    label={"Modifica abilitata?"}
                    onChange={(e) =>
                      handleChangeValue(
                        e.target.checked,
                        approvatore,
                        "modifica"
                      )
                    }
                  />
                  <div className="grid-container repeat2File pl0 center">
                    <Button
                      style={{ width: "100%" }}
                      color={"green"}
                      radius="md"
                      disabled={
                        approvatore.pass == 1 || approvatore.denominazione == ""
                      }
                      onClick={() => handleModificaApprovatori(approvatore)}
                    >
                      {approvatore.status ? "Salva" : "Modifica"}
                    </Button>
                    <Button
                      style={{ width: "100%" }}
                      color={"red"}
                      radius="md"
                      onClick={() => rimuoviApprovatore(approvatore)}
                    >
                      {approvatore.status ? "Annulla" : "Rimuovi"}
                    </Button>
                  </div>
                </div>
              ))}
              {vuoiAggiungere && (
                <>
                  <div className="grid-container repeat4 pl0 center">
                    <Input
                      key={"1"}
                      type="text"
                      name="name"
                      sx={{ maxWidth: "200px" }}
                      size="lg"
                      value={usernameApprovatore}
                      onChange={(e) => setUsernameApprovatore(e.target.value)}
                      placeholder="Username"
                      spellCheck="false"
                      autoComplete="off"
                      id="cardHolder432"
                      className="form-control "
                    />
                    <div>
                      <Input
                        type="text"
                        key={"2"}
                        value={passwordApprovatore}
                        onChange={(e) => setPasswordApprovatore(e.target.value)}
                        name="name"
                        icon={
                          errorPassApp == "Password Debole" ? (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                          ) : errorPassApp == "2" ? (
                            <FontAwesomeIcon icon={faCheck} color="green" />
                          ) : (
                            ""
                          )
                        }
                        size="lg"
                        sx={{ maxWidth: "200px" }}
                        radius={"md"}
                        placeholder="Password"
                        spellCheck="false"
                        autoComplete="off"
                        id="cardHolder123"
                        className="form-control "
                      />
                    </div>
                    <Input
                      type="text"
                      key={"3"}
                      value={denominazioneApprovatore}
                      onChange={(e) =>
                        setDenominazioneApprovatore(e.target.value)
                      }
                      name="name"
                      radius={"md"}
                      sx={{ maxWidth: "200px" }}
                      size="lg"
                      placeholder="Denominazione"
                      spellCheck="false"
                      autoComplete="off"
                      id="cardHolder0321"
                      className="form-control "
                    />
                    <Checkbox
                      checked={possoModificare}
                      label={"Modifica abilitata?"}
                      onChange={(e) => {
                        setPossoModificare(e.target.checked);
                      }}
                    />

                    <Button
                      color={"green"}
                      radius={"md"}
                      disabled={
                        errorPassApp == "Password Debole" ||
                        passwordApprovatore == "" ||
                        usernameApprovatore == "" ||
                        denominazioneApprovatore == ""
                      }
                      onClick={aggiungiApprovatore}
                    >
                      Aggiungi
                    </Button>
                  </div>
                  {errorPassApp == "Password Debole" && (
                    <div
                      style={{ maxWidth: "100% !important", marginTop: "10px" }}
                    >
                      <Alert
                        color={"gray"}
                        icon={
                          <FontAwesomeIcon icon={faInfoCircle} color="blue" />
                        }
                      >
                        La password deve contenere almeno 8 caratteri di cui una
                        maiuscola un carattere speciale e un numero,
                      </Alert>
                    </div>
                  )}
                </>
              )}
            </>
          )}
          {cookie.get("status") == "ROLE_ADMINISTRATOR" && (
            <Divider style={{ marginTop: "30px", marginBottom: "60px" }} />
          )}
          <h2>Cambia le impostazioni del tuo Account</h2>
          <h3>Modifica la tua password</h3>
          {errorPass && (
            <Alert title="Errore!" color="red">
              {errorPass}
            </Alert>
          )}
          <div className="grid-container repeat2File pl0 center">
            <PasswordInput
              size="lg"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
              placeholder="Password"
              radius="md"
              required
            />
            <PasswordInput
              size="lg"
              value={confermaPass}
              onChange={(e) => setConfermaPass(e.target.value)}
              placeholder="Conferma Password"
              radius="md"
              required
            />
          </div>

          <h3>Modifica la tua Denominazione</h3>
          <div className="grid-container repeat2File pl0 center">
            <Input
              type="text"
              value={denominazione}
              onChange={(e) => setDenominazione(e.target.value)}
              name="name"
              radius="md"
              size="lg"
              placeholder="Denominazione"
              spellCheck="false"
              autoComplete="off"
              id="cardHolder21"
              className="form-control "
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "50px",
            }}
          >
            <Button
              disabled={
                (pass == "" && confermaPass == "" && denominazione == "") ||
                pass != confermaPass ||
                errorPass != ""
              }
              radius="md"
              style={{ width: "50%", marginBottom: "150px" }}
              color={"green"}
              onClick={modificaDati}
            >
              Salva
            </Button>
          </div>
        </div>
      )}
      {activeTab == 3 && (
        <div>
          <PageGestione />
        </div>
      )}
      {activeTab == 4 && (
        <div>
          <Statistiche />
        </div>
      )}
      {activeTab == 5 && (
        <div>
          <InvioEmail />
        </div>
      )}
      {activeTab == 6 && (
        <div>
          <Azioni appalti={appalti} getForm={getForm} />
        </div>
      )}

      <Modal
        size={"lg"}
        opened={openInformazioniAggiuntive}
        onClose={() => {
          setTestoInformazioniAggiuntive([]);
          nuovoMessaggioRef.current.value = "";
          setOpenInformazioniAggiuntive(false);
        }}
        centered
        title={<h2>Inserisci messaggio da inviare</h2>}
      >
        <div>
          <div className="container_nuovo_messaggio">
            <div className="w100">
              <TextInput ref={nuovoMessaggioRef} />
            </div>
            <Button onClick={aggiungiNuovoMessaggio}>Aggiungi</Button>
          </div>
          <div className="container_testo_aggiungo_informazioni_aggiuntive">
            <ul>
              {testoInformazioniAggiuntive.map((testo) => (
                <li>
                  {testo}{" "}
                  <FontAwesomeIcon
                    icon={faTimes}
                    className={"pointer"}
                    onClick={() => rimuoviTesto(testo)}
                    color={"red"}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className="mt30 buttonConfermaContainer">
            <Button
              onClick={() => {
                setTestoInformazioniAggiuntive([]);
                nuovoMessaggioRef.current.value = "";
                setOpenInformazioniAggiuntive(false);
              }}
              color={"orange"}
              fullWidth
            >
              Annulla
            </Button>
            <Button
              disabled={testoInformazioniAggiuntive.length == 0}
              onClick={() =>
                inviaInformazioniAggiuntive(appaltoSelected.current)
              }
              color={"green"}
              fullWidth
            >
              Conferma
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
