import React from "react";
import { faArrowLeft, faArrowRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Table, TextInput, Card, Loader } from "@mantine/core";
import { useEffect } from "react";
export default function Tabella({
	getDati,
	setStringaDaCercare,
	stringaDaCercare,
	numeroPagina,
	hasPrevious,
	hasNext,
	rowHTML,
	ths,
	titolo,
	loading,
	status,
}) {
	return (
		<Card className={"shadow overflowx"}>
			<Card.Section className="border-0 card-header">
				<div className="align-items-center inline socialTraffic buttonDiversi">
					<h3 className="mb-0">{titolo}</h3>
					<div className="inline container_search">
						<TextInput
							onChange={(e) => {
								setStringaDaCercare(e.target.value);
							}}
							sx={{ minWidth: "300px", maxWidth: "600px" }}
							value={stringaDaCercare}
							placeholder={"Cerca..."}
							rightSection={
								<FontAwesomeIcon
									icon={faTimes}
									color={stringaDaCercare ? "red" : "gray"}
									className={stringaDaCercare ? "pointer" : ""}
									onClick={() => {
										setStringaDaCercare("");
									}}
								/>
							}
						/>
					</div>
				</div>
			</Card.Section>
			<div className="overflowX">
				<Table
					className="align-items-center table-flush  table"
					highlightOnHover
					verticalSpacing={"md"}
					captionSide="bottom">
					<caption>
						{loading ? (
							<div className="loaderTabella">
								<Loader />
							</div>
						) : (
							<div className="footerTabella">
								{status && (
									<>
										<Button
											radius={"50%"}
											sx={{ padding: "10px" }}
											disabled={!hasPrevious}
											onClick={() => getDati(numeroPagina - 1)}>
											<FontAwesomeIcon icon={faArrowLeft} />
										</Button>
										{numeroPagina + 1}
										<Button
											disabled={!hasNext}
											radius={"50%"}
											sx={{ padding: "10px" }}
											onClick={() => getDati(numeroPagina + 1)}>
											<FontAwesomeIcon icon={faArrowRight} />
										</Button>
									</>
								)}
							</div>
						)}
					</caption>
					<thead className="thead-light ">{ths}</thead>
					<tbody>{rowHTML}</tbody>
				</Table>
			</div>
		</Card>
	);
}
