import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Input, Button, PasswordInput, Loader, Alert } from "@mantine/core";
import { validateToken } from "./Auth";
import cookie from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";

export default function Registrazione({ setMostraNavbar }) {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [referente, setReferente] = useState("");
  const [pec, setPec] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingRegistrazione, setLoadingRegistrazione] = useState(false);
  const [verifiedEmail, setVerifiedEmail] = useState(true);
  const [verifiedPec, setVerifiedPec] = useState(true);
  const [passOk, setPassOk] = useState(false);
  let strongPassword = new RegExp(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
  );
  const navigate = useNavigate();
  const recaptchaRef = React.createRef();
  const [reCAPTCHA, setReCAPTCHA] = useState(false);

  useEffect(() => {
    setMostraNavbar(true);
  }, []);
  //Controllo se l`utente e` loggato
  useEffect(() => {
    if (cookie.get("token") != null)
      if (validateToken(cookie.get("token"))) navigate("/admin");
    setLoading(false);
  }, []);
  function emailChecker() {
    let valid = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    if (valid) {
      setVerifiedEmail(true);
    } else setVerifiedEmail(false);
  }
  function pecChecker() {
    let valid = String(pec)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    if (valid) {
      setVerifiedPec(true);
    } else setVerifiedPec(false);
  }
  useEffect(() => {
    if (email == "") {
      return;
    }
    const timeOutId = setTimeout(() => emailChecker(), 500);
    return () => clearTimeout(timeOutId);
  }, [email]);
  useEffect(() => {
    if (pec == "") {
      return;
    }
    const timeOutId = setTimeout(() => pecChecker(), 500);
    return () => clearTimeout(timeOutId);
  }, [pec]);
  function StrengthChecker() {
    let strengthBar = document.getElementById("signup-strength-bar");
    let lunghezzaBarra = 0;
    //Controllo la sicurezza della pass
    if (strongPassword.test(password)) {
      /**PASSWORD STRONG */
      setPassOk(true);
      lunghezzaBarra = 4;
      setError("");
    } else {
      /**PASSWORD DEBOLE */
      lunghezzaBarra = 1;
      setError("Password Debole");
      setPassOk(false);
    }
    strengthBar.innerHTML = "";
    for (let i = 0; i < lunghezzaBarra; i++) {
      let span = document.createElement("span");
      if (i == 0) span.classList.add("firstSpan");
      if (i == 3) span.classList.add("lastSpan");
      span.classList.add("signup-strength");
      strengthBar.appendChild(span);
    }

    let spanRef = document.getElementsByClassName("signup-strength");
    for (let i = 0; i < spanRef.length; i++) {
      switch (spanRef.length - 1) {
        case 0:
          spanRef[i].style.background = "#ff3e36";
          break;
        case 1:
          spanRef[i].style.background = "#ff691f";
          break;
        case 2:
          spanRef[i].style.background = "#ffda36";
          break;
        case 3:
          spanRef[i].style.background = "#0be881";
          break;
      }
    }
  }
  useEffect(() => {
    const timeOutId = setTimeout(() => StrengthChecker(), 100);
    return () => clearTimeout(timeOutId);
  }, [password]);
  //Controlla i campi del form
  useEffect(() => {
    if (
      nome != "" &&
      email != "" &&
      referente != "" &&
      pec != "" &&
      password != "" &&
      verifiedEmail == true &&
      verifiedPec == true &&
      passOk == true
    ) {
      setError(false);
      return;
    } else setError(true);
  }, [nome, email, referente, pec, password, verifiedEmail, verifiedPec]);
  //Funzione che effettua la registrazione di un utente
  async function registrati() {
    setLoadingRegistrazione(true);
    const url = "https://apot.mondorevive.com/auth/registerContractor";
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        nome: nome,
        username: email,
        indirizzo: referente,
        mailPec: pec,
        password: password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status == 200) {
      const data = await response.json();
      cookie.set("token", data.token);
      cookie.set("status", "ROLE_CONTRACTOR");
      navigate("/form");
      setLoadingRegistrazione(false);
    } else {
      setLoadingRegistrazione(false);
      setError("Email o Password errate");
    }
  }
  function handleSkip(e) {
    const { name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");
    let fieldIntIndex = parseInt(fieldIndex, 10);
    if (e.key === "Enter") {
      const nextfield = document.querySelector(
        `input[name=field-${fieldIntIndex + 1}]`
      );
      // If found, focus the next field
      if (nextfield !== null) {
        nextfield.focus();
      } else {
        if (error || loadingRegistrazione || !verifiedEmail || !verifiedPec)
          return;
        registrati();
      }
    }
  }
  //Funzione che verifica il recaptcha
  const handleVerify = (key) => {
    setReCAPTCHA(key);
  };

  return (
    <div className="log-container">
      {!loading && (
        <div className="log-box2 a">
          <div className="register-box min asd">
            <h1>Registra il tuo Account</h1>

            <form autoComplete="off">
              <Input
                placeholder="Nome Azienda"
                radius="lg"
                size="xl"
                name="field-1"
                onKeyUp={handleSkip}
                onChange={(e) => setNome(e.target.value)}
                className={"mb30"}
              />
              <Input
                placeholder="PEC Aziendale"
                radius="lg"
                size="xl"
                invalid={!verifiedPec}
                autoComplete="off"
                name="field-2"
                onKeyUp={handleSkip}
                onChange={(e) => setPec(e.target.value)}
                className={"mb30"}
              />
              <Input
                autoComplete="off"
                placeholder="Indirizzo Azienda"
                radius="lg"
                name="field-3"
                onKeyUp={handleSkip}
                size="xl"
                onChange={(e) => setReferente(e.target.value)}
                className={"mb30"}
              />
              <Input
                placeholder="E-Mail (Username)"
                radius="lg"
                size="xl"
                name="field-4"
                onKeyUp={handleSkip}
                onChange={(e) => setEmail(e.target.value)}
                className={"mb30"}
              />
              <PasswordInput
                size="xl"
                name="field-5"
                onKeyUp={handleSkip}
                className={"provass"}
                placeholder="Password"
                radius="lg"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
              <div id="signup-strength-bar"></div>
              {error && (
                <div style={{ maxWidth: "600px" }}>
                  <Alert
                    color={"gray"}
                    icon={<FontAwesomeIcon icon={faInfoCircle} color="blue" />}
                  >
                    La password deve contenere almeno 8 caratteri di cui almeno
                    una lettera maiuscola un carattere speciale e un numero.
                  </Alert>
                </div>
              )}

              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LcjjA8gAAAAAHpqdzMdgVtXB1aYCsQn336fmWYF"
                onChange={handleVerify}
              />
            </form>
            <Button
              disabled={
                error ||
                loadingRegistrazione ||
                !verifiedEmail ||
                !verifiedPec ||
                !reCAPTCHA
              }
              radius="md"
              size="lg"
              style={{
                marginBottom: "20px",
                marginTop: "20px",
                minHeight: "50px !important",
              }}
              onClick={registrati}
            >
              {loadingRegistrazione ? (
                <Loader color="red" variant="bars" />
              ) : (
                "Registrati"
              )}
            </Button>
            <Link
              style={{ display: "block" }}
              to={{
                pathname: "/login",
              }}
            >
              Hai un Account?
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
