import cookie from "js-cookie";
export async function validateToken() {
  const url = "https://apot.mondorevive.com/auth/tokenStatus";
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookie.get("token"),
    },
  });
  if (response.status == 200) {
    const data = await response.text();
    if (data) cookie.set("status", data);
    return true;
  } else {
    cookie.remove("token");
    cookie.remove("status");
    return false;
  }
}
