import React, { useState, useEffect, useRef } from "react";
import "../App.css";
import cookie from "js-cookie";
import {
  Checkbox,
  Select,
  MultiSelect,
  Button,
  Divider,
  Input,
  Modal,
  Loader,
  Textarea,
  Table,
  ColorSwatch,
  TextInput,
  Accordion,
} from "@mantine/core";
import {
  getPossoModificare,
  isScadutaDichiarazione445,
  isScaduto,
} from "./Utilities";
import { useLocation, useNavigate } from "react-router-dom";
import { DatePicker } from "@mantine/dates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleRight,
  faArrowLeftRotate,
  faCheck,
  faExclamationCircle,
  faInfo,
  faInfoCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import "dayjs/locale/it";
import { errorNotification, successNotification } from "./Notification";
import { ToastContainer, Flip, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import DipendentiList from "./Aggiuntivi/DipendentiList";
import dayjs from "dayjs";
export default function VerificaAppalto({ setMostraNavbar }) {
  const { id } = useParams();
  const [idComune, setIdComune] = useState(false);
  const [pec, setPec] = useState("");
  const [nome, setNome] = useState("");
  const [indirizzo, setIndirizzo] = useState("");
  const [username, setUsername] = useState("");
  const [stato, setStato] = useState("");
  const [modificaAbilitata, setModificaAbilitata] = useState(
    getPossoModificare()
  );
  const [nomeStabilimento, setNomeStabilimento] = useState("");
  //REFERENTE AZIENDA
  const [email, setEmail] = useState("");
  const [allEmail, setAllEmail] = useState([]);
  const [telefono, setTelefono] = useState("");
  const nomeReferenteAzienda = useRef("");
  const [responsabili, setResponsabili] = useState({
    rspp: "",
    rls: "",
    ddl: "",
    mc: "",
  });
  const [rspp, setRspp] = useState("");
  const [ddl, setDdl] = useState("");
  const [mc, setMc] = useState("");
  const [nomePreposto, setNomePreposto] = useState("");
  const [preposti, setPreposti] = useState([]);
  const [rlsAll, setRlsAll] = useState([]);
  const [rls, setRls] = useState({ denominazione: "", dataNominaRls: null });
  const [dataNomina, setDataNomina] = useState({
    rspp: "",
    rls: "",
    ddl: "",
    mc: "",
  });
  const [checkedCertificazioni, setCheckedCertificazioni] = useState({
    iso9001: false,
    iso14001: false,
    iso45001: false,
    sa8000: false,
  });
  const [fileCertificazioni, setFileCertificazioni] = useState({
    iso9001: null,
    iso14001: null,
    iso45001: null,
    sa8000: null,
  });
  const [referente, setReferente] = useState("");
  //CAMERA DI COMMERCIO
  const [codice, setCodice] = useState("");
  const [visura, setVisura] = useState("");
  const [dataDvr, setDataDvr] = useState("");
  const [dataDichiarazione445, setDataDichiarazione445] = useState("");
  const [fileCamera, setFileCamera] = useState({
    iscrizione: null,
    dvr: null,
    doc445: null,
  });
  const [FileLavoratori, setFileLavoratori] = useState({
    attLav: null,
    poliza: null,
  });
  const [fileDurc, setFileDurc] = useState(null);
  const [checkedFile, setCheckedFile] = useState({});
  //STABILIMENTI
  const stabilimenti = [
    { value: "Mondorevive Busto Arsizio", label: "Mondorevive Busto Arsizio" },
    { value: "Mondorevive Ferentino", label: "Mondorevive Ferentino" },
    { value: "Caltek Chignolo Po", label: "Caltek Chignolo Po" },
    { value: "MondoSD Bernate Ticino", label: "MondoSD Bernate Ticino" },
    { value: "Mondorevive Marnate", label: "Mondorevive Marnate" },
  ];
  const [stabilimentiSelezionati, setStabilimentiSelezionati] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  //dipendenti
  const [appalto, setAppalto] = useState("");
  const [azienda, setAzienda] = useState("");
  const [dpi, setDpi] = useState([]);
  const [attestati, setAttestati] = useState([]);
  const [dpiUnchanged, setDpiUnchanged] = useState();
  const [attestatiUnchanged, setAttestatiUnchanged] = useState();
  const [fileAttestati, setFileAttestati] = useState([]);
  const [dipendenti, setDipendenti] = useState([]);
  const [dataScadenzaRTC, setDataScadenzaRTC] = useState("");
  const [dataDurc, setDataDurc] = useState("");
  const [note, setNote] = useState("");

  const [dataRspp, setDataRspp] = useState(null);
  const [dataDdl, setDataDdl] = useState(null);
  const [dataMc, setDataMc] = useState(null);
  const [labelFile, setLabelFile] = useState({
    iso9001: null,
    iso14001: null,
    iso45001: null,
    sa8000: null,
    camera: null,
    dvr: null,
    dichiarazione445: null,
    dipendenti: null,
    poliza: null,
    durc: null,
    m7ba: null,
    m7fe: null,
    m7cp: null,
    m7bt: null,
    m7ma: null,
    m5ba: null,
    m5fe: null,
    m5cp: null,
    m5bt: null,
    m5ma: null,
    duba: null,
    dufe: null,
    ducp: null,
    dubt: null,
    duma: null,
  });
  const [storicoAppalti, setStoricoAppalti] = useState([]);
  const [openInformazioniAggiuntive, setOpenInformazioniAggiuntive] =
    useState(false);
  const [testoInformazioniAggiuntive, setTestoInformazioniAggiuntive] =
    useState([]);
  const appaltoSelected = useRef();
  const [datiGenerali, setDatiGenerali] = useState({});
  const descrizioneDati = useRef();
  const [richiesteAggiuntive, setRichiesteAggiuntive] = useState([]);
  const nuovoMessaggioRef = useRef();
  //TODO GESTIONE ID SE SUBAPPALTO O NO?
  const [subappalti, setSubappalti] = useState([]);
  const { state } = useLocation();
  const idAppaltoPadre =
    state !== null && state !== undefined ? state.idAppaltoPadre : undefined;

  const changePage = (page, obj = {}) => {
    navigate(page, obj);
  };

  useEffect(() => {
    setMostraNavbar(true);
  }, []);
  useEffect(() => {
    getDPI();
    getAttestati();
    getInfoAppalto();
  }, []);
  const getInfoAppalto = async (ids = null) => {
    let url;
    if (ids != null) url = "https://apot.mondorevive.com/api/appalto?id=" + ids;
    else url = "https://apot.mondorevive.com/api/appalto?id=" + id;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      //dire ok
      const data = await response.json();
      setLoading(false);
      setDatiGenerali({
        dataInizio: new Date(data.datiComuni.dataInizio),
        dataFine: new Date(data.datiComuni.dataFine),
        idAppaltatore: data.datiComuni.idAppaltatore,
      });
      setRichiesteAggiuntive(data.storicoInformazioniAggiuntive);
      setIdComune(data.datiComuni.idAppaltoComune);
      setTelefono(data.datiComuni.cellulare);
      setCheckedCertificazioni({
        iso14001: data.datiComuni.certIso14001,
        iso9001: data.datiComuni.certIso9001,
        iso45001: data.datiComuni.certIso45001,
        sa8000: data.datiComuni.certificazioneSa8000,
      });
      setAllEmail(data.datiComuni.mailList);
      setFileAttestati(data.datiComuni.fileAggiuntiviList);
      nomeReferenteAzienda.current.value = data.datiComuni.referenteAzienda;
      descrizioneDati.current.value = data.datiComuni.descrizione;
      // //Capire come mai non funziona con solo 3 cosi
      // setTimeout(() => setResponsabili({ ...responsabili, mc: data.nomeMc }), 1000);
      // setTimeout(() => setResponsabili({ ...responsabili, ddl: data.nomeDdl }), 1000);
      // setTimeout(() => setResponsabili({ ...responsabili, rspp: data.nomeRspp }), 1000);
      setRspp(data.datiComuni.nomeRspp);
      setDdl(data.datiComuni.nomeDdl);
      setMc(data.datiComuni.nomeMc);
      setStoricoAppalti(data.storicoAppalti);
      let ass = data.datiAppaltiSpecifici[0].stato.substring(
        2,
        data.datiAppaltiSpecifici[0].stato.length
      );
      setStato(ass.replace("_", " "));
      setNomeStabilimento(data.datiAppaltiSpecifici[0].nomeStabilimento);
      setNome(data.datiComuni.nomeAzienda);
      setPec(data.datiComuni.mailPec);
      setIndirizzo(data.datiComuni.indirizzo);
      setUsername(data.datiComuni.username);

      let a = [];
      data.datiComuni.rlsAppaltoList.map((rl) => {
        a.push({
          denominazione: rl.denominazione,
          dataNominaRls: new Date(rl.dataNominaRls.split("T")[0]),
        });
      });
      setRlsAll(a);
      if (data.datiComuni.nominaDdl) {
        setDataDdl(new Date(data.datiComuni.nominaDdl.split("T")[0]));
      }
      if (data.datiComuni.nominaMc)
        setDataMc(new Date(data.datiComuni.nominaMc.split("T")[0]));

      if (data.datiComuni.nominaRspp)
        setDataRspp(new Date(data.datiComuni.nominaRspp.split("T")[0]));
      setPreposti(data.datiComuni.prepostoCantiereList);
      setReferente(data.datiComuni.referenteMondorevive);
      setAppalto(data.datiComuni.tipoAppalto);
      setAzienda(data.datiComuni.tipologiaSocieta);
      setStabilimentiSelezionati([
        data.datiAppaltiSpecifici[0].nomeStabilimento,
      ]);
      setCodice(data.datiComuni.codiceCameraCommercio);
      if (data.datiComuni.dataVisuraCamerale)
        setVisura(new Date(data.datiComuni.dataVisuraCamerale.split("T")[0]));
      if (data.datiComuni.dataCertaDvr)
        setDataDvr(new Date(data.datiComuni.dataCertaDvr.split("T")[0]));
      if (data.datiComuni.dataDichiarazione445)
        setDataDichiarazione445(
          new Date(data.datiComuni.dataDichiarazione445.split("T")[0])
        );
      let arr3 = [];
      data.datiComuni.lavoratoreList.map((dipendente) => {
        let arr2 = [];

        dipendente.attestatiList.map((attestato) => {
          arr2.push({
            ...attestato,
            downlaodPresente: attestato.filename ? true : false,
          });
        });

        arr3.push({
          ...dipendente,
          downloadIdoneita: dipendente.filenameIdoneitaSanitaria ? true : false,
          downloadUnilav: dipendente.filenameUnilav ? true : false,
          attestatiList: arr2,
        });
      });

      setDipendenti(arr3);

      if (data.datiComuni.dataPolizzaAssicurativa)
        setDataScadenzaRTC(
          new Date(data.datiComuni.dataPolizzaAssicurativa.split("T")[0])
        );
      if (data.datiComuni.dataDurc)
        setDataDurc(new Date(data.datiComuni.dataDurc.split("T")[0]));
      setNote(data.datiComuni.note);
      setCheckedFile({
        dichiarazione445: data.datiComuni.dichiarazione445,
        documentoDvr: data.datiComuni.documentoDvr,
        durc: data.datiComuni.durc,
        duvri: data.datiAppaltiSpecifici[0].duvri,
        infoRischiReg: data.datiAppaltiSpecifici[0].infoRischiReg,
        iscrizioneCameraCommercio: data.datiComuni.iscrizioneCameraCommercio,
        modulo7: data.datiAppaltiSpecifici[0].modulo7,
        polizzaAssicurativaRct: data.datiComuni.polizzaAssicurativaRct,
      });
      console.log("sub", JSON.parse(JSON.stringify(data.datiAppaltiSpecifici)));
      setSubappalti(data.datiAppaltiSpecifici[0].subappalti);
    } else {
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  async function accettaAppalto(appalto) {
    const url = "https://apot.mondorevive.com/api/updateStatoAppalto";
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        id: id,
        stato: "APPROVATO",
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      //dire ok
      successNotification("Appalto accettato correttamente");
      setTimeout(() => navigate("/admin"), 500);
    } else {
      //DARE ERROR
      errorNotification("Impossibile confermare l`appalto");
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  }
  //Funzione che permette di Rifiutare un appalto
  async function rifiutaAppalto() {
    const url = "https://apot.mondorevive.com/api/updateStatoAppalto";
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        id: id,
        stato: "NON_APPROVATO",
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      //dire ok
      navigate("/admin");
    } else {
      //DARE ERROR
      errorNotification("Impossibile rifiutare l`appalto");
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  }
  //Funzione che ottiene i DPI dal db
  const getDPI = async () => {
    const url = "https://apot.mondorevive.com/api/dpi";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      //dire ok
      const data = await response.json();
      let arr = [];
      data.map((dpi) => {
        arr.push({ label: dpi.nome, status: false });
      });
      setDpi(arr);
      setDpiUnchanged(arr);
    } else {
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  //Funzione che ottiene gli attestati dal db
  const getAttestati = async () => {
    const url = "https://apot.mondorevive.com/api/attestati";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      const data = await response.json();
      let arr = [];
      data.map((attestato) => {
        arr.push({ label: attestato.nome, status: false });
      });
      setAttestati(arr);
      setAttestatiUnchanged(arr);
    } else {
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  const getFile = async (nomeFile, idA = null) => {
    const url =
      "https://apot.mondorevive.com/api/downloadAllegatoAppalto?campo=" +
      nomeFile;
    let a;
    let idComune;
    await setIdComune((idC) => {
      idComune = idC;
      return idC;
    });
    if (idA != null)
      a = JSON.stringify({
        id: id,
        idAppaltoComune: idComune,
        campo: nomeFile,
        idFile: idA,
      });
    else
      a = JSON.stringify({
        id: id,
        idAppaltoComune: idComune,
        campo: nomeFile,
      });
    const response = await toast.promise(
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + cookie.get("token"),
        },
        body: a,
        responseType: "blob",
      }),
      {
        pending: "Download in corso",
        success: {
          render() {
            return `File scaricato correttamente`;
          },
          autoClose: 1000,
        },
        error: {
          render() {
            return `Errore nel download del file`;
          },
          autoClose: 1000,
        },
      }
    );
    if (response.status == 200) {
      const data = await response.arrayBuffer();
      const filename = response.headers
        .get("content-disposition")
        .split("filename=")[1];
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename.substring(1, filename.length - 1));
      document.body.appendChild(link);
      link.click();
    } else {
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  const fromAmericaToItalia = (data) => {
    let italia = data.split("-");
    return italia[2] + "-" + italia[1] + "-" + italia[0];
  };

  //Funzione che permette di inviare una mail con delle richieste aggiuntive
  const inviaInformazioniAggiuntive = async () => {
    const url = "https://apot.mondorevive.com/api/richiestaInformazioni";
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        idAppaltoStabilimento: id,
        textList: testoInformazioniAggiuntive,
      }),
      headers: {
        Authorization: "Bearer " + cookie.get("token"),
        "Content-Type": "application/json",
      },
    });
    if (response.status == 200) {
      //dire ok
      successNotification("E-Mail inviata correttamente");
      setTestoInformazioniAggiuntive([]);
      nuovoMessaggioRef.current.value = "";
      setOpenInformazioniAggiuntive(false);
    } else {
      //DARE ERROR
      errorNotification("Impossibile inviare E-Mail");
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  //Funzione che aggiunge un nuovo messaggio alla lista dei messaggi da aggiungere
  const aggiungiNuovoMessaggio = () => {
    setTestoInformazioniAggiuntive([
      ...testoInformazioniAggiuntive,
      nuovoMessaggioRef.current.value,
    ]);
    nuovoMessaggioRef.current.value = "";
  };
  //Funzione che rimuove un testo delle informazioni aggiuntive
  const rimuoviTesto = (testoDaRim) => {
    setTestoInformazioniAggiuntive((testoInformazioniAggiuntive) => {
      let arr = [];
      testoInformazioniAggiuntive.map((testo) => {
        if (testo != testoDaRim) arr.push(testo);
      });
      return arr;
    });
  };

  //Torna all'appalto precedente oppure alla pagina dashboard
  const tornaIndietro = () => {
    if (!idAppaltoPadre) {
      navigate("/admin");
      return;
    }
    getInfoAppalto(idAppaltoPadre);
    changePage("/form/" + idAppaltoPadre);
  };
  return (
    <div className="form-container">
      <ToastContainer
        transition={Flip}
        newestOnTop
        rtl={false}
        pauseOnFocusLoss
      />
      {loading ? (
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Loader size={100} variant="dots" />
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
            className="center"
          >
            <div
              style={{ display: "flex", flexDirection: "row" }}
              className="center"
            >
              <h1>
                {idAppaltoPadre === undefined
                  ? "Verifica appalto di " + nomeStabilimento
                  : "Verifica subappalto di " + nome}
              </h1>
              <h2
                style={{
                  marginLeft: "20px",
                  color:
                    stato == "IN VERIFICA"
                      ? "gray"
                      : stato == "APPROVATO"
                      ? "green"
                      : "red",
                }}
              >
                {stato}
              </h2>
            </div>
            <div className="container_button_indietro_verifica">
              <Button
                sx={{ maxWidth: "200px" }}
                radius="md"
                color={"orange"}
                size="md"
                compact
                variant="outline"
                onClick={tornaIndietro}
              >
                Indietro
              </Button>
              {idAppaltoPadre === undefined ? (
                <Button
                  sx={{ maxWidth: "200px" }}
                  radius="md"
                  size="md"
                  color={"blue"}
                  compact
                  onClick={() =>
                    navigate("/verifiche", {
                      state: { id: datiGenerali.idAppaltatore },
                    })
                  }
                >
                  Verifiche
                </Button>
              ) : null}
            </div>
          </div>
          <form className="payment-form">
            <h2 className="card-label mt20">Dati Generali</h2>
            <div className="container_dati_generali mb20">
              <div className="container_date_dati_generali mt20">
                <div className="form-group maxW300 mb0">
                  <DatePicker
                    value={datiGenerali.dataInizio}
                    invalid={
                      datiGenerali.dataInizio == "" ||
                      datiGenerali.dataInizio == null
                    }
                    locale="it"
                    onChange={(e) => {
                      setDatiGenerali({ ...datiGenerali, dataInizio: e });
                    }}
                    placeholder={"Data Inizio presunta"}
                    label={"Data Inizio presunta"}
                    size="lg"
                    required
                    disabled
                    sx={{ maxWidth: "300px" }}
                  />
                </div>
                <div className="maxW300 mb0">
                  <DatePicker
                    value={datiGenerali.dataFine}
                    invalid={
                      datiGenerali.dataFine == "" ||
                      datiGenerali.dataFine == null
                    }
                    locale="it"
                    required
                    disabled
                    onChange={(e) => {
                      setDatiGenerali({ ...datiGenerali, dataFine: e });
                    }}
                    placeholder={"Data Fine presunta"}
                    label={"Data Fine presunta"}
                    size="lg"
                    sx={{ maxWidth: "300px" }}
                  />
                </div>
              </div>
              <div className="container_descrizione_dati_generali mt20">
                <Textarea
                  ref={descrizioneDati}
                  placeholder="Descrizione Intervento"
                  label="Descrizione Intervento"
                  radius="md"
                  size="md"
                  disabled
                  required
                  error={
                    descrizioneDati.current &&
                    descrizioneDati.current.value == ""
                  }
                />
              </div>
            </div>
            <Accordion multiple defaultValue={["3", "4", "5", "6", "7", "8"]}>
              <Accordion.Item key="1" value="1">
                <Accordion.Control>
                  <h2> Dati Azienda</h2>
                </Accordion.Control>
                <Accordion.Panel>
                  <div className="grid-container center repeat2 pl0">
                    <div className="form-group">
                      <label className="card-label">Nome Azienda</label>
                      <Input
                        type="text"
                        name="name"
                        value={nome}
                        size="lg"
                        placeholder="Nessun Valore Impostato"
                        spellCheck="false"
                        disabled
                        autoComplete="off"
                        id="cardHolder"
                        className="form-control form-control-lg"
                      />
                    </div>
                    <div className="form-group">
                      <label className="card-label">Indirizzo</label>
                      <Input
                        type="text"
                        name="name"
                        value={indirizzo}
                        size="lg"
                        placeholder="Nessun Valore Impostato"
                        spellCheck="false"
                        disabled
                        autoComplete="off"
                        id="cardHolder"
                        className="form-control form-control-lg"
                      />
                    </div>
                  </div>
                  <div className="grid-container center repeat2 pl0">
                    <div className="form-group">
                      <label className="card-label">Username</label>
                      <Input
                        type="text"
                        name="name"
                        value={username}
                        size="lg"
                        placeholder="Nessun Valore Impostato"
                        spellCheck="false"
                        disabled
                        autoComplete="off"
                        id="cardHolder"
                        className="form-control form-control-lg"
                      />
                    </div>
                    <div className="form-group">
                      <label className="card-label">PEC</label>
                      <Input
                        type="text"
                        name="name"
                        value={pec}
                        size="lg"
                        placeholder="Nessun Valore Impostato"
                        spellCheck="false"
                        disabled
                        autoComplete="off"
                        id="cardHolder"
                        className="form-control form-control-lg"
                      />
                    </div>
                  </div>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item key="2" value="2">
                <Accordion.Control>
                  <h2> Dati Richiesta</h2>
                </Accordion.Control>
                <Accordion.Panel>
                  <div className="grid-container center repeat2 pl0">
                    <div className="form-group">
                      <label className="card-label">E-Mail Aggiuntive </label>
                      {allEmail && allEmail.length == 0 && (
                        <h4 style={{ color: "red" }}>
                          Nessuna E-mail Aggiuntiva
                        </h4>
                      )}
                      {allEmail &&
                        allEmail.map((emails, count) => (
                          <div className="inline-item center" key={count}>
                            <Input
                              key={count}
                              type="text"
                              name="name"
                              size="lg"
                              disabled
                              value={emails}
                              placeholder="Nome e Cognome"
                              spellCheck="false"
                              autoComplete="off"
                              id="cardHolder"
                              style={{ marginBottom: "20px" }}
                              className="form-control form-control-lg"
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="grid-container center repeat2 pl0">
                    <div className="form-group">
                      <label className="card-label">Numero di Telefono</label>
                      <Input
                        type="text"
                        name="name"
                        size="lg"
                        invalid={telefono == ""}
                        value={telefono}
                        spellCheck="false"
                        placeholder="Nessun numero impostato"
                        disabled
                        autoComplete="off"
                        id="cardHolder"
                        className="form-control"
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="form-group">
                      <label className="card-label">Referente Azienda</label>
                      <Input
                        type="text"
                        ref={nomeReferenteAzienda}
                        name="name"
                        size="lg"
                        spellCheck="false"
                        invalid={nomeReferenteAzienda.current.value == ""}
                        disabled
                        placeholder="Nessun referente impostato"
                        autoComplete="off"
                        id="cardHolder"
                        className="form-control form-control-lg"
                      />
                    </div>
                  </div>
                  {/* RESPONSABILI */}
                  <h2 className="card-label">Responsabili</h2>
                  {/* RSPP */}
                  <div className="grid-container center repeat2 pl0">
                    <div className="form-group">
                      <label className="card-label">Cognome e Nome RSPP</label>
                      <Input
                        type="text"
                        name="name"
                        size="lg"
                        invalid={rspp == ""}
                        placeholder="Nessun Valore Impostato"
                        value={rspp}
                        spellCheck="false"
                        disabled
                        autoComplete="off"
                        id="cardHolder"
                        className="form-control form-control-lg"
                      />
                    </div>

                    <div className="form-group">
                      <label className="card-label">Data Nomina</label>

                      <DatePicker
                        value={dataRspp}
                        invalid={dataRspp == "" || dataRspp == null}
                        locale="it"
                        disabled
                        placeholder={"Seleziona una data"}
                        size="lg"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  {/* RLS */}
                  <label
                    className="card-label"
                    style={{
                      color: rlsAll.length == 0 ? "red" : "rgb(100, 100, 100)",
                      fontWeight: "500",
                    }}
                  >
                    Cognome e Nome RLS
                  </label>
                  {rlsAll.length == 0 ? (
                    <div
                      style={{
                        marginBottom: "20px",
                        marginTop: "10px",
                        color: "red",
                      }}
                    >
                      Nessun Valore impostato
                    </div>
                  ) : (
                    <>
                      <div>
                        {rlsAll.map((rlss, count) => (
                          <div
                            className="grid-container center repeat2 pl0"
                            key={count}
                          >
                            <Input
                              type="text"
                              name="name"
                              size="lg"
                              disabled
                              value={rlss.denominazione}
                              placeholder="Denominazione"
                              spellCheck="false"
                              autoComplete="off"
                              id="cardHolder"
                              className="form-control form-control-lg"
                            />

                            <DatePicker
                              value={rlss.dataNominaRls}
                              locale="it"
                              disabled={true}
                              placeholder={"Seleziona una data"}
                              size="lg"
                              style={{ width: "100%" }}
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {/* DDL */}
                  <div className="grid-container center repeat2 pl0">
                    <div className="form-group">
                      <label className="card-label">Cognome e Nome Ddl</label>

                      <Input
                        type="text"
                        value={ddl}
                        invalid={ddl == ""}
                        name="name"
                        disabled
                        placeholder="Nessun Valore Impostato"
                        style={{ color: "black !important" }}
                        size="lg"
                        spellCheck="false"
                        autoComplete="off"
                        id="cardHolder"
                        className="form-control form-control-lg"
                      />
                    </div>

                    <div className="form-group">
                      <label className="card-label">Data Incarico</label>

                      <DatePicker
                        value={dataDdl}
                        invalid={dataDdl == "" || dataDdl == null}
                        locale="it"
                        disabled
                        placeholder={"Seleziona una data"}
                        size="lg"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  {/* MC */}
                  <div className="grid-container center repeat2 pl0">
                    <div className="form-group">
                      <label className="card-label">Cognome e Nome MC</label>

                      <Input
                        type="text"
                        name="name"
                        value={mc}
                        disabled
                        invalid={mc == ""}
                        placeholder="Nessun Valore Impostato"
                        size="lg"
                        spellCheck="false"
                        autoComplete="off"
                        id="cardHolder"
                        className="form-control form-control-lg"
                      />
                    </div>

                    <div className="form-group">
                      <label className="card-label">Data Nomina</label>

                      <DatePicker
                        value={dataMc}
                        disabled
                        invalid={dataMc == "" || dataMc == null}
                        locale="it"
                        placeholder={"Seleziona una data"}
                        size="lg"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  {/* PREPOSTI DI CANTIERE*/}
                  <div className="grid-container center repeat2 pl0">
                    <div className="form-group">
                      <label className="card-label">Preposti di cantiere</label>
                      {preposti.length == 0 && (
                        <h4 style={{ color: "red" }}>
                          Nessun Preposto inserito
                        </h4>
                      )}
                      {preposti &&
                        preposti.map((preposto, count) => (
                          <div className="inline-item center" key={count}>
                            <Input
                              key={count}
                              type="text"
                              name="name"
                              size="lg"
                              disabled
                              value={preposto}
                              spellCheck="false"
                              autoComplete="off"
                              id="cardHolder"
                              style={{ marginBottom: "20px" }}
                              className="form-control form-control-lg"
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                  <Divider
                    style={{ marginTop: "30px", marginBottom: "30px" }}
                  />
                  {/*CERTIFICAZIONI*/}
                  <div className="form-group">
                    <h2 className="card-label">Certificazioni</h2>
                    <div className="checkbox">
                      <div className="inline-item mb10">
                        <Checkbox
                          size="lg"
                          label=" ISO9001"
                          checked={checkedCertificazioni.iso9001}
                          disabled
                        />

                        {checkedCertificazioni.iso9001 && (
                          <div className={"center"}>
                            <Button
                              className="mw400"
                              onClick={() => getFile("certiso9001")}
                            >
                              Download
                            </Button>
                          </div>
                        )}
                      </div>
                      <div className="inline-item mb10">
                        <Checkbox
                          size="lg"
                          label=" ISO14001"
                          checked={checkedCertificazioni.iso14001}
                          disabled
                        />
                        {checkedCertificazioni.iso14001 && (
                          <div className={"center"}>
                            <Button
                              className="mw400"
                              onClick={() => getFile("certiso14001")}
                            >
                              Download
                            </Button>
                          </div>
                        )}
                      </div>
                      <div className="inline-item mb10">
                        <Checkbox
                          size="lg"
                          label=" ISO45001"
                          disabled
                          checked={checkedCertificazioni.iso45001}
                        />
                        {checkedCertificazioni.iso45001 && (
                          <div className={"center"}>
                            <Button
                              className="mw400"
                              onClick={() => getFile("certiso45001")}
                            >
                              Download
                            </Button>
                          </div>
                        )}
                      </div>
                      <div className="inline-item mb10">
                        <Checkbox
                          size="lg"
                          label=" SA8000"
                          disabled
                          checked={checkedCertificazioni.sa8000}
                        />
                        {checkedCertificazioni.sa8000 && (
                          <div className={"center"}>
                            <Button
                              className="mw400"
                              onClick={() => getFile("certsa8000")}
                            >
                              Downlaod
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <Divider
                    style={{ marginTop: "30px", marginBottom: "30px" }}
                  />
                  <div className="grid-container repeat2 pl0">
                    <div>
                      <label className="card-label">Referente Interno</label>
                      <Input
                        type="text"
                        name="name"
                        value={referente}
                        invalid={referente == ""}
                        placeholder="Nessun Valore Impostato"
                        disabled
                        size="lg"
                        spellCheck="false"
                        autoComplete="off"
                        id="cardHolder"
                        style={{ width: "100%" }}
                        className="form-control form-control-lg"
                      />
                    </div>
                  </div>
                  <div className="grid-container repeat2 pl0">
                    <div className="form-group">
                      <label className="card-label">Appalto/Subappalto</label>
                      <Select
                        placeholder="Nessun valore selezionato"
                        value={appalto}
                        error={appalto == ""}
                        disabled
                        size="lg"
                        data={[
                          { value: "appalto", label: "Appalto" },
                          { value: "subappalto", label: "Subappalto" },
                        ]}
                      />
                    </div>
                    <div className="form-group">
                      <label className="card-label">Azienda/Autonomo</label>
                      <Select
                        size="lg"
                        value={azienda}
                        error={azienda == ""}
                        disabled
                        placeholder="Nessun valore selezionato"
                        data={[
                          { value: "azienda", label: "Azienda" },
                          { value: "Autonomo", label: "Autonomo" },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="grid-container repeat2 pl0">
                    <div>
                      <label className="card-label">
                        Seleziona lo stabilimento *
                      </label>
                      <MultiSelect
                        style={{ width: "100%" }}
                        searchable
                        error={stabilimentiSelezionati.length == 0}
                        value={stabilimentiSelezionati}
                        disabled
                        size="lg"
                        data={stabilimenti}
                        placeholder="Nessun valore selezionato"
                      />
                    </div>
                  </div>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item key="3" value="3">
                <Accordion.Control>
                  <h2>Camera Di Commercio</h2>
                </Accordion.Control>
                <Accordion.Panel>
                  <div className="grid-container repeat2 pl0">
                    <div className="form-group">
                      <label className="card-label">Codice Alfanumerico</label>
                      <div className="inline-item">
                        <Input
                          size="lg"
                          invalid={codice == ""}
                          value={codice}
                          type="text"
                          placeholder="Nessun Valore Impostato"
                          disabled
                          name="name"
                          spellCheck="false"
                          autoComplete="off"
                          id="cardHolder"
                          className="form-control form-control-lg"
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="card-label">
                        Data di visura camerale
                      </label>

                      <DatePicker
                        size="lg"
                        invalid={visura == ""}
                        locale="it"
                        disabled
                        value={visura}
                        placeholder={"Nessuna data selezionata"}
                      />
                    </div>
                  </div>
                  <div className="grid-container repeat2File pl0 center">
                    {checkedFile.iscrizioneCameraCommercio ? (
                      <Button
                        className="mw400"
                        onClick={() => getFile("iscrizionecameracommercio")}
                      >
                        Download File
                      </Button>
                    ) : (
                      <p style={{ color: "red" }}>Nessun file selezionato</p>
                    )}
                  </div>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item key="4" value="4">
                <Accordion.Control>
                  <h2>DVR</h2>
                </Accordion.Control>
                <Accordion.Panel>
                  <div className="form-group">
                    <label className="card-label">Data certa DVR</label>
                    <div className="grid-container repeat2File pl0 center">
                      <DatePicker
                        size="lg"
                        locale="it"
                        invalid={dataDvr == ""}
                        disabled
                        value={dataDvr}
                        onChange={(e) => {
                          setDataDvr(e);
                        }}
                        placeholder={"Nessuna data selezionata"}
                      />
                    </div>
                    {checkedFile.documentoDvr ? (
                      <Button className="mw200" onClick={() => getFile("dvr")}>
                        Download
                      </Button>
                    ) : (
                      <p style={{ color: "red" }}>Nessun file selezionato</p>
                    )}
                  </div>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item key="5" value="5">
                <Accordion.Control>
                  <h2>DICHIARAZIONE 445</h2>
                </Accordion.Control>
                <Accordion.Panel>
                  <div className="grid-container repeat2File pl0 center">
                    <div className="form-group">
                      <div className="exclamation">
                        {isScadutaDichiarazione445(dataDichiarazione445) && (
                          <FontAwesomeIcon
                            icon={faExclamationCircle}
                            color={"orange"}
                            size={"lg"}
                          />
                        )}
                        <DatePicker
                          size="lg"
                          disabled
                          invalid={dataDichiarazione445 == ""}
                          locale="it"
                          value={dataDichiarazione445}
                          label="Data dichiarazione 445"
                          placeholder={"Nessuna data impostata"}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="card-label">Dichiarazione 445:</label>
                      {checkedFile.dichiarazione445 ? (
                        <Button
                          style={{ width: "100%", maxWidth: "500px" }}
                          onClick={() => getFile("dichia445")}
                        >
                          Download
                        </Button>
                      ) : (
                        <p style={{ color: "red" }}>Nessun file selezionato</p>
                      )}
                    </div>
                  </div>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item key="6" value="6">
                <Accordion.Control>
                  <h2>Poliza assicurativa RTC RCO</h2>
                </Accordion.Control>
                <Accordion.Panel>
                  <div className="form-group">
                    <div className="grid-container repeat2 pl0 center">
                      <div className="exclamation">
                        {isScaduto(dataScadenzaRTC) && (
                          <FontAwesomeIcon
                            icon={faExclamationCircle}
                            color={"orange"}
                            size={"lg"}
                          />
                        )}
                        <DatePicker
                          size="lg"
                          disabled
                          invalid={dataScadenzaRTC == ""}
                          locale="it"
                          value={dataScadenzaRTC}
                          label="Data poliza assicurativa RTC RCO"
                          placeholder={"Nessuna data impostata"}
                        />
                      </div>
                      <div style={{ color: "red" }}>
                        {checkedFile.polizzaAssicurativaRct ? (
                          <Button
                            className="mw400"
                            onClick={() => getFile("polizzarct")}
                          >
                            Download File
                          </Button>
                        ) : (
                          "Nessun file selezionato"
                        )}
                      </div>
                    </div>
                  </div>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item key="7" value="7">
                <Accordion.Control>
                  <h2>DURC online validità 4 mesi</h2>
                </Accordion.Control>
                <Accordion.Panel>
                  <div className="exclamation">
                    {isScaduto(dataDurc) && (
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        color={"orange"}
                        size={"lg"}
                      />
                    )}
                    <div className="form-group">
                      <label className="card-label">Data Scadenza</label>

                      <div className="grid-container repeat2 pl0 center ">
                        <DatePicker
                          size="lg"
                          disabled
                          locale="it"
                          invalid={dataDurc == ""}
                          value={dataDurc}
                          placeholder={"Nessuna data impostata"}
                        />
                        <div style={{ color: "red" }}>
                          {checkedFile.durc ? (
                            <Button
                              className="mw400"
                              onClick={() => getFile("durc")}
                            >
                              Download File
                            </Button>
                          ) : (
                            "Nessun file selezionato"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item key="8" value="8">
                <Accordion.Control>
                  <h2>Dipendenti e attestati</h2>
                </Accordion.Control>
                <Accordion.Panel>
                  <DipendentiList
                    dipendenti={dipendenti}
                    disabled={true}
                    getFile={getFile}
                  />
                  {dipendenti.length == 0 && (
                    <h4 style={{ color: "red" }}>
                      Nessun dipendente selezionato
                    </h4>
                  )}
                  {false ? (
                    <div className="grid-container repeat1 pl0 center">
                      <div
                        className="grid-container repeat2File pl0 center"
                        style={{ width: "fit-content", color: "red" }}
                      >
                        {fileAttestati &&
                          fileAttestati.length == 0 &&
                          "Nessun File aggiuntivo selezionato"}
                        {fileAttestati &&
                          fileAttestati.map((file) => (
                            <Button
                              className="mw400"
                              onClick={() =>
                                getFile("fileAggiuntivo", file.idFile)
                              }
                            >
                              {file.nome}
                            </Button>
                          ))}
                      </div>
                    </div>
                  ) : null}
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>

            <Divider style={{ marginTop: "30px", marginBottom: "60px" }} />
            {stabilimentiSelezionati.map((stabilimento, count) => (
              <div key={count}>
                <h1 style={{ color: "red" }}>{stabilimento}</h1>

                <h2 className="card-label">Sopralluogo MODULO 7</h2>
                <div className="form-group">
                  <div className="inline-item center" style={{ color: "red" }}>
                    {checkedFile.modulo7 ? (
                      <Button
                        style={{ width: "300px" }}
                        onClick={() => getFile("modulo7")}
                      >
                        DOWNLOAD
                      </Button>
                    ) : (
                      "Nessun file Selezionato"
                    )}
                  </div>
                </div>

                <h2 className="card-label">
                  Informativa sui Rischi e Regolamenti MODULO 05 (modulo IO
                  firmato)
                </h2>
                <div className="form-group">
                  <div className="inline-item center" style={{ color: "red" }}>
                    {checkedFile.infoRischiReg ? (
                      <Button
                        style={{ width: "300px" }}
                        onClick={() => getFile("inforischi")}
                      >
                        DOWNLOAD
                      </Button>
                    ) : (
                      "Nessun file selezionato"
                    )}
                  </div>
                </div>
                <h2 className="card-label">DUVRI</h2>
                <div className="form-group">
                  <div className="inline-item center" style={{ color: "red" }}>
                    {checkedFile.duvri ? (
                      <Button
                        style={{ width: "300px" }}
                        onClick={() => getFile("duvri")}
                      >
                        DOWNLOAD
                      </Button>
                    ) : (
                      "Nessun file selezionato"
                    )}
                  </div>
                </div>
              </div>
            ))}
          </form>
          {stabilimentiSelezionati.length > 0 && (
            <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
          )}
          {idAppaltoPadre === undefined ? (
            <div>
              <h2 className="card-label mt20">Subappalti</h2>
              <Table
                striped
                style={{ marginBottom: "50px" }}
                captionSide="bottom"
              >
                {subappalti.length == 0 && (
                  <caption>Nessun elemento da visualizzare</caption>
                )}
                <thead>
                  <tr>
                    <th>Nome azienda</th>
                    <th>Indirizzo</th>
                    <th>PEC</th>
                    <th>Data inserimento</th>
                    <th>Stabilimento</th>
                    <th>Azione</th>
                  </tr>
                </thead>
                <tbody>
                  {subappalti.map((subappalto) => (
                    <tr key={subappalto.idAssociazione}>
                      <td>{subappalto.nomeAzienda}</td>
                      <td>{subappalto.indirizzoAzienda}</td>
                      <td>{subappalto.pecAzienda}</td>
                      <td>
                        {dayjs(subappalto.timestampCreazione).format(
                          "DD/MM/YYYY"
                        )}
                      </td>
                      <td>{subappalto.stabilimento}</td>
                      <td>
                        <Button
                          onClick={() => {
                            getInfoAppalto(subappalto.idSubappalto);
                            changePage("/form/" + subappalto.idSubappalto, {
                              state: { idAppaltoPadre: id },
                            });
                          }}
                          color="blue"
                        >
                          Visualizza
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
            </div>
          ) : null}
          <h2 className="card-label">Ulteriori Note</h2>
          <Textarea
            disabled
            value={note || ""}
            onChange={(e) => setNote(e.target.value)}
            error={note == ""}
            placeholder="Nessuna nota presente"
            radius="md"
            size="lg"
            required
          />
          <div
            className="grid-container repeat4 pl0 center "
            style={{ marginTop: "40px", marginBottom: "30px" }}
          >
            <Button
              leftIcon={
                <FontAwesomeIcon icon={faArrowLeftRotate} size={"lg"} />
              }
              color={"blue"}
              style={{ width: "100%" }}
              onClick={tornaIndietro}
            >
              Indietro
            </Button>
            {idAppaltoPadre === undefined ? (
              <>
                <Button
                  color={"green"}
                  leftIcon={<FontAwesomeIcon icon={faCheck} size={"lg"} />}
                  style={{ width: "100%" }}
                  onClick={accettaAppalto}
                  disabled={stato == "APPROVATO" || !modificaAbilitata}
                >
                  Conferma
                </Button>

                <Button
                  leftIcon={<FontAwesomeIcon icon={faInfoCircle} size={"lg"} />}
                  color={"orange"}
                  disabled={!modificaAbilitata}
                  style={{ width: "100%" }}
                  onClick={() => setOpenInformazioniAggiuntive(true)}
                >
                  Richiedi informazioni
                </Button>
                <Button
                  color={"red"}
                  style={{ width: "100%" }}
                  leftIcon={<FontAwesomeIcon icon={faTimes} size={"lg"} />}
                  onClick={rifiutaAppalto}
                  disabled={stato == "NON APPROVATO" || !modificaAbilitata}
                >
                  Rifiuta
                </Button>
              </>
            ) : null}
          </div>
        </>
      )}
      {idAppaltoPadre === undefined ? (
        <div>
          <h2 style={{ marginTop: "100px", marginBottom: "20px" }}>Storico</h2>
          <Table striped style={{ marginBottom: "50px" }} captionSide="bottom">
            {storicoAppalti.length == 0 && (
              <caption>Nessun elemento da visualizzare</caption>
            )}
            <thead>
              <tr>
                <th></th>
                <th>Stato</th>
                <th>Data</th>
                <th>Stabilimento</th>
                <th>Azioni</th>
              </tr>
            </thead>
            <tbody>
              {storicoAppalti.map((appalto) => (
                <tr key={appalto.id}>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: appalto.id == id ? "yellow" : "black",
                    }}
                  >
                    {appalto.id == id && (
                      <FontAwesomeIcon
                        icon={faArrowAltCircleRight}
                        color="green"
                      />
                    )}
                  </td>
                  <td>
                    <ColorSwatch
                      color={
                        appalto.stato == "A_APPROVATO"
                          ? "green"
                          : appalto.stato == "C_IN_VERIFICA"
                          ? "gray"
                          : "red"
                      }
                    />
                  </td>
                  <td>
                    {fromAmericaToItalia(appalto.dataCreazione.split("T")[0])}
                  </td>
                  <td>{appalto.nomeStabilimento}</td>
                  <td>
                    <Button
                      onClick={() => {
                        getInfoAppalto(appalto.id);
                        changePage("/form/" + appalto.id);
                      }}
                    >
                      Visualizza
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : null}
      {idAppaltoPadre === undefined ? (
        <div>
          <h2 style={{ marginTop: "50px", marginBottom: "20px" }}>
            Richieste Aggiuntive
          </h2>
          <Table striped style={{ marginBottom: "50px" }} captionSide="bottom">
            {richiesteAggiuntive.length == 0 && (
              <caption>Nessun elemento da visualizzare</caption>
            )}
            <thead>
              <tr>
                <th></th>
                <th>Data</th>
                <th>Stabilimento</th>
                <th>Informazioni Aggiuntive</th>
              </tr>
            </thead>
            <tbody>
              {richiesteAggiuntive.map((richiesta, count) => (
                <tr key={richiesta.id}>
                  <td>{count + 1}</td>
                  <td>
                    {fromAmericaToItalia(richiesta.dataCreazione.split("T")[0])}
                  </td>
                  <td>{richiesta.nomeStabilimento}</td>
                  <td>
                    <ul className="elenco_richiete_aggiuntive">
                      {richiesta.infoRichieste != null &&
                        richiesta.infoRichieste
                          .split("\n")
                          .map((richiesta) => <li>{richiesta}</li>)}
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : null}

      <Modal
        opened={openInformazioniAggiuntive}
        size={"lg"}
        onClose={() => {
          setTestoInformazioniAggiuntive([]);
          nuovoMessaggioRef.current.value = "";
          setOpenInformazioniAggiuntive(false);
        }}
        centered
        title={<h2>Inserisci messaggio da inviare</h2>}
      >
        <div>
          <div className="container_nuovo_messaggio">
            <div className="w100">
              <TextInput ref={nuovoMessaggioRef} />
            </div>
            <Button onClick={aggiungiNuovoMessaggio}>Aggiungi</Button>
          </div>
          <div className="container_testo_aggiungo_informazioni_aggiuntive">
            <ul>
              {testoInformazioniAggiuntive.map((testo) => (
                <li>
                  {testo}{" "}
                  <FontAwesomeIcon
                    icon={faTimes}
                    className={"pointer"}
                    onClick={() => rimuoviTesto(testo)}
                    color={"red"}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className="mt30 buttonConfermaContainer">
            <Button
              onClick={() => {
                setTestoInformazioniAggiuntive([]);
                nuovoMessaggioRef.current.value = "";
                setOpenInformazioniAggiuntive(false);
              }}
              color={"orange"}
              fullWidth
            >
              Annulla
            </Button>
            <Button
              disabled={testoInformazioniAggiuntive.length == 0}
              onClick={() =>
                inviaInformazioniAggiuntive(appaltoSelected.current)
              }
              color={"green"}
              fullWidth
            >
              Conferma
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
