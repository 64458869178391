import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { successNotification, errorNotification } from "./Notification";
import { ToastContainer, Flip } from "react-toastify";
import { useLocation } from "react-router-dom";
import { Button, Tabs, Input, PasswordInput, Alert } from "@mantine/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import cookie from "js-cookie";
export default function AdminDashboard({ setMostraNavbar }) {
  const { state } = useLocation();

  const [activeTab, setActiveTab] = useState("3");
  const [opened, setOpened] = useState(false);
  const [pass, setPass] = useState("");
  const [confermaPass, setConfermaPass] = useState("");
  const [denominazione, setDenominazione] = useState("");
  const [pec, setPec] = useState("");
  const [indirizzo, setIndirizzo] = useState("");
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setMostraNavbar(true);
  }, []);
  let strongPassword = new RegExp(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
  );
  let mediumPassword = new RegExp(
    "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
  );
  //gestisce il change delle tab
  const onChange = (active, tabKey) => {
    setActiveTab(active);
  };
  //Gestisce il change delle pagine
  const navigate = useNavigate();
  const changePage = (to) => {
    navigate(to);
  };
  useEffect(() => {
    if (
      !cookie.get("status") ||
      cookie.get("status") == "ROLE_ADMINISTRATOR" ||
      cookie.get("status") == "ROLE_APPROVER"
    ) {
      navigate("/login");
    } else getInfoProfilo();
  }, []);

  const getInfoProfilo = async () => {
    const url = "https://apot.mondorevive.com/api/appaltoreInfoUpdate";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      //dire ok
      const data = await response.json();
      setDenominazione(data.nome);
      setPec(data.mailPec);
      setIndirizzo(data.indirizzo);
      setUsername(data.username);
    } else {
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  //Funzione che effettua la modifica della password dell`account
  const modificaDati = async () => {
    const url = "https://apot.mondorevive.com/api/updateContractor";
    let payload;
    if (pass != "" && denominazione != "")
      payload = {
        mailPec: pec,
        nome: denominazione,
        indirizzo: indirizzo,
        password: pass,
      };
    else
      payload = {
        mailPec: pec,
        nome: denominazione,
        indirizzo: indirizzo,
        password: null,
      };

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookie.get("token"),
      },
    });
    if (response.status == 200) {
      //dire ok
      successNotification("Dati modificati con successo");
    } else {
      //DARE ERROR
      errorNotification("Impossibile modificare i dati, riprova più tardi");
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  function handleSkip(e) {
    const { name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");
    let fieldIntIndex = parseInt(fieldIndex, 10);
    if (e.key === "Enter") {
      const nextfield = document.querySelector(
        `input[name=field-${fieldIntIndex + 1}]`
      );
      // If found, focus the next field
      if (nextfield !== null) {
        nextfield.focus();
      } else {
      }
    }
  }
  function StrengthChecker() {
    let strengthBar = document.getElementById("signup-strength-bar");
    let lunghezzaBarra = 0;
    //Controllo la sicurezza della pass
    if (strongPassword.test(pass)) {
      /**PASSWORD STRONG */
      lunghezzaBarra = 4;
    } else {
      /**PASSWORD DEBOLE */
      lunghezzaBarra = 1;
      setError("Password Debole");
    }
    strengthBar.innerHTML = "";
    for (let i = 0; i < lunghezzaBarra; i++) {
      let span = document.createElement("span");
      if (i == 0) span.classList.add("firstSpan");
      if (i == 3) span.classList.add("lastSpan");
      span.classList.add("signup-strength");
      strengthBar.appendChild(span);
    }
    let spanRef = document.getElementsByClassName("signup-strength");
    for (let i = 0; i < spanRef.length; i++) {
      switch (spanRef.length - 1) {
        case 0:
          spanRef[i].style.background = "#ff3e36";
          break;
        case 1:
          spanRef[i].style.background = "#ff691f";
          break;
        case 2:
          spanRef[i].style.background = "#ffda36";
          break;
        case 3:
          spanRef[i].style.background = "#0be881";
          break;
      }
    }
  }

  useEffect(() => {
    if (pass != confermaPass) {
      setError("pass");
    } else setError("");
    const timeOutId = setTimeout(() => StrengthChecker(), 500);
    return () => clearTimeout(timeOutId);
  }, [pass]);
  useEffect(() => {
    if (pass != confermaPass) {
      setError("pass");
      return;
    } else setError("");
  }, [confermaPass]);
  return (
    <div className="form-container min100">
      <ToastContainer
        transition={Flip}
        newestOnTop
        rtl={false}
        pauseOnFocusLoss
      />

      <div className="container_profilo_utente">
        <h1>Cambia le impostazioni del tuo Account</h1>
        <h2>Modifica la tua password</h2>
        <div className="grid-container repeat2File pl0 center">
          <PasswordInput
            size="lg"
            value={pass}
            name="field-1"
            onKeyUp={handleSkip}
            onChange={(e) => setPass(e.target.value)}
            placeholder="Password"
            radius="md"
            className={"provass"}
            required
          />
          <PasswordInput
            size="lg"
            name="field-2"
            onKeyUp={handleSkip}
            className={"provass"}
            value={confermaPass}
            onChange={(e) => setConfermaPass(e.target.value)}
            placeholder="Conferma Password"
            radius="md"
            required
          />
        </div>
        <div id="signup-strength-bar"></div>
        {error == "pass" && (
          <div style={{ maxWidth: "100% !important", marginTop: "10px" }}>
            <Alert
              color={"gray"}
              icon={<FontAwesomeIcon icon={faInfoCircle} color="blue" />}
            >
              Le password non corrispondono
            </Alert>
          </div>
        )}
        {error == "Password Debole" && (
          <div style={{ maxWidth: "100% !important", marginTop: "10px" }}>
            <Alert
              color={"gray"}
              icon={<FontAwesomeIcon icon={faInfoCircle} color="blue" />}
            >
              La password deve contenere almeno 8 caratteri di cui una maiuscola
              un carattere speciale e un numero,
            </Alert>
          </div>
        )}

        <div className="grid-container repeat2File pl0 center">
          <div>
            <h2>Nome Azienda</h2>
            <div className="inline-item center">
              <Input
                type="text"
                value={denominazione}
                onChange={(e) => setDenominazione(e.target.value)}
                name="field-3"
                radius="md"
                onKeyUp={handleSkip}
                size="lg"
                placeholder="Nome Azienda"
                spellCheck="false"
                autoComplete="off"
                id="cardHolder21"
                className="form-control "
              />
            </div>
          </div>
          <div>
            <h2>PEC</h2>
            <div className="inline-item center">
              <Input
                type="text"
                value={pec}
                onChange={(e) => setPec(e.target.value)}
                name="field-4"
                radius="md"
                size="lg"
                onKeyUp={handleSkip}
                placeholder="PEC"
                spellCheck="false"
                autoComplete="off"
                id="cardHolder21"
                className="form-control "
              />
            </div>
          </div>
        </div>

        <h2>Indirizzo</h2>
        <div className="inline-item center">
          <Input
            type="text"
            value={indirizzo}
            onChange={(e) => setIndirizzo(e.target.value)}
            name="field-5"
            onKeyUp={handleSkip}
            radius="md"
            size="lg"
            placeholder="Indirizzo"
            spellCheck="false"
            autoComplete="off"
            id="cardHolder21"
            className="form-control "
          />
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Button
            disabled={
              (pass == "" && confermaPass == "" && denominazione == "") ||
              pass != confermaPass
            }
            radius="md"
            className="mw200"
            color={"green"}
            onClick={modificaDati}
          >
            Salva
          </Button>
        </div>
      </div>
    </div>
  );
}
