import React from "react";
import { Container, TextInput, Modal, Checkbox } from "@mantine/core";
import { useEffect } from "react";
import { useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faMinus,
  faPen,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, Flip, toast } from "react-toastify";
import { useCallback } from "react";
import { useRef } from "react";
import Tabella from "./Tabella";
import { fromAmericaToItalia, getPossoModificare } from "../Utilities";
import { Button, HoverCard } from "@mantine/core";
import cookie from "js-cookie";
let modificaAbilitata = getPossoModificare();
export default function PageGestione() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const clienti = useRef();
  //const [clienti, setClienti] = useState([]);
  const [rowHTML, setRowHTML] = useState([]);
  const [row, setRow] = useState([]);
  const [rowNonModificate, setRowNonModificate] = useState([]);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [numeroPagina, setNumeroPagina] = useState(0);
  const [ordinamento, setOrdinamento] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [stringaDaCercare, setStringaDaCercare] = useState("");
  const isPrimaRicerca = useRef(true);
  const isPrimo = useRef(true);
  const lastStringaCercata = useRef();
  const [voglioModificareAppaltatore, setVoglioModificareAppaltatore] =
    useState(false);
  const [appaltatoreDaModificare, setAppaltatoreDaModificare] = useState(false);
  useEffect(() => {
    getDati(0);
    modificaAbilitata = getPossoModificare();
  }, []);
  const getDati = async (page, text = false) => {
    setLoading(true);
    setNumeroPagina(page);
    let url = "";
    if (text)
      url =
        "https://apot.mondorevive.com/api/appaltatori?page=" +
        page +
        "&text=" +
        text.replace("#", "@");
    else url = "https://apot.mondorevive.com/api/appaltatori?page=" + page;
    let arr = [];
    if (!isPrimo.current) {
      Object.keys(ordinamento).map((key) => {
        if (ordinamento[key])
          arr.push({ attributo: key, direzione: ordinamento[key] });
      });
    }
    const response = await fetch(url, {
      body: JSON.stringify(arr),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("token"),
      },
    });
    if (response.status == 200) {
      const data = await response.json();
      setLoading(false);
      clienti.current = data;
      setTabella(data.content);
      setRow(data.content);
      setRowNonModificate(data.content);
      setHasNext(data.next);
      setHasPrevious(data.previous);
      if (isPrimo.current) {
        let obj = {};
        let arr2 = [];
        if (data.content.length == 0) return;
        Object.keys(data.content[0]).map((key) => {
          arr2.push(key.split(/(?=[A-Z])/).join(" "));
        });
        setHeaders(arr2);
        setOrdinamento(obj);
      }
      if (isPrimo.current) isPrimo.current = false;
    } else {
      setLoading(false);
    }
  };
  //Funzione che ottiene lo stato corrente di un ordinamento
  const ottieniOrdinamentoCorrenteObj = (key) => {
    return ordinamento[key];
  };
  const changeOrdinamento = (header) => {
    const headerCliccato = header.replace(" ", "");
    let ordina = ottieniOrdinamentoCorrenteObj(headerCliccato);
    if (ordina == null) {
      if (modificaOrdinamenti(headerCliccato, "asc")) {
        getDati(numeroPagina);
      }
    } //Direzione da null a ASC
    if (ordina == "asc") {
      if (modificaOrdinamenti(headerCliccato, "desc")) getDati(numeroPagina);
    } //Direzione da asc a DESC
    if (ordina == "desc") {
      if (modificaOrdinamenti(headerCliccato, null)) getDati(numeroPagina);
    } //Direzione da desc a null
  };
  const modificaOrdinamenti = (headerCliccato, value) => {
    if (value == null) {
      let obj = {};
      Object.keys(ordinamento).map((key) => {
        if (key != headerCliccato) obj[key] = ordinamento[key];
      });
      setOrdinamento(obj);
    }
    ordinamento[headerCliccato] = value;
    return true;
  };
  //Funzione che elimina un appaltatore
  const deleteAppaltatore = async (id) => {
    const url = "https://apot.mondorevive.com/api/appaltatore?id=" + id;
    const response = await toast.promise(
      fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + cookie.get("token"),
        },
      }),
      {
        pending: "Eliminazione in corso",
        success: {
          render() {
            return `Appaltatore eliminato correttamente`;
          },
          autoClose: 500,
        },
        error: {
          render() {
            return `Errore nell'eliminazione dell'appaltatore, riprova più tardi!`;
          },
          autoClose: 1000,
        },
      }
    );
    if (response.status == 200) {
      getDati(numeroPagina, stringaDaCercare);
    } else {
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  //Funzione che salva un appaltatore
  const salvaAppaltatore = async (value) => {
    const url = "https://apot.mondorevive.com/api/updateAppaltatore";
    const response = await toast.promise(
      fetch(url, {
        method: "POST",
        body: JSON.stringify(value),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + cookie.get("token"),
        },
      }),
      {
        pending: "Salvataggio in corso",
        success: {
          render() {
            return `Appaltatore salvato correttamente`;
          },
          autoClose: 500,
        },
        error: {
          render() {
            return `Errore nel salvataggio dell'appaltatore, riprova più tardi!`;
          },
          autoClose: 1000,
        },
      }
    );
    if (response.status == 200) {
      getDati(numeroPagina, stringaDaCercare);
    } else {
      const data = await response.json();
      //DARE ERROR
      if (response.status == 401 || response.status == 403) {
        cookie.remove("token");
        cookie.remove("status");
        navigate("/login");
      }
    }
  };
  //Funzione che modifica un valore di un campo di un appaltatore
  const changeValoriAppaltatore = async (value, tipo) => {
    setAppaltatoreDaModificare({ ...appaltatoreDaModificare, [tipo]: value });
  };
  //Funzione che salva le modifiche di un appaltatore
  const salvaModificaAppaltatore = () => {
    let obj = { ...clienti.current };
    let arr = [];
    clienti.current.content.map((cliente) => {
      if (cliente.id == appaltatoreDaModificare.id) {
        arr.push(appaltatoreDaModificare);
      } else arr.push(cliente);
    });
    obj.content = arr;
    clienti.current = obj;
    salvaAppaltatore(appaltatoreDaModificare);
    setTabella(arr);
    setVoglioModificareAppaltatore(false);
    setAppaltatoreDaModificare({});
  };
  //Funzione che dalla colonna restituisce l`html corretto
  const fromTipoToHTML = (tipo, value, id) => {
    if (tipo == "bloccato") return value ? "SI" : "NO";
    if (tipo == "timestampBloccato") return fromAmericaToItalia(value);

    return (
      <>
        {value}
        {/* <TextInput
					value={value}
					size={"md"}
					onChange={(e) => changeValoriAppaltatore(tipo, e.target.value, id)}
				/> */}
      </>
    );
  };
  //Funzione che imposta le righe di una tabella
  const setTabella = useCallback((data) => {
    let value;
    setStringaDaCercare((stringaDaCercare) => {
      value = stringaDaCercare;
      return stringaDaCercare;
    });
    setRowHTML(() => {
      let rowHTML = data.map((riga, count) => (
        <tr key={riga.id}>
          <td className="mb-0 text-sm">{count + 1}</td>
          {Object.keys(riga).map((obj) => (
            <>
              {obj != "id" && (
                <td className="mb-0 text-sm" key={riga[obj]}>
                  <>{fromTipoToHTML(obj, riga[obj], riga.id)}</>
                </td>
              )}
            </>
          ))}
          <td>
            <Button
              className={"pointer mr15"}
              color={"blue"}
              disabled={!modificaAbilitata}
              leftIcon={<FontAwesomeIcon icon={faPen} size={"lg"} />}
              onClick={() => {
                setVoglioModificareAppaltatore(true);
                setAppaltatoreDaModificare(riga);
              }}
            >
              Modifica
            </Button>
            <Button
              className={"pointer mr15"}
              color={"red"}
              disabled={!modificaAbilitata}
              leftIcon={<FontAwesomeIcon icon={faTrash} size={"lg"} />}
              onClick={() => {
                deleteAppaltatore(riga.id);
              }}
            >
              Elimina
            </Button>
          </td>
        </tr>
      ));
      return rowHTML;
    });
  }, []);
  //Header tabella
  const ths = (
    <tr>
      <th>Pos</th>
      {headers.map((header) => (
        <>
          {header != "id" && (
            <th
              className="pointer"
              key={header}
              onClick={() => changeOrdinamento(header)}
            >
              {header}
              {!ordinamento[header.replace(" ", "")] ? (
                <>
                  <FontAwesomeIcon
                    style={{ marginLeft: "10px" }}
                    icon={faMinus}
                    color={"lightGray"}
                  />
                </>
              ) : (
                <FontAwesomeIcon
                  style={{ marginLeft: "10px" }}
                  icon={
                    ordinamento[header.replace(" ", "")] == "asc"
                      ? faArrowUp
                      : faArrowDown
                  }
                  color={
                    ordinamento[header.replace(" ", "")] == "asc"
                      ? "green"
                      : "red"
                  }
                />
              )}
            </th>
          )}
        </>
      ))}
      <th>Azioni</th>
    </tr>
  );
  useEffect(() => {
    if (isPrimaRicerca.current) {
      isPrimaRicerca.current = false;
      return;
    }
    if (stringaDaCercare != lastStringaCercata.current)
      lastStringaCercata.current = stringaDaCercare;
    else return;
    const timeOutId = setTimeout(() => getDati(0, stringaDaCercare), 500);
    return () => clearTimeout(timeOutId);
  }, [stringaDaCercare]);

  return (
    <Container className="mt--7 containerMantine" fluid>
      <ToastContainer
        transition={Flip}
        newestOnTop
        rtl={false}
        pauseOnFocusLoss
      />
      <h2 className="mt20">Gestione Appaltatori</h2>
      <div className="mb-5 mb-xl-0 ">
        <Tabella
          getDati={getDati}
          setStringaDaCercare={setStringaDaCercare}
          stringaDaCercare={stringaDaCercare}
          numeroPagina={numeroPagina}
          hasPrevious={hasPrevious}
          hasNext={hasNext}
          rowHTML={rowHTML}
          ths={ths}
          loading={loading}
          labelButton={"Nuovo Cliente"}
        />
      </div>
      <Modal
        title={<h3>Modifica appaltatore - {appaltatoreDaModificare.nome}</h3>}
        transition="fade"
        size="xl"
        centered
        opened={voglioModificareAppaltatore}
        onClose={() => {
          setVoglioModificareAppaltatore(false);
          setAppaltatoreDaModificare({});
        }}
        transitionDuration={600}
        transitionTimingFunction="ease"
      >
        <div className="containerText">
          <TextInput
            label={"Username"}
            disabled={true}
            value={appaltatoreDaModificare.username}
            onChange={(e) => {
              changeValoriAppaltatore(e.target.value, "username");
            }}
          />
          <TextInput
            label={"Nome"}
            value={appaltatoreDaModificare.nome}
            onChange={(e) => {
              changeValoriAppaltatore(e.target.value, "nome");
            }}
          />
          <TextInput
            label={"Indirizzo"}
            value={appaltatoreDaModificare.indirizzo}
            onChange={(e) => {
              changeValoriAppaltatore(e.target.value, "indirizzo");
            }}
          />
          <TextInput
            label={"Mail PEC"}
            value={appaltatoreDaModificare.mailPec}
            onChange={(e) => {
              changeValoriAppaltatore(e.target.value, "mailPec");
            }}
          />
          <Checkbox
            checked={appaltatoreDaModificare.bloccato}
            label={"Bloccato?"}
            size={"lg"}
            onChange={(e) => {
              changeValoriAppaltatore(e.target.checked, "bloccato");
            }}
          />
          <Checkbox
            checked={appaltatoreDaModificare.bloccatoPermanentemente}
            label={"Black List?"}
            size={"lg"}
            onChange={(e) => {
              changeValoriAppaltatore(
                e.target.checked,
                "bloccatoPermanentemente"
              );
            }}
          />
        </div>
        <div className="buttonConfermaContainer">
          <Button
            fullWidth
            color={"orange"}
            onClick={() => {
              setVoglioModificareAppaltatore(false);
              setAppaltatoreDaModificare({});
            }}
          >
            Annulla
          </Button>
          <Button fullWidth color={"green"} onClick={salvaModificaAppaltatore}>
            Conferma
          </Button>
        </div>
      </Modal>
    </Container>
  );
}
